import {Component, ViewChild} from '@angular/core';
import {SessionService} from '../../shared/session/session.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';

import {ActivatedRoute, Router} from '@angular/router';
import {SmartModalService} from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import {SmartFormBuilder} from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import {SmartFormComponent} from '../../shared/marketpartner-components/smart-forms/smart-form/component/smart-form.component';
import {SmartModalBuilder} from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import {MarketpartnerViewComponent} from '../shared/marketpartner-view.component';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import {ExternalFtpAccount, LocalFtpAccount} from '../../shared/session/MarketPartnerInfo';
import {orderBy} from "lodash-es";
import {EnumItem} from "../../shared/services/enum-utils/classes/enum-definition";


@Component({
  selector: 'page-users-marketpartner-communication-ftp-content',
  templateUrl: './users-marketpartner-communication-ftp.component.html',
  styleUrls: ['../users-common.scss']
})


export class MarketpartnerCommunicationFtpComponent extends MarketpartnerViewComponent {
  @ViewChild('ftpYourOwnServerCheckForm') ftpYourOwnServerCheckForm?: SmartFormComponent;
  @ViewChild('serverAtDatahHubCheckForm') serverAtDatahHubCheckForm?: SmartFormComponent;

  initialized = false;
  isYourOwnServerSelected = false;
  localFtpServer = '';

  sharedFtpAccounts: LocalFtpAccount[] = [];

  ftpAvailableForSharing: LocalFtpAccount[] | undefined;

  constructor(public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              public apiRequestService: ApiRequestService,
              public smartModalService: SmartModalService,
              public router: Router) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    if (this.marketPartnerInfo) {
      this.isYourOwnServerSelected = this.marketPartnerInfo.communicationData.receiveMessageLocation === 'EXTERNAL';
    }
    await this.loadPartnerInfo();
    this.localFtpServer = this.sessionService.getLocalFtpServer();
    this.sharedFtpAccounts = this.marketPartnerInfo?.communicationData.sharedFtpAccounts || [];
    this.setInitialized(true);
  }

  get hasFromDatahubWarning(): boolean {
    return this.ftpWarnings.fromDatahubWarning;
  }

  get hasToDatahubWarning(): boolean {
    if (this.hasFromDatahubWarning) return false;
    return this.ftpWarnings.toDatahubWarning;
  }

  get listInternalFTPAccounts(): string[] {
    if (!this.marketPartnerInfo) return [];
    const accounts = this.marketPartnerInfo.communicationData.localFtpAccounts;
    return accounts.map(a => a.username).sort();
  }

  get hasDefaultExternalAccount(): boolean {
    if (!this.marketPartnerInfo) return false;
    let accounts = this.marketPartnerInfo.communicationData.externalFtpAccounts;
    accounts = accounts.filter(acc => !acc.senderEic);
    return accounts.length > 0;
  }

  get allowAdditionalExternalAccounts(): boolean {
    if (!this.marketPartnerInfo) return false;
    // yes this logic is correct (not inverted)
    return this.marketPartnerInfo.partnerSettings.contractStatus === 'NO_CONTRACT';
  }

  get allowChanges(): boolean {
    return !this.isOperatorMode;
  }

  get sortedExternalAccounts(): ExternalFtpAccount[] {
    if (!this.marketPartnerInfo) return [];
    const accounts = this.marketPartnerInfo.communicationData.externalFtpAccounts;
    // move default account (no senderEic) to bottom (if present)
    return orderBy(accounts.slice(), ['senderEic'], ['desc']);
  }

  toggleOptionCheckboxFtp() {
    if (this.marketPartnerInfo) {
      this.isYourOwnServerSelected = !this.isYourOwnServerSelected;
      if (this.isYourOwnServerSelected) {
        this.marketPartnerInfo.communicationData.receiveMessageLocation = 'EXTERNAL';
      } else {
        this.marketPartnerInfo.communicationData.receiveMessageLocation = 'LOCAL';
      }

      this.sendRequestUpdateReceiveMessageLocation();
    }
  }

  async sendRequestUpdateReceiveMessageLocation() {
    if (this.marketPartnerInfo && this.partnerId) {
      const apiMarketPartnerConfig = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerReceiveMessageLocationUpdate,
          {partnerId: this.partnerId})
        .setBodyInfo({
          receiveMessageLocation: this.marketPartnerInfo.communicationData.receiveMessageLocation
        })
        .setHandleLoading(true)
        .setHandleErrorNotification(true)
        .build();
      await this.apiRequestService.callApi(apiMarketPartnerConfig);
      await this.loadPartnerInfo();
    }
  }

  async generateNewFTPAccess() {
    if (this.marketPartnerInfo && this.partnerId) {
      const apiMarketPartnerConfig = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerInternalAccountCreate, {partnerId: this.partnerId})
        .build();
      const resultApiRequest = await this.apiRequestService.callApi(apiMarketPartnerConfig);
      if (resultApiRequest.status === 'success') {
        const modalOptions = new SmartModalBuilder()
          .setTitle('CommunicationsDialogNewPasswordGenerated')
          .setSpecialModalFromInfo({
            type: 'ftppassword',
            data: {
              server: this.localFtpServer,
              password: resultApiRequest.data.password,
              username: resultApiRequest.data.username,
            }
          })
          .setModalCSSClassSize('md')
          .showConfirmButton(false)
          .showCancelButton(true, 'ModalCloseButton')
          .build();

        await this.smartModalService.showModal(modalOptions);
        await this.loadPartnerInfo();
      }
    }
  }

  async addNewFTPCredentials() {
    if (!this.partnerId) return;
    const formConfig = new SmartFormBuilder()
      .addFormFieldFromInfo({
        name: 'senderEic', type: 'input',
        title: 'FormEIC', cssClasses: 'col-sm-6',
        validation: {
          type: 'eic',
          required: true,
        },
      })
      .addFormFieldMarketPartnerSenderRole()
      .addFormFieldFTPAddress()
      .addFormFieldFTPDirectory()
      .addFormFieldFTPUsername()
      .addFormFieldFTPPassword()
      .setApiSendDataRequestConfigFromInfo(
        new ApiRequestBuilder()
          .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerExternalAccountCreate,
            {partnerId: this.partnerId}).build()
      )
      .showSubmitButton(true)
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(() => this.loadPartnerInfo())
      .setTitle('CommunicationsTitleNewEntry')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();
    this.smartModalService.showModal(modalOptions);
  }

  async setFTPCredentials() {
    if (!this.partnerId) return;

    const formConfig = new SmartFormBuilder()
      .addFormFieldFTPAddress()
      .addFormFieldFTPDirectory()
      .addFormFieldFTPUsername()
      .addFormFieldFTPPassword()
      .setApiSendDataRequestConfigFromInfo(
        new ApiRequestBuilder()
          .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerExternalAccountCreate,
            {partnerId: this.partnerId}).build()
      )
      .showSubmitButton(true)
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(() => this.loadPartnerInfo())
      .setTitle('ButtonSetFTPCredentials')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  async editExternalFtpAccount(externalFtpAccount: ExternalFtpAccount) {
    if (!this.partnerId) return;
    const builder = new SmartFormBuilder()
      .setInitData(externalFtpAccount)
      .addRequired({name: 'id', type: 'hidden', default: this.partnerId});
    if (externalFtpAccount.senderEic) {
      builder
        .addFormFieldMarketPartnerSenderEICReadOnly()
        .addFormFieldMarketPartnerSenderRoleReadOnly();
    }
    builder
      .addFormFieldFTPAddress()
      .addFormFieldFTPDirectory()
      .addFormFieldFTPUsername()
      .addFormFieldFTPPassword()
      .setApiSendDataRequestConfigFromInfo(
        new ApiRequestBuilder()
          .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerExternalAccountUpdate,
            {partnerId: this.partnerId, id: externalFtpAccount.id}).build()
      )
      .showSubmitButton(true)
      .showCancelButton(true);

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(() => this.loadPartnerInfo())
      .setTitle('CommunicationsTitleEditEntry')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(builder.build())
      .build();

    this.smartModalService.showModal(modalOptions);

  }

  deleteExternalFtpAccount(externalFtpAccount: ExternalFtpAccount) {
    const confirmationDialog = this.smartModalService.getConfirmationDialogConfig('ButtonDelete');
    confirmationDialog.onSuccessFormValue = async () => {
      if (this.marketPartnerInfo && this.marketPartnerInfo.communicationData.externalFtpAccounts) {
        await this.sendRequestDeleteExternalAccount(externalFtpAccount.id);
        this.loadPartnerInfo();
      }
    };
    this.smartModalService.showModal(confirmationDialog);
  }

  async sendRequestDeleteExternalAccount(id: number) {
    if (this.marketPartnerInfo && this.partnerId) {
      const apiMarketPartnerConfig = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerExternalAccountDelete,
          {partnerId: this.partnerId, id})
        .setHandleLoading(true)
        .setHandleErrorNotification(true)
        .build();
      await this.apiRequestService.callApi(apiMarketPartnerConfig);
      await this.loadPartnerInfo();
    }
  }

  async removeInternalFTPAccess(internalFTPUsername: string) {
    if (this.marketPartnerInfo && this.partnerId) {
      const listShareUsersRequestConfig = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerIsFtpAccountShared)
        .setBodyInfo({ partnerId: this.partnerId, username: internalFTPUsername})
        .setHandleLoading(true)
        .setHandleErrorNotification(true)
        .build();
      const listShareUsersResult = await this.apiRequestService.callApi(listShareUsersRequestConfig);
      const userList = listShareUsersResult?.data?.partnerNames?.join('<br>');

      // Ask for confimration
      const confirmationDialog = this.smartModalService
        .getConfirmationDialogConfig('ButtonRemoveFTPAccess', userList ? 'AreYouSureConfirmationBodyFtpIsUsed' : undefined, userList);
      confirmationDialog.onSuccessFormValue = async () => {
        if (this.partnerId) {
          const apiMarketPartnerConfig = new ApiRequestBuilder()
            .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerInternalAccountDelete,
              {partnerId: this.partnerId, ftpUsername: encodeURIComponent(internalFTPUsername)})
            .build();
          const resultApiRequest = await this.apiRequestService.callApi(apiMarketPartnerConfig);
          if (resultApiRequest.status === 'success') {
            await this.loadPartnerInfo();
          }
        }
      };
      this.smartModalService.showModal(confirmationDialog);
    }
  }

  async listLocalFtpAccountsAvailableForSharing() {
    if (this.partnerId) {
      const request = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.listLocalFtpAccountsAvailableForSharing,
          {partnerId: this.partnerId}).build();
      const result = await this.apiRequestService.callApi(request);
      if (result.status === 'success') {
        this.ftpAvailableForSharing = result.data.localFtpAccounts;
      }
    }
  }

  async unshareSharedFtpAccount(username: string) {
    if (this.partnerId) {
      const request = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.unshareSharedFtpAccount)
        .setBodyInfo({ partnerId: this.partnerId, username: username}).build();
      const result = await this.apiRequestService.callApi(request);
      if (result.status === 'success') {
        await this.refreshSharedFtpAccounts();
        this.sharedFtpAccounts = this.marketPartnerInfo?.communicationData.sharedFtpAccounts || [];
      }
    }
  }

  async openShareFtpDialog() {
    if (!this.partnerId) return;

    const formConfig = new SmartFormBuilder()
      .setApiSendDataRequestConfigFromInfo(
        new ApiRequestBuilder()
          .setEndpointInfo(USERS_API_ENDPOINTS_LIST.shareLocalFtpAccount)
          .setBodyInfo({ partnerId: this.partnerId }).build()
      )
      .showSubmitButton(true)
      .showCancelButton(true);

    if(this.isOperatorMode) {
      formConfig
        .addFormFieldFromInfo({
          name: 'username', type: 'input',
          title: 'NewFTPShare.Account', cssClasses: 'col-sm-12',
          validation: {
            required: true,
          },
        });

    } else {
      await this.listLocalFtpAccountsAvailableForSharing();
      const ftpEnum: EnumItem<string>[] = this.ftpAvailableForSharing!.map(ftpAcc => ({label: ftpAcc.username, data: ftpAcc.username}));
      formConfig
        .addFormFieldFromInfo({
            name: 'username', type: 'select',
            title: 'NewFTPShare.Account', cssClasses: 'col-sm-12',
            validation: {
              required: true,
            },
            allowedValues: {
              type: 'localDefined',
              data: ftpEnum
            },
        });
    }

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(() => this.refreshSharedFtpAccounts().then(_ => this.sharedFtpAccounts = this.marketPartnerInfo?.communicationData.sharedFtpAccounts || []))
      .setTitle('NewFTPShare.Title')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig.build())
      .build();
    await this.smartModalService.showModal(modalOptions);
  }
}
