import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: []
})
export class SubmitButtonComponent {
  @Input() isSendingRequest = false;
  @Input() disabled: boolean = false;
  @Input() textKey: string = 'ButtonSave';
  @Input() color: "primary" | "accent" | "warn" = "primary";
  @Input() id: string = "submit";
  @Output() submit = new EventEmitter<void>();

  onClick() {
    this.submit.emit();
  }

}
