<div *ngIf="!refreshingModal" class="modal " tabindex="-1" role="dialog"
     id="{{currentModalId}}"
     [attr.data-backdrop]="dataBackdrop"
     [attr.data-keyboard]="dataKeyboard">

    <div *ngIf="!(hideContent)"
         [class.modal-invisible-content]="(invisibleContent)"
         class="{{'modal-dialog '+'modal-'+options.size + ((options && options.bckgNoWhite)?' modal-nonwhite-bckg ':' ') }} modal-dialog-centered"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal"><i class="pci-cross pci-circle"></i></button>
                <div *ngIf="(options && options.formConfig && options.formConfig.title)" class="modal-title">{{options.formConfig.title | translate}}</div>
                <div *ngIf="(!(options && options.formConfig && options.formConfig.title) && options.title)" class="modal-title">{{options.title | translate}}</div>
            </div>
            <div class="modal-body">
                <div *ngIf="(options && options.textModal)" [class.padding]="options.formConfig">
                  <span *ngIf="options.textAsWarning"><i class="warning fa fa-exclamation-triangle"></i></span>
                   {{ options.textModal | translate }}
                </div>
                <div *ngIf="(options && options.htmlModal)" [class.padding]="options.formConfig"
                     [innerHTML]="options.htmlModal">
                   <br *ngIf="options.formConfig"/>
                </div>

                <smart-form *ngIf="(options && options.formConfig)"
                            [isModal]="true"
                            (onSubmittedFormValue)="onSubmittedFormValue($event)"
                            (onActionClicked)="onActionFormValue($event)"
                            (onSuccessFormValue)="onSuccessFormValue($event)"
                            (onSuccessResponseValue)="onSuccessResponseValue($event)"
                            (onFieldValueChanged)="onFieldValueChanged($event)"
                            (onInitFormValue)="onInitFormValue($event)"
                            (onCancelForm)="cancelClicked()"
                            [formConfig]=options.formConfig></smart-form>
                <div *ngIf="(options && options.specialModal)">
                  <div *ngIf="(options.specialModal.type === 'ftppassword')" class="modal-body-container">
                    <p>
                      {{'CommunicationsDialogPasswordBody' | translate}}
                    </p>
                    <div style="text-align:center; margin-top: 30px;">
                      <div #containerclipboard1 style="font-size: 18px;">
                        <span style="margin-right: 10px; font-weight: bold;">{{'NewFTPAccess.TitleServer' | translate}}:</span>
                        <span > {{ options.specialModal.data.server }} </span>
                        <button ngxClipboard [cbContent]="options.specialModal.data.server" [container]="containerclipboard1" class="btn"><i class="pli-files"></i></button>
                      </div>
                      <div #containerclipboard2 style="font-size: 18px;">
                        <span style="margin-right: 10px; font-weight: bold;">{{'NewFTPAccess.TitleUsername' | translate}}:</span>
                        <span > {{ options.specialModal.data.username }} </span>
                        <button ngxClipboard [cbContent]="options.specialModal.data.username" [container]="containerclipboard2" class="btn"><i class="pli-files"></i></button>
                      </div>
                      <div #containerclipboard3 style="font-size: 18px;">
                        <span style="margin-right: 10px; font-weight: bold;">{{'NewFTPAccess.TitlePassword' | translate}}:</span>
                        <span > {{ options.specialModal.data.password }} </span>
                        <button ngxClipboard [cbContent]="options.specialModal.data.password" [container]="containerclipboard3" class="btn"><i class="pli-files"></i></button>
                      </div>

                    </div>
                  </div>
                  <div *ngIf="(options.specialModal.type === 'errorlist')">
                    <div *ngIf="options.specialModal.data.errors as items">
                      <h5 class="text-danger">{{'ErrorListTitle' | translate}}</h5>
                      <ul class="errorlist"><li *ngFor="let message of items">{{ message }}</li></ul>
                    </div>
                    <div *ngIf="options.specialModal.data.warnings as items">
                      <h5 class="text-warning">{{'WarningListTitle' | translate}}</h5>
                      <ul class="errorlist"><li *ngFor="let message of items">{{ message }}</li></ul>
                    </div>
                  </div>
                </div>
            </div>

            <div *ngIf="isAnyButtonVisible()" class="modal-footer" >
                <div *ngFor="let buttonInfo of options.buttons;" class="button-container">
                    <button *ngIf="buttonInfo.visible !== false"
                            class="{{buttonInfo.cssClasses}}"
                            [type]="buttonInfo.type ? buttonInfo.type : 'button'"
                            [class.btn-disabled]="disableAllActions"
                            (click)="actionButtonClick(buttonInfo)">
                        <i *ngIf="buttonInfo.icon" class="{{buttonInfo.icon}}"></i>
                        {{ buttonInfo.title | translate }}
                        <i *ngIf="(selectedActionId === buttonInfo.id)" class="fa fa-spinner fa-spin"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

