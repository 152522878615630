<page-loading *ngIf="!initialized"></page-loading>

<operator-sidebar-panel *ngIf="isOperatorMode"
                     [mpinfo]="marketPartnerInfo"
                     [upinfo]="companyPartnerInfo"
                     [activeViewMode]="'ftp'"></operator-sidebar-panel>

<div *ngIf="initialized && marketPartnerInfo" id="page-content"
     [class.page-content-with-sidebar]="isOperatorMode"
  >

  <div class="panel panel-bordered panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">{{'CommunicationsFTPConnection' | translate}}</h3>
    </div>

    <div class="panel-body">
      <div class="row">
        <div class="col-lg-6">
          <span class="text-danger" *ngIf="hasToDatahubWarning">{{'CommunicationsFTPFromYouToDatahubIncompleteWarning' | translate}}</span>
          <div class="category-container margin-bottom-30 vertical-separator-right">
            <div class="category-title main-title">
              {{'CommunicationsFromYouToDatahub' | translate}}
            </div>

            <div  class="category-group">
              <div class="category-line">
                <div class="category-line-key"> {{'CommunicationsFtpAddressDatahub' | translate}} </div>
              </div>
              <div class="category-line">
                <div class="category-line-value"> {{localFtpServer}}</div>
              </div>
            </div>

            <div class="category-title">
              {{'OwnFTPAccess' | translate}}
            </div>
            <div *ngFor="let thisInternalFTPAccount of listInternalFTPAccounts">
              <div  class="category-group">
                <div class="category-line">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="category-line">
                        <div class="category-line-key"> {{'CommunicationsUsername' | translate}} </div>
                      </div>
                      <div class="category-line-value"> {{thisInternalFTPAccount}} </div>
                    </div>
                    <div class="col-sm-6">
                      <button (click)="removeInternalFTPAccess(thisInternalFTPAccount)" class="btn-labeled  btn table-action-button btn-danger" style="margin-top: 12px;"><i class="btn-label pli-trash"></i>{{'ButtonRemoveFTPAccess' | translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="category-group">
              <div class="category-line">
                <div class="category-line-key"></div>
              </div>
              <button [disabled]="!allowChanges" (click)="generateNewFTPAccess()" class="btn btn-labeled btn-primary"><i class="btn-label fa fa-plus"></i>{{'ButtonGenerateNewFTPAccess' | translate}}</button>
            </div>

            <div class="category-container draw-vertical-separator-line separator-always-top-line">
              <div class="category-title">
                {{'SharedFTPAccess' | translate}}
              </div>
            </div>
            <div *ngFor="let thisSharedFTPAccount of sharedFtpAccounts">
              <div  class="category-group">
                <div class="category-line">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="category-line">
                        <div class="category-line-key"> {{'CommunicationsUsername' | translate}} </div>
                      </div>
                      <div class="category-line-value"> {{thisSharedFTPAccount.username}} </div>
                    </div>
                    <div class="col-sm-6">
                      <button (click)=" unshareSharedFtpAccount(thisSharedFTPAccount.username)" class="btn-labeled  btn table-action-button btn-danger" style="margin-top: 12px;"><i class="btn-label pli-trash"></i>{{'ButtonUnshareFTPAccess' | translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="category-group">
              <div class="category-line">
                <div class="category-line-key"></div>
              </div>
              <button (click)="openShareFtpDialog()" class="btn btn-labeled btn-primary"><i class="btn-label fa fa-plus"></i>{{'ButtonShareFTPAccess' | translate}}</button>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <span class="text-danger" *ngIf="hasFromDatahubWarning">{{'CommunicationsFTPFromDatahubToYouIncompleteWarning' | translate}}</span>
          <div class="category-container">
            <div class="category-title main-title" [class.text-error]="true" translate>
              {{'CommunicationsFromDatahubToYou' | translate}}
            </div>
            <div class="row">
              <div class="col-xs-12">
                <div class="category-container">
                  <div class="category-title">
                    <div class="category-select-checkbox">
                      <div class="category-select-checkbox-text">{{(isYourOwnServerSelected ? 'LabelSelected' : 'LabelSelect') | translate}}</div>

                      <input [disabled]="!allowChanges" id="ftp_checkbox_option_0" type="checkbox" value="0" class="magic-checkbox" (click)="toggleOptionCheckboxFtp()" [checked]="isYourOwnServerSelected">
                      <label for="ftp_checkbox_option_0"></label>

                    </div>
                    {{'CommunicationsFTPYourOwnServer' | translate}}
                  </div>
                  <div [class.panel-content-disabled]="!isYourOwnServerSelected">
                    <div *ngIf="marketPartnerInfo.communicationData.externalFtpAccounts">
                      <div *ngFor="let externalFtpAccount of sortedExternalAccounts;" class="category-group">
                        <hr/>
                        <div *ngIf="externalFtpAccount.senderEic">
                          <b>{{externalFtpAccount.senderEic}}</b>
                        </div>
                        <div *ngIf="!externalFtpAccount.senderEic">
                          <b *ngIf="!allowAdditionalExternalAccounts">{{'CommunicationsFtpServer' | translate}}</b>
                          <b *ngIf="allowAdditionalExternalAccounts">{{'CommunicationsDefaultFtpServer' | translate}}</b>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div>{{externalFtpAccount.hostname}}</div>
                            <div>{{externalFtpAccount.directory}}</div>
                            <div>{{externalFtpAccount.username}}</div>
                          </div>
                          <div class="col-md-6">
                            <button [disabled]="!allowChanges" (click)="editExternalFtpAccount(externalFtpAccount)" class="btn-labeled  btn table-action-button btn-mint"><i class="btn-label pli-pencil"></i>{{'ButtonEdit' | translate}}</button>
                            <button [disabled]="!allowChanges" *ngIf="externalFtpAccount.senderEic" (click)="deleteExternalFtpAccount(externalFtpAccount)" class="btn-labeled  btn table-action-button btn-danger"><i class="btn-label pli-trash"></i>{{'ButtonDelete' | translate}}</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button [disabled]="!allowChanges" *ngIf="!hasDefaultExternalAccount && !allowAdditionalExternalAccounts" (click)="setFTPCredentials()" class="btn btn-primary">{{'ButtonSetFTPCredentials' | translate}}</button>
                    <button [disabled]="!allowChanges" *ngIf="!hasDefaultExternalAccount && allowAdditionalExternalAccounts" (click)="setFTPCredentials()" class="btn btn-primary">{{'ButtonSetDefaultFTPCredentials' | translate}}</button>
                    <button [disabled]="!allowChanges" *ngIf="allowAdditionalExternalAccounts" (click)="addNewFTPCredentials()" class="btn-labeled btn btn-primary" style="display:block"><i class="btn-label fa fa-plus"></i>{{'ButtonSetNewFTPEntry' | translate}}</button>
                  </div>

                </div>
              </div>

              <div class="col-xs-12">
                <div class="category-container draw-vertical-separator-line separator-always-top-line">
                  <div class="category-title">
                    <div class="category-select-checkbox">
                      <div class="category-select-checkbox-text">{{(!isYourOwnServerSelected ? 'LabelSelected' : 'LabelSelect') | translate}}</div>

                      <input [disabled]="!allowChanges" id="ftp_checkbox_option_1" type="checkbox" value="1" class="magic-checkbox" (click)="toggleOptionCheckboxFtp()" [checked]="!isYourOwnServerSelected">
                      <label for="ftp_checkbox_option_1"></label>

                    </div>
                    {{'CommunicationsFTPServerAtDatahub' | translate}}
                  </div>
                  <div [class.panel-content-disabled]="isYourOwnServerSelected">
                    <p>{{'CommunicationsFTPCredentialsSameAsLeft' | translate}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
