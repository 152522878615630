<page-loading *ngIf="!initialized"></page-loading>

<!-- TODO: support for operatorMode would be nice-to-have
<operator-sidebar-panel *ngIf="isOperatorMode"
  [mpinfo]="marketPartnerInfo"
  [upinfo]="companyPartnerInfo"
  [activeViewMode]="'change-processes'"></operator-sidebar-panel>
-->

<div *ngIf="initialized" id="page-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base  no-botton-margin">

        <div id="dropdown-create" class="dropdown app-dropdown" *ngIf="createButtonsVisible.length > 0">
          <button class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
            <i class="pli-arrow-shuffle"></i>
            <span class="button-text">{{'SDATCreateDropdown' | translate}}</span>
            <i class="dropdown-caret"></i>
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let button of createButtonsVisible">
              <a (click)="button.fnClicked()" class="btn-a">{{button.label | translate}}</a>
            </li>
          </ul>
        </div>

        <!--Nav tabs-->
        <ul class="nav nav-tabs tabs-left">
          <li class="active">
            <a data-toggle="tab" href="#demo-rgt-tab-1" > {{'SDATMessageStatusActive' | translate}}  </a>
          </li>
          <li [class.active]="false">
            <a data-toggle="tab" href="#demo-rgt-tab-2" > {{'SDATMessageStatusArchive' | translate}} </a>
          </li>
        </ul>
        <!--Tabs Content-->
        <div class="tab-content">
          <div id="demo-rgt-tab-1" class="tab-pane active in">
            <smart-table *ngIf="tableAllChangeProcessesConfig" #tableAllChangeProcesses
                         [tableConfig]=tableAllChangeProcessesConfig
                         (onActionClicked)="onActionPerformed($event)"
            ></smart-table>
          </div>
          <div id="demo-rgt-tab-2" class="tab-pane">
            <smart-table *ngIf="tableArchivedChangeProcessesConfig" #tableArchivedChangeProcesses
                         [tableConfig]=tableArchivedChangeProcessesConfig
                         (onActionClicked)="onActionPerformed($event)"
            ></smart-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
