<div *ngIf="isLoggedInUserSection">
  <div app-nav id="container" class="effect aside-float aside-bright mainnav-lg lg-mainnav-lg mainnav-sm navbar-fixed mainnav-fixed aside-fixed">
  </div>
</div>
<ng-container *ngIf="isLoggedInCompanySection">
  <app-companypartners></app-companypartners>
</ng-container>
<div *ngIf="!isLoggedInUserSection && !isLoggedInCompanySection">
  <div class="not-logged-lang-container">
    <div class="dropdown">
      <!--Selected Language-->
      <a id="myLanguageSelector" class="lang-selector dropdown-toggle btn btn-default btn-a"  data-toggle="dropdown">
        <span class="lang-selected">
          {{customTranslateService.getCurrentLangLongNative()}}
        </span>
      </a>

      <!--Language Dropdown-->
      <ul class="dropdown-menu">
        <li *ngFor="let lang of customTranslateService.availableLanguages;">
          <a  (click)="customTranslateService.use(lang.short)">
            <!-- <span class="lang-id">DE</span> -->
            <span class="lang-name">{{lang.longNative}}</span>
          </a>
        </li>
      </ul>
    </div>

  </div>
  <router-outlet *ngIf="!hideRoute"></router-outlet>
</div>





