import {AbstractControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import { CUSTOM_REGEXPS_DEFS } from '../definitions/custom-validators-regexps.definitions';
import * as moment from 'moment-timezone';
import { EicValidator } from '../../../../../../libs/EICjs/eic';
import { SmartFormFormFieldValidationDefinition } from './SmartFormFormFieldValidationDefinition';
import {isEmpty} from "lodash-es";

export class CustomValidators {
  static getRegExprFromId(validatorId: string) {
    const stringRegExp = CUSTOM_REGEXPS_DEFS[validatorId];
    return  new RegExp(stringRegExp, 'i');
  }
  static getRegExprFromString(stringRegExp: string) {
    if ( typeof stringRegExp === undefined || stringRegExp === null || stringRegExp === '') {
      return null;
    }
    return  new RegExp(stringRegExp, 'i');
  }
  static doStandardValidator(validatorId: string, control: AbstractControl) {
    const regExp = CustomValidators.getRegExprFromId(validatorId);
    if ( control.value !== null &&  control.value !== undefined && !control.value.match(regExp)) {
      return { ['invalid' + validatorId]: true };
    }
    return null;
  }

  public static getValidatorControl(validationInfo: SmartFormFormFieldValidationDefinition): ValidatorFn | null {
    const upperLimit = validationInfo.upperLimit;
    const lowerLimit = validationInfo.lowerLimit;
    switch (validationInfo.type) {
      // Custom validators
      case 'datetime':
        return function(control: AbstractControl) {
          // Try to parse it with momentJs
          if (control.value !== null && control.value !== '' && control.value !== undefined &&
              ((control.value.indexOf(':') < 0) || !moment(control.value).isValid())) {
            return { ['invalidField']: true };
          }
          return null;
        };
      case 'pattern':
        return function(control: AbstractControl) {
          const regExp = CustomValidators.getRegExprFromString(validationInfo?.pattern ?? '');
          if (regExp === null) {
            return null;
          }

          if ( control.value !== null && control.value !== undefined && !control.value.match(regExp)) {
            return { ['invalidField']: true };
          }
          return null;
        };
      case 'eic':
        return function(control: AbstractControl) {
          return CustomValidators.getEicErrors(control.value, false);
        };
      case 'eicList':
        return function(control: AbstractControl) {
          if (control.value) {
            const list = control.value.split(/[, ]+/);
            for (const eic of list) {
              const errors = CustomValidators.getEicErrors(eic, false);
              if (!isEmpty(errors)) return errors;
            }
          }
          return null;
        };
      case 'eic-area':
        return function(control: AbstractControl) {
          return CustomValidators.getEicErrors(control.value, true);
        };
      case 'swisszipcode':
        return function(control: AbstractControl) {
          const regExp = CustomValidators.getRegExprFromId('swisszipcode');
          if (regExp === null) {
            return null;
          }
          if ( control.value !== null && control.value !== undefined && !control.value.toString().match(regExp)) {
            return { translatableMessage: 'ValidationErrors.NotSwissZipCode' };
          }
          return null;
        };
      case 'positivenumber':
        return function(control: AbstractControl) {
          const valueAsNumber = Number(control.value);
          if ( control.value !== null && control.value !== undefined && (isNaN(valueAsNumber) || valueAsNumber <= 0)) {
            return { translatableMessage: 'ValidationErrors.NotAPositiveNumber' };
          }
          return CustomValidators.doLengthValidator(control.value, lowerLimit, upperLimit);
        };
      case 'internet':
        return function(control: AbstractControl) {
          const regExp = CustomValidators.getRegExprFromId('internet');
          if (regExp === null) {
            return null;
          }

          if ( control.value !== null && control.value !== undefined && !control.value.match(regExp)) {
            return { translatableMessage: 'ValidationErrors.NotAnInternetAddress' };
          }
          return null;
        };
      case 'integer':
        return function(control: AbstractControl) {
          if ( control.value !== null && control.value !== undefined && isNaN(Number(control.value))) {
            return { translatableMessage: 'ValidationErrors.NotANumber' };
          }
          return CustomValidators.doLengthValidator(control.value, lowerLimit, upperLimit);
        };
      case 'periodunit':
        return function(control: AbstractControl) {
          if (!['ANN', 'MON', 'WEE', 'DAY', 'HUR', 'MIN', 'SEC'].includes(control.value)) {
            return { translatableMessage: 'ValidationErrors.NotResolution' };
          }
          return null;
        };
      case 'contact1address':
        return function(control: AbstractControl) {
          if (control.value.length !== 1) {
            return { translatableMessage: 'ValidationErrors.Contact1Address'};
          }
          return null;
        }
      case 'standard':
      default:
        if (validationInfo.type) {
          // it should be on the CUSTOM_REGEXPS_DEFS list if not throw a console.error
          if (validationInfo.type in CUSTOM_REGEXPS_DEFS) {
            return function(control: AbstractControl) {
              return CustomValidators.doStandardValidator(validationInfo.type, control);
            };
          } else {
            console.error('Custom validator of type ', validationInfo.type, ' does not exist');
            return null;
          }
        }
        if (upperLimit || lowerLimit) {
          return function(control: AbstractControl) {
            return CustomValidators.doLengthValidator(control.value, lowerLimit, upperLimit);
          };
        }
        return null;
    }
  }

  static doLengthValidator(controlValue: any, lowerLimit: number | undefined, upperLimit: number | undefined) {
    if (controlValue !== null && controlValue !== '' && controlValue !== undefined ) {
      if (upperLimit && controlValue.length > upperLimit)
        return { translatableMessage: 'ValidationErrors.TooLong' };
      if (lowerLimit && controlValue.length < lowerLimit)
        return { translatableMessage: 'ValidationErrors.TooShort' };
    }
    return null;
  }


  static checkedbox(control: AbstractControl) {
    if (control.value) {
      return null;
    } else {
      return { ['invalidcheckedbox']: true };
    }
  }

  // custom validator to check that two fields match
  // source: https://jasonwatmore.com/post/2018/11/07/angular-7-reactive-forms-validation-example
  public static mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup_: AbstractControl) => {
      const formGroup = formGroup_ as UntypedFormGroup;
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return null;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }

  public static dateRange(startControlName: string, endControlName: string): ValidatorFn {
    return (formGroup_: AbstractControl) => {
      const formGroup = formGroup_ as UntypedFormGroup;
      const c1 = formGroup.controls[startControlName];
      const c2 = formGroup.controls[endControlName];

      // return if another validator has already found an error
      if (c1.errors && !c1.errors.dateRange) return null;
      if (c2.errors && !c2.errors.dateRange) return null;

      const v1 = moment(c1.value);
      const v2 = moment(c2.value);
      if (v1.isValid() && v2.isValid() && v2 >= v1) {
        c2.setErrors(null);
      } else {
        c2.setErrors({ dateRange: true });
      }
      return null;
    };
  }

  private static getEicErrors(eic: string, isAreaCode: boolean) {
    if (!eic) return null;

    if (eic.toUpperCase() !== eic)  {
      return { translatableMessage: 'EICErrors.MustBeUppercase' };
    }

    const eicInfo = EicValidator.EIC.examine(eic);

    if (eicInfo.isValid) {
      if (!isAreaCode && eicInfo.type !== 'PARTY') {
        return { translatableMessage: 'EICErrors.TypeIsNotPARTY' };
      }
      if (isAreaCode) {
        if (eicInfo.type !== 'AREA') {
          return {translatableMessage: 'EICErrors.TypeIsNotAREA'};
        }
        if (!eicInfo.issuer || eicInfo.issuer.country !== 'CH') {
          return {translatableMessage: 'EICErrors.IssuerIsNotCH'};
        }
      }
      return null;
    } else {
      for (const e of eicInfo.errors) {
        const msg = e.errorMessage;
        if (msg === 'CHECKCHAR_MISMATCH' || msg === 'CHECKCHAR_HYPHEN') {
          return { translatableMessage: 'EICErrors.ChecksumInvalid' };
        }
        if (msg === 'INVALID_CHARACTER') {
          return { translatableMessage: 'EICErrors.InvalidCharacters' };
        }
        if (msg === 'TOO_SHORT') {
          return { translatableMessage: 'EICErrors.TooShort' };
        }
        if (msg === 'TOO_LONG') {
          return { translatableMessage: 'EICErrors.TooLong' };
        }
      }
      return  { invalidField: true };
    }
  }

}
