import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appShowHidePassword]',
  // cannot include styles here; .scss is included globally
})
export class ShowHidePasswordDirective {
  private _shown = false;

  constructor(private el: ElementRef) {
    this.setup();
  }

  setup() {
    const i = document.createElement('i');
    i.classList.add('fa');
    i.classList.add('fa-eye');
    i.addEventListener('click', () => {
      this.toggle(i);
    });
    const input = this.el.nativeElement;
    const div = input.parentNode;
    if (!div) {
      console.error('shwoHidePassword parent div not found');
      return;
    }
    div.appendChild(i);
    div.classList.add('showHidePassword');
  }

  toggle(i: HTMLElement) {
    const input = this.el.nativeElement;
    this._shown = !this._shown;
    if (this._shown) {
      input.setAttribute('type', 'text');
      i.classList.remove('fa-eye');
      i.classList.add('fa-eye-slash');
    } else {
      input.setAttribute('type', 'password');
      i.classList.add('fa-eye');
      i.classList.remove('fa-eye-slash');
    }
  }
}
