import {EnumDefinition} from '../classes/enum-definition';

export const MESSAGE_STATUS: EnumDefinition = {
  id: 'messagestatus',
  items: [
    {label: 'LabelMessageStatusReceived', data: 'RECEIVED'},
    {label: 'LabelMessageStatusProcessed', data: 'PROCESSED'},
    {label: 'LabelMessageStatusCreated', data: 'CREATED'},
    {label: 'LabelMessageStatusSaved', data: 'SAVED'},
    {label: 'LabelMessageStatusSent', data: 'SENT'},
    {label: 'LabelMessageStatusRetrying', data: 'RETRYING'},
    {label: 'LabelMessageStatusFailed', data: 'FAILED'},
  ]
};
