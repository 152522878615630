<page-loading *ngIf="!initialized"></page-loading>

<operator-sidebar-panel *ngIf="isOperatorMode"
                        [mpinfo]="marketPartnerInfo"
                        [upinfo]="companyPartnerInfo"
                        [activeViewMode]="'ostral'"></operator-sidebar-panel>

<div *ngIf="initialized && marketPartnerInfo" id="page-content"
     [class.page-content-with-sidebar]="isOperatorMode"
>

  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base no-botton-margin">
        <!--Nav tabs-->
        <ul class="nav nav-tabs tabs-left">
          <li class="active">
            <a data-toggle="tab" href="#ostral-tab-1" >
              {{'Ostral.TabPartners' | translate}}
            </a>
          </li>
        </ul>

        <!--Tabs Content-->
        <div class="tab-content">
          <div id="ostral-tab-1" class="tab-pane fade active in">
            <smart-table #tableOstral
                         [tableConfig]=tableOstralConfig
                         (onActionClicked)="onActionPerformed($event)"
            ></smart-table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
