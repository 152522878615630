<page-loading-spinner [initialized]="initialized"></page-loading-spinner>
<div class="root" *ngIf="initialized">
  <h1>{{'companyClient.companySettings.h1' | translate}}</h1>
  <form [formGroup]="companySettingsForm" (ngSubmit)="onSubmit()" method="post" class="m-top">
    <div class="multi-col-form">
      <div class="col-50">
        <mat-form-field class="rowspan">
          <mat-label>{{'FormCompanyName' | translate}}</mat-label>
          <input matInput type="text" formControlName="name" name="name" id="name">
        </mat-form-field>
        <mat-form-field class="rowspan-with-very-small">
          <mat-label>{{'FormStreet' | translate}}</mat-label>
          <input matInput type="text" formControlName="addressStreet" name="addressStreet" id="addressStreet">
        </mat-form-field>
        <mat-form-field class="very-small-input">
          <mat-label>{{'FormNr' | translate}}</mat-label>
          <input matInput type="text" formControlName="addressHouseNumber" name="addressHouseNumber" id="addressHouseNumber">
        </mat-form-field>
        <mat-form-field class="small-input">
          <mat-label>{{'FormZip' | translate}}</mat-label>
          <input matInput type="text" formControlName="addressZipCode" name="addressZipCode" id="addressZipCode">
        </mat-form-field>
        <mat-form-field class="rowspan-with-small">
          <mat-label>{{'FormTown' | translate}}</mat-label>
          <input matInput type="text" formControlName="addressTown" name="addressTown" id="addressTown">
        </mat-form-field>
      </div>
      <div class="col-50">
        <mat-form-field class="rowspan">
          <mat-label>{{'FormContactFirstName' | translate}}</mat-label>
          <input matInput type="text" formControlName="contactFirstName" name="contactFirstName" id="contactFirstName">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{'FormContactLastName' | translate}}</mat-label>
          <input matInput type="text" formControlName="contactName" name="contactName" id="contactName">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{'FormContactEmail' | translate}}</mat-label>
          <input matInput type="email" formControlName="contactEmail" name="contactEmail" id="contactEmail">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{'FormContactPhone' | translate}}</mat-label>
          <input matInput type="tel" formControlName="contactPhone" name="contactPhone" id="contactPhone">
        </mat-form-field>
      </div>
    </div>
    <submit-button [isSendingRequest]="isSendingRequest" [disabled]="companySettingsForm.invalid"></submit-button>
  </form>
</div>
