import {Injectable} from '@angular/core';
import {ApiRequestConfigInfoDefinition, HttpMethod} from '../classes/ApiRequestConfigInfoDefinition';
import {NameValueMap} from '../../marketpartner-components/smart-forms/smart-shared/classes/KeyValueMap';
import {ApiRequestStandardResponse} from '../classes/ApiRequestStandardResponse';
import {APIREQUEST_MOCKUP_DATASOURCES_DEFS} from '../definitions/api-request-mockup-datasources.definitions';
import {APIREQUEST_MOCKUP_INTERCEPTORS_DEFS} from '../definitions/api-request-mockup-interceptor.definitions';
import {
  ApiRequestMockupContextMockup,
  ApiRequestMockupInterceptorDefinition, ApiRequestMockupOperationInfo,
  StandardEntitySelector
} from '../classes/ApiRequestMockupInterceptorDefinition';
import * as moment from 'moment-timezone';
import {ColumnFilterOperator, TableColumnNameFilter} from '../../marketpartner-components/smart-forms/smart-table/classes/TableColumnFilter';
import {UtilsService} from '../../services/utils.service';
import {DateTimeService} from '../../services/date-time.service';
import {SessionService} from '../../session/session.service';
import {get, orderBy} from "lodash-es";


@Injectable()
export class ApiRequestMockupInterceptorService {
  mockupDataSources: any;
  mockupInterceptors:  NameValueMap<ApiRequestMockupInterceptorDefinition>;
  constructor(public utilsService: UtilsService, public dateTimeService: DateTimeService, public sessionService: SessionService) {
    this.mockupDataSources = JSON.parse(JSON.stringify(APIREQUEST_MOCKUP_DATASOURCES_DEFS));
    this.mockupInterceptors = APIREQUEST_MOCKUP_INTERCEPTORS_DEFS;
  }

  async getMockupServiceData(apiCallInfo: ApiRequestConfigInfoDefinition,
                             body: NameValueMap<any> = {}): Promise<ApiRequestStandardResponse | any> {
      const self = this;
      const contextMockup: ApiRequestMockupContextMockup =  {
        sessionService:  this.sessionService,
        utilsService: this.utilsService
      };

      let method: HttpMethod = 'POST';
      if (apiCallInfo.method) {
        method = apiCallInfo.method;
      }
      const mockupInterceptorId = `${method}|${apiCallInfo.endpoint}`;
      if (!(mockupInterceptorId in this.mockupInterceptors)) {
          console.error('Mockup Interceptor:', mockupInterceptorId, ' not found');
          return null;
      }
      const thisMockupInterceptor = this.mockupInterceptors[mockupInterceptorId];

      let toReturn: any = null;
      if (thisMockupInterceptor.operations) {
        thisMockupInterceptor.operations.forEach(function(thisOperationInfo: ApiRequestMockupOperationInfo) {
          const thisDataSourceId = thisOperationInfo.mockupDataSource;
          if (!(thisDataSourceId in self.mockupDataSources)) {
            console.error('Mockup Datasource:', thisDataSourceId, ' not found');
          } else {
            const thisDataSource = self.mockupDataSources[thisDataSourceId];
            const thisDataSourceData = self.mockupDataSources[thisDataSourceId].data;
            toReturn = JSON.parse(JSON.stringify(thisDataSource.data));

            switch (thisOperationInfo.standardOperation) {
              case 'DeleteEntity':
                const toDeleteEntityIndex = self.retrieveMockupDatasourceEntityIndex(thisDataSourceData,
                  thisOperationInfo.standardEntitySelector, apiCallInfo.replaceUrl, body);
                if (toDeleteEntityIndex >= 0) {
                  thisDataSourceData.splice(toDeleteEntityIndex, 1);
                }
                toReturn = null;
                break;
              case 'ReadEntity':
                let toReadEntity = null;
                const toReadEntityIndex = self.retrieveMockupDatasourceEntityIndex(thisDataSourceData,
                  thisOperationInfo.standardEntitySelector, apiCallInfo.replaceUrl, body);
                if (toReadEntityIndex >= 0) {
                  toReadEntity = JSON.parse(JSON.stringify(thisDataSourceData[toReadEntityIndex]));
                }
                toReturn = toReadEntity;
                break;
              case 'UpdateEntity':
                // retrieve the entity
                let toUpdateEntity = null;
                const toUpdateEntityIndex = self.retrieveMockupDatasourceEntityIndex(thisDataSourceData,
                  thisOperationInfo.standardEntitySelector, apiCallInfo.replaceUrl, body);

                if (toUpdateEntityIndex >= 0) {
                  toUpdateEntity = thisDataSourceData[toUpdateEntityIndex];
                  if (thisOperationInfo.modifyEntityCallback) {
                    toUpdateEntity = thisOperationInfo.modifyEntityCallback(toUpdateEntity, contextMockup,
                      apiCallInfo.replaceUrl , body );
                  }
                  if (toUpdateEntity) {
                    thisDataSourceData[toUpdateEntityIndex]  = toUpdateEntity;
                  }
                }
                toReturn = toUpdateEntity;
                break;
              case 'ListSearch':
                // do filtering
                if ( body.filtersConditions && toReturn) {
                  const toParseFilterConditions: TableColumnNameFilter[] = body.filtersConditions;
                  for (let indexInResultRow = toReturn.length - 1; indexInResultRow >= 0; indexInResultRow-- ) {
                    let passesAllFilterConditions = true;
                    toParseFilterConditions.some(function( thisFilterCondition: TableColumnNameFilter) {
                      const valueEscapedFoRegExp = self.utilsService.quotemeta(thisFilterCondition.value);
                      const columnName = thisFilterCondition.columnName;
                      const currentValue = toReturn[indexInResultRow][columnName];
                      switch (thisFilterCondition.operator) {
                        case ColumnFilterOperator.Equals:
                          // TODO: If enum it could have more than one value
                          if (typeof thisFilterCondition.value === 'boolean') {
                            if (currentValue !== thisFilterCondition.value ) {
                              passesAllFilterConditions = false;
                            }
                          } else if (! (new RegExp('^(' + valueEscapedFoRegExp + ')$', 'i').test(currentValue))) {
                              passesAllFilterConditions = false;
                          }
                          break;
                        case ColumnFilterOperator.Contains:
                          if (! (new RegExp('^(.*' + valueEscapedFoRegExp + '.*)$', 'i').test(currentValue))) {
                            passesAllFilterConditions = false;
                          }
                          break;
                        case ColumnFilterOperator.NotContains:
                          if ( (new RegExp('^(.*' + valueEscapedFoRegExp + '.*)$', 'i').test(currentValue))) {
                            passesAllFilterConditions = false;
                          }
                          break;
                        case ColumnFilterOperator.FromToDate:
                          const momentFromDate: moment.Moment = (thisFilterCondition.value && thisFilterCondition.value['from-date']) ?
                            moment(thisFilterCondition.value['from-date']) : self.dateTimeService.getMomentLocaleFromLinux(0);
                          const momentToDate: moment.Moment =  (thisFilterCondition.value && thisFilterCondition.value['to-date']) ?
                            moment(thisFilterCondition.value['to-date']) : self.dateTimeService.getMomentLocaleFromLinux(10000000000000);

                          if (!currentValue) {
                            passesAllFilterConditions = false;
                          } else {
                            const momentCurrentValue: moment.Moment = moment(currentValue);
                            if (!momentCurrentValue ||  (momentCurrentValue.valueOf() < momentFromDate.valueOf()) ||
                              (momentCurrentValue.valueOf() > momentToDate.valueOf())) {
                              passesAllFilterConditions = false;
                            }
                          }
                          break;
                      }
                      return !passesAllFilterConditions;
                    });
                    if (!passesAllFilterConditions) {
                      toReturn.splice(indexInResultRow, 1);
                    }
                  }
                }
                // do sorting
                if (body.sortOrder[0]) {
                  const sortColumName = body.sortOrder[0].columnName;
                  const sortOrder = body.sortOrder[0].order.toLowerCase();
                  toReturn = orderBy(toReturn, [sortColumName], [sortOrder]);
                }
                const totalElements = toReturn.length;

                // do pagination
                if (body.pageSize  && toReturn) {
                  toReturn = toReturn.slice(body.firstEntryIndexPage, body.firstEntryIndexPage + body.pageSize);
                }

                // Add the pageInfo
                toReturn = {
                  rowsData: toReturn,
                  pageInfo: {
                    totalElements: totalElements
                  }
                };
                break;
            }
          }
        });
      }

      if (thisMockupInterceptor.beforeWrapDataCallback) {
        toReturn = thisMockupInterceptor.beforeWrapDataCallback(toReturn, contextMockup, apiCallInfo.replaceUrl, body);
      }

      if (thisMockupInterceptor.wrapInsideData) {
        toReturn = {
          status: 'success',
          data: toReturn,
        };
      }
      return toReturn;
  }
  retrieveMockupDatasourceEntityIndex(dataSourceData: any[], standardEntitySelector?: StandardEntitySelector,
                            replaceUrl?: NameValueMap<string|number>, body?: any): number {
    let foundEntityIndex = -1;
    let sourcePropertyValue;
    if (!standardEntitySelector) {
      return foundEntityIndex;
    }
    switch (standardEntitySelector.sourceType) {
      case 'replaceUrl':
        if (replaceUrl) {
          sourcePropertyValue = replaceUrl[standardEntitySelector.sourceProperty] + '';
        }
        break;
      case 'body':
        if (body) {
          sourcePropertyValue = get(body, standardEntitySelector.sourceProperty.replace(/:/g, '.'), undefined);
        }
        break;
    }

    if (sourcePropertyValue !== 'undefined' && dataSourceData) {
      sourcePropertyValue += '';
      for (let indexEntity = 0; indexEntity < dataSourceData.length; indexEntity++ ) {
        const thisEntity = dataSourceData[indexEntity];
        const foundPropertyValue = get(thisEntity, standardEntitySelector.targetProperty.replace(/:/g, '.'), undefined);
        if ((foundPropertyValue !== 'undefined') && (foundPropertyValue + '' ===  sourcePropertyValue)) {
          foundEntityIndex = indexEntity;
          break;
        }
      }
    }

    return foundEntityIndex;
  }
}
