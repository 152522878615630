/* eslint-disable max-len */
import {SmartTableColumnDefinitionContainer} from '../classes/SmartTableColumnDefinition';

export const SMART_TABLE_COLUMNS_DEFS:  SmartTableColumnDefinitionContainer[] = [
  {
    id: 'columnId',
    definition: {
      id: 'id',
      title: 'Id',
      visible: false
    },
  },
  {
    id: 'columnSalutation',
    definition: {
      id: 'salutation',
      title: 'FormSalutation',
      enum: 'salutation',
      visible: true,
      filterable: true
    },
  },
  {
    id: 'columnFirstName',
    definition: {
      id: 'firstname',
      title: 'FormFirstName',
      visible: true,
      filterable: true
    },
  },
  {
    id: 'columnLastName',
    definition: {
      id: 'lastname',
      title: 'FormLastName',
      visible: true,
      filterable: true
    },
  },
  {
    id: 'columnEmail',
    definition: {
      id: 'email',
      title: 'FormEmail',
      visible: true,
      filterable: true
    },
  },
  {
    id: 'columnMarketPartnerNotVisible',
    definition: {
      id: 'client_id',
      title: 'FormMarketPartner',
      visible: false,
      filterable: false
    },
  },
  {
    id: 'columnUserStatus',
    definition: {
      id: 'enabled',
      title: 'FormStatus',
      visible: true,
      filterable: true,
      valueIsBoolean: true,
      enum: 'userStatus'
    },
  },
  {
    id: 'columnAccountType',
    definition: {
      id: 'type',
      title: 'columnNameAccountType',
      enum: 'accountGlobalType',
      visible: true,
      filterable: true
    },
  },
  {
    id: 'columnUserScopes',
    definition: {
      id: 'scopes',
      title: 'ColumnUserRightsCompact',
      visible: true,
      filterable: true,
      /* className: 'text-center', */
      enum: 'scopes'
    }
  },
  {
    id: 'columnMarketPartnerEIC',
    definition: {
      id: 'partnerSettings:eic',
      title: 'FormEIC',
      visible: true,
      filterable: true
    },
  },
  {
    id: 'columnMarketPartnerName',
    definition: {
      id: 'partnerSettings:name',
      title: 'FormName',
      visible: true,
      filterable: true
    },
  },
  {
    id: 'columnMarketPartnerSDATRole',
    definition: {
      id: 'partnerSettings:role',
      title: 'FormSDATRole',
      enum: 'sdatroles',
      visible: true,
      filterable: true
    },
  },
  {
    id: 'columnMarketPartnerContractStatus',
    definition: {
      id: 'partnerSettings:contractStatus',
      title: 'FormContractStatus',
      visible: true,
      filterable: true,
      enum: 'contractstatus'
    },
  },
  {
    id: 'columnMarketPartnerContactEIC',
    definition: {
      id: 'eic',
      title: 'FormEIC',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMarketPartnerContactName',
    definition: {
      id: 'name',
      title: 'FormName',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMarketPartnerContactEmail',
    definition: {
      id: 'contactEmail',
      title: 'FormEmail',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMarketPartnerContactPhone',
    definition: {
      id: 'contactPhone',
      title: 'FormTelephone',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMarketPartnerContactRole',
    definition: {
      id: 'role',
      title: 'FormSDATRole',
      enum: 'sdatroles',
      visible: true,
      filterable: true,
    },
  },

  {
    id: 'columnCreatedDate',
    definition: {
      id: 'created_ts',
      title: 'ColumnCreatedDate',
      fieldType: 'date',
      displayAs: 'localedatetimeL',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMessagesLogUUID',
    definition: {
      id: 'uuid',
      title: 'ColumnMessagesLogUUID',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMessagesLogTimestampIn',
    definition: {
      id: 'timestampIn',
      title: 'ColumnMessagesLogTimestampIn',
      fieldType: 'date',
      displayAs: 'localedatetimeLss',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMessagesLogTimestampOut',
    definition: {
      id: 'timestampOut',
      title: 'ColumnMessagesLogTimestampOut',
      fieldType: 'date',
      displayAs: 'localedatetimeLss',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMessagesLogSenderEIC',
    definition: {
      id: 'senderEic',
      title: 'ColumnMessagesLogSenderEIC',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMessagesLogRecipientEIC',
    definition: {
      id: 'recipientEic',
      title: 'ColumnMessagesLogRecipientEIC',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMessagesLogMessageId',
    definition: {
      id: 'messageId',
      title: 'ColumnMessagesLogMessageId',
      visible: true,
      filterable: true,
    },
  },

  {
    id: 'columnMessagesLogParentId',
    definition: {
      id: 'parentId',
      title: 'ColumnMessagesLogParentId',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMessagesLogProcessId',
    definition: {
      id: 'processId',
      title: 'ColumnMessagesLogProcessId',
      visible: true,
      filterable: true,
    },
  },

  {
    id: 'columnMessagesLogProcessTypeCode',
    definition: {
      id: 'processTypeCode',
      title: 'ColumnMessagesLogProcessTypeCode',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMessagesLogMessageTypeCode',
    definition: {
      id: 'messageTypeCode',
      title: 'ColumnMessagesLogMessageTypeCode',
      visible: true,
      filterable: true,
    },
  },
  {
    id: 'columnMessagesLogMessageStatus',
    definition: {
      id: 'messageStatus',
      title: 'ColumnMessagesLogMessageStatus',
      visible: true,
      filterable: true,
      enum: 'messagestatus'
    },
  },


  {
    id: 'columnCurrentSDATMessage',
    definition: {
      id: 'current_message',
      title: 'ColumnCurrentSDATMessage',
      visible: true,
      filterable: true
    },
  },
];

