import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.scss']
})
export class PasswordFieldComponent {
  hideText: boolean = true;
  @Input() controlName: string = 'password';
  @Input() labelKey: string = 'FormPassword';
  @Input() form!: FormGroup;
  @Input() showHint = true;
  @Input() errorMessageKey = 'LoginPasswordRequirementsHint';
}
