import {NavigationMenuDefinition} from '../../shared/nav/NavigationMenuDefinition';
import {
  canAccessMeteringPointsUpload,
  canAccessOstral,
  canUseSdatWebclient
} from '../../shared/services/marketpartner.service';

export const NAVIGATION_LIST: NavigationMenuDefinition[] = [
  {
    fullPath: '/users/admin/users',
    title: 'NavigationUsers',
    icon: 'pli-professor',
    subRoutes: [],
    fnAllowed: sessionService => sessionService.getCurrentScopeForDomain() === 'ADMIN' || sessionService.isOperator()
  },

  {
    fullPath: '/users/admin/marketpartners',
    title: 'NavigationMarketPartners',
    icon: 'pli-factory',
    subRoutes: [],
    fnAllowed: sessionService => sessionService.isOperator()
  },

  {
    fullPath: '/users/admin/ostral',
    title: 'NavigationOstral',
    icon: 'pli-power-3',
    subRoutes: [],
    fnAllowed: sessionService => sessionService.isOperator()
  },

  {
    fullPath: '/users/admin/companypartners',
    title: 'NavigationCompanyPartners',
    icon: 'pli-building',
    subRoutes: [],
    fnAllowed: sessionService => sessionService.isOperator()
  },

  {
    fullPath: 'NOPATH',
    title: 'NavigationSettingsHeader',
    icon: 'pli-gear',
    cannotBeVisited: true,
    fnAllowed: sessionService => sessionService.isMarketPartner(),
    subRoutes: [
      {
        fullPath: '/users/communication/data',
        title: 'NavigationCommunicationBaseData',
        icon: 'pli-tag',
      },
      {
        fullPath: '/users/communication/ftp',
        title: 'NavigationCommunicationFtp',
        icon: 'pli-communication-tower',
      },
      {
        fullPath: '/users/communication/mpd',
        title: 'NavigationCommunicationMpd',
        icon: 'pli-transform-3',
        fnAllowed: (_, mpi) => {
          if (!mpi) return false;
          return canAccessMeteringPointsUpload(mpi);
        }
      },
      {
        fullPath: '/users/communication/webclient',
        title: 'NavigationCommunicationWebclient',
        icon: 'pli-arrow-shuffle',
        fnAllowed: (_, mpi) => {
          if (!mpi) return false;
          return canUseSdatWebclient(mpi);
        }
      },
    ],
  },

  {
    fullPath: '/users/notifications',
    title: 'NavigationNotifications',
    icon: 'pli-bell',
    fnNotificationCountObservable: marketPartnerService => marketPartnerService.unreadNotifications$,
    subRoutes: []
  },

  {
    fullPath: '/users/change-processes',
    title: 'NavigationChangeProcesses',
    // icon: 'pli-post-mail-2',
    icon: 'pli-arrow-shuffle',
    subRoutes: [],
    fnAllowed: (_, mpi) => !!mpi,
    fnNotificationCountObservable: marketPartnerService => marketPartnerService.unreadProcesses$,
  },

  {
    fullPath: '/users/ostral',
    title: 'NavigationOstral',
    icon: 'pli-power-3',
    subRoutes: [],
    fnAllowed: (_sessionService, mpi) =>  {
      if (!mpi) return false;
      return canAccessOstral(mpi);
    },
  },
  {
    fullPath: '/users/metering-points',
    title: 'NavigationCommunicationMpd',
    icon: 'pli-transform-3',
    fnNotificationCountObservable: marketPartnerService => marketPartnerService.unsuppliedMeteringPoints$,
    notificationWarning: true,
    subRoutes: [],
    fnAllowed: (_, mpi) => {
      if (!mpi) return false;
      if (mpi.partnerSettings.role === 'BGV') return false;
      return !(mpi.partnerSettings.role === 'VNB' && mpi.partnerSettings.contractStatus !== 'PARTNER_CONTRACT');

    }
  },

  {
    fullPath: '/users/reports',
    title: 'NavigationReports',
    icon: 'pli-notepad',
    subRoutes: [],
    fnAllowed: sessionService => sessionService.isMarketPartner(),
  },

  {
    fullPath: '/users/marketpartners',
    title: 'NavigationContactMarketPartners',
    icon: 'pli-address-book-2',
    subRoutes: []
  },
  {
    fullPath: '/users/admin/announcements',
    title: 'Announcement',
    icon: 'pli-megaphone',
    subRoutes: [],
    fnAllowed: sessionService => sessionService.isOperator(),
  },

];
