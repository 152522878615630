<div *ngIf="tableId"
     [class]="heightScrollContainer"
     [class.smart-table-container]="true"
     [class.row-action-button-onlyicon]="showRowActionButtonOnlyIcon"
     [class.data-not-initialized]="toDisplayData === null"
     [class.show-onrow-pointer]="enableOnRowClick"
     [class.hide-header]="hideHeader"
     [class.hide-pagelength]="hidePageLength"
     [class.hide-columnfilters]="!showIndividualFilters"
     [class.hide-searchfield]="hideSearchField"
     [class.split-table]="splitTable"
     [class.hide-footer]="(hideFooter )"
     [class.hide-page-selector]="(hidePageSelector )"
     id="{{tableId}}">

    <actions-toolbar #actionsToolbarRef
        [title]="tableTitle"
        [actionsToolbar]="actionsToolbar"
        (actionClicked)="actionToolbarClicked($event)"
    ></actions-toolbar>

    <div class="container-table-loading">
        <table *ngIf="tableId" id="{{tableId}}_container" class="display nowrap" style="width:100%; ">
            <tfoot>
            <tr>
                <th *ngFor="let columnInfo of columnsInfo;let columnIndex = index"></th>
            </tr>
            </tfoot>
        </table>

        <div class="loading-first-message">
            {{'table.isLoading' | translate}}
        </div>
    </div>
</div>
