import {EnumDefinition} from '../classes/enum-definition';
import { sdat } from '../../../messaging-grpc/messaging-grpc';

export const MESSAGE_CLASS: EnumDefinition<keyof typeof sdat.MessageClass> = {
  id: 'messageclass',
  items: [
    {label: '-', data: 'MESSAGE_CLASS_UNSPECIFIED', omitFromDropdown: true},
    {label: 'SDATMessageClass.CONFIRMED', data: 'CONFIRMED'},
    {label: 'SDATMessageClass.REJECTED', data: 'REJECTED'},
    {label: 'SDATMessageClass.INFO', data: 'INFO'},
    {label: 'SDATMessageClass.REQUEST', data: 'REQUEST'},
    {label: 'SDATMessageClass.DOCUMENT_CANCELLED', data: 'DOCUMENT_CANCELLED'},
    {label: 'SDATMessageClass.PROCESS_CANCELLATION_REQUESTED', data: 'PROCESS_CANCELLATION_REQUESTED'},
    {label: 'SDATMessageClass.PROCESS_CANCELLATION_CONFIRMED', data: 'PROCESS_CANCELLATION_CONFIRMED'},
    {label: 'SDATMessageClass.PROCESS_CANCELLATION_REJECTED', data: 'PROCESS_CANCELLATION_REJECTED'},
  ]
};
