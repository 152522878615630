import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {USERS_API_ENDPOINTS_LIST} from "../../requests/api-endpoints-list";
import {ApiRequestService} from "../../../shared/api-request/service/api-request.service";
import {FormControl} from "@angular/forms";
import {
  CustomValidators
} from "../../../shared/marketpartner-components/smart-forms/smart-form/classes/CustomValidators";

@Component({
  selector: 'delete-meteringdata-dialog',
  templateUrl: './delete-meteringdata-dialog.component.html',
  styleUrls: []
})
export class DeleteMeteringdataDialogComponent {
  prefix = 'DeleteMeteringDataDialog.'
  isSendingRequest = false;
  deleteAll = false;
  mpIdFormField = new FormControl('', {
    validators: CustomValidators.getValidatorControl({type: 'metering_point_id'})
  });

  constructor(
    public apiRequestService: ApiRequestService,
    public dialogRef: MatDialogRef<DeleteMeteringdataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {partnerId: number, partnerEic: string, partnerName: string}
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.isSendingRequest = true;
    const apiConfigDefinition = this.deleteAll ? USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMeteringDataDeleteForPartner : USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMeteringDataDeleteForMeteringPoint;
    const body = this.deleteAll ? undefined : {id: this.mpIdFormField.value, administratorEic: this.data.partnerEic};
    if(this.deleteAll) {
      apiConfigDefinition.replaceUrl = {partnerId: this.data.partnerId};
    }
    this.apiRequestService.doApiRequest(apiConfigDefinition, body)
      .then(result => {
        this.isSendingRequest = false;
        if (result.status == 'success') {
          this.dialogRef.close();
        }
      });
  }
}
