import {Component, OnInit} from '@angular/core';
import {CompanypartnersBaseComponent} from "../companypartners-base.component";
import {UserInfoServer} from "../../shared/session/UserInfo";
import {AddUserDialogComponent} from "../add-user-dialog/add-user-dialog.component";
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "../api-endpoints-list";
import {UserDetailsDialogComponent} from "../user-details-dialog/user-details-dialog.component";

@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styleUrls: []
})
export class UserManagementComponent extends CompanypartnersBaseComponent implements OnInit {

  usersForTable: UserInfoServer[] = [];
  displayedColumns = ['lastname', 'firstname', 'isAdmin', 'email', 'status', 'action'];
  ownUserId: string | undefined = undefined;
  hoveredUser: number | undefined = undefined;

  ngOnInit() {
    this.loadUsers();
    this.ownUserId = this.sessionService.getCurrentUserInfoId();
  }

  async loadUsers() {
    this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.listUsers)
      .then(response => {
        this.initialized = true;
        if(response.status == 'success') {
          this.usersForTable = response.data;//for testing {firstname: undefined, email: "a@b.ch", lastname: undefined, scopes: 'USER', enabled: false}];
        } else {
          this.openSnackbar(this.translate.instant('companyClient.userManagement.fetchError'));
        }
      });
  }

  openAddUserDialog(): void {
    const dialogref = this.dialog.open(AddUserDialogComponent, {
      data: {
        ownCompanyName: this.sessionService.getCurrentCompanyPartnerInfo()?.companyPartnerSettings.name,
        partnerId: this.sessionService.getCurrentPartnerId()},
    });

    dialogref.afterClosed().subscribe(result => { if(result) this.loadUsers() });
  }

  deleteUser(user: UserInfoServer) {
    const apiInfo = COMPANYPARTNERS_API_ENDPOINTS_LIST.deleteUser;
    apiInfo.replaceUrl = {userId: user.id};
    this.requestService.doApiRequest(apiInfo)
      .then(_result => this.loadUsers());
  }

  editUser(user: UserInfoServer) {
    const dialogref = this.dialog.open(UserDetailsDialogComponent, {
      data: {
        user: user
      }
    });

    dialogref.afterClosed().subscribe(result => { if(result) this.loadUsers() });
  }
}
