import {SmartFormFormFieldValidationDefinition} from './SmartFormFormFieldValidationDefinition';
import {EnumItem} from '../../../../services/enum-utils/classes/enum-definition';


export interface SmartFormFormFieldDefinition {
    title?: string;
    type?: 'radio' | 'checkboxradio' | 'checkboxmulti' | 'input' | 'html' | 'hidden' | 'password'
                   | 'select' | 'autocomplete'
                   | 'date'   | 'datetime' | 'datetimeIso' | 'heading'
                   | 'array'  | 'object';
    objectId?: string;
    commaSeparatedList?: boolean;
    inputList?: boolean;
    optionsAsList?: boolean;
    dateAsString?: boolean;
    name: string;
    cssClasses?: string;
    disabled?: boolean;
    default?: any;
    readonlyDefault?: any;
    valueIsBoolean?: boolean;
    validation?: SmartFormFormFieldValidationDefinition;
    readOnly?: boolean;
    omitFromRequest?: boolean;
    omitFromRequestConditions?: SmartFormShowCondition[];
    toBeVerified?: boolean;
    allowedValues?: SmartFormAllowedValuesLocalDefined | SmartFormAllowedValuesGlobalRegister;
    expression?: string;
    showConditions?: SmartFormShowCondition[];
    help?: string;
}
export interface SmartFormAllowedValuesLocalDefined {
  type: 'localDefined';
  data:  EnumItem[];
}
export  enum SmartFormShowConditionOperator {
  Equals = 'equals',
  Different = 'different',
  In = 'in',
  NotIn = 'notin',
}

export interface SmartFormShowCondition {
  fieldAbsolutePath?: string; // if not defined then it will be set automatically to the current level
  fieldName: string;
  operator: SmartFormShowConditionOperator;
  value: any;
}

export interface SmartFormAllowedValuesGlobalRegister {
  type: 'globalRegister';
  data: string;
}

export interface SmartFormFormFieldDefinitionContainer {
    id: string;
    definition: SmartFormFormFieldDefinition;
}


