import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../../shared/session/session.service';
import { NAVIGATION_LIST } from './navigation-list';
import { NavigationMenuDefinition, NavigationSubMenuDefinition } from '../../shared/nav/NavigationMenuDefinition';

export const FeatureGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree =>
{
  const sessionService = inject(SessionService);
  const router = inject(Router);

  if(sessionService.isCompanyPartner()) {
    console.log('feature-check: company-partners not allowed for ', next.routeConfig?.path);
    return router.parseUrl('company/contingents');
  }

  let navigation_list_flat: (NavigationMenuDefinition|NavigationSubMenuDefinition)[] = NAVIGATION_LIST.slice();
  NAVIGATION_LIST.forEach(n => {
    if (n.subRoutes) {
      navigation_list_flat = [...navigation_list_flat, ...n.subRoutes];
    }
  });

  if (next.routeConfig && next.routeConfig.path) {
    const fullPath = '/users/' + next.routeConfig.path;

    const fnList = navigation_list_flat
      .filter(n => !!n.fnAllowed)
      .filter(n => fullPath.startsWith(n.fullPath))
      .map(n => n.fnAllowed);

    for (const fnAllowed of fnList) {
      if (fnAllowed) {
        const mpi = sessionService.getCurrentPartnerInfo();
        const allowed = fnAllowed(sessionService, mpi);
        if (!allowed) {
          console.log('feature-check: not allowed for route', fullPath);
          return router.parseUrl('dashboard');
          // return false;  // ends up with a blank page in some cases
        }
      }
    }
  }
  return true;
}
