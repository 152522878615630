/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';
import {SessionService} from '../../shared/session/session.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';

import {ActivatedRoute, Router} from '@angular/router';
import {SmartTableBuilder} from '../../shared/marketpartner-components/smart-forms/smart-table/builder/smart-table.builder';
import {SmartTableConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableConfigDefinition';
import {SmartTableComponent} from '../../shared/marketpartner-components/smart-forms/smart-table/component/smart-table.component';
import {SmartModalBuilder} from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import {SmartModalService} from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import {CustomTranslateService} from '../../shared/services/custom-translate.service';

import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import {ActionEvent} from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/ActionEvent';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {AppConfig} from '../../config/app.config';
import {NotificationsViewComponent} from '../shared/notifications-view.component';

interface ButtonSpec {
  label: string;
  fnClicked?: (() => void);
}

@Component({
  selector: 'page-users-notifications-content',
  templateUrl: './users-notifications.component.html',
  styleUrls: ['../users-common.scss', './users-notifications.scss']
})

export class NotificationsComponent extends NotificationsViewComponent {
  @ViewChild('tableNotificationsOwned') tableNotificationsOwned?: SmartTableComponent;
  @ViewChild('tableNotificationsNotOwned') tableNotificationsNotOwned?: SmartTableComponent;

  initialized = false;
  tableNotifications?: SmartTableConfigDefinition;

  createButtons: ButtonSpec[] =[{
    label: 'DeleteNotificationsButtons.DeleteBeyondWeek',
    fnClicked: async () => { await this.handleDeleteNotifications(2); }
  },{
    label: 'DeleteNotificationsButtons.DeleteBeyondMonth',
    fnClicked: async () => { await this.handleDeleteNotifications(3) }
  },{
    label: 'DeleteNotificationsButtons.DeleteAll',
    fnClicked: async () => { await this.handleDeleteNotifications(1) }
  }];

  constructor(public customTranslateService: CustomTranslateService,
              public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              public apiRequestService: ApiRequestService,
              public router: Router,
              private smartModalService: SmartModalService) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }

  async handleDeleteNotifications(olderThan: number){
    const modalOptions = new SmartModalBuilder()
      .setTitle('ActionNotificationDelete')
      .setBodyText('AreYouSureConfirmationBodyDeleteNotifications')
      .showConfirmButton(true)
      .showCancelButton(true)
      .build()
    const response = await this.smartModalService.showModal(modalOptions);
    if (response && this.tableNotificationsOwned){
      await this.deleteAllNotifications(olderThan);
      this.tableNotificationsOwned.reload();
    }
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    await this.loadAllData();

    const lang = this.customTranslateService.getCurrentLangShort();

    function summarizeMessages(row: any) {
      if (row.userMessage && row.userMessage.length > 0) {
        return row.userMessage[0][lang].subject;
      } else {
        return '?';
      }
    }

    this.tableNotifications = new SmartTableBuilder()
      .setTransformRawDataFn(row => ({...row, _messageSummary: summarizeMessages(row)}))
      .addRowClassReadTag()

      .addColumnFromInfo({id: 'messageMetadata:messageUuid', title: 'ColumnNotificationsMessageUUID',
                          visible: false})  // this column is required for action buttons to work
      .setColumnNameForId('messageMetadata:messageUuid')

      .addColumnFromInfo({id: 'errorCreationTimestamp', title: 'ColumnNotificationsTimestampCreated',
                          fieldType: 'date', displayAs: 'localedatetimeLss',
                          filterable: true})
      .addColumnFromInfo({id: 'messageMetadata:fileName', title: 'ColumnNotificationsFileName',
                          filterable: true})

      // We would have to make a separate request per-row to resolve the PartnerId. Decision - just hide.
      // .addColumnFromInfo({id: 'messageMetadata:senderEic', title: 'ColumnNotificationsSenderEIC',
      //                     visible: true, filterable: true})
      // .addColumnFromInfo({id: 'messageMetadata:recipientEic', title: 'ColumnNotificationsRecipientEIC',
      //                     visible: true, filterable: true})

      .addColumnFromInfo({id: '_messageSummary', title: 'ColumnNotificationsMessageSubject',
                          filterable: false, sortable: false})
      .setApiRequestConfigInfoFromInfo(USERS_API_ENDPOINTS_LIST.notificationsList, {partnerId: this.partnerId})
      .showFilters(true)
      .showSearchField(false)
      .addRowActionFromInfo({id: 'notification_view', title: 'ActionNotificationView',
        icon: 'pli-eye',
        className: 'btn-mint',
      })
      .addRowActionFromInfo({
        id: 'notification_delete', title: 'ActionNotificationDelete',
        icon: 'pli-trash', className: 'btn-danger',
        confirmationDialog: {
          title: 'ActionNotificationDelete',
          body: 'AreYouSureConfirmationBodyDeleteNotification',
          confirmButtonText: 'ButtonDelete',
        },
        fnVisible: () => !this.isOperatorMode
      })
      .addRowActionFromInfo({
        id: 'notification_read', title: 'ActionNotificationRead',
        icon: 'pli-mail-read', className: 'btn-success',
        fnVisible: (data: any) => data['messageMetadata:read'] === false && !this.isOperatorMode
      })
      .addRowActionFromInfo({
        id: 'notification_unread', title: 'ActionNotificationUnread',
        icon: 'pli-mail-unread', className: 'btn-warning',
        fnVisible: (data: any) => data['messageMetadata:read'] === true && !this.isOperatorMode
      })
       // == resend? (from table? TBD?)
      /*
      .addRowActionFromInfo({id: 'notification_process', title: 'ActionNotificationProcess',
        icon: 'pli-mail-unread',
        className: 'btn-warning',
      })
      */
      .build();

    this.setInitialized(true);
  }

  async loadAllData() {
    await Promise.all([
    ]);
  }

  onRowClicked(_event: any) {
  }

  async onActionPerformed(event: ActionEvent) {
    const self = this;
    const entitySelected = event.data.length ? event.data[0] : null;
    const messageUuid = entitySelected.messageMetadata.messageUuid;
    if (self.tableNotificationsOwned) {
      switch (event.actionId) {
        case 'notification_read':
          await self.setNotificationRead(messageUuid, true);
          self.tableNotificationsOwned.reload();
          break;
        case 'notification_unread':
          await self.setNotificationRead(messageUuid, false);
          self.tableNotificationsOwned.reload();
          break;
        case 'notification_view':
          if (this.isOperatorMode) {
            this.navigateTo({
              'route': AppConfig.routes.admin_marketpartner_notifications_info,
              'replace': {'partnerId': this.partnerId, messageUuid}
            });
          } else {
            this.navigateTo({
              'route': AppConfig.routes.notifications_info,
              'replace': {messageUuid}
            });
          }
          break;
        case 'notification_delete':
          await self.deleteNotification(messageUuid);
          self.tableNotificationsOwned.reload();
          break;
      }
    }
  }
}
