import {ChartData, ChartOptions, TooltipItem} from "chart.js";
import {ChartStyling} from "../chart-styling";
import {TranslateService} from "@ngx-translate/core";
import {ChartDataModel} from "../chart-data-model";

export class LineChartConfig {

  private readonly now = new Date();
  private readonly currentYear = this.now.getFullYear();

  private readonly currentMonth = this.now.getMonth();

  private readonly currentDay = this.now.getDate();

  public chartData: ChartData<'line', (number | null)[]>;
  public chartOptions: ChartOptions<'line'>;
  constructor(private data: ChartDataModel,
              public yearToDisplay: number,
              public monthToDisplay: number | undefined,
              private translate: TranslateService) {

    const daysInMonth = new Array(new Date(yearToDisplay, (monthToDisplay || 0) + 1, 0).getDate()).fill(0).map((_, i) => i+1);

    this.chartData = {
      labels: daysInMonth,
      datasets: [
        {
          data: this.data.contingentData,
          label: this.translate.instant('companyClient.consumption.contingent'),
          fill: false,
          borderColor: ChartStyling.contingentColor,
          pointBackgroundColor: ChartStyling.contingentColor,
          borderDash: [6, 4],
          backgroundColor: ChartStyling.contingentBackgroundColor,
          order: 0 // front
        },
        {
          data: this.data.previousData,
          label: (yearToDisplay - 1).toString(),
          fill: true,
          borderColor: ChartStyling.previousColor,
          pointBackgroundColor: ChartStyling.previousColor,
          backgroundColor: ChartStyling.previousBackgroundColor,
          pointRadius: ctx => this.getPointRadius(-1, ctx.dataset.data as (number | null)[], ctx.dataIndex),
          segment: {
            borderDash: ctx => this.data.additionalInfoPrevious[ctx.p1DataIndex].isComplete ? undefined : [6, 4],
          },
          order: 2 // back
        },
        {
          data: this.data.currentData,
          label: yearToDisplay.toString(),
          fill: true,
          borderColor: ChartStyling.currentColor,
          backgroundColor: ChartStyling.currentBackgroundColor,
          pointBackgroundColor: ctx => this.getPointBgColors(ctx.dataIndex),
          pointStyle: 'circle',
          pointRadius: ctx => this.getPointRadius(ctx.dataIndex, ctx.dataset.data as (number | null)[], ctx.dataIndex),
          segment: {
            borderDash: ctx => this.data.additionalInfoCurrent[ctx.p1DataIndex].isComplete ? undefined : [6, 4],
            borderColor: ctx => this.data.currentData[ctx.p1DataIndex] != null && this.data.contingentData[ctx.p1DataIndex] != null && this.data.currentData[ctx.p1DataIndex]! > this.data.contingentData[ctx.p1DataIndex]! ? ChartStyling.warningColor : ChartStyling.currentColor,
            backgroundColor: ctx => this.data.currentData[ctx.p1DataIndex] != null && this.data.contingentData[ctx.p1DataIndex] != null && this.data.currentData[ctx.p1DataIndex]! > this.data.contingentData[ctx.p1DataIndex]! ? ChartStyling.warningBackgroundColor : ChartStyling.currentBackgroundColor,
          }
        },
        {
          data: [],
          label: this.translate.instant('companyClient.consumption.contingentOverusedValue')
        }
      ]
    };

    const pluginOptions = {
      tooltip: {
        enabled: true,
        callbacks: {
          label(tooltipItem: TooltipItem<'bar'>): string | string[] {
            if(tooltipItem.parsed.y == null) return '';
            let label = tooltipItem.dataset.label || '';
            let value = tooltipItem.parsed.y;
            if(label) {
              const info = tooltipItem.datasetIndex == 1 ? data.additionalInfoPrevious : data.additionalInfoCurrent;
              label += ': ' + value.toLocaleString('DE-ch') + ' kWh (' + info[tooltipItem.dataIndex]?.dataSource +')';
            }
            return label;
          },
          footer(tooltipItems: TooltipItem<'line'>[]): string | string[] {
            let tooltipItem = tooltipItems[0];
            let currentIndex = tooltipItem.dataIndex;
            let contingentValue = (tooltipItem.chart.data.datasets[0].data[currentIndex] as number) || 0;
            let currentValue = (tooltipItem.chart.data.datasets[2].data[currentIndex] as number) || 0;
            return (currentValue > contingentValue && contingentValue > 0) ?
              tooltipItem.chart.data.datasets[3].label?.replace('{{value}}',(currentValue-contingentValue).toLocaleString('DE-ch'))! :
              '';
          }
        },
        footerColor: ChartStyling.warningColor,
        footerFont: {
          weight: '900'
        }
      },
      legend: {
        display: false
      }
    };

    this.chartOptions = ChartStyling.getOptions(pluginOptions, this.data.getSuggestedMax(), this.translate.instant('companyClient.consumption.unit'));
  }

  getPointBgColors(dataIndex: number): string {
    return this.data.isAboveContingent(dataIndex) ? ChartStyling.warningColor : ChartStyling.currentColor;
  }

  getPointRadius(day: number, data: (null | number | undefined)[], index: number): number {
    if(this.isToday(day) || this.isIsolated(data, index)) return 4;
    return 0;
  }

  isIsolated(data: (null | number | undefined)[], index: number) {
    return (data[index-1] || null) === null && (data[index+1] || null) === null;
  }

  private isToday(dayIndex: number) {
    return dayIndex + 1 == this.currentDay && this.currentMonth == this.monthToDisplay && this.currentYear == this.yearToDisplay;
  }
}
