<page-loading *ngIf="!initialized"></page-loading>

<operator-sidebar-panel *ngIf="isOperatorMode"
  [mpinfo]="marketPartnerInfo"
  [upinfo]="companyPartnerInfo"
  [activeViewMode]="'notifications'"></operator-sidebar-panel>

<div *ngIf="initialized" id="page-content">
  <div class="before-panel-info">
    <div class="row">
      <div class="col-sm-8">
        <div (click)="goBackToNotifications()" class="before-panel-title go-back-nav-button">
          <i class="fa fa-chevron-circle-left"></i>
          <div>{{'LabelNotification' | translate}} </div>
        </div>
      </div>
      <div class="col-sm-4">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base  no-botton-margin">

        <!--Tabs Content-->
        <div class="tab-content">
          <div class="tab-pane fade active in">
            <smart-form class="category-select-checkbox-form"
              #headerForm
              *ngIf="(headerFormConfig)"
              [formConfig]="headerFormConfig"></smart-form>
            <hr/>
            <div *ngFor="let msg of userMessages" class="un-message">
              <span *ngIf="msg.documentId">
                <label>{{ 'LabelSDATDocumentId' | translate }}:
                  <span class="un-value">{{ msg.documentId}}</span>
                </label>
                <br/>
              </span>
              <label>{{ 'ColumnNotificationsMessageSubject' | translate }}:
                <span class="un-value">{{ msg[lang].subject }}</span>
              </label>
              <p class="un-value">{{ msg[lang].body }}</p>
            </div>
            <hr/>
            <actions-toolbar [actionsToolbar]="actions" class="text-right"></actions-toolbar>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
