import { Injectable } from '@angular/core';
import { ApiRequestBuilder } from '../../shared/api-request/builder/api-request.builder';
import { USERS_API_ENDPOINTS_LIST } from '../requests/api-endpoints-list';
import { EnumItem } from '../../shared/services/enum-utils/classes/enum-definition';
import { EnumUtilsService } from '../../shared/services/enum-utils/enum-utils.service';
import { ApiRequestService } from '../../shared/api-request/service/api-request.service';

export type LoadableMarketPartnerRole = 'BGV' | 'VNB' | 'LF' | 'SDV';

@Injectable()
export class MarketpartnersLoaderService {

  constructor(private enumUtilsService: EnumUtilsService,
              private apiRequestService: ApiRequestService) {
  }

  async load() {
    this.loadSpecificPartner('LF').then(function (_) {
      console.log('LF market partners were loaded.');
    }, function (_) {
      console.log('LF market partners could not be loaded.');
    });
    this.loadSpecificPartner('VNB').then(function (_) {
      console.log('VNB market partners were loaded.');
    }, function (_) {
      console.log('VNB market partners could not be loaded.');
    });
    this.loadSpecificPartner('SDV').then(function (_) {
      console.log('SDV market partners were loaded.');
    }, function (_) {
      console.log('SDV market partners could not be loaded.');
    });
    this.loadSpecificPartner('BGV').then(function (_) {
      console.log('BGV market partners were loaded.');
    }, function (_) {
      console.log('BGV market partners could not be loaded.');
    });
  }

  async loadSpecificPartner(role: LoadableMarketPartnerRole) {
    let enumList: string;
    switch (role) {
      case 'BGV':
        enumList = 'bgvMarketpartners';
        break;
      case 'SDV':
        enumList = 'sdvMarketpartners';
        break;
      case 'VNB':
        enumList = 'vnbMarketpartners';
        break;
      case 'LF':
        enumList = 'lfMarketpartners';
        break;
      default:
        throw new Error('Invalid input, no partners can be loaded for that role.');
    }

    let roleConditions = {
      orConditions: [
        {
          columnName: 'role',
          operator: 'EQ',
          values: [role as string]
        }
      ]
    };
    let andConditions = [roleConditions];

    const apiMarketPartnerConfig = new ApiRequestBuilder()
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryContactSearch)
      .setBodyInfo(
        {
          query: {
            andConditions,
            // page size of 500 should be enough to laod all marketpartners
            pageRequest: {pageIndex: 0, pageSize: 5000},
            sortOrder: [
              {
                columnName: 'name',
                order: 'ASC'
              }
            ]
          }
        })
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .build();


    const resultApiRequest = await this.apiRequestService.callApi(apiMarketPartnerConfig);

    if (resultApiRequest.status === 'success') {
      if (!resultApiRequest.data) return;
      if (!resultApiRequest.data.partners) return;
      // Register the list on the enum service
      const enumItemsList: EnumItem[] = [];
      for (const p of resultApiRequest.data.partners) {
        enumItemsList.push({
          label: p.name + ' (' + p.eic + ')',
          data: p.eic
        });
      }
      this.enumUtilsService.setEnumValues(enumList, enumItemsList);
    }
  }
}
