import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {NavigationMenuDefinition, NavigationSubMenuDefinition} from "../../shared/nav/NavigationMenuDefinition";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {COMPANYPARTNERS_NAVIGATION_LIST} from "../navigation-list";
import {SessionService} from "../../shared/session/session.service";
import {MediaMatcher} from "@angular/cdk/layout";
import {CustomTranslateService} from "../../shared/services/custom-translate.service";
import {MatSnackBar, MatSnackBarRef} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {MatIconComponent} from "../../shared/material-components/mat-icon-component/mat-icon.component";
import {UserManualService} from "../../shared/services/user-manual.service";
import {UpdateService} from "../../shared/services/update-service";

@Component({
  selector: 'app-companypartners',
  templateUrl: './companypartners-app.component.html',
  styleUrls: ['./companypartners-app.component.scss']
})
export class CompanypartnersAppComponent extends MatIconComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  protected currentTabs: NavigationSubMenuDefinition[] = [];
  protected activeTab: NavigationSubMenuDefinition | undefined;

  protected sidenavCollapsed: boolean = false;

  protected navLinks = COMPANYPARTNERS_NAVIGATION_LIST;

  protected quickStartLink = '';
  protected userManualLink = '';

  private updateSubscription;
  private languageSubscription;
  private routerSubscription;
  private snackBarRef: MatSnackBarRef<any> | undefined;

  constructor(protected matIconRegistry: MatIconRegistry,
              protected domSanitizer: DomSanitizer,
              private sessionService: SessionService,
              public customTranslateService: CustomTranslateService,
              private translate: TranslateService,
              private router: Router,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private _snackBar: MatSnackBar,
              private userManualService: UserManualService,
              private updateService: UpdateService) {
    super(matIconRegistry, domSanitizer);

    this.routerSubscription = this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe({next: (event: NavigationEnd) => this.setCurrentTabs(event.urlAfterRedirects)});

    this.setCurrentTabs(this.router.routerState.snapshot.url);

    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => {
      this.sidenavCollapsed = this.mobileQuery.matches;
      changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.sidenavCollapsed = this.mobileQuery.matches;

    this.languageSubscription = this.customTranslateService.languageChanged.subscribe(_ => {
      this._snackBar.open(this.translate.instant('LanguageHasBeenChanged'), '×', {verticalPosition: "top", duration: 5000});
    });

    this.updateSubscription = this.updateService.updatesAvailable$.subscribe(
      {next: (value: boolean) => {
        if(value) {
          this.snackBarRef = this._snackBar.open(
            this.translate.instant('UpdatesNotification'),
            this.translate.instant('UpdatesNotificationDoReload'),
            {verticalPosition: 'top'}
          );

          this.snackBarRef.onAction().subscribe(_ => this.reloadPage());
        }
      }});

    this.quickStartLink = this.userManualService.getQuickstartLink('cp');
    this.userManualLink = this.userManualService.getUserManualCompanyLink();
  }

  reloadPage() {
    console.log('page reload, reason: requested by user');
    window.location.reload();
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    this.updateSubscription.unsubscribe();
    this.languageSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  setCurrentTabs(url: string) {
    // parts[0] = "", parts[1] = "company", parts[2] = "parentMenu", parts[3]? = "subRoute"
    const parts = url.split('/');
    let currentParentUrl = '/' + parts[1] + '/' + parts[2];
    let currentLink = COMPANYPARTNERS_NAVIGATION_LIST.find((e: NavigationMenuDefinition) => e.fullPath == currentParentUrl);
    if(currentLink != undefined && currentLink.subRoutes != undefined && currentLink.subRoutes.length != 0) {
      this.currentTabs = currentLink.subRoutes;
      this.activeTab = currentLink.subRoutes.find((e: NavigationSubMenuDefinition) => url.startsWith(e.fullPath));
    } else {
      this.currentTabs = [];
      this.activeTab = undefined;
    }
  }

  onBurgerClick() {
    this.sidenavCollapsed = !this.sidenavCollapsed;
  }

  isUserAllowedToSection(navLink: NavigationMenuDefinition) {
    //const mpi = this.sessionService.getCurrentPartnerInfo();
    return !(navLink.fnAllowed && !navLink.fnAllowed(this.sessionService, undefined));
  }
}
