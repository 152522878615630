<page-loading *ngIf="!initialized"></page-loading>

<div *ngIf="initialized" id="page-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base  no-botton-margin">
        <!--Tabs Content-->
        <div class="tab-content">
          <div id="demo-rgt-tab-2" class="tab-pane fade active in">
            <smart-table #tableMarketPartners
                         [offlineData]=dataSetMarketPartnersUsers
                         [tableConfig]=tableMarketPartnersConfig
                         (onRowClicked)="onRowClicked($event)"
                         (onActionClicked)="onActionPerformed($event)"
            ></smart-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
