/* eslint-disable @angular-eslint/no-output-on-prefix, @angular-eslint/component-selector, @angular-eslint/no-input-rename */
import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {EnumUtilsService} from '../../../../services/enum-utils/enum-utils.service';
import {UtilsService} from '../../../../services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomValidators} from '../classes/CustomValidators';

import {ApiRequestService} from '../../../../api-request/service/api-request.service';
import {NotificationsService} from '../../../../services/notifications.service';
import {SMART_FORM_CONFIG} from './smart-form.config';
import {SmartFormConfigDefinition} from '../classes/SmartFormConfigDefinition';
import {ApiRequestBuilder} from '../../../../api-request/builder/api-request.builder';
import {SmartFormButtonDefinition} from '../classes/SmartFormButtonDefinition';
import {
  SmartFormFormFieldDefinition,
  SmartFormShowCondition,
  SmartFormShowConditionOperator
} from '../classes/SmartFormFormFieldDefinition';
import {ActionEvent} from '../../smart-shared/classes/ActionEvent';
import {SmartCssClassesByTypeDefinition} from '../../smart-shared/classes/SmartCssClassesByTypeDefinition';
import {SmartToolbarActionDefinition} from '../../smart-shared/classes/SmartToolbarActionDefinition';
import {ActionsToolbarComponent} from '../../actions-toolbar/actions-toolbar.component';
import {NameValueMap} from '../../smart-shared/classes/KeyValueMap';
import {EnumDefinition, EnumItem} from '../../../../services/enum-utils/classes/enum-definition';
import {FieldValueChangedEvent} from '../../smart-shared/classes/FieldValueChangedEvent';

import {DateTimeService} from '../../../../services/date-time.service';
import {CustomTranslateService} from '../../../../services/custom-translate.service';
import {SmartModalService} from '../../smart-modal/service/smart-modal.service';
import {SmartModalConfigDefinition} from '../../smart-modal/classes/SmartModalConfigDefinition';
import {SmartModalBuilder} from '../../smart-modal/builder/smart-modal.builder';
import {SMART_FORM_OBJECTSARRAY_DEFS} from '../definitions/smart-form-formobjectsarray.definitions';
import {USERS_API_ENDPOINTS_LIST} from '../../../../../users/requests/api-endpoints-list';
import {find, get} from "lodash-es";

declare var $: any;

@Component({
  selector: 'smart-form',
  templateUrl: './smart-form.component.html',
  styleUrls: ['./smart-form.component.scss']
})

export class SmartFormComponent implements OnInit, AfterViewChecked {
  @Input('isModal') isModal = false;
  @Input('formConfig') formConfig?: SmartFormConfigDefinition;
  @Output() onSubmittedFormValue = new EventEmitter<NameValueMap<string>>();
  @Output() onSuccessFormValue = new EventEmitter<NameValueMap<string>>();
  @Output() onSuccessResponseValue = new EventEmitter<any>();
  @Output() onInitFormValue = new EventEmitter<NameValueMap<string>>();
  @Output() onFieldValueChanged = new EventEmitter<FieldValueChangedEvent>();

  @Output() onActionClicked = new EventEmitter<ActionEvent>();

  @Output() onCancelForm = new EventEmitter<undefined>();

  cssClassesByType: SmartCssClassesByTypeDefinition = {
    'layoutLabelInput': {
      'label': ' ',
      'input': ' ',
      'formGroupContainer': ' '
    },
    'layoutGeneral': {
      'formContainer': ''
    }
  };


  fields: SmartFormFormFieldDefinition[] = [];
  title?: string;
  resultMessage = '';
  errorResultSubmit = false;
  formId: string;
  isSendingRequest = false;
  initialized = false;
  overrideDefaultsApply: NameValueMap<any> = {};

  smartForm?: UntypedFormGroup;
  actionsToolbar?: SmartToolbarActionDefinition[];
  showInvalidFieldsAlways = true;
  showStarOnRequiredField = false;
  showActionsToolbarIfEmpty = false;
  initDataFlatten: any = {};
  initFieldsConfig: SmartFormFormFieldDefinition[] = [];
  includeUnusedInitDataInOutput = false;
  removeEmptyValuesFromOutput = false;
  objectsArrayDefinitions: NameValueMap<SmartFormFormFieldDefinition[]> = {};
  columnCounter = 0;
  messageForNotRegisteredRecipient?: any;

  @ViewChild('actionsToolbarRef') actionsToolbarRef?: ActionsToolbarComponent;

  constructor(
    private utilsService: UtilsService,
    private translate: TranslateService,
    private apiRequestService: ApiRequestService,
    public enumUtilsService: EnumUtilsService,
    private notificationsService: NotificationsService,
    private customTranslateService: CustomTranslateService,
    public dateTimeService: DateTimeService,
    private smartModalService: SmartModalService,
    private formBuilder: UntypedFormBuilder) {

    this.formId = 'form-' + this.utilsService.getRandomUID();
  }

  async ngOnInit() {
    const self = this;
    if (this.formConfig) {
      if (this.formConfig.fields) {
        this.initFieldsConfig = JSON.parse(JSON.stringify(this.formConfig.fields));
      }
      this.title = this.formConfig.title;
      this.cssClassesByType = this.formConfig.cssClassesByType;
      this.showInvalidFieldsAlways = this.formConfig.showInvalidFieldsAlways;
      this.showStarOnRequiredField = this.formConfig.showStarOnRequiredField;
      this.showActionsToolbarIfEmpty = this.formConfig.showActionsToolbarIfEmpty;
      this.includeUnusedInitDataInOutput = this.formConfig.includeUnusedInitDataInOutput;
      this.removeEmptyValuesFromOutput = this.formConfig.removeEmptyValuesFromOutput;
      this.objectsArrayDefinitions = JSON.parse(JSON.stringify(this.formConfig.objectsArrayDefinitions));

      if (this.formConfig.initialErrorMessage) {
        this.resultMessage = this.formConfig.initialErrorMessage;
        this.errorResultSubmit = true;
      }

      this.actionsToolbar = this.formConfig.actionsToolbar;
      const initFormValues = await self.reload();

      this.onInitFormValue.emit(initFormValues);
      setTimeout(() => {
        self.updateActionsToolbarSelectedData();
      }, 0);
    }
  }

  redrawFormWithChangedData(preFilledDataUnflatten: any) {
    if (!this.formConfig) throw new Error('redraw called without formConfig');
    const self = this;
    const preFilledDataFlatten = JSON.parse(JSON.stringify(
      self.utilsService.flattenObject(preFilledDataUnflatten, false)
    ));

    this.fields = self.initFieldsConfig ? JSON.parse(JSON.stringify(self.initFieldsConfig)) : [];
    if (this.formConfig) {
      this.formConfig.initData = JSON.parse(JSON.stringify(preFilledDataUnflatten));
      // Go through the fields list and explode the fields of type 'array'
      if (preFilledDataFlatten && this.formConfig) {
        for (let indexField = 0; indexField < this.fields.length; indexField++) {
          const thisField: SmartFormFormFieldDefinition = this.fields[indexField];
          if ((thisField.type === 'array') || (thisField.type === 'object')) {
            // Search in the this.formConfig.initData for objects already initialized inside the array
            const entriesArray = (thisField.type === 'array') ?
              get(this.formConfig.initData, thisField.name.replace(/:/g, '.'), []) :
              [get(this.formConfig.initData, thisField.name.replace(/:/g, '.'), {})];

            if (!thisField.objectId || !(thisField.objectId in self.objectsArrayDefinitions)) {
              // It is not defined??=
              console.error('Definition of Array ', thisField.objectId, ' could not be found');
            } else {
              const entryArrayDefinition = self.objectsArrayDefinitions[thisField.objectId];
              for (let indexEntryArray = entriesArray.length - 1; indexEntryArray >= 0; indexEntryArray--) {
                // Add them in reverse order
                let thisEntryData = JSON.parse(JSON.stringify(entriesArray[indexEntryArray]));
                if (thisEntryData === '') {
                  thisEntryData = {};
                }
                // fieldAbsolutePath
                const prefixEntry = (thisField.type === 'array') ? `${thisField.name}:${indexEntryArray}` : thisField.name;
                // showConditions
                const parentShowConditions = thisField?.showConditions;
                for (let indexArrayField = entryArrayDefinition.length - 1; indexArrayField >= 0; indexArrayField--) {
                  // Add them in reverse order
                  const toAddEntry: SmartFormFormFieldDefinition = JSON.parse(JSON.stringify(entryArrayDefinition[indexArrayField]));
                  let foundValue = get(thisEntryData, toAddEntry.name.replace(/:/g, '.'), undefined);
                  if (thisField.inputList && (thisEntryData instanceof String || typeof thisEntryData === 'string')) {
                    foundValue = thisEntryData;
                  }
                  if (foundValue !== undefined /*toAddEntry.name in thisEntryData*/) {
                    toAddEntry.default = foundValue; // thisEntryData[toAddEntry.name];
                  } else if (toAddEntry.type === 'object') {
                    toAddEntry.default = {};
                  } else if (toAddEntry.type === 'array') {
                    toAddEntry.default = [];
                  }
                  if (!(toAddEntry.cssClasses)) {
                    toAddEntry.cssClasses = ' ';
                  }

                  toAddEntry.readOnly = thisField.readOnly;

                  toAddEntry.cssClasses += ' col-xs-12 ';
                  if (thisField.type === 'array') {
                    if (indexArrayField === 0) {
                      toAddEntry.cssClasses += ' form-field-array-first ';
                      if (thisField.readOnly) {
                        // If readonly disable/hide the 'delete' icon
                        toAddEntry.cssClasses += ' form-field-array-readonly ';
                      }

                      if ((entriesArray.length > 1) && (indexEntryArray > 0) && !thisField.inputList) {
                        toAddEntry.cssClasses += ' form-field-array-not-last-entry ';
                      }
                    } else if (indexArrayField === entryArrayDefinition.length - 1) {
                      toAddEntry.cssClasses += ' form-field-array-last ';
                      if (indexEntryArray === entriesArray.length - 1) {
                        toAddEntry.cssClasses += ' form-field-array-last-entry ';
                      }
                    }
                  } else if (thisField.type === 'object') {
                    if (indexArrayField === 0 && thisField.cssClasses?.includes('form-field-array-first ')) {
                      toAddEntry.cssClasses += ' form-field-array-first ';
                      if (thisField.cssClasses?.includes(' form-field-array-readonly ')) {
                        // If readonly disable/hide the 'delete' icon
                        toAddEntry.cssClasses += ' form-field-array-readonly ';
                      }

                      if (thisField.cssClasses?.includes('form-field-array-not-last-entry ')) {
                        toAddEntry.cssClasses += ' form-field-array-not-last-entry ';
                      }
                    } else if (indexArrayField === entryArrayDefinition.length - 1 && thisField.cssClasses?.includes('form-field-array-last ')) {
                      toAddEntry.cssClasses += ' form-field-array-last ';
                      if (indexEntryArray === entriesArray.length - 1 && thisField.cssClasses?.includes('form-field-array-last-entry ')) {
                        toAddEntry.cssClasses += ' form-field-array-last-entry ';
                      }
                    }
                  }
                  toAddEntry.name = thisField.inputList ? prefixEntry : prefixEntry + `:${toAddEntry.name}`;

                  if (parentShowConditions !== undefined) {
                    toAddEntry.showConditions = this.setShowConditionsAbsolutePath(parentShowConditions, thisField.name);
                  }
                  // Only non-empty array fields, e.g. their title, shall be shown in the readonly view
                  if (!thisField.readOnly || !Array.isArray(foundValue) || foundValue?.length !== 0) {
                    this.fields.splice(indexField + 1, 0, toAddEntry);
                  }
                }
              }
            }
            if (thisField.type === 'object') {
              // remove it from the list
              this.fields.splice(indexField, 1);
              indexField = indexField - 1;
              continue;
            }
          }

          thisField.showConditions = this.setShowConditionsAbsolutePath(thisField.showConditions, thisField.name);
          thisField.omitFromRequestConditions = this.setShowConditionsAbsolutePath(thisField.omitFromRequestConditions, thisField.name);
        }
      }
    }

    this.removeDanglingConsumerHeaders();

    const initFormValues: any = {};
    const smartFormBuilderConfig: any = {};
    let newPasswordField = '';
    const formValidators: ValidatorFn[] = this.formConfig.formValidators.slice();
    this.fields.forEach(function (thisField: SmartFormFormFieldDefinition) {
      const controlValidators = [];
      let thisValue;
      thisValue = ('default' in thisField) ? thisField.default : null;

      if (thisValue === undefined || thisValue === null || thisValue === "") {
        if (thisField.readOnly && ('readonlyDefault' in thisField)) {
          thisValue = thisField.readonlyDefault;
        }
      }

      if (thisField.name in preFilledDataFlatten) {
        thisValue = preFilledDataFlatten[thisField.name];
      }
      if (thisField.valueIsBoolean) {
        if (thisValue === true) {
          thisValue = '1';
        } else if (thisValue === false) {
          thisValue = '0';
        }
      }

      if (thisField.type === 'input' && thisField.commaSeparatedList) {
        if (thisValue instanceof Array) {
          thisValue = thisValue.join(', ');
        }
      }
      if (thisField.type === 'checkboxmulti' && thisField.optionsAsList) {
        if (thisValue instanceof Array) {
          thisValue = thisValue.join(',');  // note: no space allowed
        }
      }

      if (thisField.validation && !thisField.readOnly) {
        if (thisField.validation.required) {
          controlValidators.push(Validators.required);
        }
        if (thisField.type === 'datetime') {
          thisField.validation.type = 'datetime';
        }
        if (thisField.validation.type === 'password') {
          if (newPasswordField !== '') throw new Error('smartForm does not support multiple passwords');
          newPasswordField = thisField.name;
        }
        if (thisField.validation.type === 'password-confirm') {
          if (newPasswordField === '') throw new Error('password-confirm validator without matching password validator');
          formValidators.push(CustomValidators.mustMatch(newPasswordField, thisField.name));
        } else if (thisField.validation) {
          const validator = CustomValidators.getValidatorControl(thisField.validation);
          if (validator) {
            controlValidators.push(validator);
          }
        }
      }

      if (thisField && thisField.allowedValues && thisField.allowedValues.type === 'localDefined') {
        // Resort the values to be selected
        const sortedEnum: EnumDefinition = self.enumUtilsService.resortEnumDefinition({
          id: 'tosort',
          items: thisField.allowedValues.data
        });
        thisField.allowedValues.data = sortedEnum.items;
      }

      initFormValues[thisField.name] = thisValue;
      smartFormBuilderConfig[thisField.name] = [thisValue, controlValidators];
    });
    this.smartForm = this.formBuilder.group(smartFormBuilderConfig, {validators: formValidators});
    this.subscribeToChanges();
    this.initialized = true;
    return initFormValues;
  }

  private removeDanglingConsumerHeaders(): void {
    const consumerTitleDefinition = SMART_FORM_OBJECTSARRAY_DEFS['consumerObject'][0];
    const consumerTitleObject = this.fields.find((x) => x.name.endsWith(consumerTitleDefinition.name) && x.title === consumerTitleDefinition.title);

    if (consumerTitleObject) {
      const firstSemicolonIndex = consumerTitleObject.name.indexOf(':');
      const prefix = consumerTitleObject.name.substr(0, firstSemicolonIndex + 1);
      const fieldsWithSamePrefix = this.fields.filter(field => field.name.startsWith(prefix));
      if (fieldsWithSamePrefix.length === 1 && fieldsWithSamePrefix[0] === consumerTitleObject) {
        const headingIndex = this.fields.indexOf(consumerTitleObject);
        if (headingIndex > -1) {
          this.fields.splice(headingIndex, 1);
        }
      }
    }
  }

  private setShowConditionsAbsolutePath(showConditions: SmartFormShowCondition[] | undefined, fieldName: string): SmartFormShowCondition[] | undefined {
    // Set the fieldAbsolutePath of the showConditions if not already set
    if (showConditions) {
      showConditions.forEach(function (thisShowCondition: SmartFormShowCondition) {
        // Remove the last ':' if present
        const lastIndexOfSeparator = fieldName.lastIndexOf(':');
        const prefixForFieldCondition = lastIndexOfSeparator >= 0 ? fieldName.substr(0, lastIndexOfSeparator + 1) : '';
        if (thisShowCondition.fieldAbsolutePath === undefined) {
          thisShowCondition.fieldAbsolutePath = prefixForFieldCondition;
        }
      });
    }

    return showConditions;
  }

  ngAfterViewChecked() {
    this.initDateTimeFields();
    this.initAutocompleteFields();
  }

  onTimeInputChange(newValue: string, fieldIndex: number) {
    const thisField = this.fields[fieldIndex];
    const parsedInputValue = this.parseTimeInputValue(newValue);
    const formattedDate = this.getCurrentDateInputValue(fieldIndex);
    if (this.smartForm /*&& parsedInputValue */ && formattedDate) {
      if (parsedInputValue) {
        const formattedIsoDateTime =
          this.dateTimeService.getDateTimeAsIsoFormatFromLocaleDate(formattedDate + ' ' + parsedInputValue, true);

        this.smartForm.controls[thisField.name].setValue(formattedIsoDateTime);
      } else {
        this.smartForm.controls[thisField.name].setValue(formattedDate);
      }
    }

  }

  parseTimeInputValue(inputValue: string) {
    let toReturn = null;
    if (inputValue) {
      let parsedInputValue = inputValue.trim();
      // Add the first 0 if it something like 1:59
      if (parsedInputValue.indexOf(':') === 1) {
        parsedInputValue = '0' + parsedInputValue;
      }
      // If no seconds are given, write ':00'
      const parsedInputValueSplitted = parsedInputValue.split(':');
      if (parsedInputValueSplitted.length === 2) {
        parsedInputValue += ':00';
      }

      // Check against a regular expression
      const regExp = CustomValidators.getRegExprFromId('time');
      if (parsedInputValue.match(regExp)) {
        // OK
        toReturn = parsedInputValue;
      }
    }
    return toReturn;
  }

  getCurrentDateInputValue(fieldIndex: number) {
    const self = this;
    const thisDatePickerInputSelector = '#' + self.formId + ' #datepicker-input-' + self.formId + '_' + fieldIndex;
    const inputValue = $(thisDatePickerInputSelector).val();
    return inputValue ? inputValue : null;
  }

  getCurrentTimeInputValue(fieldIndex: number) {
    const self = this;
    const thisDateTimePickerSelector = '#' + self.formId + ' #datepicker-input-' + self.formId + '_' + fieldIndex + '_time';
    const inputValue = $(thisDateTimePickerSelector).val();
    return this.parseTimeInputValue(inputValue);
  }


  getDefaultValueTimeField(fieldIndex: number): any {
    const datetimeValue = this.getDefaultValueField(fieldIndex);
    let toReturn = '00:00:00';
    if (datetimeValue) {
      const linuxTime = this.dateTimeService.getLinuxFromISO8601UTC(datetimeValue);
      if (linuxTime !== undefined) {
        const localeDateTimeFormat = this.dateTimeService.getDateTimeAsLocaleFormatLinux(linuxTime, true);
        const localeDateTimeFormatSplitted = localeDateTimeFormat.split(' ');
        toReturn = localeDateTimeFormatSplitted[localeDateTimeFormatSplitted.length - 1];
      }
    }
    return toReturn;
  }

  initAutocompleteFields() {
    const self = this;
    // See documentation for Bootstrap-3-Typeahead on:
    // https://github.com/bassjobsen/Bootstrap-3-Typeahead
    $('#' + self.formId + ' .autocomplete-not-init').each(function () {
      if (!self.smartForm) {
        return;
      }
      // @ts-ignore
      $(this).removeClass('autocomplete-not-init');
      // @ts-ignore
      const thisFieldIndex = $(this).data('fieldindex');
      const thisField = self.fields[thisFieldIndex];
      const thisAutocompleteSelector = '#' + self.formId + ' #autocomplete-input-' + self.formId + '_' + thisFieldIndex;
      const listOptions = self.getSelectOptionsField(thisFieldIndex);
      const sourceForTypeahead: any[] = [];
      if (listOptions) {
        listOptions.forEach(function (thisOption: EnumItem) {
          sourceForTypeahead.push({
            id: thisOption.data,
            name: thisOption.label
          });
        });
      }
      $(thisAutocompleteSelector).typeahead({
        minLength: 0,
        items: 5,
        showHintOnFocus: true,
        source: sourceForTypeahead,
        autoSelect: false,
        fitToElement: false,
        afterSelect: function (selectedTypeahedEntry: any) {
          this.$element[0].value = selectedTypeahedEntry?.id;
          if (!self.smartForm) {
            return;
          }
          self.smartForm.controls[thisField.name].setValue(selectedTypeahedEntry.id);
        }
      });
    });
  }

  initDateTimeFields() {
    const self = this;

    $('#' + self.formId + ' .datepicker-not-init').each(function () {
      if (!self.smartForm) {
        return;
      }
      // @ts-ignore
      $(this).removeClass('datepicker-not-init');
      // @ts-ignore
      const thisFieldIndex = $(this).data('fieldindex');
      const thisField = self.fields[thisFieldIndex];

      const dateValue = self.smartForm.controls[thisField.name].value;
      let startFormattedValue = '';

      if (typeof dateValue === 'string' || dateValue instanceof String ) {
        startFormattedValue = (thisField.type === 'date') ?
          self.dateTimeService.getDateAsLocaleFormatFromIsoDate(self.smartForm.controls[thisField.name].value) :
          self.dateTimeService.getDateTimeAsLocaleFormatFromIsoDate(self.smartForm.controls[thisField.name].value, true);
      }
      else {
        startFormattedValue = (thisField.type === 'date') ?
          self.dateTimeService.getDateAsLocaleFormatFromJson(self.smartForm.controls[thisField.name].value) :
          self.dateTimeService.getDateTimeAsLocaleFormatFromJson(self.smartForm.controls[thisField.name].value, true);
      }
      const thisDatePickerInputSelector = '#' + self.formId + ' #datepicker-input-' + self.formId + '_' + thisFieldIndex;
      const thisDatePickerObjectSelector = '#' + self.formId + ' #datepicker-object-' + self.formId + '_' + thisFieldIndex;
      // See documentation for bootstrap-datepicker on:
      // https://bootstrap-datepicker.readthedocs.io/en/latest/options.html
      $(thisDatePickerObjectSelector).datepicker({
        autoclose: true,
        todayBtn: 'linked',
        clearBtn: true,
        // todayHighlight: true,
        language: self.customTranslateService.getCurrentLangShort(),
        format: self.dateTimeService.getLocaleDateFormatPicker(),
      }).on('hide', function (_e: any) {
        const formattedDate = $(thisDatePickerObjectSelector)
          .datepicker('getFormattedDate');
        if (!self.smartForm) {
          return;
        }
        const timeValue = self.getCurrentTimeInputValue(thisFieldIndex);
        if (thisField.type === 'date' || timeValue === null) {
          const formattedIsoDate = self.dateTimeService.getDateAsIsoFormatFromLocaleDate(formattedDate);
          self.smartForm.controls[thisField.name].setValue(formattedIsoDate);
        } else if (thisField.type === 'datetime') {
          const formattedIsoDateTime = self.dateTimeService.getDateTimeAsIsoFormatFromLocaleDate(formattedDate + ' ' + timeValue, true);
          self.smartForm.controls[thisField.name].setValue(formattedIsoDateTime);
        }
      })
        .on('changeDate', function (e: any) {
          if (!self.smartForm) {
            return;
          }
          const formattedIsoDate = self.dateTimeService.getDateAsIsoFormatFullISOString(e.date);
          const timeValue = thisField.type === 'date' ? null : self.getCurrentTimeInputValue(thisFieldIndex);
          const formattedDate = self.dateTimeService.getDateAsLocaleFormatFromIsoDate(formattedIsoDate);
          $(thisDatePickerInputSelector).val(formattedDate);
          if (thisField.type === 'date' || timeValue === null) {
            self.smartForm.controls[thisField.name].setValue(formattedIsoDate);
          } else if (thisField.type === 'datetime') {
            const newValue = self.dateTimeService.getDateTimeAsIsoFormatFromLocaleDate(formattedDate + ' ' + timeValue, true);
            self.smartForm.controls[thisField.name].setValue(newValue);
          }
        });

      if (startFormattedValue) {
        if (thisField.type === 'date') {
          $(thisDatePickerObjectSelector).datepicker('setDate', startFormattedValue);
        } else {
          $(thisDatePickerObjectSelector).datepicker('setDate', (startFormattedValue.split(' '))[0]);
        }
      }
    });
  }

  updateActionsToolbarSelectedData() {
    if (this.actionsToolbarRef) {
      this.actionsToolbarRef.setSelectedData(null, this.getCurrentValueFormProcessed());
    }
  }

  subscribeToChanges(): void {
    const self = this;
    if (!self.smartForm) {
      return;
    }

    self.smartForm.valueChanges.subscribe(val => {
      if (self.actionsToolbarRef) {
        self.actionsToolbarRef.setSelectedData(null, val);
      }
    });

    const fieldKeys = Object.keys(self.smartForm.value);
    fieldKeys.forEach((fieldKey) => {
      if (self.smartForm) {
        const smartFormField = self.smartForm.get(fieldKey);
        if (smartFormField) {
          smartFormField.valueChanges.subscribe(val => {
            self.onFieldValueChanged.emit({
              field: fieldKey,
              value: val
            });
          });
        }
      }
    });
  }


  getClassesFieldContainer(fieldIndex: number): string {
    const thisField = this.fields[fieldIndex];
    let toReturn = '';
    if (thisField.type === 'hidden') {
      toReturn = 'hidden';
    } else if (thisField.cssClasses) {
      toReturn = thisField.cssClasses;
    } else {
      // return 'col-sm-6';
      toReturn = this.cssClassesByType.layoutGeneral.fieldContainer;
    }
    // add level of field (arrays)
    toReturn += this.getCSSLevelArrayOfField(thisField);

    // add type of field
    toReturn += ' form-field-type-' + thisField.type;
    return toReturn;
  }

  getCSSLevelArrayOfField(field: SmartFormFormFieldDefinition) {
    const count = (field.name.match(/:\d:/g) || []).length;

    const colClasses = field.cssClasses?.match(/col-xs-\d+/);

    if (Array.isArray(colClasses) && colClasses.length) {
      let firstColClass = colClasses[0];
      const indexOfLastHyphon = firstColClass.lastIndexOf('-');
      const colWidth = +firstColClass.substr(indexOfLastHyphon + 1);

      if (this.columnCounter !== 0 && (this.columnCounter + colWidth <= 12)) {
        if (!this.hideOrOmitConditionField(field.name)) {
          this.columnCounter = (this.columnCounter + colWidth) % 12;
        }
        return '';
      }
      if (!this.hideOrOmitConditionField(field.name)) {
        this.columnCounter = (this.columnCounter + colWidth) >= 12 ? 0 : (this.columnCounter + colWidth);
      }
    }

    return count ? ' form-field-array-level-' + count + ' ' : '';
  }

  showHelpMessageField(_fieldIndex: number) {
    // Check if error on message, then do not show help?
    return true;
  }

  getSelectOptionsField(fieldIndex: number): EnumItem[] | undefined {
    const thisField = this.fields[fieldIndex];
    if (thisField && thisField.allowedValues) {
      let data: EnumItem[];
      if (thisField.allowedValues.type === 'localDefined') {
        data = thisField.allowedValues.data;
      } else if (thisField.allowedValues.type === 'globalRegister') {
        data = this.enumUtilsService.getEnumValues(thisField.allowedValues.data);
      } else {
        return undefined;
      }
      return data.filter(enumItem => !enumItem.omitFromDropdown);
    }
    return undefined;
  }

  getSelectedOptionLabel(fieldIndex: number, fieldName: string): string | undefined {
    const thisFieldValue = this.getCurrentFormFieldValue(fieldName);
    const thisField = this.fields[fieldIndex];
    if (thisField && thisField.allowedValues) {
      let labelToTranslate: string | undefined;
      switch (thisField.allowedValues.type) {
        case 'localDefined':
          const foundItem: any = find(thisField.allowedValues.data, {data: thisFieldValue});
          if (foundItem !== undefined) {
            labelToTranslate = foundItem.label;
          }
          break;
        case 'globalRegister':
          labelToTranslate = this.enumUtilsService.getLabelFromEnumValue(thisField.allowedValues.data, thisFieldValue);
          break;
      }

      if (labelToTranslate !== undefined) {
        return this.translate.instant(labelToTranslate);
      }
    }
    return undefined;
  }

  getDefaultValueField(fieldIndex: number): any {
    const thisField = this.fields[fieldIndex];
    const fieldName = thisField.name;
    if (fieldName in this.overrideDefaultsApply) {
      return this.overrideDefaultsApply[fieldName];
    }
    if ('default' in thisField) {
      return thisField.default;
    } else {
      return null;
    }
  }

  isDirtyForm(): boolean {
    return !this.smartForm ? false : this.smartForm.dirty;
  }

  isValidForm(): boolean {
    // Check every field and not include on the validation
    // the fields that are not being shown due to a showCondition rule
    // this.smartForm.controls[fieldName].valid);
    const self = this;
    let formIsValid = true;
    if (self.smartForm) {
      Object.keys(self.smartForm.controls).forEach(function (thisFieldName: string) {
        if (self.smartForm) {
          const fieldInfo = self.getFormFieldInfoFromName(thisFieldName);
          if (!self.smartForm.controls[thisFieldName].valid) {
            // Check if field is not being shown due to a showCondition rule
            // in that case the form would still be valid
            if (!fieldInfo.showConditions || !self.hideOrOmitConditionField(thisFieldName)) {
              formIsValid = false;
            }
          }
        }
      });
    }
    return formIsValid;
    // return !this.smartForm ? false : this.smartForm.valid;
  }

  processFormValueBeforeSending(formValue: any): NameValueMap<any> {
    const self = this;
    const allFormKeys = Object.keys(formValue);
    allFormKeys.forEach(function (thisFormKey) {
      let newValue = formValue[thisFormKey];

      self.fields.some(function (thisFormFieldInfo) {
        if (thisFormFieldInfo.name === thisFormKey) {
          // TODO: add field value processing before sending if needed
          if (thisFormFieldInfo.valueIsBoolean) {
            switch (newValue) {
              case '1':
                newValue = true;
                break;
              case '0':
                newValue = false;
                break;
              default:
                newValue = undefined;
                break;
            }
          }

          switch (thisFormFieldInfo.type) {
            case 'datetime':
              if (newValue === '') {
                newValue = null;
              }
              if (newValue && !thisFormFieldInfo.dateAsString) {
                const [date, time] = newValue.split('T');
                const [year, month, day] = date.split('-');
                const [hours, minutes, seconds] = time.replace('Z', '').split(':');
                newValue = {
                  year: parseInt(year, 10),
                  month: parseInt(month, 10),
                  day: parseInt(day, 10),
                  hours: parseInt(hours, 10),
                  minutes: parseInt(minutes, 10),
                  seconds: parseInt(seconds, 10),
                };
              }
              break;
            case 'date':
              if (newValue && !thisFormFieldInfo.dateAsString) {
                const [year, month, day] = newValue.split('-');
                newValue = {
                  year: parseInt(year, 10),
                  month: parseInt(month, 10),
                  day: parseInt(day, 10)
                };
              }
              break;
            case 'input':
              if (thisFormFieldInfo.commaSeparatedList) {
                if (newValue) {
                  newValue = newValue.split(/[, ]+/);
                } else {
                  newValue = [];
                }
              }
              break;
            case 'array':
              newValue = []; // the array will be rebuild from the nested form fields
              break;
            case 'checkboxmulti':
              if (thisFormFieldInfo.optionsAsList) {
                if (newValue) {
                  newValue = newValue.split(/[,]+/);  // note: no space allowed
                } else {
                  newValue = [];
                }
              }
              break;
          }
          if (thisFormFieldInfo.showConditions && self.hideOrOmitConditionField(thisFormFieldInfo.name)) {
            // Value is hidden and not validated, do not send it on the request
            newValue = undefined;
          }
          if (thisFormFieldInfo.omitFromRequestConditions && self.hideOrOmitConditionField(thisFormFieldInfo.name, 'omitFromRequestConditions')) {
            newValue = undefined;
          }
          if (thisFormFieldInfo.omitFromRequest) {
            newValue = undefined;
          }
          return true;
        } else {
          return false;
        }
      });

      formValue[thisFormKey] = newValue;
    });
    return formValue;
  }

  markAsSavedChanges() {
    if (!this.smartForm) {
      return;
    }
    this.smartForm.markAsPristine();
  }

  markAsUnsavedChanges() {
    if (!this.smartForm) {
      return;
    }
    this.smartForm.markAsDirty();
  }

  async onDownload() {
    if (this.messageForNotRegisteredRecipient) {
      await this.apiRequestService.downloadFile({
        ...USERS_API_ENDPOINTS_LIST.downloadData
      }, {
        Filename: this.messageForNotRegisteredRecipient?.filename as string,
        Data: this.messageForNotRegisteredRecipient?.data
      });
    } else {
      console.log('Could not download message because there is none.');
    }
  }

  async onSubmit(formValue: NameValueMap<any>) {
    if (!this.smartForm || !this.formConfig) {
      return false;
    }
    let formValueToSend = JSON.parse(JSON.stringify(formValue));
    if (!this.isValidForm()) {
      this.markFormGroupTouched(this.smartForm);
      return false;
    }

    if (this.isSendingRequest) {
      return false;
    }

    // process form before sending..Ex date in some special format, etc..
    formValueToSend = this.mergeInitDataAndFormDataToObject(this.processFormValueBeforeSending(formValueToSend));
    this.resultMessage = '';
    this.isSendingRequest = true;
    this.onSubmittedFormValue.emit(formValueToSend);

    if (this.formConfig.apiSendDataRequestConfig) {
      // const resultApiRequest = await this.apiRequestService.doApiRequest(this.formConfig.apiCall, formValueToSend);
      const apiRequestConfig = new ApiRequestBuilder(this.formConfig.apiSendDataRequestConfig)
        .addBodyInfo(formValueToSend)
        .build();

      const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
      if (resultApiRequest.status === 'success') {
        if (resultApiRequest.data && resultApiRequest.data.status === 'ERROR') {
          // this case is supposed to be fixed in the backend, eventually
          this.errorResultSubmit = true;
          const lang = this.customTranslateService.getCurrentLangShort();
          this.resultMessage = resultApiRequest.data.errorMessage[lang];

          this.messageForNotRegisteredRecipient = resultApiRequest.data.messageForNotRegisteredRecipient;

        } else {
          this.errorResultSubmit = false;
          this.resultMessage = '';
          if (this.formConfig.onSuccessMessage) {
            if (this.formConfig.onSuccessMessage.showAsNotification) {
              this.notificationsService.showNotification(this.formConfig.onSuccessMessage);
            } else {
              this.resultMessage = this.formConfig.onSuccessMessage.message;
            }
          }
          this.onSuccessFormValue.emit(formValueToSend);
          this.onSuccessResponseValue.emit(resultApiRequest.data);
        }
      } else {
        this.errorResultSubmit = true;
        this.resultMessage = resultApiRequest.data;
      }
    } else {
      this.onSuccessFormValue.emit(formValueToSend);
    }
    this.isSendingRequest = false;
    if (this.errorResultSubmit && this.formConfig?.resetFormFieldsOnError) {
      this.resetFormFields();
    }
    return true;
  }

  resetFormFields() {
    this.smartForm?.reset();
  }

  onCancel() {
    this.onCancelForm.emit();
  }

  getCurrentValueFormProcessed(): NameValueMap<any> | undefined {
    if (!this.smartForm) {
      return undefined;
    }

    return this.mergeInitDataAndFormDataToObject(this.smartForm.value);
  }

  showHasErrorField(fieldIndex: number, fieldName: string): boolean {
    if (!this.smartForm) return false;
    const thisField = this.fields[fieldIndex];
    if (thisField.readOnly || thisField.type === 'hidden') return false;
    return ((this.smartForm.controls[fieldName].touched || this.showInvalidFieldsAlways) && !this.smartForm.controls[fieldName].valid);
  }

  getErrors(fieldIndex: number, fieldName: string): any {
    if (!this.smartForm) return {};
    const thisField = this.fields[fieldIndex];
    if (thisField.readOnly || thisField.type === 'hidden') return {};
    return this.smartForm.controls[fieldName].errors;
  }

  showRequiredString(fieldIndex: number): string {
    const thisField = this.fields[fieldIndex];
    return (this.showStarOnRequiredField && thisField.validation && thisField.validation.required) ? ' *' : '';
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    const ctrls = formGroup.controls;
    Object.keys(ctrls).forEach(key => ctrls[key].markAsTouched());
  }

  isFieldDisabled(fieldIndex: number): boolean {
    return this.fields[fieldIndex].disabled === true;
  }

  isFieldDirty(fieldName: string): boolean {
    if (!this.smartForm) {
      return false;
    }

    if (this.smartForm.controls[fieldName]) {
      return this.smartForm.controls[fieldName].dirty;
    } else {
      return false;
    }
  }

  isFieldReadOnly(fieldIndex: number): boolean {
    return this.fields[fieldIndex].readOnly === true;
  }

  isOptionSelectedCheckbox(fieldName: string, selectOptionData: string): boolean {
    if (!this.smartForm) {
      return false;
    }

    let splittedSelectedOptions = [];
    if (!((this.smartForm.value[fieldName] === null) || (this.smartForm.value[fieldName] === undefined))) {
      splittedSelectedOptions = this.smartForm.value[fieldName].split(',');
    }
    return splittedSelectedOptions.indexOf(selectOptionData) >= 0;
  }

  toggleOptionCheckbox(fieldName: string, selectOptionData: string, event: any) {
    const fieldInfo = this.getFormFieldInfoFromName(fieldName);
    if (fieldInfo.type === 'checkboxmulti') {
      if (this.isOptionSelectedCheckbox(fieldName, selectOptionData)) {
        this.unselectOptionCheckbox(fieldName, selectOptionData);
      } else {
        this.selectOptionCheckbox(fieldName, selectOptionData);
      }
    } else if (fieldInfo.type === 'checkboxradio') {
      // Only one option can be selected at a time, like a radio
      if (this.isOptionSelectedCheckbox(fieldName, selectOptionData)) {
        // Do nothing, one should be selected
        event.preventDefault();
        event.stopPropagation();
      } else {
        this.selectUniqueOptionCheckbox(fieldName, selectOptionData);
      }
    }
  }

  unselectOptionCheckbox(fieldName: string, selectOptionData: string) {
    if (!this.smartForm) {
      return;
    }

    if (this.isOptionSelectedCheckbox(fieldName, selectOptionData)) {
      let splittedSelectedOptions = [];
      if (!(this.smartForm.value[fieldName] === null || this.smartForm.value[fieldName] === undefined)) {
        splittedSelectedOptions = this.smartForm.value[fieldName].split(',');
      }

      splittedSelectedOptions.splice(splittedSelectedOptions.indexOf(selectOptionData), 1);
      if (splittedSelectedOptions.indexOf('') >= 0) {
        splittedSelectedOptions.splice(splittedSelectedOptions.indexOf(''), 1);
      }
      this.smartForm.controls[fieldName].setValue(splittedSelectedOptions.join(','));
    }
  }

  selectUniqueOptionCheckbox(fieldName: string, selectOptionData: string) {
    if (!this.smartForm) {
      return;
    }
    this.smartForm.controls[fieldName].setValue(selectOptionData);
  }

  selectOptionCheckbox(fieldName: string, selectOptionData: string) {
    if (!this.smartForm) {
      return;
    }

    if (!this.isOptionSelectedCheckbox(fieldName, selectOptionData)) {
      let splittedSelectedOptions = [];
      if (!(this.smartForm.value[fieldName] === null || this.smartForm.value[fieldName] === undefined)) {
        splittedSelectedOptions = this.smartForm.value[fieldName].split(',');
      }

      if (splittedSelectedOptions.indexOf('') >= 0) {
        splittedSelectedOptions.splice(splittedSelectedOptions.indexOf(''), 1);
      }

      splittedSelectedOptions.push(selectOptionData);
      this.smartForm.controls[fieldName].setValue(splittedSelectedOptions.join(','));
    }
  }

  setOptionCheckboxes(fieldName: string, options: string[]) {
    if (!this.smartForm) {
      return;
    }
    let splittedSelectedOptions = [];
    if (!(this.smartForm.value[fieldName] === null || this.smartForm.value[fieldName] === undefined)) {
      splittedSelectedOptions = this.smartForm.value[fieldName].split(',');
    }
    if (!this.utilsService.doArraysHaveSameElements(splittedSelectedOptions, options)) {
      this.smartForm.controls[fieldName].setValue(options.join(','));
    }
  }

  onFormButtonClicked(buttonInfo: SmartFormButtonDefinition) {
    const self = this;
    const actionEvent: ActionEvent = {
      actionId: buttonInfo.id,
      data: this.getCurrentValueFormProcessed()
    };
    const executeAction = () => {
      if (buttonInfo.fnClicked) {
        buttonInfo.fnClicked(actionEvent.data);
      } else {
        self.onActionClicked.emit(actionEvent);
      }
      if (buttonInfo.type === 'cancel') {
        self.onCancel();
      }
      if (buttonInfo.type === 'download') {
        self.onDownload().then(_ => console.log('success'), _ => console.log('err'));
      }
    };

    if (buttonInfo.confirmationDialog) {
      const modalOptionsForAction: any = self.getDialogForActionConfirmation(buttonInfo);
      modalOptionsForAction.onSuccessFormValue = executeAction;
      self.smartModalService.showModal(modalOptionsForAction);
    } else {
      executeAction();
    }
  }

  getDialogForActionConfirmation(actionInfo: SmartToolbarActionDefinition): SmartModalConfigDefinition {
    const modalOptions = new SmartModalBuilder()
      .setTitle(actionInfo.confirmationDialog ? actionInfo.confirmationDialog.title : actionInfo.title)
      .setBodyText(actionInfo.confirmationDialog ? actionInfo.confirmationDialog.body : '')
      .showConfirmButton(true, actionInfo.confirmationDialog ? actionInfo.confirmationDialog.confirmButtonText : undefined)
      .showCancelButton(true, actionInfo.confirmationDialog ? actionInfo.confirmationDialog.cancelText : undefined)
      .build();
    return modalOptions;
  }

  getCurrentFormFieldValue(fieldName: string): any {
    if (!this.smartForm) return;
    const fieldInfo = this.getFormFieldInfoFromName(fieldName);
    if (fieldInfo.type !== 'compound') {
      if (this.smartForm.value[fieldName] === null || this.smartForm.value[fieldName] === undefined) {
        return null;
      } else {
        return this.smartForm.value[fieldName];
      }
    }
  }

  getFormFieldInfoFromName(fieldName: string): any {
    for (let fieldIndex = 0; fieldIndex < this.fields.length; fieldIndex++) {
      if (this.fields[fieldIndex].name === fieldName) {
        return this.fields[fieldIndex];
      }
    }
    return null;
  }

  getFormFieldIdFromIndex(fieldName: string): number | undefined {
    for (let fieldIndex = 0; fieldIndex < this.fields.length; fieldIndex++) {
      if (this.fields[fieldIndex].name === fieldName) {
        return fieldIndex;
      }
    }
    return undefined;
  }

  getCssClass(elementType: string, extraClasses: string[]): string {
    let toReturnArray = [];
    if (elementType in SMART_FORM_CONFIG.cssClasses) {
      toReturnArray = JSON.parse(JSON.stringify(SMART_FORM_CONFIG.cssClasses[elementType])).split(' ');
    }

    if (elementType in this.cssClassesByType) {
      toReturnArray.push(this.cssClassesByType[elementType]);
    }
    if (extraClasses) {
      toReturnArray.push(extraClasses);
    }

    const toReturn = toReturnArray.join(' ');
    return toReturn.replace(',', ' ');
  }

  actionClicked(actionEvent: ActionEvent) {
    this.onActionClicked.emit(actionEvent);
  }

  isAnyButtonVisible() {
    let toReturn = false;
    if (this.formConfig && this.formConfig.buttons) {
      this.formConfig.buttons.forEach(function (thisButtonInfo: SmartFormButtonDefinition) {
        if (thisButtonInfo.visible !== false) {
          toReturn = true;
        }
      });
    }
    return toReturn;
  }

  setLoadingAction(actionId: string) {
    if (this.actionsToolbarRef) {
      this.actionsToolbarRef.setLoadingAction(actionId);
    }
  }

  unsetLoadingAction() {
    if (this.actionsToolbarRef) {
      this.actionsToolbarRef.unsetLoadingAction();
    }
  }

  mergeInitDataAndFormDataToObject(formFlattenData: NameValueMap<any>): any {
    const toReturnFlatten = this.includeUnusedInitDataInOutput ? JSON.parse(JSON.stringify(this.initDataFlatten)) : {};
    if (formFlattenData) {
      Object.keys(formFlattenData).forEach(thisIdKey => {
        if (!this.removeEmptyValuesFromOutput || !!formFlattenData[thisIdKey]) {
          toReturnFlatten[thisIdKey] = formFlattenData[thisIdKey];
        }
      });
    }
    return this.utilsService.unflattenObject(JSON.parse(JSON.stringify(toReturnFlatten)));
  }

  performUIAction(uiActionId: string, fieldIndex: number) {
    const self = this;
    const thisField = this.fields[fieldIndex];
    const thisFieldNameConvertedSeparators = thisField.name.replace(/:/g, '.');
    const currentData = self.getCurrentValueFormProcessed();
    const lastIndexOfSeparator = thisField.name.lastIndexOf(':');
    switch (uiActionId) {
      case 'addArrayEntry':
        // Check if the object is already an array
        // if not initalize it as empty array
        const arrayFieldName = (lastIndexOfSeparator < 0) ?
          thisField.name : thisFieldNameConvertedSeparators.substr(lastIndexOfSeparator + 1);
        const objectContainingArray = (lastIndexOfSeparator < 0) ?
          currentData : get(currentData, thisFieldNameConvertedSeparators.substr(0, lastIndexOfSeparator));
        if (!(arrayFieldName in objectContainingArray)
          || (objectContainingArray[arrayFieldName] === null)
          || !(Array.isArray(objectContainingArray[arrayFieldName]))) {
          objectContainingArray[arrayFieldName] = [];
        }
        objectContainingArray[arrayFieldName].push({});
        this.initialized = false;
        self.redrawFormWithChangedData(currentData);
        setTimeout(() => {
          self.initDateTimeFields();
        }, 0);
        break;
      case 'removeArrayEntry':
        if (lastIndexOfSeparator < 0) {
          return;
        }

        if (self.formConfig) {
          const indices = thisFieldNameConvertedSeparators.matchAll(new RegExp('\\.(0|[1-9][0-9]*)\\.?', 'g'));
          const indicesAsArray = Array.from(indices);
          const lastMatch = indicesAsArray[indicesAsArray.length - 1];
          if (!lastMatch) {
            console.error('No indices indicating an element to delete were found in the form field name.');
            break;
          }
          const arrayToRemoveDomain = thisFieldNameConvertedSeparators.substr(0, lastMatch.index);
          const positionArrayToRemove = lastMatch[0].replace(new RegExp('\\.', 'g'), '');
          const arrayToRemove = get(currentData, arrayToRemoveDomain);
          arrayToRemove.splice(+positionArrayToRemove, 1);
          this.initialized = false;
          self.redrawFormWithChangedData(currentData);
          setTimeout(() => {
            self.initDateTimeFields();
          }, 0);
        }

        break;
    }
  }

  hideOrOmitConditionField(fieldName: string, conditionType: 'showConditions' | 'omitFromRequestConditions' = 'showConditions') {
    let toReturn = false;
    const self = this;
    const thisField = this.getFormFieldInfoFromName(fieldName);
    const conditions = thisField[conditionType];

    if (thisField.readOnly) {
      if (thisField.name.includes('info:person')) {
        return this.requiredFieldIsEmpty(thisField, 'info:person', 'lastName');
      } else if (thisField.name.includes('info:organisation')) {
        return this.requiredFieldIsEmpty(thisField, 'info:organisation', 'name');
      } else if (thisField.name.includes('zipCode:swiss')) {
        return this.requiredFieldIsEmpty(thisField, 'zipCode:swiss', 'code');
      } else if (thisField.name.includes('zipCode:foreign')) {
        return this.requiredFieldIsEmpty(thisField, 'zipCode:foreign', 'code');
      } else if (thisField.name.includes('category:other')) {
        return false;
      } else if (thisField.name.includes('zipCode:switch')) {
        return true;
      }
    }

    if (conditions) {
      const atLeastOneConditionFullfilled = conditions.some(function (thisCondition: SmartFormShowCondition) {
        let conditionOk = false;
        if (self.smartForm) {
          const currentValueFieldConditions = self.smartForm.value[thisCondition.fieldAbsolutePath + thisCondition.fieldName];
          switch (thisCondition.operator) {
            case SmartFormShowConditionOperator.Equals:
              conditionOk = (currentValueFieldConditions === thisCondition.value);
              break;
            case SmartFormShowConditionOperator.Different:
              conditionOk = (currentValueFieldConditions !== thisCondition.value);
              break;
            case SmartFormShowConditionOperator.In:
              conditionOk = (thisCondition.value.indexOf(currentValueFieldConditions) >= 0);
              break;
            case SmartFormShowConditionOperator.NotIn:
              conditionOk = (thisCondition.value.indexOf(currentValueFieldConditions) === -1);
              break;
          }
        }
        return conditionOk;
      });
      toReturn = conditionType === 'omitFromRequestConditions' ? atLeastOneConditionFullfilled : !atLeastOneConditionFullfilled;
    }
    return toReturn;
  }

  private requiredFieldIsEmpty(thisField: any, item: string, requiredFieldSuffix: string): boolean {
    const index = thisField.name.indexOf(item);
    const prefix = thisField.name.substr(0, index + item.length);
    const requiredFieldName = prefix + ':' + requiredFieldSuffix;
    const requiredField = this.getFormFieldInfoFromName(requiredFieldName);

    return requiredField?.default === null || requiredField.default === undefined;
  }

  hideEmptyReadOnlyField(field: SmartFormFormFieldDefinition, fieldIndex: number): boolean {
    if (!field.readOnly) {
      return false;
    }
    if (field.type === 'heading') {
      return false;
    }
    if (field.type && ['select', 'radio', 'checkboxmulti', 'checkboxradio', 'autocomplete'].includes(field.type)) {
      return !this.getSelectedOptionLabel(fieldIndex, field.name);
    }
    return !this.getCurrentFormFieldValue(field.name);
  }

  showButtonVisible(buttonInfo: SmartFormButtonDefinition) {
    let toReturn = true;
    if (buttonInfo.type === 'download'){
      return !!this.messageForNotRegisteredRecipient;
    }
    if (buttonInfo.fnVisible) {
      toReturn = (buttonInfo.fnVisible(this.getCurrentValueFormProcessed()));
    }
    return toReturn;
  }

  public async reload(data?: any) {
    let preFilledDataUnflatten: any = {};
    if (this.formConfig) {
      if (this.formConfig.initData) {
        preFilledDataUnflatten = JSON.parse(JSON.stringify(this.formConfig.initData));
        if (this.includeUnusedInitDataInOutput) {
          this.initDataFlatten = this.utilsService.flattenObject(JSON.parse(JSON.stringify(this.formConfig.initData)), false);
        }
      }

      if (data !== undefined) {
        preFilledDataUnflatten = data;
      } else if (this.formConfig.apiGetDataRequestConfig) {
        const apiRequestConfig = new ApiRequestBuilder(this.formConfig.apiGetDataRequestConfig)
          .build();

        const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
        if (resultApiRequest.status === 'success') {
          preFilledDataUnflatten = resultApiRequest.data;
        } else {
          // return error, info could not be retrieved
          // this.notificationsService.showNotification( {type:'error'});
          return;
        }
      }
    }

    this.initialized = false;
    const initFormValues = this.redrawFormWithChangedData(preFilledDataUnflatten);
    return initFormValues;
  }
}
