import { Component } from '@angular/core';
import {MarketpartnerViewComponent} from "../shared/marketpartner-view.component";
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "../../companypartners/api-endpoints-list";
import {MeteringPoint} from "../../companypartners/metering-points/metering-point";
import {companyservice} from "../../shared/messaging-grpc/messaging-grpc";
import MeteringPointCompanyService = companyservice.MeteringPointCompanyService;

@Component({
  selector: 'admin-company-partners-meteringpoints',
  templateUrl: './admin-company-partners-meteringpoints.component.html',
  styleUrls: ['../users-common.scss']
})
export class AdminCompanyPartnersMeteringpointsComponent extends MarketpartnerViewComponent {

  meteringPoints: any;
  async onParamsUrlInitialized(params: any): Promise<void> {
    await super.onParamsUrlInitialized(params);

    this.listMeteringPoints();
  }

  listMeteringPoints() {
    this.apiRequestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.listMeteringPoints, {partnerId: this.partnerId})
      .then(result => {
        this.meteringPoints = [];
        if(result.status == 'success') {
          this.meteringPoints = result.data.meteringPoints.map((mp: MeteringPointCompanyService) =>
            new MeteringPoint({
              id: mp.id,
              mpa: mp.mpa,
              address: mp.address,
              businessPartnerName: mp.businessPartnerName,
              valid: {
                from: null,
                to: null
              }
            }));
        }
        this.initialized = true;
      });
  }

}
