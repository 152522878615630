<page-loading-spinner [initialized]="initialized"></page-loading-spinner>
<div class="root fixed-header" *ngIf="initialized">
    <h1>
        <a mat-icon-button color="primary" class="h1-back-button" routerLink="/company/contingents/meteringpoints/" id="back">
            <mat-icon svgIcon="arrow-back-circle"></mat-icon>
        </a>
        {{prefix+'h1' | translate}} {{id}}
    </h1>
  <metering-points-consumption-table [meteringPointConsumptionData]="meteringPointConsumptionData"></metering-points-consumption-table>
</div>
