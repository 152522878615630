
<div class="page-sidebar">
  <div class="page-sidebar-title go-back-nav-button" (click)="goBackToList()">
    <i class="fa fa-chevron-circle-left"></i>
    {{partnerTitle}}
  </div>
  <ng-container *ngIf="mpinfo != undefined">
    <ng-container *ngFor="let thisNavigation of listNavigationMP">
      <button *ngIf="thisNavigation.fnAllowed == undefined || thisNavigation.fnAllowed(mpinfo)" (click)="goToPath(thisNavigation.path)"
              class="btn btn-primary"
              [class.btn-primary] = "activeViewMode === thisNavigation.viewMode"
              [class.btn-default] = "activeViewMode !== thisNavigation.viewMode"
        >
        {{thisNavigation.label | translate}}
      </button>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="upinfo != undefined">
    <button *ngFor="let thisNavigation of listNavigationUP" (click)="goToPath(thisNavigation.path)"
            class="btn btn-primary"
            [class.btn-primary] = "activeViewMode === thisNavigation.viewMode"
            [class.btn-default] = "activeViewMode !== thisNavigation.viewMode"
    >
      {{thisNavigation.label | translate}}
    </button>
  </ng-container>
</div>
