import {Component, OnInit} from '@angular/core';
import {CompanypartnersBaseComponent} from "../companypartners-base.component";
import {UniteCompaniesDialogComponent} from "../unite-companies-dialog/unite-companies-dialog.component";
import {MeteringPoint} from "./metering-point";

@Component({
  selector: 'metering-points',
  templateUrl: './metering-points.component.html',
  styleUrls: []
})
export class MeteringPointsComponent extends CompanypartnersBaseComponent implements OnInit {

  prefix = 'companyClient.meteringPoints.';

  meteringPoints: MeteringPoint[] = [];

  openUniteDialog(option: string): void {
    const dialogRef = this.dialog.open(UniteCompaniesDialogComponent, {
      data: {
        ownCompanyName: this.sessionService.getCurrentCompanyPartnerInfo()?.companyPartnerSettings.name,
        partnerId: this.partnerId,
        selectedOption: option},
    });

    dialogRef.afterClosed().subscribe((completed: boolean) => {
      if(completed) {
        window.location.reload();
      }
    });
  }

  ngOnInit() {
    this.meteringDataSubscription$.subscribe(consumptionData => {
      if(consumptionData !== undefined) {
        this.meteringPoints = consumptionData.businessPartners.flatMap((bp: any) => bp.meteringPoints).map(e => new MeteringPoint(e));
        this.initialized = true;
      } else {
        this.meteringDataService.getConsumptionData(this.partnerId);
      }
    });
  }
}
