import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiRequestService} from "../../shared/api-request/service/api-request.service";
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "../api-endpoints-list";

@Component({
  selector: 'add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: []
})
export class AddUserDialogComponent {

  email: string = '';
  admin: boolean = false;
  isSendingRequest = false;
  errorMessage: string = '';

  constructor(
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {ownCompanyName: string, partnerId: string},
    private requestService: ApiRequestService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.isSendingRequest = true;
    this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.inviteUser, {scopes: this.admin ? 'ADMIN' : 'USER', email: this.email})
      .then(_result => {
        this.isSendingRequest = false;
        this.dialogRef.close(true);
      });
  }
}
