export enum EnumSortType {
  Alphabetical = 'alphabetical',
  Original = 'original',
}

export interface EnumDefinition<T= string> {
    id: string;
    items: EnumItem<T>[];
    sortType?: EnumSortType; // default ('alphabetical')
}

export interface EnumItem<T= string> {
    label: string;
    data: T;
    omitFromDropdown?: boolean;
}
