import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ech-address-info',
  templateUrl: './ech-address-info.component.html',
  styleUrls: ['../users-common.scss', './users-metering-points-info.component.scss']
})
export class EchAddressInfoComponent {
  @Input() address: any;
}
