<mat-sidenav-container class="companypartners-container">
  <mat-sidenav class="companypartners-sidenav"
               [class.collapsed]="sidenavCollapsed"
               [class.uncollapsed]="!sidenavCollapsed"
               mode="side"
               opened
               disableClose >

    <mat-nav-list>
      <div class="space-between">
        <div>
          <a mat-list-item (click)="onBurgerClick()" id="navToggleButton">
            <mat-icon matListItemIcon svgIcon="burger"></mat-icon>
            <div matListItemTitle class="nav-list-title">
              <img ngSrc="/assetsextern/images/logos/logo_swisseldex.svg" alt="swisseldex_logo" height="18" width="120" class="logo">
            </div>
          </a>
          <nav-dropdown></nav-dropdown>
          <a *ngFor="let link of navLinks"
             mat-list-item
             [routerLink]="link.fullPath"
             routerLinkActive="active"
             [id]="'sidenav_Link_' + link.title"
             [class.display-none]="!isUserAllowedToSection(link)"
             class="companypartners-sidenav-nav-link">
            <mat-icon matListItemIcon [svgIcon]="link.icon"></mat-icon>
            <div matListItemTitle class="nav-list-title">{{link.title | translate}}</div>
          </a>
        </div>
        <div class="nav-footer">
          <mat-divider></mat-divider>
          <a mat-list-item
             [href]="quickStartLink"
             target="_blank"
             id="quickStartLink"
             class="companypartners-sidenav-nav-link">
            <mat-icon matListItemIcon svgIcon="quickstart"></mat-icon>
            <div matListItemTitle class="nav-list-title">{{'NavSideQuickstartGuide' | translate}}</div>
          </a>
          <a mat-list-item
             [href]="userManualLink"
             target="_blank"
             id="userManualLink"
             class="companypartners-sidenav-nav-link">
            <mat-icon matListItemIcon svgIcon="manual"></mat-icon>
            <div matListItemTitle class="nav-list-title">{{'NavSideUserManual' | translate}}</div>
          </a>
        </div>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="companypartners-content"
                       [class.handle-height-tabnav]="currentTabs.length > 0"
                       [class.collapsed]="sidenavCollapsed"
                       [class.uncollapsed]="!sidenavCollapsed">
    <nav mat-tab-nav-bar
         *ngIf="currentTabs.length > 0"
         [tabPanel]="tabPanel"
         mat-stretch-tabs="false"
         mat-align-tabs="start"
         class="companypartners-content-tabnav">
      <a mat-tab-link
         [id]="'tabnav_link_'+tab.title"
         *ngFor="let tab of currentTabs"
         [routerLink]="tab.fullPath"
         [active]="activeTab == tab"
         [class.active]="activeTab == tab"
         class="companypartners-content-tabnav-link">
        {{tab.title | translate}}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel class="companypartners-content-tabnav-content">
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </mat-sidenav-content>
</mat-sidenav-container>
