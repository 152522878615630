/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild, OnDestroy} from '@angular/core';
import {SessionService} from '../../shared/session/session.service';
import {TranslateService} from '@ngx-translate/core';
import {MarketPartnerService, canShowUnsuppliedMeteringPoints} from '../../shared/services/marketpartner.service';
import {NotificationsService} from '../../shared/services/notifications.service';

import {ActivatedRoute, Router} from '@angular/router';

import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';

import {SmartTableBuilder} from '../../shared/marketpartner-components/smart-forms/smart-table/builder/smart-table.builder';
import {SmartTableConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableConfigDefinition';
import {RowActionEvent} from '../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableRowActionDefinition';
import {SmartTableComponent} from '../../shared/marketpartner-components/smart-forms/smart-table/component/smart-table.component';
import {SmartModalBuilder} from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import {SmartModalService} from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import {SdatRequestDialogsService} from '../shared/sdat-request-dialogs.service';
import {MeteringPointsImportService, FrontendImportStatus} from './metering-points-import.service';
import {MeteringPointsExportService} from './metering-points-export.service';

import {AppConfig} from '../../config/app.config';
import {MarketpartnerViewComponent} from '../shared/marketpartner-view.component';
import {escape} from 'lodash-es';
import { map, filter, skipWhile, distinctUntilChanged, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Component({
  selector: 'page-users-metering-points',
  templateUrl: './users-metering-points.component.html',
  styleUrls: ['../users-common.scss']
})

export class MeteringPointsComponent extends MarketpartnerViewComponent implements OnDestroy {
  @ViewChild('tableAll') tableAll?: SmartTableComponent;
  @ViewChild('tableUnsupplied') tableUnsupplied?: SmartTableComponent;

  initialized = false;
  tableAllConfig?: SmartTableConfigDefinition;
  tableUnsuppliedConfig?: SmartTableConfigDefinition;
  importStatus$ = this.meteringPointsImportService.importStatus$;
  reloadListSubscription: Subscription | undefined;
  downloadProgress$ = this.meteringPointsExportService.downloadProgress$;
  unsuppliedCount$ = this.marketPartnerService.unsuppliedMeteringPoints$;

  constructor( public activatedRoute: ActivatedRoute,
               public router: Router,
               public sessionService: SessionService,
               private translate: TranslateService,
               private smartModalService: SmartModalService,
               public marketPartnerService: MarketPartnerService,
               public apiRequestService: ApiRequestService,
               public sdatRequestDialogs: SdatRequestDialogsService,
               public notificationsService: NotificationsService,
               public meteringPointsImportService: MeteringPointsImportService,
               public meteringPointsExportService: MeteringPointsExportService) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    this.tableAllConfig = this.buildTable(false);
    const mpi = this.marketPartnerInfo;
    if (canShowUnsuppliedMeteringPoints(mpi)) {
      this.tableUnsuppliedConfig = this.buildTable(true);
    }
    this.marketPartnerService.updateUnsuppliedCount();
    this.setInitialized(true);

    if (this.accessMeteringPointsDownload) {
      this.meteringPointsExportService.startPolling();
    }
    if (this.accessMeteringPointsUpload) {
      this.meteringPointsImportService.startPolling();
      this.reloadListSubscription = this.importStatus$.pipe(
        map(importStatus => importStatus.status),
        // if the page was opened in 'FINISHED', don't reload just now
        skipWhile(status => status === 'FINISHED'),
        distinctUntilChanged(),
        tap(status => console.log('importStatus:', status)),
        filter(status => status === 'FINISHED')
      ).subscribe(() => {
        console.log('refreshing metering points list');
        if (this.tableAll) this.tableAll.reload();
        if (this.tableUnsupplied) this.tableUnsupplied.reload();
        this.marketPartnerService.updateUnsuppliedCount();
      });
    }
  }

  private buildTable(forUnsupplied: boolean): SmartTableConfigDefinition {
    const builder = new SmartTableBuilder()
      .addColumnFromInfo({id: 'mpId', title: 'MeteringPoints.ColumnId',
        visible: false, filterable: false})
      .setColumnNameForId('mpId')
      .addColumnFromInfo({id: 'id', title: 'MeteringPoints.ColumnId',
                          visible: true, filterable: true})
      .addColumnFromInfo({id: 'meteringPointAdministratorHtml', title: 'MeteringPoints.ColumnAdministrator',
                          showAsHTML: true, filterable: false, sortable: false})
      .setApiRequestConfigInfoFromInfo(USERS_API_ENDPOINTS_LIST.meteringPointsList, {
        partnerId: this.sessionService.getCurrentPartnerId(),
        type: forUnsupplied ? 'UNSUPPLIED_ON_DATE' : undefined,
      })
      .addRowActionFromInfo({id: 'metering_points_view', title: 'MeteringPoints.ActionView',
                             icon: 'pli-eye', className: 'btn-mint',
                             fnClicked: (actionEvent) => this.view(actionEvent)})
      .showFilters(true)
      .showSearchField(false)
      .setPageLength(10)
      .addInitialSorting({columnId: 'id', order: 'asc'});

    // buttons
    if (!forUnsupplied) {
      if (this.accessMeteringPointsUpload) {
        builder
          .addToolbarActionFromInfo({id: '', title: 'MeteringPoints.ActionUpload',
                                     icon: 'pli-upload', className: 'btn-primary',
                                     fnEnabled: () => this.allowUpload(),
                                     fnClicked: () => this.upload()});
      }
      if (this.accessMeteringPointsDownload) {
        builder
          .addToolbarActionFromInfo({id: '', title: 'MeteringPoints.ActionDownload',
                                     icon: 'pli-download', className: 'btn-primary',
                                     fnEnabled: () => this.allowDownload(),
                                     fnClicked: () => this.download()});
      }
      if (this.marketPartnerRole === 'LF') {
        builder
          .addToolbarActionFromInfo({id: '', title: 'SDATButtons.SupplyNewPoint',
                                     icon: 'pli-arrow-shuffle', className: 'btn-primary',
                                     fnClicked: () => this.startSupply()});
      }
      if (this.marketPartnerRole === 'SDV') {
        builder
          .addToolbarActionFromInfo({id: '', title: 'SDATButtons.ASPRegistration',
                                     icon: 'pli-arrow-shuffle', className: 'btn-primary',
                                     fnClicked: () => this.registerASP()});
      }
    }

    if (this.showAdditionalMeteringPointColumns) {
      builder
        .addColumnFromInfo({id: 'balanceSupplierHtml', title: 'MeteringPoints.ColumnBalanceSupplier',
                            showAsHTML: true, filterable: false, sortable: false})
        .addColumnFromInfo({id: 'balanceResponsibleHtml', title: 'MeteringPoints.ColumnBalanceResponsible',
                            showAsHTML: true, filterable: false, sortable: false})
        .addColumnFromInfo({id: 'ancillaryServiceProvidersHtml', title: 'MeteringPoints.ColumnAncillaryServiceProvider',
                            showAsHTML: true, filterable: false, sortable: false});
    }

    builder
      .addColumnFromInfo({id: 'isEligibleForSwitch', title: 'MeteringPoints.ColumnEligibleForSwitch',
                          valueIsBoolean: true, enum: 'yesOrNo',
                          filterable: false, sortable: false});

    const party2html = (party: any) => {
      if (!party) return '';
      const eicHtml = escape(party.eic);
      // This fixed line-breaks. Disabled for now, because people copy-paste this into XML documents.
      // .replace(/-/g, '&#8209;');
      if (party.name) {
        return eicHtml + '<br>' + escape(party.name);
      } else {
        return eicHtml;
      }
    };
    builder
      .setTransformRawDataFn((data: any) => ({
        ...data,
        ancillaryServiceProvidersHtml: data.ancillaryServiceProviders.map(party2html).join(', '),
        balanceResponsibleHtml: party2html(data.balanceResponsible),
        balanceSupplierHtml: party2html(data.balanceSupplier),
        meteringPointAdministratorHtml: party2html(data.meteringPointAdministrator),
        mpId: data.id + '-' + data.meteringPointAdministrator.eic
      }));

    return builder.build();
  }

  ngOnDestroy(): void {
    if (this.reloadListSubscription) {
      this.reloadListSubscription.unsubscribe();
    }
    this.meteringPointsImportService.stopPolling();
    this.meteringPointsExportService.stopPolling();
    super.ngOnDestroy();
  }

  allowUpload(): boolean {
    const status = this.importStatus$.getValue();
    return status.status === 'NONE';
  }

  allowDownload(): boolean {
    const status = this.downloadProgress$.getValue();
    return status === undefined;
  }

  cancelExport() {
    this.meteringPointsExportService.cancelDownload();
  }

  showImportResult(status: FrontendImportStatus) {
    if (!status.result) return;
    this.meteringPointsImportService.showImportResult(status.result);
  }

  view(event: RowActionEvent) {
    const entitySelected = event.data[0];
    this.navigateToInfo(entitySelected.meteringPointAdministrator.eic, entitySelected.id);
  }

  navigateToInfo(mpAdminEic: string, mpId: string) {
    if (mpAdminEic && mpId) {
      this.navigateTo({
        'route': AppConfig.routes.metering_points_info,
        'replace': {mpAdminEic, mpId}
      });
    } else {
      console.error('navigateToInfo(', mpAdminEic, mpId, ')');
      throw new Error('navigateToInfo');
    }
  }

  async startSupply() {
    const result = await this.sdatRequestDialogs.startSupply();
    if (result) {
      const mp = result.formValue.meteringPoint;
      if (result.response.processId) {
        this.navigateToInfo(mp.administratorEic, mp.id);
      }
    }
  }

  async registerASP() {
    const result = await this.sdatRequestDialogs.registerASP();
    if (result) {
      const mp = result.formValue.meteringPoint;
      if (result.response.processId) {
        this.navigateToInfo(mp.administratorEic, mp.id);
      }
    }
  }

  download() {
    this.meteringPointsExportService.showExportDialog();
  }

  async upload() {
    const file = await this.apiRequestService.selectFile('.xml,.xml.gz');
    if (!file) return;
    console.log('file.name:', file.name);

    // frontend filename validation (ELDEXDHUB-1838) - copied from shared-code.MessageType
    const re = /^MPImport([A-Z0-9_-]{0,244}.xml|[A-Z0-9_-]{0,241}.xml.gz)$/;
    if (!file.name.match(re)) {
      const title = 'MeteringPoints.ImportNotAllowedInvalidFilenameTitle';
      let message = 'MeteringPoints.ImportNotAllowedInvalidFilenameText';
      message = this.translate.instant(message, {filename: file.name});
      const modalOptions = new SmartModalBuilder()
        .setTitle(title)
        .setBodyText(message)
        .showConfirmButton(true, 'ModalCloseButton')
        .setModalCSSClassSize('md')
        .build();
      await this.smartModalService.showModal(modalOptions);
      return;
    }

    await this.meteringPointsImportService.startUpload(file);
  }

  getImportStatusText(s: FrontendImportStatus) {
    if (s.status === 'NONE') return '';
    if (s.status === 'UPLOADING') return 'MeteringPoints.ImportStatusUploadingText';
    if (s.status === 'SCHEDULED') return 'MeteringPoints.ImportStatusScheduledText';
    if (s.status === 'FINISHED' && s.result) {
      if (s.result.accepted) {
        if (s.result.accepted.warnings.length === 0 && s.result.accepted.errors.length === 0) {
          return 'MeteringPoints.ImportSuccessText';
        } else {
          return 'MeteringPoints.ImportSuccessWithWarningsText';
        }
      }
      if (s.result.rejected) return 'MeteringPoints.ImportFailedText';
    }
    if (s.status === 'IMPORTING') return 'MeteringPoints.ImportStatusImportingText';
    if (s.status === 'VALIDATING') return 'MeteringPoints.ImportStatusValidatingText';
    return 'UNKNOWN_STATUS';
  }
}
