import {Component, OnInit} from '@angular/core';
import {CompanypartnersBaseComponent} from "../companypartners-base.component";
import {ConsumptionDataCompanyPartner} from "../../shared/chart/consumption-data-model";

@Component({
  selector: 'consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.scss']
})
export class ConsumptionComponent extends CompanypartnersBaseComponent implements OnInit {

  consumptionData?: ConsumptionDataCompanyPartner;

  ngOnInit() {
    this.meteringDataSubscription$.subscribe(consumptionData => {
      if(consumptionData !== undefined) {
        this.consumptionData = consumptionData;
        this.initialized = true;
      } else {
        this.meteringDataService.getConsumptionData(this.partnerId);
      }
    });
    //let consumptionData = ChartDataMock.getDummyData();
  }
}
