import {MarketpartnerViewComponent} from './marketpartner-view.component';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import {ActivatedRoute, Router} from '@angular/router';

import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {SessionService} from '../../shared/session/session.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';
import { Component } from '@angular/core';

@Component({ template: '' })
export class NotificationsViewComponent extends MarketpartnerViewComponent {

  constructor( public activatedRoute: ActivatedRoute,
               public router: Router,
               public sessionService: SessionService,
               public marketPartnerService: MarketPartnerService,
               public apiRequestService: ApiRequestService) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }
  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    this.marketPartnerService.updateUnreadNotificationsCount();
  }

  async setNotificationRead(messageUuid: string, state: boolean) {
    if (this.isOperatorMode) {
      // no permission to change read/unread status
      return;
    }
    const apiRequestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.notificationMarkRead, {
        messageUuid,
        'read': state.toString()
      })
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
    if (resultApiRequest.status === 'success') {
      this.marketPartnerService.updateUnreadNotificationsCount();
    }
  }

  async deleteNotification(messageUuid: string) {
    if (this.partnerId === undefined) return;
    const apiRequestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.notificationDelete, {messageUuid, partnerId: this.partnerId})
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
    if (resultApiRequest.status === 'success') {
      this.marketPartnerService.updateUnreadNotificationsCount();
    }
  }

  async deleteAllNotifications(olderThan: number){
    if (this.partnerId === undefined) return;
    const apiRequestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.notificationsDeleteAll)
      .setBodyInfo({partnerId: this.partnerId, olderThan: olderThan})
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
    if (resultApiRequest.status === 'success') {
      this.marketPartnerService.updateUnreadNotificationsCount();
    }
  }

  goBackToNotifications() {
    this.router.navigate(['../'], {relativeTo: this.activatedRoute});
  }

}
