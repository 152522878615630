/* eslint-disable max-len */
import {SmartFormFormFieldDefinitionContainer} from '../classes/SmartFormFormFieldDefinition';

export const SMART_FORM_FORMFIELD_DEFS: SmartFormFormFieldDefinitionContainer[] = [
  {
    id: 'formFieldLanguage',
    definition: {
      title: '',
      type: 'select',
      name: 'lang',
      cssClasses: 'col-xs-12',
      validation: {
        required: false
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'languages'
      },
    },
  },
  {
    id: 'formFieldMarketPartnerSDATRoleDisabled',
    definition: {
      title: 'FormSDATRole',
      type: 'select',
      name: 'partnerSettings:role',
      disabled: true,
      cssClasses: 'col-sm-12',
      validation: {
        required: false,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'sdatroles'
      }
    },
  },
  {
    id: 'formFieldMarketPartnerSDATRoleReadOnly',
    definition: {
      title: 'FormSDATRole',
      type: 'select',
      name: 'partnerSettings:role',
      disabled: true,
      readOnly: true,
      cssClasses: 'col-sm-12',
      validation: {
        required: false,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'sdatroles'
      }
    },
  },
  {
    id: 'formFieldMarketPartnerSDATRole',
    definition: {
      title: 'FormSDATRole',
      type: 'select',
      name: 'partnerSettings:role',
      cssClasses: 'col-sm-12',
      validation: {
        required: true,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'sdatroles'
      }
    },
  },
  {
    id: 'formFieldMarketPartnerContractStatus',
    definition: {
      title: 'FormContractStatus',
      type: 'select',
      name: 'partnerSettings:contractStatus',
      cssClasses: 'col-sm-12',
      default: 'NO_CONTRACT',
      validation: {
        required: true,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'contractstatus'
      }
    },
  },
  {
    id: 'formFieldMarketPartnerContractStatusHidden',
    definition: {
      title: 'FormContractStatus',
      type: 'hidden',
      name: 'partnerSettings:contractStatus',
      validation: {
        required: false,
      },
    },
  },
  {
    id: 'formFieldMarketPartnerLanguage',
    definition: {
      title: 'FormLanguage',
      type: 'select',
      name: 'partnerSettings:isoLanguageCode',
      cssClasses: 'col-sm-12',
      default: 'de',
      validation: {
        required: true,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'languages'
      }
    },
  },
  {
    id: 'formFieldMarketPartnerInitialAdminEmail',
    definition: {
      title: 'FormInitialAdminEmail',
      type: 'input',
      name: 'initialAdmin:email',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'email',
        required: true,
      },
    },
  },
  {
    id: 'formFieldMarketPartnerAutoSyntaxCheckForIncomingMessages',
    definition: {
      title: 'FormAutoSyntaxCheckForIncomingMessages',
      type: 'checkboxradio',
      name: 'partnerSettings:autoSyntaxCheckForIncomingMessages',
      valueIsBoolean: true,
      default: false,
      cssClasses: 'col-sm-12',
      validation: {
        required: true,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'yesOrNo'
      }
    },
  },
  {
    id: 'formFieldMarketPartnerContactEmail',
    definition: {
      title: 'FormContactEmail',
      type: 'input',
      name: 'partnerSettings:contactEmail',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'email',
        required: true,
      },
    },
  },
  {
    id: 'formFieldMarketPartnerContactEmailReadOnly',
    definition: {
      title: 'FormContactEmail',
      type: 'input',
      readOnly: true,
      disabled: true,
      name: 'partnerSettings:contactEmail',
      cssClasses: 'col-sm-12',
      validation: {
        required: false,
      },
    },
  },
  {
    id: 'formFieldMarketPartnerContactEmailHidden',
    definition: {
      title: 'FormContactEmail',
      type: 'hidden',
      name: 'partnerSettings:contactEmail',
      cssClasses: 'col-sm-12',
      validation: {
        required: false,
      },
    },
  },
  {
    id: 'formFieldMarketPartnerContactPhone',
    definition: {
      title: 'FormContactPhone',
      type: 'input',
      name: 'partnerSettings:contactPhone',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'phone_any',
        required: false,
      },
    },
  },
  {
    id: 'formFieldMarketPartnerContactPhoneReadOnly',
    definition: {
      title: 'FormContactPhone',
      type: 'input',
      readOnly: true,
      disabled: true,
      name: 'partnerSettings:contactPhone',
      cssClasses: 'col-sm-12',
      validation: {
        required: false,
      },
    },
  },
  {
    id: 'formFieldMarketPartnerNameReadOnly',
    definition: {
      title: 'FormName',
      type: 'input',
      name: 'partnerSettings:name',
      readOnly: true,
      disabled: true,
      cssClasses: 'col-sm-12',
      validation: {
        type: 'partnername',
        required: false,
      },
    },
  },
  {
    id: 'formFieldMarketPartnerName',
    definition: {
      title: 'FormName',
      type: 'input',
      name: 'partnerSettings:name',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'partnername',
        required: true,
      },
    },
  },
  {
    id: 'formFieldSalutation',
    definition: {
      title: 'FormSalutation',
      type: 'select',
      name: 'salutation',
      cssClasses: 'col-sm-6',
      allowedValues: {
        type: 'globalRegister',
        data: 'salutation'
      },
      validation: {
        required: true,
      },
    },
  },
  {
    id: 'formFieldSalutationReadOnly',
    definition: {
      title: 'FormSalutation',
      type: 'select',
      name: 'salutation',
      cssClasses: 'col-sm-6',
      allowedValues: {
        type: 'globalRegister',
        data: 'salutation'
      },
      disabled: true,
      readOnly: true,
      validation: {
        required: false,
      },
    },
  },
  {
    id: 'formFieldTelephone',
    definition: {
      title: 'FormTelephoneWithMobileHint',
      type: 'input',
      name: 'telephone',
      cssClasses: 'col-sm-6',
      validation: {
        type: 'phone_sms',
        required: true,
      },
    },
  },
  {
    id: 'formFieldTelephoneReadOnly',
    definition: {
      title: 'FormTelephone',
      type: 'input',
      name: 'telephone',
      cssClasses: 'col-sm-6',
      validation: {
        type: 'phone_sms',
        required: false,
      },
    },
  },
  {
    id: 'formFieldFirstname',
    definition: {
      title: 'FormFirstName',
      type: 'input',
      name: 'firstname',
      cssClasses: 'col-sm-6',
      validation: {
        type: 'firstname',
        required: true,
      },
    },
  },
  {
    id: 'formFieldFirstnameReadOnly',
    definition: {
      title: 'FormFirstName',
      type: 'input',
      name: 'firstname',
      cssClasses: 'col-sm-6',
      disabled: true,
      readOnly: true,
      validation: {
        type: 'firstname',
        required: false,
      },
    },
  },
  {
    id: 'formFieldLastname',
    definition: {
      title: 'FormLastName',
      type: 'input',
      name: 'lastname',
      cssClasses: 'col-sm-6',
      validation: {
        type: 'lastname',
        required: true,
      },
    },
  },
  {
    id: 'formFieldLastnameReadOnly',
    definition: {
      title: 'FormLastName',
      type: 'input',
      name: 'lastname',
      cssClasses: 'col-sm-6',
      disabled: true,
      readOnly: true,
      validation: {
        type: 'lastname',
        required: true,
      },
    },
  },
  {
    id: 'formFieldEmail',
    definition: {
      title: 'FormEmail',
      type: 'input',
      name: 'email',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'email',
        required: true,
      },
    },
  },
  {
    id: 'formFieldEmailReadOnly',
    definition: {
      title: 'FormEmail',
      type: 'input',
      name: 'email',
      cssClasses: 'col-sm-12',
      disabled: true,
      readOnly: true,
      validation: {
        type: 'email',
        required: false,
      },
    },
  },
  {
    id: 'formFieldScopes',
    definition: {
      title: 'FormUserRights',
      type: 'checkboxradio',
      name: 'scopes',
      cssClasses: 'col-sm-6',
      validation: {
        required: true,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'scopes'
      },
    },
  },
  {
    id: 'formFieldMarketPartnerClientReadOnly',
    definition: {
      title: 'FormMarketPartner',
      type: 'input',
      name: 'client_id',
      cssClasses: 'col-sm-6',
      disabled: true,
      readOnly: true,
      validation: {
        required: false,
      },
    },
  },
  {
    id: 'formFieldOldPassword',
    definition: {
      title: 'FormOldPassword',
      type: 'password',
      name: 'old_password',
      cssClasses: 'col-sm-12',
      validation: {
        required: true,
      },
    },
  },
  {
    id: 'formFieldNewPassword',
    definition: {
      title: 'FormNewPassword',
      type: 'password',
      name: 'new_password',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'password',
        required: true,
      },
      help: 'LoginPasswordRequirementsHint'
    },
  },
  {
    id: 'formFieldNewPasswordConfirm',
    definition: {
      title: 'FormNewPasswordConfirm',
      type: 'password',
      omitFromRequest: true,
      name: 'new_password_confirm',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'password-confirm',
        required: true,
      }
    },
  },
  {
    id: 'formFieldMarketPartnerEICReadOnly',
    definition: {
      title: 'FormEIC',
      type: 'input',
      name: 'partnerSettings:eic',
      readOnly: true,
      disabled: true,
      cssClasses: 'col-sm-12',
    },
  },
  {
    id: 'formFieldMarketPartnerSenderEICReadOnly',
    definition: {
      title: 'FormEIC',
      type: 'input',
      name: 'senderEic',
      readOnly: true,
      disabled: true,
      cssClasses: 'col-sm-6',
    },
  },
  {
    id: 'formFieldMarketPartnerSenderRole',
    definition: {
      title: 'FormSDATRole',
      type: 'select',
      name: 'senderRole',
      cssClasses: 'col-sm-6',
      validation: {
        required: true,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'sdatroles'
      }
    },
  },
  {
    id: 'formFieldMarketPartnerSenderRoleReadOnly',
    definition: {
      title: 'FormSDATRole',
      type: 'select',
      name: 'senderRole',
      readOnly: true,
      disabled: true,
      cssClasses: 'col-sm-6',
      validation: {
        required: false,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'sdatroles'
      }
    },
  },
  {
    id: 'formFieldFTPAddress',
    definition: {
      title: 'FormFTPAddress',
      type: 'input',
      name: 'hostname',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'ftphostname',
        required: true,
      },
    },
  },
  {
    id: 'formFieldFTPDirectory',
    definition: {
      title: 'FormFTPDirectory',
      type: 'input',
      name: 'directory',
      help: 'FormFTPDirectoryHelp',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'ftpdirectory',
        required: true,
      },
    },
  },
  {
    id: 'formFieldFTPUsername',
    definition: {
      title: 'FormFTPUsername',
      type: 'input',
      name: 'username',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'ftpusername',
        required: true,
      },
    },
  },
  {
    id: 'formFieldFTPPassword',
    definition: {
      title: 'FormFTPPassword',
      type: 'password',
      name: 'password',
      cssClasses: 'col-sm-12',
      validation: {
        type: 'ftppassword',
        required: true,
      },
    },
  },
  {
    id: 'formFieldMarketPartnerSelection',
    definition: {
      title: 'FormMarketPartner',
      type: 'autocomplete',
      name: 'client_id',
      cssClasses: 'col-sm-6',
      disabled: false,
      validation: {
        required: true,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'marketpartners'
      },
    },
  },
  {
    id: 'formFieldAccountTypeWithoutMarketPartner',
    definition: {
      title: 'FormAccountType',
      type: 'checkboxradio',
      name: 'type',
      cssClasses: 'col-sm-6',
      default: 'OPERATORADMIN',
      validation: {
        required: true,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'accountGlobalTypeWithoutMarketPartner'
      },
    },
  },
  {
    id: 'formFieldAccountTypeMarketPartnerReadOnly',
    definition: {
      title: 'FormAccountType',
      type: 'checkboxradio',
      name: 'type',
      cssClasses: 'col-sm-6',
      default: 'MARKETPARTNER',
      disabled: true,
      readOnly: true,
      validation: {
        required: false,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'accountGlobalType'
      },
    },
  },
  {
    id: 'formFieldSDATWebClientForAll',
    definition: {
      type: 'checkboxmulti',
      name: 'sdat_forall_processes',
      cssClasses: 'chechboxes-2-columns',
      validation: {
        required: false,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'sdatforall'
      },
    },
  },
];

