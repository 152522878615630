/* eslint-disable max-len */
import {SmartTableRowClassesDefinitionContainer} from '../classes/SmartTableRowClassesDefinition';
import {companyservice} from "../../../../messaging-grpc/messaging-grpc";
import BusinessPartner = companyservice.BusinessPartner;

export const SMART_TABLE_ROWCLASSES_DEFS: SmartTableRowClassesDefinitionContainer[] = [
  {
    id: 'rowClassesReadTag',
    definition: function (data: any, _dataIndex: number): string | undefined {
      let unread = false;
      if (data) {
        if (data.read !== undefined && !data.read) {
          unread = true;
        } else if (data['messageMetadata:read'] === false) {
          unread = true;
        }
      }
      return unread ? 'row-font-bold' : undefined;
    }
  },
  {
    id: 'rowClassesRegistrationStatus',
    definition: function (data: BusinessPartner, _dataIndex: number): string {
      type RegistrationStatus = keyof typeof companyservice.RegistrationStatus;
      if (data) {
        switch (data.registrationStatus as any as RegistrationStatus) {
          case "REGISTRATION_STATUS_UNSPECIFIED":
            return 'text-danger';
          case "UNREGISTERED":
            return 'row-font-bold';
          case "INVITED":
            return '';
          case "REGISTERED":
            return '';
          case "EXPIRED":
            return '';
          default:
            return '';
        }
      }
      return '';
    }
  },

];
