/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';
import {SessionService} from '../../shared/session/session.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';
import {CustomTranslateService} from '../../shared/services/custom-translate.service';

import {ActivatedRoute, Router} from '@angular/router';
import {MarketpartnerViewComponent} from '../shared/marketpartner-view.component';


import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import {SmartFormBuilder} from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import {CustomValidators} from '../../shared/marketpartner-components/smart-forms/smart-form/classes/CustomValidators';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {NameValueMap} from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/KeyValueMap';
import {SmartFormComponent} from '../../shared/marketpartner-components/smart-forms/smart-form/component/smart-form.component';
import {SmartFormConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormConfigDefinition';
import { ApiRequestConfigInfoDefinition } from '../../shared/api-request/classes/ApiRequestConfigInfoDefinition';

@Component({
  selector: 'page-users-reports-content',
  templateUrl: './users-reports.component.html',
  styleUrls: ['../users-common.scss']
})

export class ReportsComponent extends MarketpartnerViewComponent  {
  @ViewChild('reportForm')  reportForm?: SmartFormComponent;

  initialized = false;
  reportFormConfig?: SmartFormConfigDefinition;

  constructor(public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              public router: Router,
              public apiRequestService: ApiRequestService,
              public customTranslateService: CustomTranslateService) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);

    this.reportFormConfig = new SmartFormBuilder()
      .addRequired({
        type: 'select', title: 'Reports.FormFieldType',
        name: 'report_type', cssClasses: 'col-xs-12',
        default: 'STATISTICS',
        allowedValues: {
          type: 'globalRegister',
          data: 'reporttype'
        },
      })
      .addRequired({
        title: 'FormFromDate', type: 'date', dateAsString: true,
        name: 'start_date',
      })
      .addRequired({
        title: 'FormToDate', type: 'date', dateAsString: true,
        name: 'end_date',
      })
      .addFormValidator(CustomValidators.dateRange('start_date', 'end_date'))
      .showSubmitButton(true, 'ButtonCreateAndDownload')
      .showCancelButton(false)
      .build();

    this.setInitialized(true);
  }

  onSuccessFormValue(formValue: NameValueMap<any>) {
    if (!this.partnerId) return;
    console.log(formValue);

    let apiCallInfo: ApiRequestConfigInfoDefinition = USERS_API_ENDPOINTS_LIST.getReport;
    this.apiRequestService.downloadFile(apiCallInfo, {
      PartnerId: this.partnerId.toString(),
      StartDate: formValue.start_date,
      EndDate: formValue.end_date,
      Language: this.customTranslateService.getCurrentLangShort(),
      ReportType: formValue.report_type
    });
  }
}
