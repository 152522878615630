<h1 mat-dialog-title *ngIf="data.user.enabled">{{data.user.firstname}} {{data.user.lastname}}</h1>
<h1 mat-dialog-title *ngIf="!data.user.enabled">{{'companyClient.objectProperties.user.unregisteredName' | translate}}</h1>

<div mat-dialog-content>
  <p>{{data.user.email}}</p>
  <mat-checkbox [(ngModel)]="shouldBeAdmin" id="admin">{{'companyClient.addUser.giveAdminAccess' | translate}}</mat-checkbox>
</div>

<div mat-dialog-actions [align]="'end'" class="padded-actions">
  <button mat-flat-button (click)="onNoClick()" id="modalCancel">{{'ModalCancelButton' | translate}}</button>
  <submit-button [isSendingRequest]="isSendingRequest" (submit)="submit()" [textKey]="'ModalConfirmButton'"></submit-button>
</div>
