import {Component, OnInit} from '@angular/core';
import {CompanypartnersBaseComponent} from "../companypartners-base.component";
import {MeteringPointConsumptionData} from "../../shared/model/metering-point-consumption-data";
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "../api-endpoints-list";

@Component({
  selector: 'metering-point-info',
  templateUrl: './metering-point-info.component.html',
  styleUrls: ['./metering-point-info.component.scss']
})
export class MeteringPointInfoComponent extends CompanypartnersBaseComponent implements OnInit {

  id: string | null = null;
  adminId: string | null = null;
  businessPartnerId: string | null = null;
  prefix = 'companyClient.meteringPointInfo.'
  meteringPointConsumptionData: MeteringPointConsumptionData[] = [];

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.adminId = this.activatedRoute.snapshot.paramMap.get('adminId');
    this.businessPartnerId = this.activatedRoute.snapshot.paramMap.get('businessPartnerId');

    this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.getMeteringPointMonthlyDetails,
      {
        selector: {id: this.id, administratorId: this.adminId},
        businessPartner: {partnerId: this.adminId, businessPartnerId: this.businessPartnerId}
      })
      .then(result => {
        this.meteringPointConsumptionData = [];
        if(result.status == 'success') {
          this.meteringPointConsumptionData = result.data.monthlyConsumption;
          this.initialized = true;
        } else {
          this.router.navigate(['/company/contingents/meteringpoints']);
        }
      });
  }
}
