// source: https://github.com/E-Group-ICT/EICjs
// Author: Ármin Scipiades <armin.scipiades@egroup.hu>
//
// Modifications SCS:
// - removed no-swiss EICs from list (we don't allow those)
// - removed lodash dependency (2023-07-13)
// - rewrite to ts class to avoid "_ is not defined" issues (2024-06-05)

export class EicValidator {
    private static readonly charValues: {[x: string]: number} = {
        '0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9,
        'a': 10, 'b': 11, 'c': 12, 'd': 13, 'e': 14, 'f': 15, 'g': 16, 'h': 17, 'i': 18, 'j': 19, 'k': 20, 'l': 21, 'm': 22, 'n': 23, 'o': 24, 'p': 25, 'q': 26, 'r': 27, 's': 28, 't': 29, 'u': 30, 'v': 31, 'w': 32, 'x': 33, 'y': 34, 'z': 35,
        '-': 36
    };

    private static readonly valueChars: {[x: string]: string} = {
      "0": "0",
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "6": "6",
      "7": "7",
      "8": "8",
      "9": "9",
      "10": "a",
      "11": "b",
      "12": "c",
      "13": "d",
      "14": "e",
      "15": "f",
      "16": "g",
      "17": "h",
      "18": "i",
      "19": "j",
      "20": "k",
      "21": "l",
      "22": "m",
      "23": "n",
      "24": "o",
      "25": "p",
      "26": "q",
      "27": "r",
      "28": "s",
      "29": "t",
      "30": "u",
      "31": "v",
      "32": "w",
      "33": "x",
      "34": "y",
      "35": "z",
      "36": "-"
    };


    private static mapping(x: string) {
        return EicValidator.charValues[x];
    };

    private static weighting(x: number, index: number) {
        return (16-index)*x;
    };

    // https://www.entsoe.eu/fileadmin/user_upload/edi/library/downloads/EIC_Reference_Manual_Release_5.pdf pp14-16
    private static readonly types: {[x: string]: string} = {
        'x': "PARTY",
        'y': "AREA",
        'z': "MEASUREMENT_POINT",
        'v': "LOCATION",
        'w': "RESOURCE",
        't': "TIE_LINE",
        'a': "SUBSTATION",
    };

    // https://www.entsoe.eu/data/energy-identification-codes-eic/eic-lio-websites/Pages/default.aspx
    private static readonly issuers: {[x: string]: {name: string, country: string}} = {
        "12": {"name": "Swissgrid", "country": "CH"},
    };

    /**
     *  Does given string look like an EIC code? This function returns true if given string may be an EIC coce:
     *  it has the correct length and format; however, this function does not examine the check character.
     *
     *  @param  {string} str The examined string
     *  @return {boolean} True if the given string looks like an EIC code: has the correct length, format, etc.
     */
    private static mayBeEIC(str: string) {
        if(str.length!=15 && str.length!=16) return false;
        str = str.toLowerCase();
        for(var i=0, len=str.length; i<len; ++i) {
            if(!((str.charCodeAt(i)>=97 && str.charCodeAt(i)<=122) || (str.charCodeAt(i)>=48 && str.charCodeAt(i)<=57) || str[i] == '-')) return false;
        }
        return true;
    };

    /**
     *  Calculates the check character for given string. The string must be 15 characters long (or 16 characters long,
     *  but in that case the 16th character is discarded).
     *
     *  @param  {string} str The examined string. Must be 15 or 16 characters long, and must be a well-formed EIC-string.
     *  @return A single character that is the check character for the given string.
     */
    private static calcCheckChar(str: string) {
        var s = str.substring(0,15).toLowerCase().split("");
        var c = s
          .map(EicValidator.mapping)
          .map(EicValidator.weighting)
          .reduce((previousValue, currentValue) => previousValue + currentValue);

        return EicValidator.valueChars[(36 - ((c - 1)%37))];
    };

    /**
     *  Check to see if a given EIC string is valid.
     *
     *  Returns true iff the given string is exactly 16 characters long, it's in the correct format, and the
     *  check character checks out.
     */
    private static isValid(str: string) {
        return EicValidator.examine(str).isValid;
    };

    /**
     *  Examine a string to see if it's a valid EIC code.
     *
     *  This resturns an object containing the possibly lists of errors and warnings concerning the given string, and also the issuer and type, if any.
     */
    private static examine(str: string) {
        var result = {
            isValid: true,
            errors: [] as {errorMessage: string, errorParams?: any}[],
            warnings: [] as {errorMessage: string, errorParams?: any}[],
            issuer: undefined as {[x: string]: string} | undefined,
            type: undefined as string | undefined
        };

        if(str.length < 16) {
            result.errors.push({ errorMessage: "TOO_SHORT" });
        }
        if(str.length > 16) {
            result.errors.push({ errorMessage: "TOO_LONG" });
        }

        str = str.toLowerCase();
        for(var i=0, len=str.length; i<len; ++i) {
            if(!((str.charCodeAt(i)>=97 && str.charCodeAt(i)<=122) || (str.charCodeAt(i)>=48 && str.charCodeAt(i)<=57) || str[i] == '-')) {
                result.errors.push({ errorMessage: "INVALID_CHARACTER", errorParams: [i, str[i]] });
            }
        }

        // if we have an error by this time, we just throw away the pencil: no other check makes sense.
        if(result.errors.length) {
            result.isValid = false;
            return result;
        }

        var cc = EicValidator.calcCheckChar(str);
        if(str[15] != cc) {
            result.errors.push({ errorMessage: "CHECKCHAR_MISMATCH", errorParams: [cc, str[15]] });
        }

        if(str[15] == cc && cc == '-') {
            result.errors.push({ errorMessage: "CHECKCHAR_HYPHEN" });
        }

        if(!(str[2] in EicValidator.types)) {
            result.warnings.push({ errorMessage: "UNKNOWN_TYPE", errorParams: [str[2]] });
        }

        if(!(str.substring(0,2) in EicValidator.issuers)) {
            result.warnings.push({ errorMessage: "UNKNOWN_ISSUER", errorParams: [str.substring(0,2)] });
        }

        result.issuer = EicValidator.getIssuer(str);
        result.type = EicValidator.getType(str);
        result.isValid = result.errors.length === 0;

        return result;
    };


    /**
     *  Return the type of the object represented by a valid EIC code. The type may be "PARTY", "AREA", "MEASUREMENT_POINT", "LOCATION",
     *  "RESOURCE", "TIE_LINE" or "SUBSTATION". For more information about these, see the
     *  [Reference Manual](https://www.entsoe.eu/fileadmin/user_upload/edi/library/downloads/EIC_Reference_Manual_Release_5.pdf).
     */
    private static getType(str: string) {
        if(!EicValidator.mayBeEIC(str)) throw new Error("Malformed EIC code");
        return EicValidator.types[str[2]];
    };

    private static getIssuer(str: string) {
        if(!EicValidator.mayBeEIC(str)) throw new Error("Malformed EIC code");
        return EicValidator.issuers[str.substr(0, 2)];
    };

    public static EIC = {
        mayBeEIC: EicValidator.mayBeEIC,
        calcCheckChar: EicValidator.calcCheckChar,
        isValid: EicValidator.isValid,
        getType: EicValidator.getType,
        getIssuer: EicValidator.getIssuer,
        examine: EicValidator.examine
    };
}
