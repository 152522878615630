import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {Meta} from '@angular/platform-browser';
import {SessionService} from './shared/session/session.service';

import {AppConfig} from './config/app.config';
import {NavigationEnd, Router, RoutesRecognized} from '@angular/router';

import {CustomTranslateService} from './shared/services/custom-translate.service';
import { UpdateService } from './shared/services/update-service';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {
  isLoggedInUserSection = false;
  isLoggedInCompanySection = false;

  routerEventsSubscription?: Subscription;

  hideRoute = false;

  constructor(
    public customTranslateService: CustomTranslateService,
    private sessionService: SessionService,
    private updateService: UpdateService,

    private meta: Meta,
    private router: Router,
  ) {

    this.meta.updateTag({
      name: 'description',
      content: AppConfig.title
    });


    this.routerEventsSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof RoutesRecognized) {
      } else if (event instanceof NavigationEnd) {

        this.isLoggedInUserSection = (event.urlAfterRedirects.indexOf('/users') === 0);
        this.isLoggedInCompanySection = (event.urlAfterRedirects.indexOf('/company') === 0);
      }
    });

    this.customTranslateService.languageChanged.subscribe((_language: string) => {
      this.hideRoute = true;
      setTimeout(() =>  {
        this.hideRoute = false;
      }, 0);
    });
  }

  async ngOnInit() {
    $.niftyNotyReset(); // Template specific logic

    // note: activatedRoute.queryParamMap doesn't work here
    const params = new URLSearchParams(window.location.search);
    const queryParamLang = params.get('lang');
    if (queryParamLang) {
      for (const lang of this.customTranslateService.availableLanguages) {
        if (lang.short === queryParamLang) {
          console.log('overriding lastLanguage from url query param:', queryParamLang);
          this.sessionService.changeLastLanguage(queryParamLang);
        }
      }
    }

    const lastLanguage = this.sessionService.getLastLanguage();
    if (lastLanguage) {
      console.log('lastLanguage:', lastLanguage);
      this.customTranslateService.use(lastLanguage);
    }

    // UpdateService must be instantiated before login, or else we miss the
    // initial SwUpdate notification and keep running an outdated version.
    this.updateService.updatesAvailable$.subscribe((available) => {
      console.log('updates available:', available);
    });
  }
}
