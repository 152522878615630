import { EnumDefinition, EnumSortType } from '../classes/enum-definition';
import { sdat } from '../../../messaging-grpc/messaging-grpc';

// upstream: messaging-grpc/common/src/sdat.proto

export const SDAT_BUSINESS_REASON: EnumDefinition<keyof typeof sdat.BusinessReason> = {
  id: 'sdatbusinessreason',
  items: [
    {label: '-', data: 'BUSINESS_REASON_UNSPECIFIED', omitFromDropdown: true},

    // SDAT change processes:
    {label: 'SDATBusinessReason.SUPPLIER_CHANGE', data: 'SUPPLIER_CHANGE'}, // E03 = Lieferantenwechsel
    {label: 'SDATBusinessReason.SUPPLIER_END', data: 'SUPPLIER_END'}, // E20 = Lieferende
    {label: 'SDATBusinessReason.CONSUMER_CHANGE', data: 'CONSUMER_CHANGE'}, // E92 = Einzug / EV-Wechsel
    {label: 'SDATBusinessReason.CONSUMER_END', data: 'CONSUMER_END'}, // E93 = Auszug
    {label: 'SDATBusinessReason.ASP_START', data: 'ASP_START'}, // C16 = Anmeldung SDV
    {label: 'SDATBusinessReason.ASP_END', data: 'ASP_END'}, // C17 = Abmeldung SDV
    {label: 'SDATBusinessReason.BASE_SUPPLY_REGISTRATION_BASE', data: 'BASE_SUPPLY_REGISTRATION_BASE'}, // E06 = Grundversorgung
    {label: 'SDATBusinessReason.BASE_SUPPLY_REGISTRATION_SUBSTITUTE', data: 'BASE_SUPPLY_REGISTRATION_SUBSTITUTE'}, // C25 = Ersatzversorgung
    {label: 'SDATBusinessReason.CONSUMER_MASTER_DATA_CHANGE', data: 'CONSUMER_MASTER_DATA_CHANGE'}, // C18 = Stammdatenaenderung EV durch LF/EZ
    {label: 'SDATBusinessReason.MP_MASTER_DATA_CHANGE', data: 'MP_MASTER_DATA_CHANGE'}, // C19 = Stammdatenaenderung Messpunkt

    // information/query:
    {label: 'SDATBusinessReason.CHECK_INFORMATION', data: 'CHECK_INFORMATION'}, // C10 = Austausch Zuordnungsliste
    {label: 'SDATBusinessReason.QUERY_SWITCH_INFORMATION', data: 'QUERY_SWITCH_INFORMATION'}, // C21 = Anfrage Wechseldatum
    {label: 'SDATBusinessReason.QUERY_METERING_POINT_INFORMATION', data: 'QUERY_METERING_POINT_INFORMATION'}, // C22 = Anfrage Stammdateninfo zum Messpunkt
    {label: 'SDATBusinessReason.QUERY_MEASUREMENTS', data: 'QUERY_MEASUREMENTS'}, // C23 = Anfrage Messdaten
    {label: 'SDATBusinessReason.QUERY_MEASUREMENT_AGGREGATES', data: 'QUERY_MEASUREMENT_AGGREGATES'}, // C24 = Anfrage Messdatenaggregate

    // document/process cancellation:
    {label: 'SDATBusinessReason.CANCELLATION', data: 'CANCELLATION'}, // E05 = Annullierung

    // Datahub change processes:
    {label: 'SDATBusinessReason.MP_REGISTRATION', data: 'MP_REGISTRATION'}, // H01 = Registration neuer Messpunkt
    {label: 'SDATBusinessReason.MP_DEREGISTRATION', data: 'MP_DEREGISTRATION'}, // H02 = Deregistration bestehender Messpunkt
    {label: 'SDATBusinessReason.MP_DATA_CHANGE', data: 'MP_DATA_CHANGE'}, // H03 = Datenaenderung Messpunkt

    // SDAT reasons routed (via ftp) only by Datahub, thus not needed:
    // C{11,12,13,37,87}, E{0D,E44,E88,E89}
  ]
};

const webclient_processes: (keyof typeof sdat.BusinessReason)[] = [
  'SUPPLIER_CHANGE', 'SUPPLIER_END',
  'BASE_SUPPLY_REGISTRATION_BASE', 'BASE_SUPPLY_REGISTRATION_SUBSTITUTE',
  'CONSUMER_CHANGE', 'CONSUMER_END',
  'ASP_START', 'ASP_END',
  'CONSUMER_MASTER_DATA_CHANGE', 'MP_MASTER_DATA_CHANGE',
  'QUERY_SWITCH_INFORMATION', 'QUERY_METERING_POINT_INFORMATION',
  'CANCELLATION'
];

export const SDAT_BUSINESS_REASON_WEBCLIENT: EnumDefinition<keyof typeof sdat.BusinessReason> = {
  id: 'sdatbusinessreason_webclient',
  items: webclient_processes.map(item => SDAT_BUSINESS_REASON.items.find(e => e.data === item) ?? {label: '-', data: 'BUSINESS_REASON_UNSPECIFIED', omitFromDropdown: true}),
  sortType: EnumSortType.Original
};

