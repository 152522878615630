<h1 mat-dialog-title *ngIf="step == 1">{{'companyClient.uniteCompanies.h1start' + (data.selectedOption == 'otherToSelf' ? 'OTS' : 'STO') | translate}}</h1>
<h1 mat-dialog-title *ngIf="step == 2">{{'companyClient.uniteCompanies.h1confirm' + (data.selectedOption == 'otherToSelf' ? 'OTS' : 'STO') | translate}}</h1>
<h1 mat-dialog-title *ngIf="step == 3">{{'companyClient.uniteCompanies.h1summary' + (data.selectedOption == 'otherToSelf' ? 'OTS' : 'STO') | translate}}</h1>

<div mat-dialog-content *ngIf="step == 1">
  <ng-container *ngIf="data.selectedOption == 'selfToOther'">
    {{'companyClient.uniteCompanies.giveSelfToOther' | translate: {companyName: data.ownCompanyName} }}<br><br>
    <mat-form-field class="rowspan">
      <mat-label>{{'FormEmail' | translate}}</mat-label>
      <input matInput type="email" name="email" id="email" [(ngModel)]="email">
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="data.selectedOption == 'otherToSelf'">
    {{'companyClient.uniteCompanies.giveOtherToSelf' | translate: {companyName: data.ownCompanyName} }}<br><br>
    <mat-form-field class="rowspan">
      <mat-label>{{'FormRegistrationCode' | translate}}</mat-label>
      <input matInput [formControl]="regCodeFormField" type="text" name="regCode" id="regCode">
      <mat-error>{{errorMessage}}</mat-error>
    </mat-form-field>
  </ng-container>
</div>

<div mat-dialog-content *ngIf="step == 2">
  <button mat-button (click)="backToStep1()" id="backToStep1">
    <mat-icon class="icon-in-text-button" svgIcon="arrow-back"></mat-icon>{{'ActionNotificationBack' | translate}}
  </button>
  <br><br>
  <ng-container *ngIf="data.selectedOption == 'otherToSelf'">
    <p>{{'companyClient.uniteCompanies.confirmOTS' | translate}}</p>
    <div class="company-link">
      <div class="rowspan">{{data.ownCompanyName}}</div>
      <mat-icon svgIcon="link" class="inline-text muted"></mat-icon>
      <span>{{otherCompanyName}}</span>
    </div><br>
    <p>{{'companyClient.uniteCompanies.warningOTS' | translate}}</p>
    <p class="text-danger">{{errorMessage}}</p>
  </ng-container>

  <ng-container *ngIf="data.selectedOption == 'selfToOther'">
    <p>{{'companyClient.uniteCompanies.confirmSTO' | translate}}</p>
    <b>{{email}}</b>
  </ng-container>
</div>

<div mat-dialog-content *ngIf="step == 3 && data.selectedOption == 'otherToSelf'">
  <p>{{'companyClient.uniteCompanies.summaryOTS' | translate}}</p>
  <div class="company-link">
    <div class="rowspan">{{data.ownCompanyName}}</div>
    <mat-icon svgIcon="link" class="inline-text muted"></mat-icon>
    <span>{{otherCompanyName}}</span>
  </div>
</div>

<div mat-dialog-content *ngIf="step == 3 && data.selectedOption == 'selfToOther'">
  <p>{{'companyClient.uniteCompanies.summarySTO' | translate}}</p>
  <b>{{email}}</b>
</div>

<div mat-dialog-actions [align]="'end'" class="padded-actions">
  <button *ngIf="step < 3" mat-flat-button cdkFocusInitial (click)="onCloseDialog(false)" id="modalCancel">{{'ModalCancelButton' | translate}}</button>

  <submit-button *ngIf="step == 1" [isSendingRequest]="isSendingRequest"
                 [disabled]="(data.selectedOption == 'selfToOther' && (email == undefined || email == '')) ||
                      (data.selectedOption == 'otherToSelf' && (regCodeFormField.value == undefined || regCodeFormField.value == ''))"
                 (submit)="gotoStep2()" [textKey]="'companyClient.uniteCompanies.actionContinue'"></submit-button>

  <submit-button *ngIf="step == 2" [isSendingRequest]="isSendingRequest" (submit)="confirm()" [textKey]="'companyClient.uniteCompanies.actionConfirm' + (data.selectedOption == 'otherToSelf' ? 'OTS' : 'STO')"></submit-button>

  <submit-button *ngIf="step == 3" [isSendingRequest]="isSendingRequest" (submit)="onCloseDialog(true)" [textKey]="'companyClient.uniteCompanies.actionOk'"></submit-button>
</div>
