/* eslint-disable max-len */
import {SmartTableRowActionDefinitionContainer} from '../classes/SmartTableRowActionDefinition';
import {UserInfoOperatorServer} from '../../../../session/UserInfo';


export const SMART_TABLE_ROWACTIONS_DEFS: SmartTableRowActionDefinitionContainer[] = [
  {
    id: 'rowActionEditMarketPartnerUser',
    definition: {
      id: 'modify_user_rights',
      title: 'ActionModifyUserRights',
      icon: 'pli-professor',
      className: 'btn-mint',
      fnVisible: function (rowDataById: any) {
        return !rowDataById.actionsDisabled;
      }
    }
  },
  {
    id: 'rowActionDeactivateUser',
    definition: {
      id: 'deactivate_user',
      title: 'ActionDeactivateUser',
      icon: 'pli-security-block',
      className: 'btn-danger',
      confirmationDialog: {
        title: 'ActionDeactivateUser',
        body: 'AreYouSureConfirmationBody'
      },
      fnVisible: function (rowDataById: any) {
        return rowDataById.status === 'active' && !rowDataById.actionsDisabled;
      }
    },
  },
  {
    id: 'rowActionActivateUser',
    definition: {
      id: 'activate_user',
      title: 'ActionActivateUser',
      icon: 'pli-unlock',
      className: 'btn-info',
      confirmationDialog: {
        title: 'ActionActivateUser',
        body: 'AreYouSureConfirmationBody'
      },
      fnVisible: function (rowDataById: any) {
        return rowDataById.status !== 'active' && !rowDataById.actionsDisabled;
      }
    }
  },
  {
    id: 'rowActionViewMarketPartner',
    definition: {
      id: 'view_marketpartner',
      title: 'ActionEditMarketPartner',
      icon: 'pli-pencil',
      className: 'btn-mint'
    }
  },
  {
    id: 'rowActionModifyOperatorRole',
    definition: {
      id: 'modify_operator_role',
      title: 'ActionModifyOperatorRole',
      icon: 'pli-professor',
      className: 'btn-mint',
      fnVisible: function (rowDataById: UserInfoOperatorServer) {
        return rowDataById.type !== 'MARKETPARTNER' && !rowDataById.actionsDisabled;
      }
    }
  },
  {
    id: 'rowActionSetMarketPartnerContract',
    definition: {
      id: 'setcontract_marketpartner',
      title: 'ActionSetMarketPartnerContract',
      icon: 'pli-letter-open',
      className: 'btn-mint'
    }
  },
  {
    id: 'rowActionDeleteGlobalUser',
    definition: {
      id: 'delete_global_user',
      title: 'ActionDeleteUser',
      icon: 'pli-trash',
      className: 'btn-danger',
      confirmationDialog: {
        title: 'ActionDeleteUser',
        body: 'AreYouSureConfirmationBodyDeleteUser',
        confirmButtonText: 'ButtonDelete'
      },
      fnVisible: function (rowDataById: any) {
        return !rowDataById.actionsDisabled;
      }
    },
  },

  {
    id: 'rowActionDeleteMeteringPoints',
    definition: {
      id: 'delete_meteringpoints',
      title: 'ActionDeleteMeteringPoints',
      icon: 'pli-transform-3',
      className: 'btn-danger',
      confirmationDialog: {
        title: 'ActionDeleteMeteringPoints',
        body: 'AreYouSureConfirmationBodyDeleteMeteringPoints',
        confirmButtonText: 'ButtonDelete'
      },
      fnVisible: rowData => rowData['partnerSettings:role'] === 'VNB'
    },
  },

  {
    id: 'rowActionDeleteMarketPartner',
    definition: {
      id: 'delete_marketpartner',
      title: 'ActionDeleteMarketPartner',
      icon: 'pli-trash',
      className: 'btn-danger',
      confirmationDialog: {
        title: 'ActionDeleteMarketPartner',
        body: 'AreYouSureConfirmationBodyDeleteMarketPartner',
        confirmButtonText: 'ButtonDelete'
      }
    },
  },

  {
    id: 'rowActionDeleteMarketPartnerUser',
    definition: {
      id: 'delete_marketpartner_user',
      title: 'ActionDeleteUser',
      icon: 'pli-trash',
      className: 'btn-danger',
      confirmationDialog: {
        title: 'ActionDeleteUser',
        body: 'AreYouSureConfirmationBodyDeleteUser',
        confirmButtonText: 'ButtonDelete'
      },
      fnVisible: function (rowDataById: any) {
        return !rowDataById.actionsDisabled;
      }
    },
  },
];
