import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {MediaMatcher} from "@angular/cdk/layout";
import {ApiRequestService} from "../shared/api-request/service/api-request.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {DateTimeService} from "../shared/services/date-time.service";
import {SessionService} from "../shared/session/session.service";
import {FormBuilder} from "@angular/forms";
import {CustomTranslateService} from "../shared/services/custom-translate.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {MeteringDataService} from "./metering-data.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  template: ''
})
export class CompanypartnersBaseComponent implements OnDestroy {

  isSendingRequest = false;
  initialized = false;
  isAdmin = false;
  isMobile = false;
  mobileQuery: MediaQueryList;
  partnerId: number;
  private _mobileQueryListener: () => void;
  meteringDataSubscription$ = this.meteringDataService.consumptionData$.pipe(takeUntilDestroyed());

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              protected dateTimeService: DateTimeService,
              protected translate: TranslateService,
              public customTranslateService: CustomTranslateService,
              protected requestService: ApiRequestService,
              protected meteringDataService: MeteringDataService,
              protected sessionService: SessionService,
              protected readonly fb: FormBuilder,
              protected _snackBar: MatSnackBar,
              protected activatedRoute: ActivatedRoute,
              protected router: Router,
              public dialog: MatDialog) {

    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => {changeDetectorRef.detectChanges(); this.isMobile = this.mobileQuery.matches}
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.isAdmin = sessionService.isCompanyPartnerAdmin();
    this.partnerId = parseInt(this.activatedRoute.snapshot.paramMap.get('partnerId') || '') || this.sessionService.getCurrentPartnerId() || 0;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  openSnackbar(message: string, duration : number | undefined = undefined, verticalPosition: 'top' | 'bottom' = 'top') {
    this._snackBar.open(message, '×', {verticalPosition: verticalPosition, duration: duration});
  }

}
