import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SmartModalBuilder } from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import { SmartModalService } from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import { SmartFormBuilder } from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import { SmartFormConfigDefinition } from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormConfigDefinition';
import { USERS_API_ENDPOINTS_LIST } from '../requests/api-endpoints-list';
import { sdat, sdatwebclient } from '../../shared/messaging-grpc/messaging-grpc';
import { EnumItem } from '../../shared/services/enum-utils/classes/enum-definition';
import { SmartFormFormFieldDefinition } from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormFormFieldDefinition';
import { AbstractControl } from '@angular/forms';
import { MarketPartnerService } from '../../shared/services/marketpartner.service';
import { SMART_FORM_OBJECTSARRAY_DEFS } from '../../shared/marketpartner-components/smart-forms/smart-form/definitions/smart-form-formobjectsarray.definitions';
import { MarketpartnersLoaderService } from './marketpartners-loader.service';
import { LastResponses } from '../users-change-processes-info/users-change-processes-info.component';

type BusinessReason = keyof typeof sdat.BusinessReason;
type RejectionReason = keyof typeof sdat.RejectionReason;

export interface DialogCallbacks {
  acceptDialog?: ((message: sdatwebclient.DocumentDetails, lastResponses: LastResponses | undefined) => Promise<any>);
  rejectDialog?: ((message: sdatwebclient.DocumentDetails, lastResponse: LastResponses | undefined | null) => Promise<any>);
}

// common form fields
const aspListDefinition: SmartFormFormFieldDefinition = {
  name: 'ancillaryServiceProviderEic', title: 'SDATDialogs.FormFields.AncillaryServiceProviderEics',
  type: 'array', cssClasses: 'col-xs-12', objectId: 'ancillaryServiceProvidersObject',
  inputList: true
};

@Injectable()
export class SdatResponseDialogsService {
  constructor(
    private smartModalService: SmartModalService,
    private translate: TranslateService,
    private marketPartnerService: MarketPartnerService,
    private marketPartnersLoaderService: MarketpartnersLoaderService
  ) {
    this.marketPartnersLoaderService.load();
  }

  public getButtonCallbacks(businessReason: BusinessReason): DialogCallbacks {
    const defaultReasons: RejectionReason[] = [
      'METERING_POINT_NOT_IDENTIFIABLE',          // E10
      'METERING_POINT_IN_BASIC_SUPPLY_WITHOUT_VALID_GRID_ACCESS', // E11
      'OTHER_REASON',                             // E14
      'NO_CORRECTIONS',                           // E15
      'UNAUTHORISED_BALANCE_SUPPLIER',            // E16
      'DATE_NOT_WITHIN_TIME_LIMITS',              // E17
      'UNAUTHORISED_BALANCE_RESPONSIBLE',         // E18
      'METERING_POINT_BLOCKED_FOR_SWITCHING',     // E22
      'NO_VALID_COLLABORATION',                   // E36
      'NO_VALID_GRID_ACCESS_CONTRACT',            // E37
      'NO_ONGOING_SWITCH_FOR_MP',                 // E47
      'ALREADY_EXISTING_RELATION',                // E59
      'UNAUTHORISED_ANCILLARY_SERVICE_PROVIDER'   // C10
    ];

    if (businessReason === 'SUPPLIER_CHANGE') {
      return {
        acceptDialog: (msg, lastResponses) => this.supplierChangeConfirmation(msg, lastResponses),
        rejectDialog: (msg, lastRejectionReason) => this.reject(msg, defaultReasons, lastRejectionReason)
      };
    } else if (businessReason === 'SUPPLIER_END') {
      return {
        acceptDialog: (msg, lastResponses) => this.supplierEndConfirmation(msg, lastResponses),
        rejectDialog: (msg, lastRejectionReason) => this.reject(msg, defaultReasons, lastRejectionReason)
      };
    } else if (businessReason === 'ASP_START') {
      return {
        acceptDialog: (msg, lastResponses) => this.aspStartConfirmation(msg, lastResponses),
        rejectDialog: (msg, lastResponses) => this.reject(msg, defaultReasons, lastResponses)
      };
    } else if (businessReason === 'ASP_END') {
      return {
        acceptDialog: (msg, lastResponses) => this.aspEndConfirmation(msg, lastResponses),
        rejectDialog: (msg, lastResponses) => this.reject(msg, defaultReasons, lastResponses)
      };
    } else if (businessReason === 'QUERY_METERING_POINT_INFORMATION') {
      return {
        acceptDialog: (msg, lastResponses) => this.queryMpInfoConfirmation(msg, lastResponses),
        rejectDialog: (msg, lastResponses) => this.reject(msg, defaultReasons, lastResponses)
      };
    } else if (businessReason === 'QUERY_SWITCH_INFORMATION') {
      return {
        acceptDialog: (msg, lastResponses) => this.querySwitchInfoConfirmation(msg, lastResponses),
        rejectDialog: (msg, lastResponses) => this.reject(msg, defaultReasons, lastResponses)
      };
    } else if (businessReason === 'CONSUMER_END') {
      return {
        acceptDialog: (msg, lastResponses) => this.requestConsumerEnd(msg, lastResponses),
        rejectDialog: (msg, lastResponses) => this.reject(msg, defaultReasons, lastResponses)
      };
    } else if (businessReason === 'CONSUMER_CHANGE') {
      return {
        acceptDialog: (msg, lastResponses) => this.requestConsumerChange(msg, lastResponses),
        rejectDialog: (msg, lastResponses) => this.reject(msg, defaultReasons, lastResponses)
      };
    }
    else {
      return {};
    }
  }

  public getCancelProcessButtonCallbacks(): DialogCallbacks {
    return {
      acceptDialog: msg => this.requestProcessCancellation(msg),
      rejectDialog: (msg, lastResponses) => this.reject(msg, [
        'OTHER_REASON',                     // E14
        'UNAUTHORISED_BALANCE_SUPPLIER',    // E16
        'DATE_NOT_WITHIN_TIME_LIMITS',      // E37
      ], lastResponses)
    };
  }

  private reject(message: sdatwebclient.DocumentDetails, reasons: RejectionReason[], lastResponse: LastResponses | null | undefined = undefined) {
    const reasonsEnum: EnumItem<RejectionReason>[] = reasons.map(
      data => ({data, label: 'SDATRejectionReason.' + data})
    );
    const lastRejectionReason = lastResponse?.lastRejection?.content?.document?.header?.reason?.value;
    const formBuilder = SdatResponseDialogsService.buildForm('rejectRequest', 'REJECT', message)
      .addRequired({
        name: 'rejectionReason', title: 'LabelSDATRejectionReason',
        type: 'select', cssClasses: 'col-xs-12', default: lastRejectionReason,
        allowedValues: {type: 'localDefined', data: reasonsEnum}
      });
    const modalText = !!lastRejectionReason ? 'SDATDialogs.PreviousResponseRejection' : ( !!lastResponse?.lastConfirmationRequest ? 'SDATDialogs.PreviousResponseConfirmation' : undefined);
    return this.runModal('SDATDialogs.TitleRejectRequest', 'REJECT', formBuilder.build(), modalText);
  }

  private supplierChangeConfirmation(message: sdatwebclient.DocumentDetails, lastResponses: LastResponses | undefined = undefined) {
    const formBuilder = SdatResponseDialogsService.buildForm('confirmSupplierChange', 'ACCEPT', message)
      .setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS)
      .addOptional(aspListDefinition)
      .addOptionalPair({
        name: 'previousBalance:supplier', title: 'SDATDialogs.FormFields.PreviousBalanceSupplierEic',
        type: 'autocomplete', cssClasses: 'col-xs-6', validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'lfMarketpartners'
        },
      }, {
        name: 'previousBalance:responsible', title: 'SDATDialogs.FormFields.PreviousBalanceResponsibleEic',
        type: 'autocomplete', cssClasses: 'col-xs-6', validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'bgvMarketpartners'
        },
      }, 'SDATDialogs.FormErrors.BalanceSupplierBalanceResponsibleBothOrNone');

    const lastConfirmationResponse = lastResponses?.lastConfirmationRequest?.supplierChange;
    if (lastConfirmationResponse) {
      formBuilder.setInitData(lastConfirmationResponse);
    }
    const modalText = !!lastConfirmationResponse ? 'SDATDialogs.PreviousResponseConfirmation' : (!!lastResponses?.lastRejection ? 'SDATDialogs.PreviousResponseRejection' : undefined);
    return this.runModal('SDATDialogs.TitleModifySupply', 'ACCEPT', formBuilder.build(), modalText);
  }

  private supplierEndConfirmation(message: sdatwebclient.DocumentDetails, lastResponses: LastResponses | undefined = undefined) {
    const formBuilder = SdatResponseDialogsService.buildForm('confirmSupplierEnd', 'ACCEPT', message)
      .setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS)
      .addOptional(aspListDefinition)
      .addOptional({
        name: 'previousBalanceResponsibleEic', title: 'SDATDialogs.FormFields.PreviousBalanceResponsibleEic',
        type: 'autocomplete', cssClasses: 'col-xs-6', validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'bgvMarketpartners'
        },
      })
      .addFormValidator((formGroup: AbstractControl) => {
        const br = formGroup.get('previousBalanceResponsibleEic');
        const aspList = formGroup.get('ancillaryServiceProviderEic');
        if (!br || !aspList) throw new Error('missing form fields');
        if (aspList.value && !br.value) {
          br.setErrors({translatableMessage: 'SDATDialogs.FormErrors.BalanceResponsibleRequiredWithAsp'});
        } else {
          // re-run EIC validation (to clear the above error)
          br.setErrors(br.validator ? br.validator(br) : null);
        }
        return null;
      });
    const lastConfirmationResponse = lastResponses?.lastConfirmationRequest?.supplierEnd;
    if (lastConfirmationResponse) {
      formBuilder.setInitData(lastConfirmationResponse);
    }
    const modalText = !!lastConfirmationResponse ? 'SDATDialogs.PreviousResponseConfirmation' : (!!lastResponses?.lastRejection ? 'SDATDialogs.PreviousResponseRejection' : undefined);
    return this.runModal('SDATDialogs.TitleEndSupply', 'ACCEPT', formBuilder.build(), modalText);
  }

  private aspStartConfirmation(message: sdatwebclient.DocumentDetails, lastResponses: LastResponses | undefined = undefined) {
    const formBuilder = SdatResponseDialogsService.buildForm('confirmASPStart', 'ACCEPT', message)
      .addOptionalPair({
        name: 'currentBalance:supplier', title: 'SDATDialogs.FormFields.CurrentBalanceSupplierEic',
        type: 'autocomplete', cssClasses: 'col-xs-6', validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'lfMarketpartners'
        },
      }, {
        name: 'currentBalance:responsible', title: 'SDATDialogs.FormFields.CurrentBalanceResponsibleEic',
        type: 'autocomplete', cssClasses: 'col-xs-6', validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'bgvMarketpartners'
        },
      }, 'SDATDialogs.FormErrors.BalanceSupplierBalanceResponsibleBothOrNone');

    const lastConfirmationResponse = lastResponses?.lastConfirmationRequest?.aspStart;
    if (lastConfirmationResponse) {
      formBuilder.setInitData(lastConfirmationResponse);
    }
    const modalText = !!lastConfirmationResponse ? 'SDATDialogs.PreviousResponseConfirmation' : (!!lastResponses?.lastRejection ? 'SDATDialogs.PreviousResponseRejection' : undefined);
    return this.runModal('SDATDialogs.TitleASPRegister', 'ACCEPT', formBuilder.build(), modalText);
  }

  private aspEndConfirmation(message: sdatwebclient.DocumentDetails, lastResponses: LastResponses | undefined = undefined) {
    const formBuilder = SdatResponseDialogsService.buildForm('confirmASPEnd', 'ACCEPT', message)
      .addOptional({
        name: 'currentBalanceSupplierEic', title: 'SDATDialogs.FormFields.CurrentBalanceSupplierEic',
        type: 'autocomplete', cssClasses: 'col-xs-12', validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'lfMarketpartners'
        },
      });

    const lastConfirmationResponse = lastResponses?.lastConfirmationRequest?.aspEnd;
    if (lastConfirmationResponse) {
      formBuilder.setInitData(lastConfirmationResponse);
    }
    const modalText = !!lastConfirmationResponse ? 'SDATDialogs.PreviousResponseConfirmation' : (!!lastResponses?.lastRejection ? 'SDATDialogs.PreviousResponseRejection' : undefined);
    return this.runModal('SDATDialogs.TitleASPDeregister', 'ACCEPT', formBuilder.build(), modalText);
  }

  private queryMpInfoConfirmation(message: sdatwebclient.DocumentDetails, lastResponses: LastResponses | undefined = undefined) {
    const formBuilder = SdatResponseDialogsService.buildForm('answerMPInfoQuery', 'ACCEPT', message)
      .setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS)
      .addRequired({
        name: 'characteristics', objectId: 'meteringPointCharacteristicsObject',
        type: 'object', cssClasses: 'col-xs-12'
      })
      .addOptional({
        name: 'address',
        type: 'object', cssClasses: 'col-xs-12', objectId: 'meteringPointAddressObject'
      })
      .addOptional({
        name: 'parties',
        type: 'object', cssClasses: 'col-xs-12', objectId: 'meteringPointPartiesObject'
      });

    const lastConfirmationResponse = lastResponses?.lastConfirmationRequest?.mpInfo;
    if (lastConfirmationResponse) {
      formBuilder.setInitData(lastConfirmationResponse);
    }
    const modalText = !!lastConfirmationResponse ? 'SDATDialogs.PreviousResponseConfirmation' : (!!lastResponses?.lastRejection ? 'SDATDialogs.PreviousResponseRejection' : undefined);
    return this.runModal('SDATDialogs.TitleQueryMpInformation', 'ACCEPT', formBuilder.build(), modalText);
  }

  private querySwitchInfoConfirmation(message: sdatwebclient.DocumentDetails, lastResponses: LastResponses | undefined = undefined) {
    const formBuilder = SdatResponseDialogsService.buildForm('answerSwitchInfoQuery', 'ACCEPT', message)
      .setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS)
      .addOptional({
        name: 'contractTerminationInfo',
        type: 'object', cssClasses: 'col-xs-12', objectId: 'contractTerminationInfoObject'
      });
    const lastConfirmationResponse = lastResponses?.lastConfirmationRequest?.switchInfo;
    if (lastConfirmationResponse) {
      formBuilder.setInitData(lastConfirmationResponse);
    }
    const modalText = !!lastConfirmationResponse ? 'SDATDialogs.PreviousResponseConfirmation' : (!!lastResponses?.lastRejection ? 'SDATDialogs.PreviousResponseRejection' : undefined);
    return this.runModal('SDATDialogs.TitleQuerySwitchInformation', 'ACCEPT', formBuilder.build(), modalText);
  }

  private requestConsumerEnd(message: sdatwebclient.DocumentDetails, lastResponses: LastResponses | undefined = undefined) {
    const formBuilder = SdatResponseDialogsService.buildForm('confirmConsumerEnd', 'ACCEPT', message)
      .setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS)
      .addOptional(aspListDefinition);

    const lastConfirmationResponse = lastResponses?.lastConfirmationRequest?.consumerEnd;
    if (lastConfirmationResponse) {
      formBuilder.setInitData(lastConfirmationResponse);
    }
    const modalText = !!lastConfirmationResponse ? 'SDATDialogs.PreviousResponseConfirmation' : (!!lastResponses?.lastRejection ? 'SDATDialogs.PreviousResponseRejection' : undefined);
    return this.runModal('SDATDialogs.TitleRequestConsumerEnd', 'ACCEPT', formBuilder.build(), modalText);
  }

  private requestConsumerChange(message: sdatwebclient.DocumentDetails, lastResponses: LastResponses | undefined = undefined) {
    const formBuilder = SdatResponseDialogsService.buildForm('confirmConsumerChange', 'ACCEPT', message)
      .setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS)
      .addOptional({
        name: 'previousBalance',
        type: 'object', cssClasses: 'col-xs-12', objectId: 'balancePartiesObject'
      })
      .addOptional(aspListDefinition)
      .addOptional({
        name: 'unused', title: 'SDATDialogs.FormFields.GridBillingMethod',
        type: 'select', cssClasses: 'col-xs-6',
        allowedValues: {type: 'globalRegister', data: 'gridbillingmethod'}
      });

    const lastConfirmationResponse = lastResponses?.lastConfirmationRequest?.consumerChange;
    if (lastConfirmationResponse) {
      formBuilder.setInitData(lastConfirmationResponse);
    }
    const modalText = !!lastConfirmationResponse ? 'SDATDialogs.PreviousResponseConfirmation' : (!!lastResponses?.lastRejection ? 'SDATDialogs.PreviousResponseRejection' : undefined);
    return this.runModal('SDATDialogs.TitleRequestConsumerChange', 'ACCEPT', formBuilder.build(), modalText);
  }

  private requestProcessCancellation(message: sdatwebclient.DocumentDetails) {
    const formBuilder = SdatResponseDialogsService.buildForm('confirmProcessCancellation', 'ACCEPT', message);
    return this.runModal('SDATDialogs.TitleCancellation', 'ACCEPT', formBuilder.build());
  }

  private static buildForm(endpoint: string, kind: ('ACCEPT' | 'REJECT'), message: sdatwebclient.DocumentDetails): SmartFormBuilder {
    console.log(kind, 'dialog for message:', message);
    if (!message.document) throw new Error('invalid message');
    if (!message.document.messageMetadata) throw new Error('invalid message');
    const meta = message.document.messageMetadata;

    const info = USERS_API_ENDPOINTS_LIST[endpoint];
    return new SmartFormBuilder()
      .addRequired({
        name: 'reference:uuid', type: 'hidden',
        default: meta.messageUuid
      })
      .addRequired({
        name: 'reference:partnerId', type: 'hidden',
        default: meta.recipientId
      })
      .showSubmitButton(true, kind === 'ACCEPT' ? 'SDATDialogs.ButtonAccept' : 'SDATDialogs.ButtonReject')
      .showCancelButton(true)
      .setApiSendDataRequestConfigFromInfo({
        info,
        options: {}
      })
      .removeEmptyValuesFromOutput(true);
  }

  private async runModal(title: string, kind: ('ACCEPT' | 'REJECT'), formConfig: SmartFormConfigDefinition, modalText: string | undefined = undefined) {
    let formValue: any;
    let response: any;
    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(v => formValue = v)
      .setOnSuccessResponse(r => response = r)
      .setTitle(title)
      .setModalCSSClassSize('lg')
      .setBodyText(modalText, true)
      .setFormConfigFromInfo(formConfig)
      .build();
    await this.smartModalService.showModal(modalOptions);
    console.log('formValue:', formValue);
    console.log('response:', response);
    if (response) {
      await this.showSuccessConfirmation(response, kind);
      console.log('Response confirmation dialog closed.');
      this.marketPartnerService.updateUnsuppliedCount();
      return {formValue, response};
    } else {
      return undefined;
    }
  }

  private async showSuccessConfirmation(response: any, kind: ('ACCEPT' | 'REJECT')) {
    let message;
    // response: ProcessResult (processingcore.proto)
    if (kind === 'REJECT') {
      message = this.translate.instant('SDATDialogs.SuccessConfirmationRejectedText');
    } else if (response.processId) {
      // is this reachable?
      message = this.translate.instant('SDATDialogs.SuccessConfirmationAcceptedWithProcessIdText',
        response);
    } else {
      message = this.translate.instant('SDATDialogs.SuccessConfirmationAcceptedText');
    }

    const modalOptions = new SmartModalBuilder()
      .setTitle('SDATDialogs.SuccessConfirmationTitle')
      .setBodyText(message)
      .showConfirmButton(true, 'ModalCloseButton')
      .setModalCSSClassSize('md')
      .build();
    const request = await this.smartModalService.showModal(modalOptions);
    return {request, response};
  }
}

