import {EventEmitter, Injectable} from '@angular/core';
import {SmartModalConfigDefinition} from '../classes/SmartModalConfigDefinition';
import {SmartModalBuilder} from '../builder/smart-modal.builder';

@Injectable()
export class SmartModalService {
  onShowModal: EventEmitter<SmartModalConfigDefinition> = new EventEmitter();
  onHideModal: EventEmitter<undefined> = new EventEmitter();

  constructor() {
  }

  showModal(options: SmartModalConfigDefinition): Promise<any> {
    if (!('size' in options)) {
      options.size = 'lg';
    }
    return new Promise((resolve) => {
      this.onShowModal.emit({onModalClosed: resolve, ...options});
    });
  }

  showModalAwaitAPISuccess(options: SmartModalConfigDefinition): Promise<any> {
    if (!options.formConfig) throw new Error('form required');
    if (!options.formConfig.apiSendDataRequestConfig) throw new Error('API request required');
    return new Promise<any>((resolve, reject) => {
      options.onSuccessResponseValue = resolve;
      this.showModal(options).then(
        () => reject('dialog closed by user')
      ).catch(reject);
    });
  }

  getConfirmationDialogConfig(title: string, body?: string, additionalHTMLData?: string): SmartModalConfigDefinition {
    const modalOptions =  new SmartModalBuilder()
      .setTitle(title)
      .setBodyText( body ? body : 'AreYouSureConfirmationBody')
      .setBodyHtml(additionalHTMLData ?? '')
      .showConfirmButton(true)
      .showCancelButton(true)
      .build();

    return modalOptions;
  }
}
