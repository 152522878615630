import {Component, Input} from '@angular/core';

@Component({
  selector: 'page-loading-spinner',
  templateUrl: './page-loading-spinner.component.html',
  styleUrls: ['page-loading-spinner.component.scss']
})
export class PageLoadingSpinnerComponent {
  @Input() initialized = false;
}
