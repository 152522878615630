import {EventEmitter, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../session/session.service';
import * as moment from 'moment-timezone';

@Injectable()
export class CustomTranslateService {
  languageChanged: EventEmitter<string> = new EventEmitter();
  public currentLang: string;
  public defaultLang = 'de';
  public availableLanguages = [{
    short: 'de',
    longNative: 'Deutsch',
    locale: 'de-CH',
  }, {
    short: 'fr',
    longNative: 'Français',
    locale: 'fr-CH',
  }, {
    short: 'it',
    longNative: 'Italiano',
    locale: 'it-CH',
  }];

  constructor(
    private sessionService: SessionService,
    private translate: TranslateService,
  ) {
    translate.addLangs(this.availableLanguages.map(lang => lang.short));
    translate.setDefaultLang(this.defaultLang);

    let initialLang = this.defaultLang;
    const browserLang = translate.getBrowserLang();
    for (const lang of this.availableLanguages) {
      if (lang.short === browserLang) initialLang = lang.short;
    }
    console.log('browserLang:', browserLang, '; initialLang:', initialLang);
    translate.use(initialLang);
    this.useLocaleFromLang(initialLang);
    this.currentLang = initialLang;
  }

  private useLocaleFromLang(langShort: string) {
    for (const lang of this.availableLanguages) {
      if (lang.short === langShort) moment.locale(lang.locale);
    }
  }

  public getCurrentLangShort() {
    return this.currentLang;
  }

  public getCurrentLangLongNative() {
    const langShort = this.getCurrentLangShort();
    for (const lang of this.availableLanguages) {
      if (lang.short === langShort) return lang.longNative;
    }
    return langShort;
  }

  public use(language: string) {
    if (this.availableLanguages.filter(lang => lang.short === language).length === 0) {
      console.error('language not supported:', language);
      return;
    }

    if (language !== this.getCurrentLangShort()) {
      console.log('changing language to', language);
      this.translate.use(language);
      this.useLocaleFromLang(language);
      this.currentLang = language;
      this.sessionService.changeLastLanguage(language);
      this.languageChanged.emit(language);
    }
  }
}

