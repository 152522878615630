import { Injectable } from '@angular/core';
import {ApiRequestBuilder} from "../../shared/api-request/builder/api-request.builder";
import {USERS_API_ENDPOINTS_LIST} from "../requests/api-endpoints-list";
import {SmartModalBuilder} from "../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder";
import {BehaviorSubject} from "rxjs";
import {SmartModalService} from "../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service";
import {ApiRequestService} from "../../shared/api-request/service/api-request.service";
import {TranslateService} from "@ngx-translate/core";
import {CustomTranslateService} from "../../shared/services/custom-translate.service";

@Injectable({
  providedIn: 'root'
})
export class OstralUploadService {

  private uploading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private smartModalService: SmartModalService,
    private apiRequestService: ApiRequestService,
    private translate: TranslateService,
    private customTranslateService: CustomTranslateService
  ) { }

  public async startUpload(file: File) {
    // backend needs multipart to extract the original filename
    const formData = new FormData();
    formData.append('file', file);

    const requestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(false)  // show as dialog instead
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.uploadBusinessPartnerData)
      .setBodyFormData(formData)
      .build();

    this.uploading$.next(true);
    let uploadResult;
    try {
      uploadResult = await this.apiRequestService.callApi(requestConfig);
    } finally {
      this.uploading$.next(false);
    }

    // show as dialog, because a toast if easy to miss after 5min waiting
    const modalBuilder = new SmartModalBuilder()
      .setModalCSSClassSize('md')
      .showCancelButton(true, 'ModalCloseButton')

    // error
    if (!uploadResult || uploadResult.status !== 'success') {
      let msg;
      if (uploadResult && uploadResult.data) {
        msg = await this.apiRequestService.convertApiErrorToString(uploadResult.data);
        if(msg == 'EntityTooLargeError') {
          msg = 'EntityTooLargeErrorTryZip';
        }
      }
      modalBuilder
        .setTitle('Ostral.ImportUploadFailedTitle')
        .setBodyText(msg || 'Ostral.ImportUploadFailedText');

    // success
    } else {
      const warningsHtml = uploadResult.data?.warnings?.length > 0 ? '<br><br><div class="text-warning scrollable-block"><p>'+
        uploadResult.data.warnings.map((w: any) => w[this.customTranslateService.getCurrentLangShort()]).join('</p><p>')+
        '</p></div>'
        : '';

      modalBuilder
        .setTitle('Ostral.ImportUploadSuccessTitle')
        .setBodyHtml(this.translate.instant('Ostral.ImportUploadSuccessText').replace('{{numOfRows}}', uploadResult.data.processedLines)+
          warningsHtml);
    }

    await this.smartModalService.showModal(modalBuilder.build());
  }
}
