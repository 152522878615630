import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CompanypartnersBaseComponent} from "../../companypartners-base.component";
import {FormGroup, Validators} from "@angular/forms";
import {UserType} from "../../../shared/session/SessionInfo";
import {NAV_API_ENDPOINTS_LIST} from "../../../shared/nav/api-endpoints-list";

// FIXME workaround: grpc structure mismatch
type ProfileInfo = {
  email: string;
  enabled: boolean;
  firstname: string;
  lastname: string;
  id: number;
  lang: string | undefined;
  salutation: 'MR' | 'MRS';
  telephone: string;
  type: UserType
}
@Component({
  selector: 'change-profile',
  templateUrl: './change-profile.component.html',
  styleUrls: []
})
export class ChangeProfileComponent extends CompanypartnersBaseComponent implements OnInit {

  userInfoForm!: FormGroup;
  @Output() afterInitialized = new EventEmitter<void>();

  ngOnInit() {
    this.fillForm({lang: undefined, telephone: '', salutation: 'MR', lastname: '', firstname: '', email: '', type: UserType.UNAUTHORIZED, enabled: false, id: 0});
    this.requestService.doApiRequest(NAV_API_ENDPOINTS_LIST.profileInfo)
      .then((result: {status: string, data: ProfileInfo}) => {
        this.initialized = true;
        this.afterInitialized.emit();
        if(result.status == 'success') {
          this.fillForm(result.data);
        } else {
          this.openSnackbar(this.translate.instant('companyClient.account.fetchError'));
        }
      });
  }

  onSubmit() {
    this.isSendingRequest = true;
    this.requestService.doApiRequest(NAV_API_ENDPOINTS_LIST.profileUpdate, this.userInfoForm.getRawValue())
      .then((result: {status: string, data: ProfileInfo}) => {
        this.isSendingRequest = false;
        if(result.status == 'success') {
          this.openSnackbar(this.translate.instant('ProfileHasBeenChanged'), 5000);
        } else {
          this.openSnackbar(this.translate.instant('ProfileHasNotBeenChanged'), 7000);
        }
        this.fillForm(result.data);
      });

  }

  fillForm(content: ProfileInfo) {
    this.userInfoForm = this.fb.group({
      email: [{value: content.email, disabled: true}, {nonNullable: true}],
      firstname: [content.firstname, {validators: Validators.required, nonNullable: true}],
      lastname: [content.lastname, {validators: Validators.required, nonNullable: true}],
      salutation: [content.salutation, {validators: Validators.required, nonNullable: true}],
      lang: [content.lang],
      telephone: [content.telephone, {validators: Validators.required, nonNullable: true}]
    });
  }

  updateLanguage() {
    this.requestService.doApiRequest(NAV_API_ENDPOINTS_LIST.langUpdate, {lang: this.userInfoForm.value.lang})
      .then((_result: {status: string, data: ProfileInfo}) => {
        this.customTranslateService.use(this.userInfoForm.value.lang);
      });
  }
}
