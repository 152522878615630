<h1 mat-dialog-title>{{'companyClient.addUser.h1' | translate}}</h1>

<div mat-dialog-content>
  <p [innerHtml]="'companyClient.addUser.description' | translate:{companyName: data.ownCompanyName}"></p>
  <mat-form-field class="rowspan">
    <mat-label>{{'FormEmail' | translate}}</mat-label>
    <input cdkFocusInitial matInput type="email" name="email" id="email" [(ngModel)]="email">
  </mat-form-field>
  <mat-checkbox [(ngModel)]="admin" id="admin">{{'companyClient.addUser.giveAdminAccess' | translate}}</mat-checkbox>
</div>

<div mat-dialog-actions [align]="'end'" class="padded-actions">
  <button mat-flat-button (click)="onNoClick()" id="modalCancel">{{'ModalCancelButton' | translate}}</button>
  <submit-button [isSendingRequest]="isSendingRequest" [disabled]="!email.match('.+@.+')" (submit)="submit()" [textKey]="'companyClient.addUser.actionAdd'"></submit-button>
</div>
