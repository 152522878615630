import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MeteringPointConsumptionData} from "../../shared/model/metering-point-consumption-data";

@Component({
  selector: 'user-details-dialog',
  templateUrl: './users-ostral-meteringpoint-dialog.component.html',
  styleUrls: []
})
export class UsersOstralMeteringpointDialogComponent {
  prefix = 'companyClient.meteringPointInfo.'
  displayedColumns = ['year', 'month', 'value'];
  constructor(
    public dialogRef: MatDialogRef<UsersOstralMeteringpointDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {id: string, consumptionData: MeteringPointConsumptionData[]}
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
