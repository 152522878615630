/* eslint-disable max-len */
import {SmartTableInitialSortingDefinitionContainer} from '../classes/SmartTableInitialSortingDefinition';

export const SMART_TABLE_INITIALSORTING_DEFS: SmartTableInitialSortingDefinitionContainer[] = [
  {
    id: 'sortByDescriptionAsc',
    definition: {
      columnId: 'description',
      order: 'asc'
    },
  },
  {
    id: 'sortByDescriptorAsc',
    definition: {
      columnId: 'descriptor',
      order: 'asc'
    },
  },
];
