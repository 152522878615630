import { Component } from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'mat-icon-component',
  template: '',
  styleUrls: []
})
export class MatIconComponent {

  constructor(protected matIconRegistry: MatIconRegistry,
              protected domSanitizer: DomSanitizer) {

    this.matIconRegistry.addSvgIcon(
      'gauge',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/nav_gauge.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eye',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/eye.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eye-strike',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/eye_strike.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'burger',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/nav_burger.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'users',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/nav_users.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/chevron_right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/chevron_left.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-up',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/chevron_up.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/chevron_down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/filter.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'cog',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/cog.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'link',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/link.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-back',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/arrow_back.svg')
    );
    /*this.matIconRegistry.addSvgIcon(
      'trash-danger',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/trash_danger.svg')
    );*/
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'check-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/check_circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'arrow-back-circle',
        this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/arrow_back_circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'info-circle',
        this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/info.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'add-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/add_circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/letter.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'quickstart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/page_bookmark.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'manual',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/book_half_lines.svg')
    );
  }

}
