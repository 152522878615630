import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import { Location } from '@angular/common';

import {APP_CONFIG} from '../../config/app.config';
import {IAppConfig} from '../../config/iapp.config';
import {SessionService} from '../../shared/session/session.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';
import {environment} from '../../../environments/environment';
import {EnvironmentDefinition} from '../../../environments/environmentDefinition';

import {CustomTranslateService} from '../../shared/services/custom-translate.service';
import {UtilsService} from '../../shared/services/utils.service';
import {SmartModalService} from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import {SmartFormBuilder} from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import {SmartModalBuilder} from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {NAV_API_ENDPOINTS_LIST} from '../../shared/nav/api-endpoints-list';
import {NAVIGATION_LIST} from '../shared/navigation-list';
import {NavigationMenuDefinition} from '../../shared/nav/NavigationMenuDefinition';
import {ActivatedRoute, Router} from '@angular/router';
import {StandardViewComponent} from '../../shared/marketpartner-components/standard-view/standard-view.component';
import {NotificationsService} from '../../shared/services/notifications.service';
import { UpdateService } from '../../shared/services/update-service';
import { UserManualService } from '../../shared/services/user-manual.service';
import { Observable, NEVER } from 'rxjs';
import { map } from 'rxjs/operators';

declare var $: any;
@Component({
  selector: '[app-nav]',
  templateUrl: './nav.component.html',
  styleUrls: ['nav.component.scss']
})

export class NavComponent  extends StandardViewComponent implements OnInit, AfterViewInit {
  hideRoute = false;
  appConfig: IAppConfig;
  showOstralGuide = false;

  environment: EnvironmentDefinition;
  logoUrl: string;
  languageChangedSubscription: any;
  leftSideNavigationOptions: NavigationMenuDefinition[];

  public updatesAvailable$ = this.updateService.updatesAvailable$;

  hasFtpWarnings$ = this.marketPartnerService.partnerWarnings$.pipe(
    map(warnings => warnings.anyFtpWarning)
  );

  constructor(@Inject(APP_CONFIG) appConfig: IAppConfig,
              public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public utilsService: UtilsService,
              public customTranslateService: CustomTranslateService,
              public userManualService: UserManualService,
              private smartModalService: SmartModalService,
              private apiRequestService: ApiRequestService,
              public router: Router,
              public activatedRoute: ActivatedRoute,
              private location: Location,
              private notificationsService: NotificationsService,
              private updateService: UpdateService) {
    super(activatedRoute, router);
    this.leftSideNavigationOptions = NAVIGATION_LIST;

    this.languageChangedSubscription = this.customTranslateService.languageChanged.subscribe(() => {
      this.hideRoute = true;
      setTimeout(() =>  {
        this.hideRoute = false;
      }, 0);
    });

    this.logoUrl = this.sessionService.getLogoUrl();
    this.environment = environment;
    this.appConfig = appConfig;
    this.sessionService = sessionService;
    this.logoutClicked = this.logoutClicked.bind(this);
    this.changeLanguageMenuOption = this.changeLanguageMenuOption.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.showOstralGuide = this.sessionService.isVnb();
  }

  ngOnInit() {
    super.ngOnInit();

    this.marketPartnerService.updateUnreadNotificationsCount();
    this.marketPartnerService.updateUnreadProcessesCount();
    this.marketPartnerService.updateUnsuppliedCount();
  }

  ngAfterViewInit(): void {
    this.initNavigation();
  }

  initNavigation() {
    // Template specific logic, do not change
    $.unbindSmallNav();
    $.niftyNotyReset();
    $(document).trigger('nifty.ready');
  }

  isUserAllowedToSection(navigation: NavigationMenuDefinition) {
    const mpi = this.sessionService.getCurrentPartnerInfo();
    return !(navigation.fnAllowed && !navigation.fnAllowed(this.sessionService, mpi));

  }

  getNotificationCountObservable(navigation: NavigationMenuDefinition): Observable<number> {
    if (!navigation.fnNotificationCountObservable) return NEVER;
    return navigation.fnNotificationCountObservable(this.marketPartnerService);
  }

  isLeftSideNavigationActive(navigation: NavigationMenuDefinition) {
    const currentLocationPath = this.location.path();
    return currentLocationPath.indexOf(navigation.fullPath) === 0;
  }

  navigationSelected() {

  }

  reloadPage() {
    console.log('page reload, reason: requested by user');
    window.location.reload();
  }

  changeProfile() {
    const self = this;
    const formConfig = new SmartFormBuilder()
      .addFormFieldEmailReadOnly()
      .addFormFieldSalutation()
      .addFormFieldFirstname()
      .addFormFieldLastname()
      .addFormFieldTelephone()
      .setApiGetDataRequestConfigFromInfo(new ApiRequestBuilder().setEndpointInfo(NAV_API_ENDPOINTS_LIST.profileInfo).build())
      .setApiSendDataRequestConfigFromInfo(new ApiRequestBuilder().setEndpointInfo(NAV_API_ENDPOINTS_LIST.profileUpdate).build())
      .showSubmitButton(true)
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(async function () {
        self.sessionService.updateCurrentUserInfo();
      })
      .setTitle('NavProfile')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  changeLanguageMenuOption() {
    const self = this;
    const formConfig = new SmartFormBuilder()
      .addFormFieldFromId('formFieldLanguage', this.customTranslateService.getCurrentLangShort())
      .setApiSendDataRequestConfigFromInfo(new ApiRequestBuilder().setEndpointInfo(NAV_API_ENDPOINTS_LIST.langUpdate).build())
      .showSubmitButton(true)
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(async function (submittedForm: any) {
        self.customTranslateService.use(submittedForm.lang);
      })
      .setTitle('NavChangeLanguage')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);

  }

  onLanguageChanged(languageFormValue: any) {
    this.customTranslateService.use(languageFormValue.language);
  }

  changePasswordMenuOption() {
    const formConfig = new SmartFormBuilder()
      .addFormFieldOldPassword()
      .addFormFieldNewPassword()
      .addFormFieldNewPasswordConfirm()
      .setApiSendDataRequestConfigFromInfo(new ApiRequestBuilder().setEndpointInfo(NAV_API_ENDPOINTS_LIST.passwordUpdate).build())
      .showSubmitButton(true)
      .resetFormOnSubmitError(true)
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setTitle('NavChangePassword')
      .setModalCSSClassSize('sm')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  async logoutClicked() {
    const apiRequestConfig = new ApiRequestBuilder()
      .setEndpointInfo(NAV_API_ENDPOINTS_LIST.logout)
      .setHandleLoading(true)
      .setHandleErrorNotification(false)  // we don't want to see e.g. "your session has expired"
      .build();

    // if the server doesn't answer, still remove our tokens from localstorage
    try {
      const logoutPromise = this.apiRequestService.callApi(apiRequestConfig);
      const timeout = new Promise(resolve => setTimeout(resolve, 5000));
      const result = await Promise.race([logoutPromise, timeout]);
      if (result && result.status && result.status !== 'success') {
        console.error(result);
        // await new Promise(resolve => setTimeout(resolve, 2000));  // for testing
      }
    } finally {
      this.sessionService.logout();
    }
  }

  async changeLoggedPartner(partnerId: number) {
    try {
      // this will trigger a page reload
      await this.sessionService.changePartner(partnerId);
    } catch (error) {
      const errorCodeString = await this.apiRequestService.convertApiErrorToString(error);
      this.notificationsService.showNotification( {type: 'error', message: errorCodeString});
    }
  }
}
