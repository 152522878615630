import {EnumDefinition} from '../classes/enum-definition';

export const SDAT_ROLES: EnumDefinition = {
  id: 'sdatroles',
  items: [
    // {label: '-', data: 'ROLE_UNSPECIFIED', omitFromDropdown: true},
    {label: 'LabelMarketPartnerRole.UNB', data: 'UNB'},
    {label: 'LabelMarketPartnerRole.VNB', data: 'VNB'},
    {label: 'LabelMarketPartnerRole.LF', data: 'LF'},
    {label: 'LabelMarketPartnerRole.BGV', data: 'BGV'},
    {label: 'LabelMarketPartnerRole.SDV', data: 'SDV'},
    {label: 'LabelMarketPartnerRole.EV', data: 'EV'}
  ]
};
