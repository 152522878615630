/* eslint-disable max-len */
import {NameValueMap} from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/KeyValueMap';
import {FTPEntry} from '../classes/FTPEntry';
import {ChangeProcess} from '../classes/ChangeProcess';

export interface MockUser {
  id: number;
  status: 'active' | 'inactive';
  email: string;
  firstname: string;
  lastname: string;
  market_partner: string;
  scopes: string;
  client_id: string;
  test?: any;
}

export interface MockMarketPartner {
  data: string;
  label: string;
}

export const MOCKUP_RESPONSES_LIST: NameValueMap<(MockUser | MockMarketPartner | FTPEntry | ChangeProcess)[]> = {
};
