<page-loading *ngIf="!initialized"></page-loading>

<operator-sidebar-panel *ngIf="isOperatorMode"
                     [mpinfo]="marketPartnerInfo"
                     [upinfo]="companyPartnerInfo"
                     [activeViewMode]="'data'"></operator-sidebar-panel>

<div *ngIf="initialized" id="page-content"
     [class.page-content-with-sidebar]="isOperatorMode"
  >
  <div class="before-panel-info">
    <div class="row">
      <div class="col-xs-12">
        <div class="before-panel-title">
          {{'NavigationCommunicationBaseData' | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base  no-botton-margin">

        <!--Tabs Content-->
        <div class="tab-content">
          <div class="tab-pane fade active in">
            <smart-form class="category-select-checkbox-form"
                        #baseDataForm
                        *ngIf="(baseDataFormConfig)"
                        (onSuccessFormValue)="onSuccessFormValue($event)"
                        [formConfig]="baseDataFormConfig"></smart-form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
