import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../shared/session/session.service';
import { SmartModalBuilder } from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import { SmartModalService } from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import { SmartFormBuilder } from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import { SmartFormConfigDefinition } from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormConfigDefinition';
import { USERS_API_ENDPOINTS_LIST } from '../requests/api-endpoints-list';
import { SmartFormFormFieldDefinition } from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormFormFieldDefinition';
import { MarketPartnerService } from '../../shared/services/marketpartner.service';
import { SMART_FORM_OBJECTSARRAY_DEFS } from '../../shared/marketpartner-components/smart-forms/smart-form/definitions/smart-form-formobjectsarray.definitions';
import { sdatwebclient } from '../../shared/messaging-grpc/messaging-grpc';
import { MarketpartnersLoaderService } from './marketpartners-loader.service';


@Injectable()
export class SdatRequestDialogsService {
  constructor(
    private smartModalService: SmartModalService,
    private translate: TranslateService,
    private sessionService: SessionService,
    private marketPartnerService: MarketPartnerService,
    private marketpartnersLoaderService: MarketpartnersLoaderService)
  {
    this.marketpartnersLoaderService.load();
  }

  startSupply() {
    const formBuilder = this.buildForm('requestSupplierChange');
    return this.runModal('SDATDialogs.TitleStartSupplyForNewPoint', this.buildModifySupplyForm(formBuilder));
  }

  modifySupply(mpId?: string, administratorEic?: string) {
    const formBuilder = this.buildForm('requestSupplierChange');
    return this.runModal('SDATDialogs.TitleModifySupply', this.buildModifySupplyForm(formBuilder, mpId, administratorEic));
  }

  private mpId(mpId?: string): SmartFormFormFieldDefinition {
    return {
      name: 'meteringPointId', title: 'SDATDialogs.FormFields.MeteringPointId',
      readOnly: !!mpId, default: mpId,
      type: 'input', cssClasses: 'col-xs-6', validation: {type: 'metering_point_id'}
    };
  }

  private mpAdministratorEic(administratorEic?: string): SmartFormFormFieldDefinition {
    return {
      name: 'meteringPointAdministratorEic', title: 'SDATDialogs.FormFields.MeteringPointAdministratorEic',
      type: 'autocomplete', cssClasses: 'col-xs-6',
      readOnly: !!administratorEic, default: administratorEic,
      validation: {type: 'eic'},
      allowedValues: {
        type: 'globalRegister',
        data: 'vnbMarketpartners'
      },
    };
  }

  private mpIdSelectorId(mpId?: string): SmartFormFormFieldDefinition {
    return {
      name: 'meteringPoint:id', title: 'SDATDialogs.FormFields.MeteringPointId',
      readOnly: !!mpId, default: mpId,
      type: 'input', cssClasses: 'col-xs-6', validation: {type: 'metering_point_id'}
    };
  }

  private mpIdSelectorAdministratorEic(administratorEic?: string): SmartFormFormFieldDefinition {
    return {
      name: 'meteringPoint:administratorEic', title: 'SDATDialogs.FormFields.MeteringPointAdministratorEic',
      type: 'autocomplete', cssClasses: 'col-xs-6',
      readOnly: !!administratorEic, default: administratorEic,
      validation: {type: 'eic'},
      allowedValues: {
        type: 'globalRegister',
        data: 'vnbMarketpartners'
      },
    };
  }

  private buildModifySupplyForm(formBuilder: SmartFormBuilder, mpId?: string, administratorEic?: string): SmartFormConfigDefinition {
    return formBuilder
      .addRequired(this.mpIdSelectorId(mpId))
      .addRequired(this.mpIdSelectorAdministratorEic(administratorEic))
      .addRequired({
        name: 'date', title: 'SDATDialogs.FormFields.Date',
        type: 'date', cssClasses: 'col-xs-12',
      })
      .addRequired({
        name: 'balanceResponsibleEic', title: 'SDATDialogs.FormFields.BalanceResponsibleEic',
        type: 'autocomplete', cssClasses: 'col-xs-6',
        validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'bgvMarketpartners'
        },
      })
      .addRequired({
        name: 'gridBillingMethod', title: 'SDATDialogs.FormFields.GridBillingMethod',
        type: 'select', cssClasses: 'col-xs-6',
        allowedValues: {type: 'globalRegister', data: 'gridbillingmethod'}
      }).build();
  }

  endSupply(mpId?: string, administratorEic?: string) {
    const formBuilder = this.buildForm('requestSupplierEnd')
      .addRequired(this.mpIdSelectorId(mpId))
      .addRequired(this.mpIdSelectorAdministratorEic(administratorEic))
      .addRequired({
        name: 'date', title: 'SDATDialogs.FormFields.Date',
        type: 'date', cssClasses: 'col-xs-12',
      });
    return this.runModal('SDATDialogs.TitleEndSupply', formBuilder.build());
  }

  registerASP() {
    const formBuilder = this.buildForm('registerASP')
      .addRequired(this.mpIdSelectorId())
      .addRequired(this.mpIdSelectorAdministratorEic())
      .addRequired({
        name: 'date', title: 'SDATDialogs.FormFields.Date',
        type: 'date', cssClasses: 'col-xs-12',
      });
    return this.runModal('SDATDialogs.TitleASPRegister', formBuilder.build());
  }

  deregisterASP(mpId?: string, administratorEic?: string) {
    const formBuilder = this.buildForm('deregisterASP')
      .addRequired(this.mpIdSelectorId(mpId))
      .addRequired(this.mpIdSelectorAdministratorEic(administratorEic))
      .addRequired({
        name: 'date', title: 'SDATDialogs.FormFields.Date',
        type: 'date', cssClasses: 'col-xs-12',
      });
    return this.runModal('SDATDialogs.TitleASPDeregister', formBuilder.build());
  }

  cancel(administratorEic?: string) {
    const formBuilder = this.buildForm('requestProcessCancellation')
      .addRequired(this.mpAdministratorEic(administratorEic))
      .addRequired({
        name: 'processId', title: 'SDATDialogs.FormFields.ProcessId',
        type: 'input', cssClasses: 'col-xs-6'
      });
    return this.runModal('SDATDialogs.TitleCancellation', formBuilder.build());
  }

  annulDocument(message: sdatwebclient.DocumentDetails) {
    if (!message.document || !message.document.messageMetadata) {
      console.error('incomplete messageMetadata!');
      return;
    }
    const formBuilder = new SmartFormBuilder()
      .showSubmitButton(true, 'SDATDialogs.ButtonExecute')
      .showCancelButton(true)
      .setApiSendDataRequestConfigFromInfo({
        info: USERS_API_ENDPOINTS_LIST['cancelDocument'],
        options: {}
      })
      .addReadOnly({
        title: 'ConfirmDocumentAnnulation',
        name: 'confirmAnnulation',
        omitFromRequest: true,
        default: ' ',
        cssClasses: 'col-xs-12'
      })
      .addRequired({name: 'reference:uuid', type: 'hidden', default: message.document?.messageMetadata?.messageUuid})
      .addRequired({name: 'reference:partnerId', type: 'hidden', default: message.document?.messageMetadata?.senderId});

    return this.runModal('SDATDialogs.TitleDocumentCancellation', formBuilder.build());
  }

  changeConsumerMasterData(mpId?: string, administratorEic?: string) {
    const formBuilder = this.buildForm('changeConsumerMasterData')
      .setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS)
      .addRequired(this.mpIdSelectorId(mpId))
      .addRequired(this.mpIdSelectorAdministratorEic(administratorEic))
      .addRequired({
        name: 'date', title: 'SDATDialogs.FormFields.Date',
        type: 'date', cssClasses: 'col-xs-12',
      })
      .addRequired({
        name: 'gridBillingMethod', title: 'SDATDialogs.FormFields.GridBillingMethod',
        type: 'select', cssClasses: 'col-xs-12',
        allowedValues: {type: 'globalRegister', data: 'gridbillingmethod'}
      })
      .addOptional({
        name: 'consumer', title: 'SDATDialogs.FormFields.Contact',
        type: 'object', cssClasses: 'col-xs-12', objectId: 'consumerObject'
      });
    return this.runModal('SDATDialogs.TitleChangeConsumerMasterData', formBuilder.build(), 'xl');
  }

  changeMpMasterData(mpId?: string) {
    const formBuilder = this.buildForm('changeMPMasterData')
      .setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS)
      .addRequired(this.mpId(mpId))
      .addRequired({
        name: 'date', title: 'SDATDialogs.FormFields.Date',
        type: 'date', cssClasses: 'col-xs-6',
      })
      .addRequired({
        name: 'balanceSupplierEic', title: 'SDATDialogs.FormFields.BalanceSupplierEic',
        type: 'autocomplete', cssClasses: 'col-xs-6',
        validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'lfMarketpartners'
        },
      })
      .addRequired({
        name: 'characteristics', objectId: 'meteringPointCharacteristicsObject',
        type: 'object', cssClasses: 'col-xs-12'
      })
      .addRequired({
        name: 'address',
        type: 'object', cssClasses: 'col-xs-12', objectId: 'meteringPointAddressObject'
      });

    return this.runModal('SDATDialogs.TitleMPMasterDataChange', formBuilder.build(), 'xl');
  }

  requestConsumerChange(mpId?: string, administratorEic?: string) {
    const formBuilder = this.buildForm('requestConsumerChange')
      .setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS)
      .addRequired(this.mpIdSelectorId(mpId))
      .addRequired(this.mpIdSelectorAdministratorEic(administratorEic))
      .addRequired({
        name: 'date', title: 'SDATDialogs.FormFields.Date',
        type: 'date', cssClasses: 'col-xs-12',
      })
      .addRequired({
        name: 'balanceResponsibleEic', title: 'SDATDialogs.FormFields.BalanceResponsibleEic',
        type: 'autocomplete', cssClasses: 'col-xs-6',
        validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'bgvMarketpartners'
        },
      })
      .addRequired({
        name: 'gridBillingMethod', title: 'SDATDialogs.FormFields.GridBillingMethod',
        type: 'select', cssClasses: 'col-xs-6',
        allowedValues: {type: 'globalRegister', data: 'gridbillingmethod'}
      })
      .addRequired({
        name: 'consumer', title: 'SDATDialogs.FormFields.Contact',
        type: 'object', cssClasses: 'col-xs-12', objectId: 'consumerObject'
      });
    return this.runModal('SDATDialogs.TitleRequestConsumerChange', formBuilder.build(), 'xl');
  }

  requestConsumerEnd(mpId?: string, administratorEic?: string) {
    const formBuilder = this.buildForm('requestConsumerEnd')
      .addRequired(this.mpIdSelectorId(mpId))
      .addRequired(this.mpIdSelectorAdministratorEic(administratorEic))
      .addRequired({
        name: 'date', title: 'SDATDialogs.FormFields.Date',
        type: 'date', cssClasses: 'col-xs-12',
      });
    return this.runModal('SDATDialogs.TitleRequestConsumerEnd', formBuilder.build());
  }

  queryMeasurements(mpId?: string, administratorEic?: string) {
    const formBuilder = this.buildForm('queryMeasurements')
      .addRequired(this.mpIdSelectorId(mpId))
      .addRequired(this.mpIdSelectorAdministratorEic(administratorEic))
      .addRequired({
        name: 'interval:startDateTime', title: 'SDATDialogs.FormFields.StartDate',
        type: 'datetime', cssClasses: 'col-xs-12',
      })
      .addRequired({
        name: 'interval:endDateTime', title: 'SDATDialogs.FormFields.EndDate',
        type: 'datetime', cssClasses: 'col-xs-12',
      });
    return this.runModal('SDATDialogs.TitleQueryMeasurements', formBuilder.build());
  }

  queryMeasurementAggregates(administratorEic?: string) {
    const formBuilder = this.buildForm('queryMeasurementAggregates')
      .addRequired(this.mpAdministratorEic(administratorEic))
      .addRequired({
        name: 'areaEic', title: 'SDATDialogs.FormFields.AreaEic',
        type: 'input', cssClasses: 'col-xs-6',
        validation: {type: 'eic-area'}
      })
      .addRequired({
        name: 'interval:startDateTime', title: 'SDATDialogs.FormFields.StartDate',
        type: 'datetime', cssClasses: 'col-xs-12',
      })
      .addRequired({
        name: 'interval:endDateTime', title: 'SDATDialogs.FormFields.EndDate',
        type: 'datetime', cssClasses: 'col-xs-12',
      })
      .addRequired({
        name: 'aggregationCriteria:meteringPointType', title: 'SDATDialogs.FormFields.MeteringPointType',
        type: 'select', cssClasses: 'col-xs-6',
        allowedValues: {type: 'globalRegister', data: 'meteringpointtype'}
      })
      // should be optional see https://tools.scs.ch/issues/browse/ELDEXDHUB-2497
      .addRequired({
        name: 'aggregationCriteria:settlementMethod', title: 'MeteringPointEvent.Keys.settlementMethod',
        type: 'select', cssClasses: 'col-xs-6',
        allowedValues: {type: 'globalRegister', data: 'settlementmethod'}
      })
      .addOptional({
        name: 'aggregationCriteria:balanceSupplier', title: 'SDATDialogs.FormFields.BalanceSupplierEic',
        type: 'autocomplete', cssClasses: 'col-xs-6',
        validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'lfMarketpartners'
        },
      })
      .addOptional({
        name: 'aggregationCriteria:balanceResponsible', title: 'SDATDialogs.FormFields.BalanceResponsibleEic',
        type: 'autocomplete', cssClasses: 'col-xs-6',
        validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'bgvMarketpartners'
        },
      });
    return this.runModal('SDATDialogs.TitleQueryMeasurementAggregates', formBuilder.build());
  }

  queryMpInformation(mpId?: string, administratorEic?: string) {
    const formBuilder = this.buildForm('queryMPInfo')
      .addRequired(this.mpIdSelectorId(mpId))
      .addRequired(this.mpIdSelectorAdministratorEic(administratorEic));
    return this.runModal('SDATDialogs.TitleQueryMpInformation', formBuilder.build());
  }

  querySwitchInformation(mpId?: string, administratorEic?: string) {
    const formBuilder = this.buildForm('querySwitchInfo')
      .addRequired(this.mpIdSelectorId(mpId))
      .addRequired(this.mpIdSelectorAdministratorEic(administratorEic))
      .addRequired({
        name: 'currentBalanceSupplierEic', title: 'SDATDialogs.FormFields.CurrentBalanceSupplierEic',
        type: 'autocomplete', cssClasses: 'col-xs-6',
        validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'lfMarketpartners'
        },
      });
    return this.runModal('SDATDialogs.TitleQuerySwitchInformation', formBuilder.build());
  }

  registerBaseSupply(mpId?: string) {
    return this.registerSupply('Base', mpId);
  }

  registerSubstituteSupply(mpId?: string) {
    return this.registerSupply('Substitute', mpId);
  }

  private registerSupply(kind: 'Base' | 'Substitute', mpId?: string) {
    const formBuilder = this.buildForm('register' + kind + 'Supply')
      .setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS)
      .addRequired(this.mpId(mpId))
      .addRequired({
        name: 'date', title: 'SDATDialogs.FormFields.Date',
        type: 'date', cssClasses: 'col-xs-12',
      })
      .addRequired({
        name: 'balanceSupplierEic', title: 'SDATDialogs.FormFields.BalanceSupplierEic',
        type: 'autocomplete', cssClasses: 'col-xs-6',
        validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'lfMarketpartners'
        },
      })
      .addRequired({
        name: 'balanceResponsibleEic', title: 'SDATDialogs.FormFields.BalanceResponsibleEic',
        type: 'autocomplete', cssClasses: 'col-xs-6',
        validation: {type: 'eic'},
        allowedValues: {
          type: 'globalRegister',
          data: 'bgvMarketpartners'
        },
      })
      .addOptional({
        name: 'ancillaryServiceProviderEic', title: 'SDATDialogs.FormFields.AncillaryServiceProviderEics',
        type: 'array', cssClasses: 'col-xs-12', objectId: 'ancillaryServiceProvidersObject',
        inputList: true
      });

    return this.runModal('SDATDialogs.TitleRegister' + kind + 'Supply', formBuilder.build());
  }

  buildForm(endpoint: string): SmartFormBuilder {
    return new SmartFormBuilder()
      .showSubmitButton(true, 'SDATDialogs.ButtonExecute')
      .showCancelButton(true)
      .showDownloadButton(true)
      .setApiSendDataRequestConfigFromInfo({
        info: USERS_API_ENDPOINTS_LIST[endpoint],
        options: {}
      })
      // common to all requests
      .addRequired({
        name: 'requestingPartnerId', type: 'hidden',
        default: this.sessionService.getCurrentPartnerId()
      });
  }

  private async runModal(title: string, formConfig: SmartFormConfigDefinition, size?: 'sm' | 'md' | 'lg' | 'xl') {
    let formValue: any;
    let response: any;
    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(v => formValue = v)
      .setOnSuccessResponse(r => response = r)
      .setTitle(title)
      .setModalCSSClassSize(size ?? 'lg')
      .setFormConfigFromInfo(formConfig)
      .build();
    await this.smartModalService.showModal(modalOptions);
    console.log('formValue:', formValue);
    console.log('response:', response);
    if (response) {
      await this.showSuccessConfirmation(response);
      console.log('Response confirmation dialog closed.');
      this.marketPartnerService.updateUnsuppliedCount();
      return {formValue, response};
    } else {
      return undefined;
    }
  }

  async showSuccessConfirmation(response: any) {
    let message;
    // response: ProcessResult (processingcore.proto)
    if (response.executedAs === 'SHORTCUT') {
      if (response.processId) {
        message = this.translate.instant('SDATDialogs.SuccessConfirmationWithProcessIdText', response);
      } else {
        message = this.translate.instant('SDATDialogs.SuccessConfirmationWithoutProcessIdText');
      }
    } else if (response.executedAs === 'SDAT_MESSAGE_SENT'){
      message = this.translate.instant('SDATDialogs.SuccessConfirmationWithPendingSDATText');
    }

    const modalOptions = new SmartModalBuilder()
      .setTitle('SDATDialogs.SuccessConfirmationTitle')
      .setBodyText(message)
      .showConfirmButton(true, 'ModalCloseButton')
      .setModalCSSClassSize('md')
      .build();
    const request = await this.smartModalService.showModal(modalOptions);
    return {request, response};
  }
}
