/* eslint-disable @angular-eslint/component-selector */
import {Component} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {StandardViewComponent} from '../../shared/marketpartner-components/standard-view/standard-view.component';

import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiRequestService } from '../../shared/api-request/service/api-request.service';

@Component({
  selector: 'page-admin-announcements-content',
  templateUrl: './admin-announcements.component.html',
  styleUrls: []
})

export class AdminAnnouncementsComponent extends StandardViewComponent {
  form: UntypedFormGroup;

  constructor(public activatedRoute: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              public router: Router,
              private apiRequestService: ApiRequestService) {
    super(activatedRoute, router);

    this.form = this.formBuilder.group({
      'subject': ['', [Validators.required]],
      'body1': [''],
      'body2': [''],
    });
  }

  async onSubmit() {
    const params = {
      subject: this.form.value.subject,
      body1: this.form.value.body1,
      body2: this.form.value.body2,
    };
    await this.apiRequestService.downloadFile(USERS_API_ENDPOINTS_LIST.operatorEmailSendAnnouncement, params);
  }

  async onParamsUrlInitialized(params: any) {
    super.onParamsUrlInitialized(params);
    this.setInitialized(true);
  }
}
