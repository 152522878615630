export type AdditionalChartInfo = {isComplete: boolean, dataSource: string, key: string}
export class ChartDataModel {
  currentData: (null|number)[] = [];
  previousData: (null|number)[] = [];
  contingentData: (null|number)[] = [];
  additionalInfoCurrent: AdditionalChartInfo[] = [];
  additionalInfoPrevious: AdditionalChartInfo[] = [];
  private suggestedMax: number | undefined = undefined;

  public getSuggestedMax(forceRecalc: boolean = false): number {
    if(this.suggestedMax == undefined || forceRecalc) {
      this.suggestedMax = this.calculateSuggestedMax();
    }
    return this.suggestedMax;
  }

  public isAboveContingent(i: number): boolean {
    return this.currentData[i] != null && this.contingentData[i] != null && (this.contingentData[i]! > 0) && (this.currentData[i]! > this.contingentData[i]!);
  }

  private calculateSuggestedMax(): number {
    let maxValue = Math.max(
      ...this.contingentData.map(v => v == null ? 0 : v),
      ...this.previousData.map(v => v == null ? 0 : v),
      ...this.currentData.map(v => v == null ? 0 : v),
      0);
    return maxValue + maxValue/20;
  }
}

export class BarChartDataModel extends ChartDataModel {
  overusedData: (null | number)[] = [];

  constructor(chartDataModel: ChartDataModel) {
    super();
    this.contingentData = chartDataModel.contingentData.slice();
    this.previousData = chartDataModel.previousData.slice();
    this.additionalInfoPrevious = chartDataModel.additionalInfoPrevious.slice();
    this.additionalInfoCurrent = chartDataModel.additionalInfoCurrent.slice();

    this.overusedData = chartDataModel.contingentData.map((contingent: null | number, i: number) =>
        contingent == null ? null :
        chartDataModel.currentData[i] == null ? null :
        contingent > chartDataModel.currentData[i]! ? null :
        chartDataModel.currentData[i]! - contingent);

    this.currentData = chartDataModel.currentData.map((current: number | null, i: number) =>
        current == null ? null :
        this.overusedData[i] == null ? current :
        current - this.overusedData[i]!);
  }
}
