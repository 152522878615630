import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiRequestService} from "../../shared/api-request/service/api-request.service";
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "../api-endpoints-list";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'unite-companies-dialog',
  templateUrl: './unite-companies-dialog.component.html',
  styleUrls: ['./unite-companies-dialog.component.scss']
})
export class UniteCompaniesDialogComponent {

  step = 1;
  otherCompanyName: string = '';
  errorMessage: string = '';
  email: string | undefined;
  isSendingRequest = false;
  regCodeFormField = new FormControl('', {});

  constructor(
    public dialogRef: MatDialogRef<UniteCompaniesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {ownCompanyName: string, partnerId: string, selectedOption: 'selfToOther' | 'otherToSelf'},
    private requestService: ApiRequestService
  ) {}

  onCloseDialog(completed: boolean): void {
    this.dialogRef.close(completed);
  }

  backToStep1() {
    this.step = 1;
  }

  async gotoStep2() {
    this.errorMessage = '';

    if(this.data.selectedOption == 'selfToOther') {
      this.step = 2;
    } else {
      this.isSendingRequest = true;

      const result = await this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.getCompanyByRegistrationCode, {registrationCode: this.regCodeFormField.value});

      this.isSendingRequest = false;
      if (result.status == 'success' && (result.data.businessPartner || result.data.companyPartner)) {
        this.otherCompanyName = result.data.businessPartner?.name || result.data.companyPartner?.name;
        this.step = 2;
      } else {
        this.errorMessage = await this.requestService.convertApiErrorToString(result.data);
        this.regCodeFormField.setErrors({'incorrect': true});
      }
    }
  }

  async confirm() {
    this.isSendingRequest = true;

    if(this.data.selectedOption == 'selfToOther') {
      await this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.initiateCompanyPartnerTransfer,
        {sourcePartnerId: this.data.partnerId, targetEmail: this.email});
      this.step = 3;

    } else {
      const result = await this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.uniteCompanyPartner,
        {partnerId: this.data.partnerId, registrationCode: this.regCodeFormField.value});
      //console.log(result);
      if(result.status == 'success' && result.data.registrationResult == 'SUCCESS') {
        this.step = 3;
      } else {
        this.errorMessage = await this.requestService.convertApiErrorToString(result.data);
      }
    }

    this.isSendingRequest = false;
  }
}
