import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {SessionService} from '../../shared/session/session.service';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import { SmartModalService } from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import { SmartFormBuilder } from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import { SmartModalBuilder } from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import * as moment from 'moment';
import { SmartFormShowConditionOperator } from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormFormFieldDefinition';

interface BackendExportStatus {
  noDownload?: {};
  running?: BackendExportProgress;
}
interface BackendExportProgress {
  processed: number;
  total: number;
}
interface FrontendExportProgress {
  items_processed: number;
  items_total: number;
  percentage: number;
}

@Injectable()
export class MeteringPointsExportService {
  public downloadProgress$ = new BehaviorSubject<undefined|FrontendExportProgress>(undefined);
  private pollSubscription?: Subscription;

  constructor(
    private apiRequestService: ApiRequestService,
    private sessionService: SessionService,
    private smartModalService: SmartModalService,
  ) {}

  public startPolling() {
    this.updateDownloadStatus();
    if (this.pollSubscription) return;
    const polling_interval_ms = 3000;
    this.pollSubscription = interval(polling_interval_ms)
      .subscribe(() => this.updateDownloadStatus());
  }

  public stopPolling() {
    if (this.pollSubscription) this.pollSubscription.unsubscribe();
    this.pollSubscription = undefined;
  }

  private async updateDownloadStatus() {
    // ignore polling errors, because percentage display is just nice-to-have.
    // The web browser's download widget already shows the real error state.
    const requestConfig = new ApiRequestBuilder()
      .setHandleLoading(false)
      .setHandleErrorNotification(false)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.meteringPointsDownloadStatus)
      .setBodyInfo({partnerId: this.sessionService.getCurrentPartnerId()})
      .build();

    const result = await this.apiRequestService.callApi(requestConfig);
    if (result && result.status === 'success') {
      // const mockData: BackendExportStatus = {running: {processed: 200, total: 1800}};
      // result.data = mockData;
      const status = result.data as BackendExportStatus;
      if (status.noDownload) {
        this.downloadProgress$.next(undefined);
      } else if (status.running) {
        // always show at least 1%
        const percentage = Math.max(1, 100 * status.running.processed / Math.max(status.running.total, 1));
        this.downloadProgress$.next({
          percentage,
          items_total: status.running.total,
          items_processed: status.running.processed,
        });
      }
    }
  }

  public async cancelDownload() {
    const requestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.meteringPointsDownloadCancel)
      .setBodyInfo({partnerId: this.sessionService.getCurrentPartnerId()})
      .build();
    await this.apiRequestService.callApi(requestConfig);
    await this.updateDownloadStatus();
  }

  public async showExportDialog() {
    const formConfig = new SmartFormBuilder()
      .showSubmitButton(true, 'SDATDialogs.ButtonExecute')
      .showCancelButton(true)
      .addRequired({
        name: 'exportType', title: 'MeteringPoints.ExportConfigDialog.FormFieldExportType',
        type: 'select', cssClasses: 'col-xs-12',
        allowedValues: {type: 'globalRegister', data: 'exporttype'}
      })
      .addRequired({
        name: 'date', title: 'MeteringPoints.ExportConfigDialog.FormFieldSelectDateOfExportState',
        type: 'date', cssClasses: 'col-xs-12',
        dateAsString: true,
        showConditions: [{
          fieldName: 'exportType',
          operator: SmartFormShowConditionOperator.Equals,
          value: 'state'
        }]
      })
      .setInitData({
        exportType: 'events',
        date: moment().format('YYYY-MM-DD'),
      })
      .build();

    const modalOptions = new SmartModalBuilder()
      .setTitle('MeteringPoints.ExportConfigDialog.Title')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();
    const formValue = await this.smartModalService.showModal(modalOptions);
    if (!formValue) return;
    let params;
    if (formValue.exportType === 'state') {
      if (!formValue.date) return;
      params = {StateDate: formValue.date};
    } else {
      params = {};
    }
    await this.apiRequestService.downloadFile(USERS_API_ENDPOINTS_LIST.meteringPointsDownload, params);
    await this.updateDownloadStatus();
  }

  // without progress report
  public async downloadSingleMeteringPoint(mpId: string, mpAdminEic: string, date?: string) {
    if (date) {
      const params = {
        MeteringPointId: mpId,
        AdministratorEic: mpAdminEic,
        StateDate: date,
      };
      await this.apiRequestService.downloadFile(USERS_API_ENDPOINTS_LIST.meteringPointsExport, params);
    } else {
      const params = {
        MeteringPointId: mpId,
        AdministratorEic: mpAdminEic,
      };
      await this.apiRequestService.downloadFile(USERS_API_ENDPOINTS_LIST.meteringPointsExport, params);
    }
  }
}
