import {ApiRequestConfigInfoDefinitionMap} from '../api-request/classes/ApiRequestConfigInfoDefinition';

export const NAV_API_ENDPOINTS_LIST: ApiRequestConfigInfoDefinitionMap = {
  'logout': {
    endpoint: '/api/logout',
    method: 'POST'
  },
  'profileInfo': {
    endpoint: '/api/profile/info',
    method: 'GET'
  },
  'profileUpdate': {
    endpoint: '/api/profile/info',
    method: 'PUT'
  },
  'langUpdate': {
    endpoint: '/api/profile/lang',
    method: 'PUT'
  },
  'passwordUpdate': {
    endpoint: '/api/profile/password',
    method: 'PUT'
  },
};
