/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {UtilsService} from '../../shared/services/utils.service';
import {SessionService} from '../../shared/session/session.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';

import {ActivatedRoute, Router} from '@angular/router';
import {SmartFormBuilder} from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import {SmartFormConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormConfigDefinition';
import {FieldValueChangedEvent} from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/FieldValueChangedEvent';

import {SmartFormComponent} from '../../shared/marketpartner-components/smart-forms/smart-form/component/smart-form.component';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {MarketpartnerViewComponent} from '../shared/marketpartner-view.component';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import { EnumUtilsService } from '../../shared/services/enum-utils/enum-utils.service';

@Component({
  selector: 'page-users-marketpartner-communication-webclient-content',
  templateUrl: './users-marketpartner-communication-webclient.component.html',
  styleUrls: ['../users-common.scss']
})


export class MarketpartnerCommunicationWebclientComponent extends MarketpartnerViewComponent {
  @ViewChild('sdatProcessForAllForm')  sdatProcessForAllForm?: SmartFormComponent;
  @ViewChild('sdatProcessForm')  sdatProcessForm?: SmartFormComponent;
  @ViewChild('useChangeProcessShortcutsForm')  useChangeProcessShortcutsForm?: SmartFormComponent;

  sendWebClientSettings$: Subject<null> = new Subject();

  sdatProcessFormConfig?: SmartFormConfigDefinition;
  sdatProcessForAllFormConfig?: SmartFormConfigDefinition;
  selectableShortcuts = this.enumUtilsService.getAllEnumValues('sdatbusinessreason_webclient');


  constructor(public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              private utilsService: UtilsService,
              public apiRequestService: ApiRequestService,
              private enumUtilsService: EnumUtilsService,
              public router: Router) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);

    // Note: debouncing is to avoid current-state confusion when reading the
    // from-state from an event handler. The original code had this bug. We
    // could instead use angular reactive forms as intended, but probably not
    // in combination with multiple SmartForms.
    //
    this.sendWebClientSettings$.pipe(
        debounceTime(200)
    ).subscribe(() => this.sendWebClientSettings());
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);

    if (this.marketPartnerInfo) {
      const mpi = this.marketPartnerInfo;

      this.sdatProcessForAllFormConfig = new SmartFormBuilder()
        .addFormFieldSDATWebClientForAll(!this.allowChanges)
        .setInitData(
          {
            sdat_forall_processes: this.utilsService.doArraysHaveSameElements(
              this.selectableShortcuts, mpi.sdatWebClientSettings.changeProcessWebOverride
            ) ? '1' : ''
          })
        .showSubmitButton(false)
        .showCancelButton(false)
        .build();

      this.sdatProcessFormConfig = new SmartFormBuilder()
        .addOptional({
              type: 'checkboxmulti',
              name: 'sdat_processes',
              cssClasses: 'chechboxes-4-columns',
              allowedValues: {
                type: 'globalRegister',
                data: 'sdatbusinessreason_webclient'
              },
              disabled: !this.allowChanges
        })
        .setInitData({
          sdat_processes: mpi.sdatWebClientSettings.changeProcessWebOverride ?
            mpi.sdatWebClientSettings.changeProcessWebOverride.join(',') : null
        })
        .showSubmitButton(false)
        .showCancelButton(false)
        .build();
    }

    this.setInitialized(true);
  }

  get allowChanges(): boolean {
    return !this.isOperatorMode;
  }

  get shortCutEnabledState(): boolean {
    return this.marketPartnerInfo?.meteringPointsDirectorySettings.useChangeProcessShortcuts ?? false;
  }

  get canAccessMeteringPointsUploadState(): boolean {
    return this.marketPartnerInfo === undefined ? false : this.marketPartnerService.canAccessMeteringPointsUpload(this.marketPartnerInfo);
  }

  changedCheckboxSdatProcessForAll(event: FieldValueChangedEvent) {
    if (!this.sdatProcessForm) return;
    if (event.value === '1') {
      this.sdatProcessForm.setOptionCheckboxes('sdat_processes', this.selectableShortcuts);
    } else {
      // "select all" checkbox was un-checked (manually or indirectly)
      const currentValuesSelected = this.sdatProcessForm.getCurrentFormFieldValue('sdat_processes').split(',');
      if (this.utilsService.doArraysHaveSameElements(this.selectableShortcuts, currentValuesSelected)) {
        // "select all" checkbox was un-checked manually
        this.sdatProcessForm.setOptionCheckboxes('sdat_processes', []);
      }
    }
  }

  changedSelectionSdatProcess(event: FieldValueChangedEvent) {
    if (this.sdatProcessForm && this.sdatProcessForAllForm) {
      const currentValueArray = event.value.split(',');
      const everythingSelected = this.utilsService.doArraysHaveSameElements(this.selectableShortcuts, currentValueArray);
      setTimeout(() => {
        if (!this.sdatProcessForAllForm) return;
        if (everythingSelected) {
          this.sdatProcessForAllForm.selectOptionCheckbox('sdat_forall_processes', '1');
        } else {
          this.sdatProcessForAllForm.unselectOptionCheckbox('sdat_forall_processes', '1');
        }
      }, 0);
    }
    this.sendWebClientSettings$.next(null);
  }

  async sendWebClientSettings() {
    if (!this.partnerId) return;
    if (!this.sdatProcessForm) throw new Error('unreachable');

    // Get the current forms values
    const sdatProcessesValue = this.sdatProcessForm.getCurrentFormFieldValue('sdat_processes');
    let changeProcessWebOverride;
    if (sdatProcessesValue) {
      changeProcessWebOverride = sdatProcessesValue.split(',');
    } else {
      // apparently, that's how we must represent "empty list" for the API to be happy
      changeProcessWebOverride = undefined;
    }

    const dataToSend = {
      sdatWebClientSettings: {
        changeProcessWebOverride
      }
    };
    console.log('dataToSend:', dataToSend);

    const requestConfig = new ApiRequestBuilder()
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerSdatWebClientSettingsUpdate, {partnerId: this.partnerId})
      .setBodyInfo(dataToSend)
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .build();
    await this.apiRequestService.callApi(requestConfig);
  }
}
