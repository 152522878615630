<div *ngIf="!initialized" class="form-loading-container">
  <i class="fa fa-spinner fa-spin" ></i>
</div>


<div *ngIf="initialized" [class.modal-form]="isModal" class="panel" id="{{formId}}">
    <div *ngIf="(title && !isModal)" class="panel-heading">
        <h3 class="panel-title">{{title}}</h3>
    </div>

    <actions-toolbar  #actionsToolbarRef
                     [title]="title"
                     [actionsToolbar]="actionsToolbar"
                     [showIfEmpty]="showActionsToolbarIfEmpty"
                     (actionClicked)="actionClicked($event)"></actions-toolbar>

    <div class="form-container {{cssClassesByType.layoutGeneral.formContainer}}" >
        <form [formGroup]="smartForm" #form="ngForm"   (ngSubmit)="onSubmit(smartForm.value)">
            <div class="form-content">
                <div class="row">
                    <div *ngFor="let field of fields; let fieldIndex = index" class="{{getClassesFieldContainer(fieldIndex)}}"
                          [class.form-field-array-main-readonly]="(field.type === 'array' && field.readOnly)"
                          [class.hide-condition-field]="hideOrOmitConditionField(field.name) || hideEmptyReadOnlyField(field, fieldIndex)"
                        >
                        <div class="form-ui-add-array-entry btn-link">
                          <i (click)="performUIAction('addArrayEntry', fieldIndex)" class="pli-add"></i>
                        </div>
                        <div class="form-ui-delete-array-entry btn-link">
                          <i (click)="performUIAction('removeArrayEntry', fieldIndex)"  class="pli-trash"></i>
                        </div>

                        <div *ngIf="field.type === 'heading'"
                             class="heading">
                          <span [innerHtml]='field.title | translate'></span>
                        </div>
                        <div  class="form-group {{cssClassesByType.layoutLabelInput.formGroupContainer}}"
                              *ngIf="field.type !== 'heading'"
                              [class.form-field-readonly]="isFieldReadOnly(fieldIndex)"
                              [class.form-field-disabled]="isFieldDisabled(fieldIndex)"
                              [class.has-error]="showHasErrorField(fieldIndex,field.name)"
                              [class.form-field-dirty]="isFieldDirty(field.name)"
                              [class.form-field-pristine]="!isFieldDirty(field.name)">
                            <label *ngIf="field.title" class="control-label {{cssClassesByType.layoutLabelInput.label}}">
                              <span [innerHtml]='field.title | translate'></span>{{showRequiredString(fieldIndex)}}
                            </label>

                            <div *ngIf="!isFieldReadOnly(fieldIndex)">
                              <div *ngIf="field.type === 'input'" class="input-group {{cssClassesByType.layoutLabelInput.input}}">
                                  <input  type="text" class="form-control" placeholder="{{field.placeholder}}" formControlName="{{field.name}}">
                              </div>

                              <div *ngIf="field.type === 'html'" class="input-group html-field {{cssClassesByType.layoutLabelInput.input}}">
                                  <div [innerHTML]="field.default"></div>
                              </div>

                              <input *ngIf="field.type === 'hidden'" type="text" class="form-control  {{cssClassesByType.layoutLabelInput.input}}" placeholder="{{field.placeholder}}" formControlName="{{field.name}}">

                              <div *ngIf="field.type === 'password'" class="input-group">
                                <input type="password" appShowHidePassword class="form-control {{cssClassesByType.layoutLabelInput.input}}" placeholder="{{field.placeholder}}" formControlName="{{field.name}}">
                              </div>

                              <div *ngIf="field.type === 'select'" class="{{cssClassesByType.layoutLabelInput.input}}">
                                <select class="form-control" formControlName="{{field.name}}">
                                  <!--<option *ngIf="(getDefaultValueField(fieldIndex) === null)"  value=""></option>-->
                                  <option disabled *ngIf="!field.readOnly" value="null">{{'FormSelectEmpty' | translate}}</option>
                                  <option *ngFor="let selectOption of getSelectOptionsField(fieldIndex);let optionIndex = index"  value="{{selectOption.data}}">{{selectOption.label | translate}}</option>
                                </select>
                              </div>

                              <div *ngIf="field.type === 'autocomplete'" class="input-group autocomplete-not-init {{cssClassesByType.layoutLabelInput.input}}" [attr.data-fieldindex]="fieldIndex">
                                <input type="text" class="hidden" formControlName="{{field.name}}">
                                <input id="{{'autocomplete-input-' +formId +'_'+ fieldIndex}}" type="text" class="autocomplete form-control" formControlName="{{field.name}}">
                              </div>

                              <div *ngIf="(field.type === 'radio')"  class="{{cssClassesByType.layoutLabelInput.input}}">
                                <div class="{{field.type}}"  *ngFor="let selectOption of getSelectOptionsField(fieldIndex);let optionIndex = index">
                                  <input *ngIf="field.type === 'radio'" id="{{'radio_' +formId +'_'+ fieldIndex +'_'+optionIndex}}" type="radio"    value="{{selectOption.data}}" class="{{'magic-radio'}}" formControlName="{{field.name}}">
                                  <label *ngIf="field.type === 'radio'" for="{{'radio_' +formId +'_'+ fieldIndex +'_'+optionIndex}}">{{selectOption.label | translate}}</label>
                                </div>
                              </div>

                              <div *ngIf="((field.type === 'checkboxradio') || (field.type === 'checkboxmulti'))"  class="{{cssClassesByType.layoutLabelInput.input}}">
                                <input type="text" class="hidden" formControlName="{{field.name}}">
                                <div class="{{field.type}}"  *ngFor="let selectOption of getSelectOptionsField(fieldIndex);let optionIndex = index">
                                  <input  id="{{'checkboxmulti_' +formId +'_'+ fieldIndex +'_'+optionIndex}}" type="checkbox"    value="{{selectOption.data}}" class="{{'magic-checkbox'}}" (click)="toggleOptionCheckbox(field.name,selectOption.data, $event)" [checked]="isOptionSelectedCheckbox(field.name,selectOption.data)">
                                  <label  for="{{'checkboxmulti_' +formId +'_'+ fieldIndex +'_'+optionIndex}}">{{selectOption.label | translate}}</label>
                                </div>
                              </div>
                              <div *ngIf="((field.type === 'date') || (field.type === 'datetime'))"  >
                                <input type="text" class="hidden" formControlName="{{field.name}}">
                                <input *ngIf="(field.type === 'datetime')"
                                       id="{{'datepicker-input-' +formId +'_'+ fieldIndex+'_time'}}"
                                       (input)="onTimeInputChange($event.target.value, fieldIndex )"
                                       value="{{getDefaultValueTimeField(fieldIndex)}}"
                                       type="text" class="form-control form-time-picker" placeholder="hh:mm:ss">
                                <div class="input-group date datepicker-not-init"
                                     [class.datetime]="(field.type === 'datetime')"
                                     id="{{'datepicker-object-' +formId +'_'+ fieldIndex}}"  [attr.data-fieldindex]="fieldIndex" >
                                  <input id="{{'datepicker-input-' +formId +'_'+ fieldIndex}}" type="text" class="form-control"  value="{{dateTimeService.getDateAsLocaleFormatFromIsoDate(getDefaultValueField(fieldIndex))}}"  placeholder="{{dateTimeService.getLocaleDateFormatPicker()}}">
                                  <div class="input-group-addon"><i class="pli-calendar-4"></i></div>
                                </div>
                              </div>
                            </div>

                            <div *ngIf="isFieldReadOnly(fieldIndex)">
                              <input type="text" class="hidden" formControlName="{{field.name}}">
                              <div *ngIf="(['select', 'radio', 'checkboxmulti', 'checkboxradio'].indexOf(field.type) >= 0)">
                                <div class="form-control form-control-read-only">{{getSelectedOptionLabel(fieldIndex, field.name)}}</div>
                              </div>
                              <div *ngIf="!(['select', 'radio', 'checkboxmulti', 'checkboxradio', 'date', 'datetime', 'datetimeIso', 'array'].indexOf(field.type) >= 0)">
                                <div class="form-control form-control-read-only">{{getCurrentFormFieldValue(field.name)}}</div>
                              </div>
                              <div *ngIf="(field.type === 'date')">
                                <div class="form-control form-control-read-only">{{dateTimeService.getDateAsLocaleFormatFromJson(getCurrentFormFieldValue(field.name))}}</div>
                              </div>
                              <div *ngIf="(field.type === 'datetime')">
                                <div class="form-control form-control-read-only">{{dateTimeService.getDateTimeAsLocaleFormatFromJson(getCurrentFormFieldValue(field.name), true)}}</div>
                              </div>
                              <div *ngIf="(field.type === 'datetimeIso')">
                                <div class="form-control form-control-read-only">{{dateTimeService.getDateTimeAsLocaleFormatFromIsoDate(getCurrentFormFieldValue(field.name), true)}}</div>
                              </div>
                            </div>

                           <small *ngIf="showHelpMessageField" class="help-block">{{field.help  | translate}}</small>
                           <div *ngIf="showHasErrorField(fieldIndex,field.name)">
                             <small *ngIf="getErrors(fieldIndex,field.name).mustMatch" class="help-block">{{'PasswordsDoNotMatch' | translate}}</small>
                             <small *ngIf="getErrors(fieldIndex,field.name).dateRange" class="help-block">{{'InvalidDateRange' | translate}}</small>
                             <small *ngIf="getErrors(fieldIndex,field.name).translatableMessage" class="help-block">{{getErrors(fieldIndex,field.name).translatableMessage | translate}}</small>
                           </div>
                        </div>

                    </div>
                </div>
            </div>
            <div *ngIf="isAnyButtonVisible() || resultMessage" class="panel-footer text-right">
                <div *ngIf="resultMessage" class="form-message" [class.text-danger]="errorResultSubmit" [class.text-success]="!errorResultSubmit">
                    {{resultMessage | translate}}
                </div>

                <div *ngIf="isAnyButtonVisible()" class="buttons-container" >
                    <div *ngFor="let buttonInfo of formConfig.buttons;" class="button-container">
                        <button *ngIf="buttonInfo.visible !== false && showButtonVisible(buttonInfo)"
                                class="{{buttonInfo.cssClasses}}"
                                [type]="buttonInfo.type !== 'submit' ? 'button' : 'submit'"
                                [disabled]="isSendingRequest && buttonInfo.type !== 'cancel'"
                                (click)="onFormButtonClicked(buttonInfo)">
                            <i *ngIf="buttonInfo.icon" class="{{buttonInfo.icon}}"></i>
                            {{buttonInfo.title | translate}}
                            <i *ngIf="isSendingRequest && buttonInfo.type !== 'cancel'" class="fa fa-spinner fa-spin"></i></button>
                    </div>
                </div>


            </div>
        </form>
    </div>
</div>
