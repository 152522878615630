<page-loading-spinner [initialized]="initialized"></page-loading-spinner>
<div class="root" *ngIf="initialized">
  <div class="headline-space-button">
    <h1>{{'companyClient.userManagement.h1' | translate}}</h1>
    <button mat-flat-button color="primary" (click)="openAddUserDialog()" id="addUser">{{'companyClient.userManagement.addPerson' | translate}}</button>
  </div>
  <table mat-table [dataSource]="usersForTable" class="table-striped">
    <ng-container matColumnDef="lastname">
      <th mat-header-cell *matHeaderCellDef>{{'companyClient.objectProperties.user.firstname' | translate}}</th>
      <td mat-cell *matCellDef="let user" [attr.colspan]="user.enabled? '1' : '2'" (mouseenter)="hoveredUser = user.id" (mouseleave)="hoveredUser = undefined">
        <ng-container *ngIf="user.enabled">
          {{user.firstname}}
        </ng-container>
        <ng-container *ngIf="!user.enabled">
          {{'companyClient.objectProperties.user.unregisteredName' | translate}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="firstname">
      <th mat-header-cell *matHeaderCellDef>{{'companyClient.objectProperties.user.lastname' | translate}}</th>
      <ng-container *matCellDef="let user">
        <td mat-cell *ngIf="user.enabled" (mouseenter)="hoveredUser = user.id" (mouseleave)="hoveredUser = undefined">
          {{user.lastname}}
        </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="isAdmin">
      <th mat-header-cell *matHeaderCellDef>{{'companyClient.objectProperties.user.isAdmin' | translate}}</th>
      <td mat-cell *matCellDef="let user" (mouseenter)="hoveredUser = user.id" (mouseleave)="hoveredUser = undefined">
        <div class="button-align">{{ (user.scopes == 'ADMIN' ? 'LabelYesornoYes' : 'LabelYesornoNo') | translate }}</div>
        <button *ngIf="user.id != ownUserId && hoveredUser == user.id" mat-icon-button (click)="editUser(user)" [id]="'edit_'+user.id">
          <mat-icon svgIcon="edit" class="muted"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>{{'companyClient.objectProperties.user.email' | translate}}</th>
      <td mat-cell *matCellDef="let user" (mouseenter)="hoveredUser = user.id" (mouseleave)="hoveredUser = undefined"> {{user.email}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{'companyClient.objectProperties.user.status' | translate}}</th>
      <td mat-cell *matCellDef="let user" (mouseenter)="hoveredUser = user.id" (mouseleave)="hoveredUser = undefined">
        {{ (user.enabled? 'companyClient.objectProperties.user.registered' : 'companyClient.objectProperties.user.unregistered') | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="align-end"><mat-icon svgIcon="filter" class="button-fit"></mat-icon></th>
      <td mat-cell *matCellDef="let user" class="align-end" (mouseenter)="hoveredUser = user.id" (mouseleave)="hoveredUser = undefined">
        <button *ngIf="user.id != ownUserId" mat-icon-button (click)="deleteUser(user)" [id]="'delete_'+user.id">
          <mat-icon svgIcon="delete" class="muted"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
