import {Chart,ChartData,ChartMeta,ChartOptions,TooltipItem} from "chart.js";
import {ChartDataModel, BarChartDataModel} from "../chart-data-model";
import {TranslateService} from "@ngx-translate/core";
import {ChartStyling} from "../chart-styling";

export class BarChartConfig {

  public chartData: ChartData<'bar', (number | null)[]>;
  public chartOptions: ChartOptions<'bar'>;

  private data: BarChartDataModel;
  private dateHighlighter = {
    id: 'dateHighlighter',
    beforeDatasetsDraw(chart: Chart, _args: { index: number; meta: ChartMeta }, options: {selectedMonth: number | undefined, selectedYear: number}): boolean | void {
      const padding = 10;
      const y = chart.chartArea.top - padding / 2;
      const h = chart.height + padding;
      const wOneMonth = chart.scales['x'].getPixelForValue(1) - chart.scales['x'].getPixelForValue(0);
      const wOneMonthPadded = wOneMonth + padding;
      const paddingXOffset = (-wOneMonth - padding) / 2;

      if (options.selectedMonth !== undefined) {
        const xSelectedPadded = chart.scales['x'].getPixelForValue(options.selectedMonth) + paddingXOffset;

        chart.ctx.fillStyle = ChartStyling.selectedBackgroundColor;
        chart.ctx.fillRect(xSelectedPadded, y, wOneMonthPadded, h);
      }

      if(options.selectedYear == new Date().getFullYear()) {
        const currentMonth = new Date().getMonth();
        chart.ctx.fillStyle = ChartStyling.previousBackgroundColor; //ChartStyling.contingentLightColor;
        const xCurrent = chart.scales['x'].getPixelForValue(currentMonth);
        const wToEnd = chart.scales['x'].getPixelForValue(12) - xCurrent + padding;
        const xPadded = xCurrent + paddingXOffset;
        chart.ctx.fillRect(xPadded, y, wToEnd, h);
      }

      if(chart.data.datasets.map(d => d.data.length).reduce((p, c) => p + c) == 0) {
        chart.ctx.textAlign = 'center';
        chart.ctx.fillStyle = ChartStyling.previousColor;
        let textX = chart.scales['x'].getPixelForValue((chart.data.labels!.length-1)/2);
        let textY = chart.scales['y'].getPixelForValue(0.5);
        chart.ctx.fillText('No data', textX, textY);
      }
    }
  };

  constructor(cData: ChartDataModel,
              public yearToDisplay: number,
              public monthToHighlight: number | undefined,
              private translate: TranslateService) {

    this.data = new BarChartDataModel(cData);

    Chart.register(this.dateHighlighter);

    this.chartData = {
      labels: this.translate.instant('months'),
      datasets: [
        {
          data: this.data.contingentData,
          label: this.translate.instant('companyClient.consumption.contingent'),
          backgroundColor: ChartStyling.contingentColor,
          borderColor: ChartStyling.contingentColor,
          categoryPercentage: 0.4,
          order: 1
        },
        {
          data: this.data.previousData,
          label: (yearToDisplay - 1).toString(),
          backgroundColor: ChartStyling.previousColor,
          borderColor: ChartStyling.previousColor,
          categoryPercentage: 0.4,
          order: 0
        },
        {
          data: this.data.currentData,
          label: yearToDisplay.toString(),
          backgroundColor: ChartStyling.currentColor,
          borderColor: ChartStyling.currentColor,
          categoryPercentage: 0.4,
          order: 2,
          stack: 'current'
        },
        {
          data: this.data.overusedData,
          label: this.translate.instant('companyClient.consumption.contingentOverused'),
          backgroundColor: ChartStyling.warningColor,
          borderColor: ChartStyling.warningColor,
          categoryPercentage: 0.4,
          order: 2,
          stack: 'current'
        }
      ]
    };

    let pluginOptions = {
      dateHighlighter: {
        enabled: true,
        selectedMonth: this.monthToHighlight,
        selectedYear: this.yearToDisplay
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label(tooltipItem: TooltipItem<'bar'>): string | string[] {
            if(tooltipItem.parsed.y == null) return '';
            let label = tooltipItem.dataset.label || '';
            let value = tooltipItem.parsed.y;
            let month = tooltipItem.dataIndex;
            let overused = tooltipItem.chart.data.datasets[3].data[month] as number;
            if(tooltipItem.datasetIndex == 2 && overused != null) {
              value += overused;
            }
            if(label) {
              const info = tooltipItem.datasetIndex == 1 ? cData.additionalInfoPrevious : cData.additionalInfoCurrent;
              label += ': ' + value.toLocaleString('DE-ch') + ' kWh (' + info[tooltipItem.dataIndex]?.dataSource +')';
            }
            return label;
          }
        },
        footerColor: ChartStyling.warningColor,
        footerFont: {
          weight: ChartStyling.footerWeight
        }
      }
    };

    this.chartOptions = ChartStyling.getOptions(pluginOptions, this.data.getSuggestedMax(), this.translate.instant('companyClient.consumption.unit'));
  }
}
