import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PartnerInfo, SessionInfo, TokenAuthority, UserType} from './SessionInfo';
import {MarketPartnerInfo, MarketPartnerRole} from './MarketPartnerInfo';
import {AccessTokenContent} from './TokenContent';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment-timezone';
import {ACCOUNT_API_ENDPOINTS_LIST} from '../../account/api-endpoints-list';
import {orderBy} from 'lodash-es';
import {companypartner} from "../messaging-grpc/messaging-grpc";
import CompanyPartnerSettings = companypartner.CompanyPartnerSettings;

@Injectable()
export class SessionService {
  public partnerInfoSource = new BehaviorSubject<MarketPartnerInfo|null>(null);

  private accessToken: string | null = null;
  private refreshAccessTokenAt = moment();
  private sessionInfo?: SessionInfo;

  private localStorageKeys = {
    refreshAccessTokenAt: 'refreshAccessTokenAt',
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    lastLanguage: 'lastLanguage'
  }

  constructor(
    private http: HttpClient
  ) {
    const refreshAt = localStorage.getItem(this.localStorageKeys.refreshAccessTokenAt);
    const accessToken = localStorage.getItem(this.localStorageKeys.accessToken);
    if (refreshAt && accessToken) {
      this.refreshAccessTokenAt = moment(refreshAt);
      this.accessToken = localStorage.getItem(this.localStorageKeys.accessToken);
      // this.refreshAccessTokenAt = moment().add(15, 'seconds');  // for testing token refresh
    }
    window.addEventListener('storage', this.onLocalStorage.bind(this), false);
  }

  private onLocalStorage(e: StorageEvent) {
    if (e.storageArea !== localStorage) return;
    if (e.key === this.localStorageKeys.refreshAccessTokenAt) {
      this.refreshAccessTokenAt = e.newValue ? moment(e.newValue) : moment();
    } else if (e.key === this.localStorageKeys.accessToken) {
      const oldToken = this.accessToken;
      this.accessToken = e.newValue;
      if (oldToken !== this.accessToken) {
        console.log('accessToken was changed by other tab/window');
        if (oldToken && this.accessToken) {
          if (!this.accessTokensAreEquivalent(oldToken, this.accessToken)) {
            console.log('page reload, reason: different user/pid');
            this.reloadPageWhenVisible();
          } else {
            console.log('same user/pid as before, no action');
          }
        } else {
          console.log('page reload, reason: login or logout from other tab');
          this.reloadPageWhenVisible();
        }
      }
      // refreshToken change ignored (it always implies an accessToken change)
    }
  }

  private accessTokensAreEquivalent(tokenA: string, tokenB: string) {
    try {
      const a = jwt_decode<AccessTokenContent>(tokenA);
      const b = jwt_decode<AccessTokenContent>(tokenB);
      return a.sub === b.sub && a.partner_id === b.partner_id;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  private reloadPageWhenVisible() {
    console.log('page will be reloaded when visible');
    if (!document.hidden) {
      window.location.reload();
    } else {
      let isReloading = false;
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden && !isReloading) {
          isReloading = true;
          window.location.reload();
        }
      }, false);
    }
  }

  async getAccessToken(): Promise<string | undefined> {
    await this.maybeRefreshToken();
    return this.accessToken || undefined;
  }

  private async httpGetData(url: string) {
    if (!this.accessToken) throw new Error('we have no accessToken');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      })
    };
    const response = await this.http.get<any>(url, httpOptions).toPromise();
    return response.data;
  }

  private async httpGetCompanyPartner(partnerId: number): Promise<CompanyPartnerSettings> {
    if (!this.accessToken) throw new Error('we have no accessToken');
    const url = ACCOUNT_API_ENDPOINTS_LIST.getCompanyPartner.endpoint.replace('{partnerId}', partnerId.toString())
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      })
    };
    const response = await this.http.get<any>(url, httpOptions).toPromise();
    //console.log("Company Partner: ",response.data.companyPartnerSettings);
    return response.data.companyPartnerSettings;
  }

  private async loadSessionInfo(): Promise<void> {
    try {
      if (!this.accessToken) throw new Error('loadSessionInfo: got no accessToken');
      const tokenContent = jwt_decode<AccessTokenContent>(this.accessToken);
      console.log('tokenContent:', tokenContent);

      if(tokenContent.authorities.includes(TokenAuthority.COMPANYPARTNER)) {
        await this.getCompanyPartnerSessionInfo(tokenContent);
      } else {
        await this.getMarketPartnerSessionInfo(tokenContent);
      }
    } catch (error) {
      this.invalidateSession();
      throw error;
    }
  }

  private async getCompanyPartnerSessionInfo(tokenContent: AccessTokenContent) {
    const [profile, companyPartnerSettings, partnerInfo] = await Promise.all([
      this.httpGetData(ACCOUNT_API_ENDPOINTS_LIST.profileInfo.endpoint),
      this.httpGetCompanyPartner(tokenContent.partner_id),
      this.httpGetData(ACCOUNT_API_ENDPOINTS_LIST.marketpartners.endpoint)
    ]);

    this.sessionInfo = {
      user_info: profile,
      partnerId: tokenContent.partner_id,
      partnerName: tokenContent.partner_name,
      partnerInfo: null,
      authorities: tokenContent.authorities,
      partners: partnerInfo.relationships,
      localFtpServer: '',
      companyPartnerInfo: {companyPartnerSettings: companyPartnerSettings}
    };
    //console.log("sessionInfo " , this.sessionInfo);
  }

  private async getMarketPartnerSessionInfo(tokenContent: AccessTokenContent) {
    let partnerInfoPromise = Promise.resolve(null);
    if (tokenContent.partner_id) {
      partnerInfoPromise = this.httpGetData('/api/marketpartnersdirectory/' + tokenContent.partner_id);
    }
    const [profile, marketpartners, ftpserver, partnerInfo] = await Promise.all([
      this.httpGetData(ACCOUNT_API_ENDPOINTS_LIST.profileInfo.endpoint),
      this.httpGetData(ACCOUNT_API_ENDPOINTS_LIST.marketpartners.endpoint),
      this.httpGetData(ACCOUNT_API_ENDPOINTS_LIST.ftpserver.endpoint),
      partnerInfoPromise
    ]);

    this.sessionInfo = {
      user_info: profile,
      partnerId: tokenContent.partner_id,
      partnerName: tokenContent.partner_name,
      partnerInfo: partnerInfo,
      authorities: tokenContent.authorities,
      partners: marketpartners.relationships,
      localFtpServer: ftpserver,
      companyPartnerInfo: null
    };
    this.partnerInfoSource.next(partnerInfo);
  }

  extractEmailFromToken(token: string) {
    const content = jwt_decode<AccessTokenContent>(token);
    return content.sub;
  }

  async login1Password(username: string, password: string): Promise<void> {
    const url = ACCOUNT_API_ENDPOINTS_LIST.login1Password.endpoint;
    const response = await this.http.post<any>(url, {username, password}).toPromise();
    this.accessToken = response.data.otpToken;
  }

  async login2Otp(otp: string): Promise<void> {
    const url = ACCOUNT_API_ENDPOINTS_LIST.login2Otp.endpoint;
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer ' + this.accessToken})
    };
    const response = await this.http.post<any>(url, {otp}, httpOptions).toPromise();
    this.setSessionTokens(response.data);
    await this.loadSessionInfo();
  }

  async logout() {
    this.invalidateSession();
    // Refresh website so new updates will be applied
    console.log('page reload, reason: logout');
    window.location.reload();
  }

  async maybeRefreshToken(): Promise<void> {
    const refreshToken = localStorage.getItem(this.localStorageKeys.refreshToken);
    if (!refreshToken) return;
    // console.log('check: token refresh', this.refreshAccessTokenAt.fromNow());
    if (this.accessToken && moment().isBefore(this.refreshAccessTokenAt)) {
      return;  // access token still valid
    }
    try {
      console.log('refreshing accessToken...');

      const tokenContent = jwt_decode<AccessTokenContent>(refreshToken);
      console.log('tokenContent refreshtoken:', JSON.stringify(tokenContent));

      const httpOptions = {
        headers: new HttpHeaders({'Authorization': 'Bearer ' + refreshToken})
      };
      const response = await this.http.post<any>('/api/token/refresh', null, httpOptions).toPromise();
      console.log('token refresh successful.');
      this.setSessionTokens(response.data);
    } catch (error) {
      console.error(error);
      this.invalidateSession();
      // don't bother showing error messages, just reload (go to login)
      // see https://tools.scs.ch/issues/browse/ELDEXDHUB-1218
      console.log('page reload, reason: refreshing accessToken unsuccessful');
      this.reloadPageWhenVisible();
    }
  }

  async isAuthenticated(): Promise<boolean> {
    await this.maybeRefreshToken();
    if (this.accessToken && !this.sessionInfo) {
      try {
        // we cannot pass the auth guard until we have user profile, etc.
        await this.loadSessionInfo();
      } catch (error) {
        console.log(error);
        console.log('--> session invalid');
        this.invalidateSession();
      }
    }

    // invalidates token if it doesn't have usable account/scope info
    this.getCurrentAccountType();
    this.getCurrentScopeForDomain();

    return !!this.accessToken;
  }

  public setTemporaryAccessToken(token: string) {
    this.invalidateSession();
    this.accessToken = token;
  }

  private setSessionTokens(responseData: any) {
    try {
      const {accessToken, refreshToken} = responseData;
      const tokenContent = jwt_decode<AccessTokenContent>(accessToken);

      console.log('accessToken:', JSON.stringify(jwt_decode<AccessTokenContent>(accessToken)));
      console.log('refreshToken:', JSON.stringify(jwt_decode<AccessTokenContent>(refreshToken)));

      // use clock of client for refresh (we know that the token was issued just now)
      const issuedAt = moment.unix(tokenContent.iat);
      const expiresAt = moment.unix(tokenContent.exp);
      const duration = moment.duration(expiresAt.diff(issuedAt));
      const refreshAt = moment().add(duration).subtract(1, 'minute');
      // const refreshAt = moment().add(20, 'seconds');
      // console.log('accessToken issued', issuedAt.fromNow(), 'for', duration.humanize());
      // console.log('will refresh', refreshAt.fromNow());

      this.accessToken = accessToken;
      this.refreshAccessTokenAt = refreshAt;
      localStorage.setItem(this.localStorageKeys.refreshToken, refreshToken);
      localStorage.setItem(this.localStorageKeys.accessToken, accessToken);
      localStorage.setItem(this.localStorageKeys.refreshAccessTokenAt, refreshAt.toISOString());
    } catch (error) {
      this.invalidateSession();
      throw error;
    }
  }

  public invalidateSession() {
    this.sessionInfo = undefined;
    this.accessToken = null;
    // note: clearing the localStorage items affects other tabs
    // (it forces them to throw away all tokens and schedule a page reload)
    localStorage.removeItem(this.localStorageKeys.accessToken);
    localStorage.removeItem(this.localStorageKeys.refreshToken);
    localStorage.removeItem(this.localStorageKeys.refreshAccessTokenAt);
  }

  public async changePartner(partnerId: number) {
    console.log('changePartner to ID', partnerId);
    const refreshToken = localStorage.getItem(this.localStorageKeys.refreshToken);
    if (!refreshToken) throw new Error('no refreshToken');
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer ' + refreshToken}),
      params: { partner_id: partnerId.toString() }
    };
    const response = await this.http.post<any>('/api/token/change', null, httpOptions).toPromise();
    this.setSessionTokens(response.data);

    // await this.loadSessionInfo();  // not enough to reload all content
    console.log('reloading page because of changePartner()');
    window.location.reload();
  }

  public async updateCurrentUserInfo() {
    await this.loadSessionInfo();
  }

  public getCurrentUserInfoFirstname(): string | undefined {
    return !this.sessionInfo ? undefined : this.sessionInfo.user_info.firstname;
  }

  public getCurrentUserInfoLastname(): string | undefined {
    return !this.sessionInfo ? undefined : this.sessionInfo.user_info.lastname;
  }

  public getCurrentUserInfoEmail(): string | undefined {
    return !this.sessionInfo ? undefined : this.sessionInfo.user_info.email;
  }

  public getCurrentUserInfoId(): string | undefined {
    return (!this.sessionInfo || !this.sessionInfo.user_info) ? undefined : this.sessionInfo.user_info.id;
  }

  public getCurrentUserInfoLanguage(): string | undefined {
    return (!this.sessionInfo || !this.sessionInfo.user_info) ? undefined : this.sessionInfo.user_info.lang;
  }

  public getLastLanguage(): string | null {
    return localStorage.getItem(this.localStorageKeys.lastLanguage);
  }

  public changeLastLanguage(_language: string) {
    localStorage.setItem(this.localStorageKeys.lastLanguage, _language);
  }

  public isOperator(): boolean {
    if (!this.sessionInfo) return false;
    const auth = this.sessionInfo.authorities;
    return auth.includes(TokenAuthority.OPERATORADMIN) ||
      auth.includes(TokenAuthority.OPERATORUSER);
  }

  public isOperatorAdmin(): boolean {
    if (!this.sessionInfo) return false;
    const auth = this.sessionInfo.authorities;
    return auth.includes(TokenAuthority.OPERATORADMIN);
  }

  public getCurrentPartnerId(): number | undefined {
    return this.sessionInfo ? this.sessionInfo.partnerId : undefined;  // XXX why is this not set?
  }

  public getCurrentPartnerName(): string | undefined {
    return this.sessionInfo ? this.sessionInfo.partnerName : undefined;
  }

  public getCurrentPartnerEic(): string | undefined {
    if (!this.sessionInfo) return undefined;
    const partnerInfo = this.sessionInfo.partnerInfo;
    if (!partnerInfo) return undefined;
    return partnerInfo.partnerSettings.eic;
  }

  public getCurrentPartnerInfo(): MarketPartnerInfo | undefined {
    if (!this.sessionInfo) return undefined;
    if (!this.sessionInfo.partnerInfo) return undefined;
    return this.sessionInfo.partnerInfo;
  }

  public getCurrentPartnerRole(): MarketPartnerRole | undefined {
    const partnerInfo = this.getCurrentPartnerInfo();
    if (!partnerInfo) return undefined;
    return partnerInfo.partnerSettings.role;
  }

  public getPartnerList(): PartnerInfo[] {
    if (!this.sessionInfo) return [];
    const partners = this.sessionInfo.partners;
    return orderBy(partners.slice(), ['partnerName'], ['asc']);
  }

  public areMultiplePartnersAvailable(): boolean {
    return !!this.sessionInfo && !!this.sessionInfo.partners && (this.sessionInfo.partners.length > 1);
  }

  public getCurrentScopeForDomain(): ('USER'|'ADMIN') {
    if (!this.sessionInfo) return 'USER';  // shouldn't matter in this case
    const auth = this.sessionInfo.authorities;

    let admin = false;
    let user = false;
    if (auth.includes(TokenAuthority.OPERATORADMIN)) admin = true;
    if (auth.includes(TokenAuthority.OPERATORUSER)) user = true;
    if (auth.includes(TokenAuthority.ADMIN)) admin = true;
    if (auth.includes(TokenAuthority.USER)) user = true;

    if ((admin && user) || (!admin && !user)) {
      console.error('Cannot deduce (user|admin) state from authorities:', auth);
      this.invalidateSession();
      return 'USER';
    }
    return admin ? 'ADMIN' : 'USER';
  }

  public getCurrentAccountType(): UserType {
    if (!this.sessionInfo) return UserType.UNAUTHORIZED;
    const authorities = this.sessionInfo.authorities;
    if (authorities.includes(TokenAuthority.OPERATORADMIN)) return UserType.OPERATORADMIN;
    if (authorities.includes(TokenAuthority.OPERATORUSER)) return UserType.OPERATORUSER;
    if (authorities.includes(TokenAuthority.COMPANYPARTNER)) return UserType.COMPANYPARTNER;
    if (!authorities.includes(TokenAuthority.MARKETPARTNER)) {
      console.error('Cannot deduce accountType from authorities:', authorities);
      this.invalidateSession();
      return UserType.UNAUTHORIZED;
    }
    return UserType.MARKETPARTNER;
  }

  public isCompanyPartner(): boolean {
    return this.getCurrentAccountType() == UserType.COMPANYPARTNER;
  }

  public isVnb(): boolean {
    return this.getCurrentPartnerRole() == 'VNB';
  }

  public isCompanyPartnerAdmin(): boolean {
    return this.getCurrentScopeForDomain() == 'ADMIN' && this.isCompanyPartner();
  }

  public getCurrentCompanyPartnerInfo() {
    return this.sessionInfo?.companyPartnerInfo;
  }

  public isMarketPartner(): boolean {
    return (!!this.getCurrentPartnerId() && (!this.isOperator()));
  }

  public getLocalFtpServer() {
    if (!this.sessionInfo) return '';
    return this.sessionInfo.localFtpServer;
  }

  public getSharedFtpAccounts() {
    return this.sessionInfo?.partnerInfo?.communicationData?.sharedFtpAccounts || [];
  }

  public getDeploymentCategory(): (undefined | 'testing' | 'staging') {
    if (!document.domain) return undefined;
    // if (document.domain.startsWith('localhost')) return 'testing';
    if (document.domain.startsWith('testing')) return 'testing';
    if (document.domain.startsWith('staging')) return 'staging';
    return undefined;
  }

  public getLogoUrl(): string {
    const deployment = this.getDeploymentCategory();
    let suffix = '';
    if (deployment === 'testing') suffix = '_testing';
    if (deployment === 'staging') suffix = '_staging';
    return 'assetsextern/images/logos/logo_swisseldex' + suffix + '.svg';
  }

  public updatedMarketpartnerInfo(partnerId: number, partnerInfo: MarketPartnerInfo) {
    if (!this.sessionInfo) return;
    if (!this.sessionInfo.partnerInfo) return;
    if (this.sessionInfo.partnerInfo.partnerSettings.partnerId === partnerId) {
      this.sessionInfo.partnerInfo = partnerInfo;
      this.partnerInfoSource.next(partnerInfo);
    }
  }
}
