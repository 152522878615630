import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {BarChartConfig} from "./bar-chart-config";
import {ChartData, ChartOptions} from "chart.js";
import {ConsumptionDataCompanyPartner} from "../consumption-data-model";
import {ChartDataMapper} from "../chart-data-mapper";
import {TranslateService} from "@ngx-translate/core";
import {ChartDataModel} from "../chart-data-model";

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnChanges {

  @Input() yearToDisplay: number = 0;
  @Input() monthToHighlight: number | undefined;
  @Input() mpFilter: {mpid: string, vnb: string, bp: string}[] | undefined;
  @Input() consumptionData: ConsumptionDataCompanyPartner | undefined

  @Output() monthSelected = new EventEmitter<number>();

  chartData?: ChartData<'bar', (number | null)[]>;
  chartOptions?: ChartOptions<'bar'>;
  chartConfig?: BarChartConfig;
  dataModel?: ChartDataModel;

  constructor(private translate: TranslateService) {
  }

  ngOnChanges(): void {
    if (this.consumptionData != undefined) {
      this.dataModel = ChartDataMapper.toChartDatasetData(this.translate, this.consumptionData, this.yearToDisplay, undefined, this.mpFilter);
      this.chartConfig = new BarChartConfig(this.dataModel, this.yearToDisplay, this.monthToHighlight, this.translate);
      this.chartData = this.chartConfig.chartData;
      this.chartOptions = this.chartConfig.chartOptions;
    }
  }

  onChartClicked($event: any) {
    if($event.active.length > 0) {
      this.monthSelected.emit($event.active[0].index);
    }
  }
}
