/* eslint-disable @angular-eslint/no-output-on-prefix, @angular-eslint/component-selector, @angular-eslint/no-input-rename */
import {Component, Input} from '@angular/core';
import {SessionService} from '../../shared/session/session.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConfig} from '../../config/app.config';
import {MarketPartnerInfo} from '../../shared/session/MarketPartnerInfo';
import {CompanyPartnerInfo} from "../../shared/session/CompanyPartnerInfo";
import {canAccessOstral} from "../../shared/services/marketpartner.service";

@Component({
  selector: 'operator-sidebar-panel',
  templateUrl: './operator-sidebar-panel.component.html',
  styleUrls: ['./operator-sidebar-panel.component.scss']
})

export class OperatorSidebarPanelComponent {
  @Input('activeViewMode') activeViewMode?: string;
  @Input('mpinfo') mpinfo: MarketPartnerInfo | undefined;
  @Input('upinfo') upinfo: CompanyPartnerInfo | undefined;

  listNavigationMP = [
    {
      label: 'NavigationUsers',
      viewMode: 'users',
      path: AppConfig.routes.admin_marketpartner_users
    },
    {
      label: 'NavigationCommunicationBaseData',
      viewMode: 'data',
      path: AppConfig.routes.admin_marketpartner_communication_data
    },
    {
      label: 'NavigationCommunicationFtp',
      viewMode: 'ftp',
      path: AppConfig.routes.admin_marketpartner_communication_ftp
    },
    {
      label: 'NavigationCommunicationWebclient',
      viewMode: 'webclient',
      path: AppConfig.routes.admin_marketpartner_communication_webclient
    },
    {
      label: 'NavigationCommunicationMpd',
      viewMode: 'mpd',
      path: AppConfig.routes.admin_marketpartner_communication_mpd
    },
    {
      label: 'NavigationOstral',
      viewMode: 'ostral',
      path: AppConfig.routes.admin_marketpartner_ostral,
      fnAllowed: canAccessOstral
    },
    {
      label: 'NavigationNotifications',
      viewMode: 'notifications',
      path: AppConfig.routes.admin_marketpartner_notifications
    },
    {
      label: 'NavigationReports',
      viewMode: 'reports',
      path: AppConfig.routes.admin_marketpartner_reports
    },
   ];

  listNavigationUP = [
    {
      label: 'NavigationUsers',
      viewMode: 'users',
      path: AppConfig.routes.admin_companypartner_users
    },
    {
      label: 'NavigationMeteringPoints',
      viewMode: 'meteringpoints',
      path: AppConfig.routes.admin_companypartner_meteringpoints
    }
  ];

  constructor(public sessionService: SessionService,
              public activatedRoute: ActivatedRoute,
              public router: Router) {


  }

  get partnerTitle() {
    if (!this.mpinfo && !this.upinfo) return '';
    return this.mpinfo?.partnerSettings.name || this.upinfo?.companyPartnerSettings.name;
  }

  goToPath(pathString: string) {
    if (this.mpinfo) {
      this.router.navigate([pathString.replace('{partnerId}', this.mpinfo.partnerSettings.partnerId.toString())]);
    }
    else if(this.upinfo) {
      this.router.navigate([pathString.replace('{partnerId}', this.upinfo.companyPartnerSettings.partnerId!.toString())]);
    }
  }
  goBackToList() {
    if(this.mpinfo) {
      this.router.navigate([AppConfig.routes.admin_marketpartner_communication_list]);
    } else if(this.upinfo) {
      this.router.navigate([AppConfig.routes.admin_companypartners]);
    }
  }
}
