<mat-form-field class="nav-form-field">
  <mat-select id="companyDropdown" [value]="companyName" #companyDropdown>
    <div class="divided-dropdown">
      <div>
        <mat-option [value]="companyName" id="company">{{companyName}}</mat-option>
        <mat-option *ngFor="let company of otherCompanies"
                    [value]="company.partnerId"
                    (click)="switchCompany(company.partnerId)"
                    [id]="'company_'+company.partnerId">
          {{company.partnerName}}
        </mat-option>
      </div>
      <div>
        <mat-divider></mat-divider>
        <button class="select-button" mat-button (click)="companyDropdown.close()" [routerLink]="'/company/account'" id="accountLink">
          {{ 'companyClient.navigation.account' | translate }}
        </button>
        <button class="select-button" mat-button (click)="onLogoutClicked()" id="logout">{{ 'NavLogout' | translate }}</button>
      </div>
    </div>
  </mat-select>
</mat-form-field>
