<page-loading *ngIf="!initialized"></page-loading>

<div *ngIf="initialized" id="page-content">
  <div class="before-panel-info">
    <div class="row">
      <div class="col-sm-8">
        <div (click)="goBack()" class="before-panel-title go-back-nav-button">
          <i class="fa fa-chevron-circle-left"></i>
          <div>{{'MeteringPoints.Label' | translate}}</div>
        </div>
      </div>
      <div class="col-sm-4">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base  no-botton-margin">

        <div class="tab-content">
          <div class="tab-pane fade active in">
            <h4>{{'MeteringPoints.TitleState' | translate}}</h4>
            <smart-form class="category-select-checkbox-form"
              #dateSelectForm
              *ngIf="(dateSelectFormConfig)"
              (onFieldValueChanged)="onDateSelected()"
              [formConfig]="dateSelectFormConfig"></smart-form>
            <div *ngIf="!hideState && data.state.notAvailable">
              <span *ngIf="data.state.displayDateAssignedSince">
                {{'MeteringPoints.TextStateNotAvailableSince' | translate: data.state}}
              </span>
              <span *ngIf="data.state.displayDateAssignedUntil">
                {{'MeteringPoints.TextStateNotAvailableUntil' | translate: data.state}}
              </span>
              <span *ngIf="!data.state.displayDateAssignedUntil && !data.state.displayDateAssignedSince">
                {{'MeteringPoints.TextStateNotAvailableEver' | translate}}
              </span>
            </div>

            <div class="mp-details-list" *ngIf="!data.state.notAvailable && !hideState">

              <div class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPoints.ColumnDateOfState' |translate"></label>
                <div class="mp-details-value">{{data.displayDateOfState}}</div>
              </div>
              <div class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPoints.ColumnDateOfStateValidUntil' |translate"></label>
                <div class="mp-details-value">{{data.state.displayDateValidUntil}}</div>
              </div>
              <div class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPoints.ColumnId' |translate"></label>
                <div class="mp-details-value">{{data.meteringPoint.id}}</div>
              </div>
              <div class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPoints.ColumnAdministrator' |translate"></label>
                <div class="mp-details-value">{{data.meteringPoint.administratorEic}}</div>
              </div>

              <ng-container *ngIf="showAdditionalMeteringPointColumns">
                <div class="mp-details-item">
                  <label class="mp-details-label" [innerHtml]="'MeteringPoints.ColumnBalanceSupplier' |translate"></label>
                  <div class="mp-details-value">{{data.state.assigned.parties.balanceSupplierEic}}</div>
                </div>
                <div class="mp-details-item">
                  <label class="mp-details-label" [innerHtml]="'MeteringPoints.ColumnBalanceResponsible' |translate"></label>
                  <div class="mp-details-value">{{data.state.assigned.parties.balanceResponsibleEic}}</div>
                </div>
                <div class="mp-details-item">
                  <label class="mp-details-label" [innerHtml]="'MeteringPoints.ColumnAncillaryServiceProvider' |translate"></label>
                  <div class="mp-details-value">{{data.state.assigned.parties.ancillaryServiceProviderEics}}</div>
                </div>
              </ng-container>

              <div *ngIf="data?.state?.assigned?.isEligibleForSwitch !== undefined" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPoints.ColumnEligibleForSwitch' |translate"></label>
                <div class="mp-details-value" [innerHtml]="mapYesOrNo(data.state.assigned.isEligibleForSwitch)! | translate"></div>
              </div>

              <div *ngIf="data?.state?.assigned?.gridBillingMethod !== undefined" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPoints.ColumnGridBillingMethod' |translate"></label>
                <div class="mp-details-value">{{ 'LabelGridBillingMethod.' + data.state.assigned.gridBillingMethod |translate }}</div>
              </div>

              <ng-container *ngFor="let address of data.state.assigned.consumer?.address">
                <div class="mp-details-item">
                  <label class="mp-details-label">{{'MeteringPointEvent.Keys.address' |translate}} {{'MeteringPointEvent.Keys.consumer' |translate}}</label>
                  <div class="mp-details-value">
                    <ng-container *ngIf="address.item.info.person as person">
                      {{person.mrMrs}} {{person.title}} {{person.firstName}} {{person.lastName}}<br>
                    </ng-container>
                    <ng-container *ngIf="address.item.info.organisation as org">
                      {{org.name}} {{org.nameAddOn1}} {{org.nameAddOn2}}<br>
                      <ng-container *ngIf="org.person as person">
                        {{person.mrMrs}} {{person.title}} {{person.firstName}} {{person.lastName}}<br>
                      </ng-container>
                    </ng-container>
                    <app-ech-address-info [address]="address.item.address"></app-ech-address-info>
                    <p>
                      <span *ngIf="address.category?.basic as basic">({{'MeteringPointEvent.Enums.basic.' + basic |translate}})</span>
                      <span *ngIf="address.category?.other as other">({{other}})</span>
                    </p>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngFor="let email of data.state.assigned.consumer?.email">
                <div class="mp-details-item">
                  <label class="mp-details-label">{{'MeteringPointEvent.Keys.email' |translate}} {{'MeteringPointEvent.Keys.consumer' |translate}}</label>
                  <div class="mp-details-value">{{email.item}}
                    <span *ngIf="email.category?.basic as basic">({{'MeteringPointEvent.Enums.basic.' + basic |translate}})</span>
                    <span *ngIf="email.category?.other as other">({{other}})</span>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngFor="let phone of data.state.assigned.consumer?.phone">
                <div class="mp-details-item">
                  <label class="mp-details-label">{{'MeteringPointEvent.Keys.phone' |translate}} {{'MeteringPointEvent.Keys.consumer' |translate}}</label>
                  <div class="mp-details-value">{{phone.item}}
                    <span *ngIf="phone.category?.basic as basic">({{'MeteringPointEvent.Enums.basic.' + basic |translate}})</span>
                    <span *ngIf="phone.category?.other as other">({{other}})</span>
                  </div>
                </div>
              </ng-container>

              <div *ngIf="data?.state?.assigned?.characteristics" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPoints.CharacteristicType' |translate"></label>
                <div class="mp-details-value">{{ 'MeteringPointEvent.Enums.type.' + data.state.assigned.characteristics.type |translate }}</div>
              </div>
              <div *ngIf="data?.state?.assigned?.characteristics" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPointEvent.Keys.meteringMethod' |translate"></label>
                <div class="mp-details-value">{{ 'MeteringPointEvent.Enums.meteringMethod.' + data.state.assigned.characteristics.meteringMethod |translate }}</div>
              </div>
              <div *ngIf="data?.state?.assigned?.characteristics" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPointEvent.Keys.settlementMethod' |translate"></label>
                <div class="mp-details-value">{{ 'MeteringPointEvent.Enums.settlementMethod.' + data.state.assigned.characteristics.settlementMethod |translate }}</div>
              </div>
              <div *ngIf="data?.state?.assigned?.characteristics" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPointEvent.Keys.meterReadingPeriod' |translate"></label>
                <div class="mp-details-value">{{data.state.assigned.characteristics.meterReadingPeriod.resolution}} {{'MeteringPointEvent.Enums.unit.' + data.state.assigned.characteristics.meterReadingPeriod.unit |translate  }}</div>
              </div>

              <div *ngIf="data?.state?.assigned?.address" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPointEvent.Keys.egId' |translate"></label>
                <div class="mp-details-value">{{data.state.assigned.address.egId}}</div>
              </div>
              <div *ngIf="data?.state?.assigned?.address" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPointEvent.Keys.ewId' |translate"></label>
                <div class="mp-details-value">{{data.state.assigned.address.ewId}}</div>
              </div>
              <div *ngIf="data?.state?.assigned?.address" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPointEvent.Keys.floorId' |translate"></label>
                <div class="mp-details-value">{{data.state.assigned.address.floorId}}</div>
              </div>
              <div *ngIf="data?.state?.assigned?.address" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPointEvent.Keys.floorAddOn' |translate"></label>
                <div class="mp-details-value">{{data.state.assigned.address.floorAddOn}}</div>
              </div>
              <div *ngIf="data?.state?.assigned?.address" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPointEvent.Keys.parcelId' |translate"></label>
                <div class="mp-details-value">{{data.state.assigned.address.parcelId}}</div>
              </div>
              <div *ngIf="data?.state?.assigned?.address" class="mp-details-item">
                <label class="mp-details-label" [innerHtml]="'MeteringPoints.House' |translate"></label>
                <div class="mp-details-value">
                  <app-ech-address-info [address]="data.state.assigned.address.address"></app-ech-address-info>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="actionsToolbarState.length > 0" class="tab-pane fade active in">
            <hr/>
            <actions-toolbar [actionsToolbar]="actionsToolbarState" ></actions-toolbar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="initialized" id="page-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base  no-botton-margin">
        <div class="tab-content">

          <div *ngIf="accessMpAdminMode">
            <input id="mpAdminMode" class="magic-checkbox" type="checkbox" (change)="toggleMpAdminMode($event.target.checked)" />
            <label for="mpAdminMode">
              {{ 'MeteringPoints.MpAdminModeCheckbox' | translate }}
            </label>
          </div>
          <div *ngIf="accessInvalidations">
            <input id="showInvalidations" class="magic-checkbox" type="checkbox" (change)="toggleInvalidations($event.target.checked)" />
            <label for="showInvalidations">
              {{ 'MeteringPoints.ShowInvalidatedCheckbox' | translate : {count: invalidatedCount} }}
            </label>
          </div>

          <h4>{{'MeteringPoints.TitleAllEvents' | translate}}</h4>

          <div class="tab-pane fade active in">
            <smart-table
              #eventTable
              [tableConfig]="tableEventsConfig"
              [offlineData]="displayedEvents"
            ></smart-table>
          </div>
          <div *ngIf="actionsToolbarEvents.length > 0" class="tab-pane fade active in">
            <hr/>
            <actions-toolbar [actionsToolbar]="actionsToolbarEvents"></actions-toolbar>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
