import { SMART_TABLE_DEFS } from '../definitions/smart-table.definitions';
import {SMART_TABLE_ROWACTIONS_DEFS} from '../definitions/smart-table-rowactions.definitions';
import {SMART_TABLE_ROWCLASSES_DEFS} from '../definitions/smart-table-rowclasses.definitions';
import {SMART_TABLE_INITIALSORTING_DEFS} from '../definitions/smart-table-initialsorting.definitions';
import {SMART_TABLE_COLUMNS_DEFS} from '../definitions/smart-table-columns.definitions';

import {SmartTableColumnDefinition, SmartTableColumnDefinitionContainer} from '../classes/SmartTableColumnDefinition';
import {SmartToolbarActionDefinition} from '../../smart-shared/classes/SmartToolbarActionDefinition';
import {SmartTableRowActionDefinition, SmartTableRowActionDefinitionContainer} from '../classes/SmartTableRowActionDefinition';
import {SmartTableInitialSortingDefinition,
    SmartTableInitialSortingDefinitionContainer} from '../classes/SmartTableInitialSortingDefinition';
import {SmartTableConfigDefinition} from '../classes/SmartTableConfigDefinition';
import {ApiRequestConfigInfoDefinition} from '../../../../api-request/classes/ApiRequestConfigInfoDefinition';
import {SmartTableRowClassesDefinitionContainer} from '../classes/SmartTableRowClassesDefinition';
import {TableColumnFilter} from '../classes/TableColumnFilter';

export class SmartTableBuilder {
    private  _tableConfig: SmartTableConfigDefinition = {
        cssClassesByType: {},
        columnsInfo: [],
        tableTitle: '',
        rowActionsInfo: {
            showColumn: 'after',
            columnTitle: 'TableColumnActions',
            actions: [],
        },
        apiRequestConfig: undefined,
        initialSorting: [],
        showIndividualFilters: false,
        showRowActionButtonOnlyIcon: true,
        hideFooter: false,
        hidePageLength: false,
        hideSearchField: false,
        hideHeader: false,
        pageLength: 25,
        actionsToolbar: [],
        columnNameForId: '',
        heightScrollContainer: '',
        splitTable: false,
        enableOnRowClick: false,
        fnRowClasses: [],
        fixedFiltersColumns: [],
        hideFilteredFromMaxEntriesFooterString: true,
        fnTransformRawData: row => row,
    };

    private _allDefinitions: any = {};
    constructor(  initConfig?: SmartTableConfigDefinition) {
        this._allDefinitions = SMART_TABLE_DEFS;

        if (initConfig) {
            this._tableConfig = initConfig;
        }

        this.importTableRowActionsDefs(SMART_TABLE_ROWACTIONS_DEFS);
        this.importTableRowClassesDefs(SMART_TABLE_ROWCLASSES_DEFS);
        this.importInitialSortingDefs(SMART_TABLE_INITIALSORTING_DEFS);
        this.importTableColumnsDefs(SMART_TABLE_COLUMNS_DEFS);
    }

    protected importTableColumnsDefs(toImport: SmartTableColumnDefinitionContainer[]) {
        this.importDefinitionsItems(toImport, 'columns');
    }

    protected importTableRowActionsDefs(toImport: SmartTableRowActionDefinitionContainer[]) {
        this.importDefinitionsItems(toImport, 'rowActions');
    }

    protected importTableRowClassesDefs(toImport: SmartTableRowClassesDefinitionContainer[]) {
      this.importDefinitionsItems(toImport, 'rowClasses');
    }

    protected importInitialSortingDefs(toImport: SmartTableInitialSortingDefinitionContainer[]) {
        this.importDefinitionsItems(toImport, 'initialSorting');
    }

    private importDefinitionsItems(toImport: any[], category: string) {
        const self = this;
        if (!self._allDefinitions[category]) {
            self._allDefinitions[category] = {};
        }
        toImport.forEach(function(thisEntry) {
            self._allDefinitions[category][thisEntry.id] =  thisEntry.definition;
        });
    }

    public showPagination(pagination: boolean) {
        if (pagination) {
            this._tableConfig.hideFooter = false;
        } else {
            this._tableConfig.hideFooter = true;
        }
        return this;
    }

    public setColumnNameForId(columnNameForId: string) {
        this._tableConfig.columnNameForId = columnNameForId;
        return this;
    }

    public setHeightScrollerCSSClass(heightScrollContainer: string) {
        this._tableConfig.heightScrollContainer = heightScrollContainer;
        return this;
    }

    public setPageLength(pageLength: number) {
      this._tableConfig.pageLength = pageLength;
      return this;
    }

    // called for each row of the API response (before flattening and preprocessing)
    public setTransformRawDataFn(fn: (_: any) => any) {
      this._tableConfig.fnTransformRawData = fn;
      return this;
    }

    public showHeader(showHeader: boolean) {
        this._tableConfig.hideHeader = !showHeader;
        return this;
    }

    public showRowActionButtonOnlyIcon(showRowActionButtonOnlyIcon: boolean) {
      this._tableConfig.showRowActionButtonOnlyIcon = showRowActionButtonOnlyIcon;
      return this;
    }

    public showSearchField(showSearchField: boolean) {
        this._tableConfig.hideSearchField = !showSearchField;
        return this;
    }

    public showFilteredFromMaxEntriesFooterString(showFilteredString: boolean) {
      this._tableConfig.hideFilteredFromMaxEntriesFooterString = !showFilteredString;
      return this;
    }

    public showFilters(showFilters: boolean) {
        this._tableConfig.showIndividualFilters = showFilters;
        return this;
    }

    public enableOnRowClick(enableOnRowClick: boolean) {
      this._tableConfig.enableOnRowClick = enableOnRowClick;
      return this;
    }


    public splitTable(splitTable: boolean) {
        this._tableConfig.splitTable = splitTable;
        return this;
    }

    public addInitialSorting(initialSorting: SmartTableInitialSortingDefinition) {
        this._tableConfig.initialSorting.push(initialSorting);
        return this;
    }

    public addRowClassFromId(rowClassId: string) {
      this._tableConfig.fnRowClasses.push(this._allDefinitions.rowClasses[rowClassId]);
      return this;
    }

    public addColumnFromId(columnId: string) {
        this._tableConfig.columnsInfo.push(this._allDefinitions.columns[columnId]);
        return this;
    }

    public addColumnFromInfo(columnInfo: SmartTableColumnDefinition) {
      this._tableConfig.columnsInfo.push(columnInfo);
      return this;
    }

    public addToolbarActionFromInfo(actionInfo: SmartToolbarActionDefinition) {
        this._tableConfig.actionsToolbar.push(actionInfo);
        return this;
    }

    public addRowActionFromId(actionId: string) {
        if ( this._tableConfig.rowActionsInfo) {
          this._tableConfig.rowActionsInfo.actions.push(this._allDefinitions.rowActions[actionId]);
        }
        return this;
    }

    public addRowActionFromInfo(actionInfo: SmartTableRowActionDefinition) {
        if ( this._tableConfig.rowActionsInfo) {
          this._tableConfig.rowActionsInfo.actions.push(actionInfo);
        }
        return this;
    }

    public setTableTitle(tableTitle: string) {
        this._tableConfig.tableTitle = tableTitle;
        return this;
    }

    public addFixedColumnFilter(columnId: string, columnFilter: TableColumnFilter) {
      this._tableConfig.fixedFiltersColumns.push({
        columnId: columnId,
        columnFilter: columnFilter
      });
      return this;
    }
    public setApiRequestConfigInfoFromInfo(apiRequestConfigInfo: ApiRequestConfigInfoDefinition, body?: object) {
      if (!this._tableConfig.apiRequestConfig) {
        this._tableConfig.apiRequestConfig = {
          info: apiRequestConfigInfo,
          options: {
            handleLoading: true,
            handleErrorNotification: true
          },
          body
        };
      } else {
        this._tableConfig.apiRequestConfig.info = apiRequestConfigInfo;
      }
      return this;
    }

    public build(): SmartTableConfigDefinition {
        return this._tableConfig;
    }


    // Custom Methods
    public  addColumnId() {
        return this.addColumnFromId('columnId');
    }

    public  addColumnSalutation() {
      return this.addColumnFromId('columnSalutation');
    }

    public  addColumnFirstName() {
      return this.addColumnFromId('columnFirstName');
    }
    public  addColumnLastName() {
      return this.addColumnFromId('columnLastName');
    }

    public  addColumnAccountType() {
      return this.addColumnFromId('columnAccountType');
    }

    public  addColumnUserScopes() {
      return this.addColumnFromId('columnUserScopes');
    }

    public  addColumnMarketPartnerNotVisible() {
      return this.addColumnFromId('columnMarketPartnerNotVisible');
    }

    public  addColumnEmail() {
      return this.addColumnFromId('columnEmail');
    }

    public  addColumnUserStatus() {
      return this.addColumnFromId('columnUserStatus');
    }

    public addRowActionModifyMarketPartnerUser() {
      return this.addRowActionFromId('rowActionEditMarketPartnerUser');
    }

    public addRowActionActivateUser() {
      return this.addRowActionFromId('rowActionActivateUser');
    }

    public addRowActionDeactivateUser() {
      return this.addRowActionFromId('rowActionDeactivateUser');
    }

    public addRowActionModifyOperatorRole() {
      return this.addRowActionFromId('rowActionModifyOperatorRole');
    }

    public addRowActionDeleteGlobalUser() {
      return this.addRowActionFromId('rowActionDeleteGlobalUser');
    }

    public addRowActionDeleteMarketPartnerUser() {
      return this.addRowActionFromId('rowActionDeleteMarketPartnerUser');
    }

    public addRowActionViewMarketPartner() {
      return this.addRowActionFromId('rowActionViewMarketPartner');
    }

    public addRowActionSetMarketPartnerContract() {
      return this.addRowActionFromId('rowActionSetMarketPartnerContract');
    }

    public addRowActionDeleteMeteringPoints() {
      return this.addRowActionFromId('rowActionDeleteMeteringPoints');
    }
    public addRowActionDeleteMarketPartner() {
      return this.addRowActionFromId('rowActionDeleteMarketPartner');
    }

    public  addColumnDescription() {
        return this.addColumnFromId('columnDescription');
    }
    public  addColumnDescriptor() {
        return this.addColumnFromId('columnDescriptor');
    }
    public  addColumnNumber() {
        return this.addColumnFromId('columnNumber');
    }
    public  addColumnUnit() {
        return this.addColumnFromId('columnUnit');
    }
    public  addColumnValueText() {
        return this.addColumnFromId('columnValueText');
    }

    public  addColumnCreatedDate() {
      return this.addColumnFromId('columnCreatedDate');
    }

    public  setFixedLargeHeightScroller() {
        return this.setHeightScrollerCSSClass('fixed-large-scrollheight');
    }

    public  flagColumnIdAsRefId() {
        return this.setColumnNameForId('id');
    }

    public  flagColumnMessagesLogUUIDsRefId() {
      return this.setColumnNameForId('uuid');
    }

    public  addColumnMessagesLogUUID() {
      return this.addColumnFromId('columnMessagesLogUUID');
    }

    public  addColumnMessagesLogTimestampIn() {
      return this.addColumnFromId('columnMessagesLogTimestampIn');
    }

    public  addColumnMessagesLogTimestampOut() {
      return this.addColumnFromId('columnMessagesLogTimestampOut');
    }

    public  addColumnMessagesLogSenderEIC() {
      return this.addColumnFromId('columnMessagesLogSenderEIC');
    }

    public  addColumnMessagesLogRecipientEIC() {
      return this.addColumnFromId('columnMessagesLogRecipientEIC');
    }

    public  addColumnMessagesLogMessageId() {
      return this.addColumnFromId('columnMessagesLogMessageId');
    }

    public  addColumnMessagesLogParentId() {
      return this.addColumnFromId('columnMessagesLogParentId');
    }

    public  addColumnMessagesLogProcessId() {
      return this.addColumnFromId('columnMessagesLogProcessId');
    }

    public  addColumnMessagesLogProcessTypeCode() {
      return this.addColumnFromId('columnMessagesLogProcessTypeCode');
    }

    public  addColumnMessagesLogMessageTypeCode() {
      return this.addColumnFromId('columnMessagesLogMessageTypeCode');
    }

    public  addColumnMessagesLogMessageStatus() {
      return this.addColumnFromId('columnMessagesLogMessageStatus');
    }

    public  addColumnMarketPartnerEIC() {
      return this.addColumnFromId('columnMarketPartnerEIC');
    }

    public  addColumnMarketPartnerName() {
      return this.addColumnFromId('columnMarketPartnerName');
    }

    public  addColumnMarketPartnerSDATRole() {
      return this.addColumnFromId('columnMarketPartnerSDATRole');
    }

    public  addColumnMarketPartnerContractStatus() {
      return this.addColumnFromId('columnMarketPartnerContractStatus');
    }

    public  addRowClassReadTag() {
      return this.addRowClassFromId('rowClassesReadTag');
    }
}
