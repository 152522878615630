import {InjectionToken} from '@angular/core';
import {IAppConfig} from './iapp.config';
export let APP_CONFIG = new InjectionToken('app.config');

export const AppConfig: IAppConfig = {
  title: 'Swisseldex Datahub',
  routes: {
    users: '/users',
    login: '/account/login',

    admin_marketpartner_communication_list: '/users/admin/marketpartners',
    admin_marketpartner_communication_webclient: '/users/admin/marketpartners/{partnerId}/communication/webclient',
    admin_marketpartner_communication_mpd: '/users/admin/marketpartners/{partnerId}/communication/mpd',
    admin_marketpartner_users: '/users/admin/marketpartners/{partnerId}/users',
    admin_marketpartner_communication_data: '/users/admin/marketpartners/{partnerId}/communication/data',
    admin_marketpartner_communication_ftp: '/users/admin/marketpartners/{partnerId}/communication/ftp',
    admin_marketpartner_ostral: '/users/admin/marketpartners/{partnerId}/ostral',
    admin_marketpartner_reports: '/users/admin/marketpartners/{partnerId}/reports',
    admin_marketpartner_notifications: '/users/admin/marketpartners/{partnerId}/notifications',
    admin_marketpartner_notifications_info: '/users/admin/marketpartners/{partnerId}/notifications/{messageUuid}',
    admin_marketpartner_ostral_gp_info: '/users/admin/marketpartners/{partnerId}/ostral/{mpId}/{gpId}',

    admin_companypartners: '/users/admin/companypartners',
    admin_companypartner_users: '/users/admin/companypartners/{partnerId}/users',
    admin_companypartner_meteringpoints: '/users/admin/companypartners/{partnerId}/metering-points',

    change_processes_create: '/users/change-processes/create/{processType}',
    change_processes_info: '/users/change-processes/{processUuid}',
    notifications: '/users/notifications',
    notifications_info: '/users/notifications/{messageUuid}',
    metering_points: '/users/metering-points',
    metering_points_info: '/users/metering-points/{mpAdminEic}/{mpId}',
    ostral_gp_info: '/users/ostral/{mpId}/{gpId}',
  },
  buildNumber: 1001
};
