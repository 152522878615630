import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SmartTableComponent} from "./smart-forms/smart-table/component/smart-table.component";
import {SmartFormComponent} from "./smart-forms/smart-form/component/smart-form.component";
import {SmartModalComponent} from "./smart-forms/smart-modal/component/smart-modal.component";
import {
  ActionsToolbarComponent
} from "./smart-forms/actions-toolbar/actions-toolbar.component";
import {PageLoadingComponent} from "./page-loading/page-loading.component";
import {ShowHidePasswordDirective} from "./showHidePassword/show-hide-password.directive";
import {SharedModule} from "../modules/shared.module";
import {ClipboardModule} from "ngx-clipboard";
import {MaterialModule} from "../material-components/material.module";



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ClipboardModule
  ],
  declarations: [
    SmartTableComponent,
    SmartFormComponent,
    SmartModalComponent,
    ActionsToolbarComponent,
    PageLoadingComponent,
    ShowHidePasswordDirective
  ],
  exports: [
    SmartTableComponent,
    SmartFormComponent,
    SmartModalComponent,
    ActionsToolbarComponent,
    PageLoadingComponent,
    ShowHidePasswordDirective
  ]
})
export class MarketpartnerModule { }
