<div class="ack-of-acc"
     *ngIf="ackOfAcceptance">
  <i class="fa fa-check-circle"></i>
</div>
<smart-form class="category-select-checkbox-form"
  #formHeader
  *ngIf="formConfigHeader"
  [formConfig]="formConfigHeader"
></smart-form>
<hr/>
<smart-form class="category-select-checkbox-form"
  #formBody
  *ngIf="formConfigBody"
  [formConfig]="formConfigBody"
></smart-form>
