/* eslint-disable max-len */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UsersDashboardComponent} from './users-dashboard/users-dashboard.component';
import {AdminUsersComponent} from './admin-users/admin-users.component';
import {MarketpartnerCommunicationMpdComponent} from './users-marketpartner-communication-mpd/users-marketpartner-communication-mpd.component';
import {MarketpartnerCommunicationFtpComponent} from './users-marketpartner-communication-ftp/users-marketpartner-communication-ftp.component';
import {MarketpartnerCommunicationDataComponent} from './users-marketpartner-communication-data/users-marketpartner-communication-data.component';
import {AdminUsersMarketPartnersComponent} from './admin-marketpartners/admin-marketpartners.component';
import {ContactMarketpartnersComponent} from './users-contact-marketpartners/users-contact-marketpartners.component';
import {ChangeProcessesComponent} from './users-change-processes/users-change-processes.component';
import {ReportsComponent} from './users-reports/users-reports.component';
import {NotificationsComponent} from './users-notifications/users-notifications.component';
import {NotificationsInfoComponent} from './users-notifications-info/users-notifications-info.component';
import {MeteringPointsComponent} from './users-metering-points/users-metering-points.component';
import { MeteringPointsInfoComponent } from './users-metering-points-info/users-metering-points-info.component';
import { ChangeProcessesInfoComponent } from './users-change-processes-info/users-change-processes-info.component';
import { MarketpartnerCommunicationWebclientComponent } from './users-marketpartner-communication-webclient/users-marketpartner-communication-webclient.component';
import { FeatureGuard } from './shared/feature.guard';
import { AdminAnnouncementsComponent } from './admin-announcements/admin-announcements.component';
import {OstralComponent} from "./users-ostral/users-ostral.component";
import {OstralInfoComponent} from "./users-ostral-info/users-ostral-info.component";
import {AdminCompanyPartnersComponent} from "./admin-company-partners/admin-company-partners.component";
import {
  AdminCompanyPartnersMeteringpointsComponent
} from "./admin-company-partners-meteringpoints/admin-company-partners-meteringpoints.component";
import {AdminOstralComponent} from "./admin-ostral/admin-ostral.component";


const usersRoutes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'dashboard', component: UsersDashboardComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/users', component: AdminUsersComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/marketpartners', component: AdminUsersMarketPartnersComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/announcements', component: AdminAnnouncementsComponent, pathMatch: 'full', canActivate: [FeatureGuard]},

  {path: 'admin/ostral', component: AdminOstralComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/companypartners', component: AdminCompanyPartnersComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/companypartners/:partnerId/metering-points', component: AdminCompanyPartnersMeteringpointsComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/companypartners/:partnerId/users', component: AdminUsersComponent, pathMatch: 'full', canActivate: [FeatureGuard]},

  {path: 'communication/data', component: MarketpartnerCommunicationDataComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'communication/mpd', component: MarketpartnerCommunicationMpdComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'communication/webclient', component: MarketpartnerCommunicationWebclientComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'communication/ftp', component: MarketpartnerCommunicationFtpComponent, pathMatch: 'full', canActivate: [FeatureGuard]},

  {path: 'admin/marketpartners/:partnerId/communication/data', component: MarketpartnerCommunicationDataComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/marketpartners/:partnerId/communication/webclient', component: MarketpartnerCommunicationWebclientComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/marketpartners/:partnerId/communication/mpd', component: MarketpartnerCommunicationMpdComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/marketpartners/:partnerId/communication/ftp', component: MarketpartnerCommunicationFtpComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/marketpartners/:partnerId/users', component: AdminUsersComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/marketpartners/:partnerId/ostral', component: OstralComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/marketpartners/:partnerId/ostral/:mpId/:gpId', component: OstralInfoComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/marketpartners/:partnerId/reports', component: ReportsComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/marketpartners/:partnerId/notifications', component: NotificationsComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'admin/marketpartners/:partnerId/notifications/:messageUuid', component: NotificationsInfoComponent, pathMatch: 'full', canActivate: [FeatureGuard]},

  {path: 'change-processes', component: ChangeProcessesComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'change-processes/:processUuid', component: ChangeProcessesInfoComponent, pathMatch: 'full', canActivate: [FeatureGuard]},

  {path: 'reports', component: ReportsComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'notifications', component: NotificationsComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'notifications/:messageUuid', component: NotificationsInfoComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'metering-points', component: MeteringPointsComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'metering-points/:mpAdminEic/:mpId', component: MeteringPointsInfoComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'ostral', component: OstralComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'ostral/:mpId/:gpId', component: OstralInfoComponent, pathMatch: 'full', canActivate: [FeatureGuard]},

  {path: 'marketpartners', component: ContactMarketpartnersComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
];

@NgModule({
  imports: [
    RouterModule.forChild(usersRoutes)
  ],

  exports: [
    RouterModule
  ]
})

export class UsersRoutingModule {
}
