import { Injectable} from '@angular/core';
import {escape} from 'lodash-es';
import {UtilsService} from './utils.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationMessage} from '../marketpartner-components/smart-forms/smart-shared/classes/NotificationMessage';

declare var $: any; // niftyNoty will not work if you change it back to -> import * as $ from 'jquery';
@Injectable()
export class NotificationsService {
  constructor(
    private translate: TranslateService,
    private utilsService: UtilsService,
  ) {
  }

  hideNotification(notificationId: string) {
    $( '.' + notificationId ).fadeOut( function () {
      // @ts-ignore
      $(this).remove();
    } );
  }

  showNotification(options: NotificationMessage) {
    const thisNotificationId = 'notification-' + this.utilsService.getRandomUID();
    const delayShowMs =  options.delayShowMs ? options.delayShowMs : 0;
    const self = this;

    let closeBtnToUse = true;
    let iconToUse = '';
    let typeToUse = options.type ? options.type : 'info';
    let titleToUse = '';
    let typeNiftyNotifications = '';
    let timerToUse = 2000;
    const messageToUse = options.message;

    switch (typeToUse) {
      case 'loading':
        titleToUse = 'NotificationCommunicatingServer';
        closeBtnToUse = false;
        typeToUse = 'info';
        iconToUse = 'pli-loading-2 icon-2x fa-spin';
        timerToUse = 1000000;
        typeNiftyNotifications = 'loading';
        break;
      case 'error':
        titleToUse =  'Error';
        timerToUse = 5000;
        iconToUse =  'pli-exclamation icon-2x';
        typeNiftyNotifications = 'danger';
        break;
      case 'success':
        titleToUse =  'Success';
        timerToUse = 5000;
        iconToUse =  'pli-exclamation icon-2x';
        typeNiftyNotifications = 'success';
        break;
    }

    // Nifty Template niftyNoty plugin:
    // Check documentation under 'doc\NiftyOfflineDoc' OR
    // http://preview.wdphp.com/52/1402/documentation/
    // @ts-ignore
    $.niftyNoty({
      type: typeNiftyNotifications ,
      icon : iconToUse,
      container : 'floating',
      floating    : {
        position    : 'top-right',
        animationIn : 'jellyIn ' + thisNotificationId + ' ' + (delayShowMs > 0 ? ' hidden ' : ''),
        animationOut: 'fadeOut'
      },
      // Note: The .html attribute is just to control full layout;
      //       niftyNoty does not html-escape .title and .message!
      title : escape(self.translate.instant(titleToUse)),
      message : escape(self.translate.instant(messageToUse)),
      closeBtn : closeBtnToUse,
      timer : timerToUse,
      // html : '<strong>Well done!</strong> You successfully read this important alert message.',
    });

    if (delayShowMs > 0) {
      setTimeout(() =>  {
        $( '.' + thisNotificationId ).removeClass('hidden');
      }, delayShowMs);
    }

    return thisNotificationId;
  }
}
