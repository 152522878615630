import {inject} from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, UrlTree} from '@angular/router';
import {SessionService} from './session.service';
import {Observable} from "rxjs";


export const AuthGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree =>
{
  const sessionService = inject(SessionService);
  const router = inject(Router);
  return sessionService.isAuthenticated().then(isAuthenticated =>
  {
    if (isAuthenticated) {
      return true;
    }

    console.log('not authenticated, navigating to login');
    router.navigate(['/account/login']);
    return false;
  });
}
