<page-loading *ngIf="!initialized"></page-loading>

<div *ngIf="initialized" id="page-content">
  <div class="row">
    <div class="col-sm-12" style="max-width: 50em;">
      <div class="panel">
        <div class="panel-heading">
          <h3 class="panel-title">E-Mail Announcement</h3>
        </div>
        <form class="form-horizontal" [formGroup]="form" (ngSubmit)="onSubmit(form.value)" >
          <div class="panel-body">
            <div class="form-group">
              <label class="control-label text-semibold">Betreff</label>
              <input type="text" class="form-control" formControlName="subject" name="subject" autofocus
                spellcheck="true"
                placeholder="Deutscher Betreff">
            </div>
            <div class="form-group">
              <label class="control-label text-semibold">Erster Block (wird im Mail fett dargestellt)</label>
              <textarea class="form-control" formControlName="body1" name="body1"
                spellcheck="true" rows="5"
                placeholder="Deutscher Text"></textarea>
            </div>
            <div class="form-group">
              <label class="control-label text-semibold">Zweiter Block</label>
              <textarea class="form-control" formControlName="body2" name="body2"
                spellcheck="true" rows="5"
                placeholder="Dies ist der Haupttext. Er wird zu einem einzigen Abschnitt, Leerzeilen werden ignoriert.

Alle drei Textabschnitte werden von DeepL übersetzt. UTF-8 Symbole gehen, HTML scheinbar auch, aber Markdown geht nicht."></textarea>
            </div>
          </div>
          <div class="panel-footer text-right">
            <button class="btn btn-success" type="submit" [disabled]="!form.valid">Emails zum download erzeugen</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
