import {EnumDefinition} from '../classes/enum-definition';
import { base } from '../../../messaging-grpc/messaging-grpc';

export const SDAT_PROCESS_DIRECTION: EnumDefinition<keyof typeof base.Direction> = {
  id: 'sdatprocessdirection',
  items: [
    {label: '-', data: 'DIRECTION_UNSPECIFIED', omitFromDropdown: true},
    {label: 'SDATProcessDirection.OUTGOING', data: 'OUTGOING'},
    {label: 'SDATProcessDirection.INCOMING', data: 'INCOMING'},
  ]
};
