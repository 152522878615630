import {EnumDefinition, EnumSortType} from '../classes/enum-definition';
import {USER_SCOPES } from './user-scopes';
import {SDAT_ROLES} from './sdat-roles';
import {SDAT_BUSINESS_REASON, SDAT_BUSINESS_REASON_WEBCLIENT} from './sdat-business-reason';
import {CONTRACT_STATUS} from './contract-status';
import {MESSAGE_CLASS} from './message-class';
import {MESSAGE_STATUS} from './message-status';
import {TRANSACTION_TYPE} from './transaction-type';
import {SDAT_PROCESS_DIRECTION} from './sdat-process-direction';

export const ENUM_LIST: EnumDefinition[] = [
  USER_SCOPES,
  SDAT_ROLES,
  SDAT_BUSINESS_REASON,
  SDAT_BUSINESS_REASON_WEBCLIENT,
  SDAT_PROCESS_DIRECTION,
  CONTRACT_STATUS,
  MESSAGE_CLASS,
  MESSAGE_STATUS,
  TRANSACTION_TYPE,
  {
    id: 'sdatforall',
    items: [
      {label: 'LabelSDATUseWebClientForAll', data: '1'},
    ]
  },
  {
    id: 'usechangeprocessshortcuts',
    items: [
      {label: 'LabelUseChangeProcessShortcuts', data: '1'},
    ]
  },
  {
    id: 'meteringPointsFlagCheckbox',
    items: [
      {label: 'MeteringPointDirectoryCompletenessLabel', data: '1'},
    ]
  },
  {
    id: 'MeteringPointDirectoryCompletenessValue',
    items: [
      {label: '-', data: 'METERING_POINTS_COMPLETE_FLAG_UNSPECIFIED'},
      {label: 'MeteringPointDirectoryCompletenessValue.COMPLETE', data: 'COMPLETE'},
      {label: 'MeteringPointDirectoryCompletenessValue.INCOMPLETE', data: 'INCOMPLETE'},
    ]
  },
  {
    id: 'languages',
    items: [
      {label: 'LabelLanguageGerman', data: 'de'},
      // {label: 'LabelLanguageEnglish', data: 'en'},  // not allowed currently (no translations)
      {label: 'LabelLanguageFrench', data: 'fr'},
      {label: 'LabelLanguageItalian', data: 'it'}
    ]
  },
  {
    id: 'userStatus',
    items: [
      {label: 'UserStatusInactive', data: '0'},
      {label: 'UserStatusActive', data: '1'}
    ]
  },
  {
    id: 'yesOrNo',
    items: [
      {label: 'LabelYesornoNo', data: '0'},
      {label: 'LabelYesornoYes', data: '1'}
    ]
  },
  {
    id: 'checkedOrNot',
    items: [
      {label: '', data: '1'}
    ]
  },
  {
    id: 'accountGlobalType',
    items: [
      {label: 'LabelAccountMarketPartner', data: 'MARKETPARTNER'},
      {label: 'LabelAccountOperatorUser', data: 'OPERATORUSER'},
      {label: 'LabelAccountOperatorAdmin', data: 'OPERATORADMIN'},
      {label: 'LabelAccountCompanyPartner', data: 'COMPANYPARTNER'},
    ]
  },
  {
    id: 'accountGlobalTypeWithoutMarketPartner',
    items: [
      {label: 'LabelAccountOperatorUser', data: 'OPERATORUSER'},
      {label: 'LabelAccountOperatorAdmin', data: 'OPERATORADMIN'}
    ]
  },
  {
    id: 'salutation',
    items: [
      {label: 'LabelSalutationMr', data: 'MR'},
      {label: 'LabelSalutationMrs', data: 'MRS'},
    ],
  },
  {
    id: 'salutationEch',
    items: [
      {label: 'LabelSalutationMrs', data: 'FRAU'},
      {label: 'LabelSalutationMr', data: 'HERR'},
      {label: 'LabelSalutationMiss', data: 'FRAEULEIN'},
    ],
  },
  {
    id: 'reportfileformat',
    items: [
      {label: 'Reports.LabelFileFormat.XLS', data: 'XLS'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'reporttype',
    items: [
      {label: 'Reports.LabelType.STATISTICS', data: 'STATISTICS'},
      {label: 'Reports.LabelType.TRANSFER_LOG', data: 'TRANSFER_LOG'},
    ]
  },
  {
    id: 'sdvMarketpartners',
    items: [] // Will be filled later on
  },
  {
    id: 'lfMarketpartners',
    items: [] // Will be filled later on
  },
  {
    id: 'vnbMarketpartners',
    items: [] // Will be filled later on
  },
  {
    id: 'bgvMarketpartners',
    items: [] // Will be filled later on
  },
  {
    id: 'gridbillingmethod',
    items: [
      {label: 'LabelGridBillingMethod.GRID_BILLING_METHOD_UNSPECIFIED', data: 'GRID_BILLING_METHOD_UNSPECIFIED', omitFromDropdown: true},
      {label: 'LabelGridBillingMethod.DSO', data: 'DSO'},
      {label: 'LabelGridBillingMethod.SUPPLIER', data: 'SUPPLIER'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'meteringpointtype',
    items: [
      {label: 'LabelMeteringPointType.METERING_POINT_TYPE_UNSPECIFIED', data: 'METERING_POINT_TYPE_UNSPECIFIED', omitFromDropdown: true},
      {label: 'LabelMeteringPointType.CONSUMPTION', data: 'CONSUMPTION'},
      {label: 'LabelMeteringPointType.PRODUCTION', data: 'PRODUCTION'},
      {label: 'LabelMeteringPointType.EXCHANGE', data: 'EXCHANGE'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'meteringmethod',
    items: [
      {label: 'LabelMeteringMethod.METERING_METHOD_UNSPECIFIED', data: 'METERING_METHOD_UNSPECIFIED', omitFromDropdown: true},
      {label: 'LabelMeteringMethod.CONTINUOUS', data: 'CONTINUOUS'},
      {label: 'LabelMeteringMethod.NON_CONTINUOUS', data: 'NON_CONTINUOUS'},
      {label: 'LabelMeteringMethod.NOT_METERED', data: 'NOT_METERED'},
      {label: 'LabelMeteringMethod.CALCULATED', data: 'CALCULATED'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'settlementmethod',
    items: [
      {label: 'LabelSettlementMethod.SETTLEMENT_METHOD_UNSPECIFIED', data: 'SETTLEMENT_METHOD_UNSPECIFIED', omitFromDropdown: true},
      {label: 'LabelSettlementMethod.PROFILED', data: 'PROFILED'},
      {label: 'LabelSettlementMethod.NON_PROFILED', data: 'NON_PROFILED'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'exporttype',
    items: [
      {label: 'MeteringPoints.ExportConfigDialog.ExportTypeEnum.Events', data: 'events'},
      {label: 'MeteringPoints.ExportConfigDialog.ExportTypeEnum.State', data: 'state'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'zipcodetype',
    items: [
      {label: 'LabelZipCodeType.UNSPECIFIED', data: 'UNSPECIFIED', omitFromDropdown: true},
      {label: 'LabelZipCodeType.CH', data: 'CH'},
      {label: 'LabelZipCodeType.FOREIGN', data: 'FOREIGN'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'mailaddressinfotype',
    items: [
      {label: 'LabelMailAddressInfoType.UNSPECIFIED', data: 'UNSPECIFIED', omitFromDropdown: true},
      {label: 'LabelMailAddressInfoType.PERSON', data: 'PERSON'},
      {label: 'LabelMailAddressInfoType.ORGANISATION', data: 'ORGANISATION'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'basecategory',
    items: [
      {label: 'LabelBaseCategory.UNSPECIFIED', data: 'UNSPECIFIED'},
      {label: 'LabelBaseCategory.PRIVATE', data: 'PRIVATE'},
      {label: 'LabelBaseCategory.BUSINESS', data: 'BUSINESS'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'phonebasecategory',
    items: [
      {label: 'LabelBasePhoneCategory.UNSPECIFIED', data: 'PHONE_CATEGORY_UNSPECIFIED'},
      {label: 'LabelBasePhoneCategory.PRIVATE_PHONE', data: 'PRIVATE_PHONE'},
      {label: 'LabelBasePhoneCategory.PRIVATE_MOBILE', data: 'PRIVATE_MOBILE'},
      {label: 'LabelBasePhoneCategory.PRIVATE_FAX', data: 'PRIVATE_FAX'},
      {label: 'LabelBasePhoneCategory.PRIVATE_INTERNET_VOICE', data: 'PRIVATE_INTERNET_VOICE'},
      {label: 'LabelBasePhoneCategory.BUSINESS_CENTRAL', data: 'BUSINESS_CENTRAL'},
      {label: 'LabelBasePhoneCategory.BUSINESS_DIRECT', data: 'BUSINESS_DIRECT'},
      {label: 'LabelBasePhoneCategory.BUSINESS_MOBILE', data: 'BUSINESS_MOBILE'},
      {label: 'LabelBasePhoneCategory.BUSINESS_FAX', data: 'BUSINESS_FAX'},
      {label: 'LabelBasePhoneCategory.BUSINESS_INTERNET_VOICE', data: 'BUSINESS_INTERNET_VOICE'},
      {label: 'LabelBasePhoneCategory.PAGER', data: 'PAGER'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'contractterminationunit',
    items: [
      {label: 'LabelContractTerminationUnit.UNSPECIFIED', data: 'CONTRACT_TERMINATION_UNIT_UNSPECIFIED', omitFromDropdown: true},
      {label: 'LabelContractTerminationUnit.DAY', data: 'DAY'},
      {label: 'LabelContractTerminationUnit.MONTH', data: 'MONTH'},
    ],
    sortType: EnumSortType.Original
  },
  {
    id: 'registrationStatus',
    items: [
      {label: 'Ostral.Company.REGISTRATION_STATUS_UNSPECIFIED', data: 'REGISTRATION_STATUS_UNSPECIFIED'},
      {label: 'Ostral.Company.UNREGISTERED', data: 'UNREGISTERED'},
      {label: 'Ostral.Company.INVITED', data: 'INVITED'},
      {label: 'Ostral.Company.REGISTERED', data: 'REGISTERED'},
      {label: 'Ostral.Company.EXPIRED', data: 'EXPIRED'},
    ]
  }
];
