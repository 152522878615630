<span *ngIf="address.addressLine1 as line">{{ line }}<br/></span>
<span *ngIf="address.addressLine2 as line">{{ line }}<br/></span>
<span *ngIf="address.localisation as al">
  {{al.street}} {{al.houseNumber}} {{al.dwellingNumber}}<br>
</span>
<span *ngIf="address.postOfficeBox as pob">{{ pob.number }} {{ pob.text  }}<br/></span>
{{ address.locality }}
<span *ngIf="address.zipCode?.swiss as zip">
  {{ zip.code }} {{ zip.codeAddOn }} {{ zip.codeId }}
</span>
<span *ngIf="address.zipCode?.foreign as zip">
  {{ zip.code }}
</span>
{{address.town}}<br>
{{address.country.nameShort}}
