import {Component, OnInit} from '@angular/core';
import {ApiRequestBuilder} from "../../shared/api-request/builder/api-request.builder";
import {NAV_API_ENDPOINTS_LIST} from "../../shared/nav/api-endpoints-list";
import {CompanypartnersBaseComponent} from "../companypartners-base.component";
import {PartnerInfo} from "../../shared/session/SessionInfo";

@Component({
  selector: 'nav-dropdown',
  templateUrl: './nav-dropdown.component.html',
  styleUrls: ['./nav-dropdown.component.scss']
})
export class NavDropdownComponent extends CompanypartnersBaseComponent implements OnInit {

  protected companyName = '';
  protected otherCompanies: PartnerInfo[] = [];
  async onLogoutClicked() {
    const apiRequestConfig = new ApiRequestBuilder()
      .setEndpointInfo(NAV_API_ENDPOINTS_LIST.logout)
      .setHandleLoading(true)
      .setHandleErrorNotification(false)  // we don't want to see e.g. "your session has expired"
      .build();

    // if the server doesn't answer, still remove our tokens from localstorage
    try {
      const logoutPromise = this.requestService.callApi(apiRequestConfig);
      const timeout = new Promise(resolve => setTimeout(resolve, 5000));
      const result = await Promise.race([logoutPromise, timeout]);
      if (result && result.status && result.status !== 'success') {
        console.error(result);
        // await new Promise(resolve => setTimeout(resolve, 2000));  // for testing
      }
    } finally {
      this.sessionService.logout();
    }
  }

  async switchCompany(partnerId: number) {
    try {
      // this will trigger a page reload
      await this.sessionService.changePartner(partnerId);
    } catch (error) {
      const errorCodeString = await this.requestService.convertApiErrorToString(error);
      this.openSnackbar(errorCodeString);
    }
  }

  ngOnInit(): void {
    this.companyName = this.sessionService.getCurrentPartnerName() || '';
    this.otherCompanies = this.sessionService.getPartnerList().filter(p => p.partnerName != this.companyName);
  }
}
