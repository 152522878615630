/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';
import { MarketpartnerViewComponent } from '../shared/marketpartner-view.component';
import { ApiRequestBuilder } from '../../shared/api-request/builder/api-request.builder';
import { SessionService } from '../../shared/session/session.service';
import { MarketPartnerService } from '../../shared/services/marketpartner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestService } from '../../shared/api-request/service/api-request.service';
import { USERS_API_ENDPOINTS_LIST } from '../requests/api-endpoints-list';
import { processingcore, sdatwebclient } from '../../shared/messaging-grpc/messaging-grpc';
import {sortBy} from "lodash-es";

export interface LastResponses {
  lastConfirmationRequest?: processingcore.ConfirmationRequest | undefined;
  lastRejection?: sdatwebclient.IDocumentDetails | undefined;
}

@Component({
  selector: 'users-change-processes-info',
  templateUrl: './users-change-processes-info.component.html',
  styleUrls: ['../users-common.scss']
})
export class ChangeProcessesInfoComponent extends MarketpartnerViewComponent {
  processUuid = '';
  initialized = false;
  messages: any[] = [];

  lastResponses: LastResponses = {
    lastConfirmationRequest: undefined,
    lastRejection: undefined
  };

  constructor(public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              public apiRequestService: ApiRequestService,
              public router: Router) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);

    if (this.partnerId !== undefined && params.processUuid) {
      this.processUuid = params.processUuid;
      this.reload();
    }
  }

  async reload() {
    this.setInitialized(false);
    const response = await this.apiRequestService.callApi(
      new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.changeProcessInfo)
        .setBodyInfo({
          uuid: this.processUuid,
          partnerId: this.partnerId
        })
        .setHandleErrorNotification(false).build()  // see below for why we don't report errors
    );
    if (response.status !== 'success') {
      // e.g. when switching to a different partner (or using an old bookmark), the
      // backend doesn't return "access denied" or similar, just 500 "internal server error"
      this.goBack();
      return;
    }
    this.messages = sortBy(response.data.messages, message => {
      const ts = message.document.messageMetadata.timestamp;
      if (!ts) throw new Error('cannot sort message without metadata timestamp');
      return new Date(ts);
    }).reverse();
    this.lastResponses.lastRejection = this.messages.filter(m => m.document.direction === 'OUTGOING' && m.document.messageClass === 'REJECTED')
      .sort(m => m.document.messageMetadata.timestamp).find(x => x !== undefined);

    const lastConfirmation = this.messages.filter(m => m.document.direction === 'OUTGOING' && m.document.messageClass === 'CONFIRMED')
      .sort(m => m.document.messageMetadata.timestamp).find(x => x !== undefined);

    if (!!lastConfirmation) {
      if (lastConfirmation?.document?.businessReason === 'QUERY_METERING_POINT_INFORMATION') {
        this.lastResponses.lastConfirmationRequest = { mpInfo: this.extractQueryMpInfo(lastConfirmation)};
      }
      else if (lastConfirmation?.document?.businessReason === 'QUERY_SWITCH_INFORMATION') {
        this.lastResponses.lastConfirmationRequest = { switchInfo: this.extractQuerySwitchInfo(lastConfirmation)};
      }
      else {
        const apiMarketPartnerConfig = new ApiRequestBuilder()
          .setEndpointInfo(USERS_API_ENDPOINTS_LIST.retrievePreviousConfirmation)
          .setBodyInfo({partnerId: this.partnerId, processId: lastConfirmation?.content?.document?.header?.processId})
          .setHandleLoading(true)
          .setHandleErrorNotification(true)
          .build();

        const resultApiRequest = await this.apiRequestService.callApi(apiMarketPartnerConfig);

        if (resultApiRequest.status === 'success') {
          this.lastResponses.lastConfirmationRequest = resultApiRequest.data;
        }
      }
    }

    this.setInitialized(true);
    this.markProcessAsRead();
    console.log('SDAT messages:', this.messages);
  }

  async markProcessAsRead() {
    const apiRequestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.setProcessRead)
      .setBodyInfo({read: true, id: {uuid: this.processUuid, partnerId: this.partnerId}})
      .build();
    await this.apiRequestService.callApi(apiRequestConfig);
    this.marketPartnerService.updateUnreadProcessesCount();
  }

  goBack() {
    this.router.navigate(['../'], {relativeTo: this.activatedRoute});
  }

  private extractQueryMpInfo(lastConfirmation: any): any {
    return {
      characteristics: lastConfirmation?.content?.document?.body?.mpCharacteristics,
      address: lastConfirmation?.content?.document?.body?.mpAddress,
      parties: lastConfirmation?.content?.document?.body?.parties ?? []
    };
  }

  private extractQuerySwitchInfo(lastConfirmation: any): any {
    return {
      contractTerminationInfo: lastConfirmation?.content?.document?.body?.contractTerminationInfo,
    };
  }
}
