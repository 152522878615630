import { SmartFormFormFieldDefinition, SmartFormShowConditionOperator } from '../classes/SmartFormFormFieldDefinition';
import { NameValueMap } from '../../smart-shared/classes/KeyValueMap';

export const SMART_FORM_OBJECTSARRAY_DEFS: NameValueMap<SmartFormFormFieldDefinition[]> = {
  'consumerObject': [
    {
      title: 'SDATDialogs.FormFields.Contact',
      type: 'heading',
      name: 'contact:title',
      omitFromRequest: true,
      readOnly: false,
      validation: {
        required: false
      },
    },
    // {
    //   type: 'object',
    //   objectId: 'namedPersonIdObject',
    //   name: 'localId',
    //   readOnly: false,
    //   validation: {
    //     required: false
    //   },
    // },
    {
      title: 'SDATDialogs.FormFields.AddressInformation',
      type: 'array',
      objectId: 'addressInformationContactObject',
      name: 'address',
      readOnly: false,
      validation: {
        required: true,
        type: 'contact1address'
      },
    },
    {
      title: 'SDATDialogs.FormFields.Email.Title',
      type: 'array',
      objectId: 'emailObject',
      name: 'email',
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      title: 'SDATDialogs.FormFields.Phone',
      type: 'array',
      objectId: 'phoneObject',
      name: 'phone',
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      title: 'SDATDialogs.FormFields.Internet',
      type: 'array',
      objectId: 'internetObject',
      name: 'internet',
      readOnly: false,
      validation: {
        required: false
      },
    }
  ],
  /* namedPersonID is not given in pdf, just in schema.
  'namedPersonIdObject': [
    {
      title: 'FormSpecifyPersonID',
      type: 'checkboxradio',
      name: 'checkbox',
      omitFromRequest: true,
      valueIsBoolean: true,
      default: false,
      cssClasses: 'col-sm-12',
      validation: {
        required: true,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'yesOrNo'
      }
    },
    {
      title: 'SDATDialogs.FormFields.Category',
      type: 'input',
      name: 'category',
      cssClasses: 'col-xs-6',
      validation: {
        required: true,
        upperLimit: 50
      },
      showConditions: [{
        fieldName: 'checkbox',
        operator: SmartFormShowConditionOperator.Equals,
        value: '1'
      }],
    },
    {
      title: 'SDATDialogs.FormFields.Id',
      type: 'input',
      name: 'id',
      cssClasses: 'col-xs-6',
      validation: {
        required: true,
        upperLimit: 50
      },
      showConditions: [{
        fieldName: 'checkbox',
        operator: SmartFormShowConditionOperator.Equals,
        value: '1'
      }],
    },
  ],
*/
  'addressInformationContactObject': [
    {
      type: 'object',
      objectId: 'mailAddressObject',
      name: 'item',
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      type: 'object',
      objectId: 'categoryObject',
      name: 'category',
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      type: 'object',
      objectId: 'validityObject',
      name: 'validity',
      readOnly: false,
      validation: {
        required: false
      },
    }
  ],
  'mailAddressObject': [
    {
      type: 'object',
      objectId: 'mailAddressInfoObject',
      name: 'info',
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      type: 'object',
      objectId: 'addressInformationObject',
      name: 'address',
      readOnly: false,
      validation: {
        required: false
      },
    }
  ],
  'mailAddressInfoObject': [
    {
      title: 'SDATDialogs.FormFields.MailAddressInfoType',
      type: 'select',
      name: 'switch',
      omitFromRequest: true,
      allowedValues: {type: 'globalRegister', data: 'mailaddressinfotype'},
      validation: {
        required: true,
      },
    },
    {
      type: 'object',
      objectId: 'personInformationObject',
      name: 'person',
      readOnly: false,
      validation: {
        required: false
      },
      showConditions: [{
        fieldName: 'switch',
        operator: SmartFormShowConditionOperator.Equals,
        value: 'PERSON'
      }],
    },
    {
      type: 'object',
      objectId: 'organisationInformationObject',
      name: 'organisation',
      readOnly: false,
      validation: {
        required: false
      },
      showConditions: [{
        fieldName: 'switch',
        operator: SmartFormShowConditionOperator.Equals,
        value: 'ORGANISATION'
      }],
    }
  ],
  'personInformationObject': [
    {
      title: 'FormSalutation',
      type: 'select',
      name: 'mrMrs',
      cssClasses: 'col-xs-3',
      allowedValues: {type: 'globalRegister', data: 'salutationEch'},
      validation: {
        required: false
      },
    },
    {
      title: 'MeteringPointEvent.Keys.title',
      type: 'input',
      name: 'title',
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 50
      },
    },
    {
      title: 'FormFirstName',
      type: 'input',
      name: 'firstName',
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 30
      },
    },
    {
      title: 'FormLastName',
      type: 'input',
      name: 'lastName',
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: true,
        upperLimit: 30
      },
    },
  ],
  'organisationPersonInformationObject': [
    {
      title: 'FormSalutation',
      type: 'select',
      name: 'mrMrs',
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      allowedValues: {type: 'globalRegister', data: 'salutationEch'},
      validation: {
        required: false
      },
    },
    {
      title: 'MeteringPointEvent.Keys.title',
      type: 'input',
      name: 'title',
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 50
      },
    },
    {
      title: 'FormFirstName',
      type: 'input',
      name: 'firstName',
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 30
      },
    },
    {
      title: 'FormLastName',
      type: 'input',
      name: 'lastName',
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 30
      },
    },
  ],
  'organisationInformationObject': [
    {
      title: 'FormName',
      type: 'input',
      name: 'name',
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      readOnly: false,
      validation: {
        required: true,
        upperLimit: 60
      },
    },
    {
      title: 'MeteringPointEvent.Keys.nameAddOn1',
      type: 'input',
      name: 'nameAddOn1',
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      readOnly: false,
      validation: {
        required: false,
        upperLimit: 60
      },
    },
    {
      title: 'MeteringPointEvent.Keys.nameAddOn2',
      type: 'input',
      name: 'nameAddOn2',
      cssClasses: 'col-xs-3',
      readonlyDefault: '-',
      readOnly: false,
      validation: {
        required: false,
        upperLimit: 60
      },
    },
    {
      type: 'object',
      objectId: 'organisationPersonInformationObject',
      name: 'person',
      readOnly: false,
      validation: {
        required: false
      }
    },
  ],
  'addressInformationObject': [
    {
      title: 'SDATDialogs.FormFields.AddressInformation',
      type: 'heading',
      name: 'title',
      readonlyDefault: '-',
      omitFromRequest: true,
      cssClasses: 'col-xs-12',
    },
    {
      title: 'SDATDialogs.FormFields.AddressLine1',
      type: 'input',
      name: 'addressLine1',
      readOnly: false,
      cssClasses: 'col-xs-3',
      readonlyDefault: '-',
      validation: {
        required: false,
        upperLimit: 60
      },
    },
    {
      title: 'SDATDialogs.FormFields.AddressLine2',
      type: 'input',
      name: 'addressLine2',
      readOnly: false,
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 60
      },
    },
    {
      title: 'MeteringPointEvent.Keys.localisation',
      type: 'object',
      name: 'localisation',
      objectId: 'localisationObject',
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      title: 'SDATDialogs.FormFields.PostOfficeBox',
      type: 'object',
      name: 'postOfficeBox',
      objectId: 'postOfficeBoxObject',
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      title: 'SDATDialogs.FormFields.Locality',
      type: 'input',
      name: 'locality',
      readOnly: false,
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 40
      },
    },
    {
      title: 'SDATDialogs.FormFields.Town',
      type: 'input',
      name: 'town',
      readOnly: false,
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: true,
        upperLimit: 40
      },
    },
    {
      type: 'object',
      name: 'zipCode',
      objectId: 'zipCodeObject',
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      type: 'object',
      name: 'country',
      objectId: 'countryObject',
      readOnly: false,
      validation: {
        required: true
      },
    },
  ],
  'mpAddressInformationObject': [
    {
      title: 'SDATDialogs.FormFields.AddressInformation',
      type: 'heading',
      name: 'title',
      omitFromRequest: true,
      cssClasses: 'col-xs-12',
    },
    {
      title: 'SDATDialogs.FormFields.AddressLine1',
      type: 'input',
      name: 'addressLine1',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 60
      },
    },
    {
      title: 'SDATDialogs.FormFields.AddressLine2',
      type: 'input',
      name: 'addressLine2',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 60
      },
    },
    {
      title: 'MeteringPointEvent.Keys.localisation',
      type: 'object',
      name: 'localisation',
      objectId: 'localisationObject',
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      title: 'SDATDialogs.FormFields.Locality',
      type: 'input',
      name: 'locality',
      readOnly: false,
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 40
      },
    },
    {
      title: 'SDATDialogs.FormFields.Town',
      type: 'input',
      name: 'town',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: true,
        upperLimit: 40
      },
    },
    {
      type: 'object',
      name: 'zipCode',
      objectId: 'zipCodeObject',
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      type: 'object',
      name: 'country',
      objectId: 'countryObject',
      readOnly: false,
      validation: {
        required: true
      },
    },
  ],
  'localisationObject': [
    {
      title: 'MeteringPointEvent.Keys.localisation',
      type: 'heading',
      name: 'title',
      omitFromRequest: true,
      cssClasses: 'col-xs-12',
    },
    {
      title: 'MeteringPointEvent.Keys.street',
      type: 'input',
      name: 'street',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 60
      },
    },
    {
      title: 'SDATDialogs.FormFields.HouseNumber',
      type: 'input',
      name: 'houseNumber',
      readOnly: false,
      readonlyDefault: '-',
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 12
      },
    },
    {
      title: 'SDATDialogs.FormFields.DwellingNumber',
      type: 'input',
      name: 'dwellingNumber',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 10
      },
    },
  ],
  'postOfficeBoxObject': [
    {
      title: 'SDATDialogs.FormFields.PostOfficeBox',
      type: 'heading',
      name: 'title',
      omitFromRequest: true,
      cssClasses: 'col-xs-12',
    },
    {
      title: 'SDATDialogs.FormFields.Number',
      type: 'input',
      name: 'number',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        type: 'positivenumber',
        upperLimit: 8,
      },
      omitFromRequestConditions: [
        {
          fieldName: 'text',
          operator: SmartFormShowConditionOperator.In,
          value: ["", null, undefined]
        },
      ]
    },
    {
      title: 'SDATDialogs.FormFields.Text',
      type: 'input',
      name: 'text',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 15
      }
    },
  ],
  'zipCodeObject': [
    {
      title: 'MeteringPointEvent.Keys.zipCode',
      type: 'heading',
      name: 'title',
      omitFromRequest: true,
      cssClasses: 'col-xs-12',
    },
    {
      title: 'MeteringPointEvent.Keys.type',
      type: 'select',
      name: 'switch',
      allowedValues: {type: 'globalRegister', data: 'zipcodetype'},
      default: 'CH',
      cssClasses: 'col-xs-3',
      readOnly: false,
      validation: {
        required: false
      },
      omitFromRequest: true,
    },
    {
      type: 'object',
      name: 'swiss',
      objectId: 'swissZipCodeObject',
      readOnly: false,
      validation: {
        required: false
      },
      showConditions: [{
        fieldName: 'switch',
        operator: SmartFormShowConditionOperator.Equals,
        value: 'CH'
      }],
    },
    {
      type: 'object',
      name: 'foreign',
      objectId: 'foreignZipCodeObject',
      readOnly: false,
      validation: {
        required: false
      },
      showConditions: [{
        fieldName: 'switch',
        operator: SmartFormShowConditionOperator.Equals,
        value: 'FOREIGN'
      }],
    },
  ],
  'swissZipCodeObject': [
    {
      title: 'MeteringPointEvent.Keys.zipCode',
      type: 'input',
      name: 'code',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: true,
        type: 'swisszipcode',
      },
    },
    {
      title: 'SDATDialogs.FormFields.SwissZipCodeAddOn',
      type: 'input',
      name: 'codeAddOn',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        upperLimit: 2
      },
    },
    {
      title: 'SDATDialogs.FormFields.SwissZipCodeId',
      type: 'input',
      name: 'codeId',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: false,
        type: 'integer'
      },
    },
  ],
  'foreignZipCodeObject': [
    {
      title: 'MeteringPointEvent.Keys.zipCode',
      type: 'input',
      name: 'code',
      cssClasses: 'col-xs-3',
      readOnly: false,
      validation: {
        required: true,
        upperLimit: 15
      },
    },
  ],
  'countryObject': [
    {
      title: 'MeteringPointEvent.Keys.country',
      type: 'heading',
      name: 'title',
      omitFromRequest: true,
      cssClasses: 'col-xs-12',
    },
    {
      title: 'MeteringPointEvent.Keys.code',
      type: 'input',
      name: 'id',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        type: 'positivenumber',
        upperLimit: 4,
        lowerLimit: 4
      },
    },
    {
      title: 'MeteringPointEvent.Keys.idIso2',
      type: 'input',
      name: 'idIso2',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        upperLimit: 2,
        lowerLimit: 2
      },
    },
    {
      title: 'MeteringPointEvent.Keys.nameShort',
      type: 'input',
      name: 'nameShort',
      readonlyDefault: '-',
      readOnly: false,
      cssClasses: 'col-xs-3',
      validation: {
        required: true,
        upperLimit: 50
      },
    },
  ],
  'emailObject': [
    {
      title: 'SDATDialogs.FormFields.Email.Address',
      type: 'input',
      name: 'item',
      readOnly: false,
      validation: {
        required: true,
        type: 'email',
      },
    },
    {
      type: 'object',
      objectId: 'categoryObject',
      name: 'category',
      readOnly: false,
      validation: {
        required: true
      },
    },
    {
      type: 'object',
      objectId: 'validityObject',
      name: 'validity',
      readOnly: false,
      validation: {
        required: true
      },
    }
  ],
  'phoneObject': [
    {
      title: 'FormTelephone',
      type: 'input',
      name: 'item',
      readOnly: false,
      validation: {
        required: true,
        type: 'phone_ech'
      },
    },
    {
      type: 'object',
      objectId: 'phoneCategoryObject',
      name: 'category',
      readOnly: false,
      validation: {
        required: true
      },
    },
    {
      type: 'object',
      objectId: 'validityObject',
      name: 'validity',
      readOnly: false,
      validation: {
        required: true
      },
    }
  ],
  'internetObject': [
    {
      title: 'SDATDialogs.FormFields.InternetAddress',
      type: 'input',
      name: 'item',
      readOnly: false,
      validation: {
        required: true,
        type: 'internet'
      },
    },
    {
      type: 'object',
      objectId: 'categoryObject',
      name: 'category',
      readOnly: false,
      validation: {
        required: true
      },
    },
    {
      type: 'object',
      objectId: 'validityObject',
      name: 'validity',
      readOnly: false,
      validation: {
        required: true
      },
    }
  ],
  'categoryObject': [
    {
      title: 'SDATDialogs.FormFields.Category',
      type: 'select',
      name: 'basic',
      allowedValues: {type: 'globalRegister', data: 'basecategory'},
      readOnly: false,
      validation: {
        required: true
      },
      omitFromRequestConditions: [
        {
        fieldName: 'other',
        operator: SmartFormShowConditionOperator.NotIn,
        value: ["", null, undefined]
        },
      ]
    },
    {
      title: 'SDATDialogs.FormFields.OtherCategory',
      type: 'input',
      name: 'other',
      readOnly: false,
      validation: {
        required: true
      },
      showConditions: [{
        fieldName: 'basic',
        operator: SmartFormShowConditionOperator.Equals,
        value: 'UNSPECIFIED'
      }]
    }
  ],
  'phoneCategoryObject': [
    {
      title: 'SDATDialogs.FormFields.Category',
      type: 'select',
      name: 'basic',
      allowedValues: {type: 'globalRegister', data: 'phonebasecategory'},
      readOnly: false,
      validation: {
        required: true
      },
      omitFromRequestConditions: [
        {
          fieldName: 'other',
          operator: SmartFormShowConditionOperator.NotIn,
          value: ["", null, undefined]
        },
      ]
    },
    {
      title: 'SDATDialogs.FormFields.OtherCategory',
      type: 'input',
      name: 'other',
      readOnly: false,
      validation: {
        required: true
      },
      showConditions: [{
        fieldName: 'basic',
        operator: SmartFormShowConditionOperator.Equals,
        value: 'UNSPECIFIED'
      }]
    }
  ],
  'validityObject': [
    {
      title: 'SDATDialogs.FormFields.ValidityFrom',
      type: 'date',
      name: 'from',
      cssClasses: 'col-xs-6',
      readOnly: false,
      validation: {
        required: true
      },
    },
    {
      title: 'SDATDialogs.FormFields.ValidityTo',
      type: 'date',
      name: 'to',
      cssClasses: 'col-xs-6',
      readOnly: false,
      validation: {
        required: false
      },
    },
  ],
  'meteringPointAddressObject': [
    {
      title: 'SDATDialogs.FormFields.MeteringPointAddress',
      type: 'heading',
      name: 'address:title',
      omitFromRequest: true,
      cssClasses: 'col-xs-12',
    },
    {
      title: 'MeteringPointEvent.Keys.egId',
      type: 'input',
      name: 'egId',
      cssClasses: 'col-xs-4',
      validation: {
        type: 'positivenumber',
        upperLimit: 9,
      },
    },
    {
      title: 'MeteringPointEvent.Keys.ewId',
      type: 'input',
      name: 'ewId',
      cssClasses: 'col-xs-4',
      validation: {
        type: 'positivenumber',
        upperLimit: 3,
      },
    },
    {
      title: 'MeteringPointEvent.Keys.floorId',
      type: 'input',
      name: 'floorId',
      cssClasses: 'col-xs-4',
      validation: {
        type: 'positivenumber',
        upperLimit: 3,
      },
    },
    {
      title: 'MeteringPointEvent.Keys.floorAddOn',
      type: 'input',
      name: 'floorAddOn',
      cssClasses: 'col-xs-4',
      validation: {
        upperLimit: 60,
      },
    },
    {
      title: 'MeteringPointEvent.Keys.parcelId',
      type: 'input',
      name: 'parcelId',
      cssClasses: 'col-xs-4',
      validation: {
        upperLimit: 14,
      },
    },
    {
      type: 'object',
      objectId: 'mpAddressInformationObject',
      name: 'address',
      readOnly: false,
      validation: {
        required: false
      },
    }
  ],
  'meteringPointCharacteristicsObject': [
    {
      title: 'SDATDialogs.FormFields.MeteringPointCharacteristicsTitle',
      name: 'title',
      type: 'heading',
      omitFromRequest: true,
      cssClasses: 'col-xs-12',
    },
    {
      name: 'type', title: 'SDATDialogs.FormFields.MeteringPointType',
      type: 'select', cssClasses: 'col-xs-4',
      allowedValues: {type: 'globalRegister', data: 'meteringpointtype'},
      validation: {required: true},
    },
    {
      name: 'meteringMethod', title: 'MeteringPointEvent.Keys.meteringMethod',
      type: 'select', cssClasses: 'col-xs-4',
      allowedValues: {type: 'globalRegister', data: 'meteringmethod'},
      validation: {required: true},
    },
    {
      name: 'settlementMethod', title: 'MeteringPointEvent.Keys.settlementMethod',
      type: 'select', cssClasses: 'col-xs-4',
      allowedValues: {type: 'globalRegister', data: 'settlementmethod'},
      validation: {required: true},
    },
    {
      type: 'object',
      objectId: 'meteringPointResolutionObject',
      name: 'meterReadingPeriod',
      readOnly: false,
      validation: {
        required: true
      },
    }
  ],
  'meteringPointResolutionObject': [
    {
      name: 'resolution', title: 'MeteringPointEvent.Keys.resolution',
      type: 'input', cssClasses: 'col-xs-4',
      validation: {
        type: 'positivenumber',
        required: true,
      },
    },
    {
      name: 'unit', title: 'MeteringPointEvent.Keys.unit',
      type: 'input', cssClasses: 'col-xs-4',
      validation: {
        required: true,
        type: 'periodunit'
      },
    }
  ],
  'meteringPointPartiesObject': [
    {
      title: 'FormSpecifyParties',
      type: 'checkboxradio',
      name: 'checkbox',
      omitFromRequest: true,
      valueIsBoolean: true,
      default: false,
      cssClasses: 'col-sm-12',
      validation: {
        required: true,
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'yesOrNo'
      }
    },
    {
      type: 'object',
      objectId: 'balancePartiesObject',
      name: 'balance',
      readOnly: false,
      validation: {
        required: false
      },
      showConditions: [{
        fieldName: 'checkbox',
        operator: SmartFormShowConditionOperator.Equals,
        value: '1'
      }],
    },
    {
      title: 'SDATDialogs.FormFields.AncillaryServiceProviderEics',
      name: 'ancillaryServiceProviders',
      readOnly: false,
      cssClasses: 'col-xs-12',
      type: 'array',
      inputList: true,
      objectId: 'ancillaryServiceProvidersObject',
      showConditions: [{
        fieldName: 'checkbox',
        operator: SmartFormShowConditionOperator.Equals,
        value: '1'
      }],
    }
  ],
  'ancillaryServiceProvidersObject': [
    {
      title: 'SDATDialogs.FormFields.AncillaryServiceProvider',
      type: 'autocomplete',
      // name is irrelavant if parent is an input list
      name: 'sdv',
      readOnly: false,
      validation: {
        required: true,
        type: 'eic'
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'sdvMarketpartners'
      },
    }
  ],
  'balancePartiesObject': [
    {
      title: 'SDATDialogs.FormFields.BalanceSupplierEic',
      type: 'autocomplete',
      name: 'supplier',
      readOnly: false,
      validation: {
        required: true,
        type: 'eic'
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'lfMarketpartners'
      },
    },
    {
      title: 'SDATDialogs.FormFields.BalanceResponsibleEic',
      type: 'autocomplete',
      name: 'responsible',
      readOnly: false,
      validation: {
        required: true,
        type: 'eic'
      },
      allowedValues: {
        type: 'globalRegister',
        data: 'bgvMarketpartners'
      },
    }
  ],
  'contractTerminationInfoObject': [
    {
      title: 'SDATDialogs.FormFields.ContractTerminationInfo',
      type: 'heading',
      name: 'contractInfo',
      omitFromRequest: true,
      readOnly: false,
      validation: {
        required: false
      },
    },
    {
      title: 'SDATDialogs.FormFields.ContractTerminationDate',
      type: 'date',
      name: 'date',
      readOnly: false,
      validation: {
        required: true
      },
    },
    {
      type: 'object',
      objectId: 'contractTerminationResolutionObject',
      name: 'timeOfNotice',
      readOnly: false,
      validation: {
        required: false
      },
    }
  ],
  'contractTerminationResolutionObject': [
    {
      title: 'SDATDialogs.FormFields.ContractTerminationTimeOfNotice',
      type: 'heading',
      name: '',
    },
    {
      title: 'SDATDialogs.FormFields.ContractTerminationResolution',
      type: 'input',
      name: 'resolution',
      readOnly: false,
      cssClasses: 'col-xs-6',
      validation: {
        required: false,
        type: 'positivenumber',
        upperLimit: 8,
      },
    },
    {
      name: 'unit',
      title: 'SDATDialogs.FormFields.ContractTerminationUnit',
      type: 'select',
      cssClasses: 'col-xs-4',
      allowedValues: {type: 'globalRegister', data: 'contractterminationunit'}
    },
  ]
};
