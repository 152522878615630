import {Injectable} from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable()
export class DateTimeService {
  formatDateIso = 'YYYY-MM-DD';
  formatDateTimeIso = 'YYYY-MM-DDTHH:mm:ssZ';
  constructor() {
  }

  public getLocaleDateFormat(): string {
    return moment.localeData().longDateFormat('L');
  }

  public getLocaleDateTimeFormat(includeSeconds = false): string {
    return moment.localeData().longDateFormat('L') + ' HH:mm' + (includeSeconds ? ':ss' : '');
  }

  public getLocaleDateFormatPicker(): string {
    return this.getLocaleDateFormat().toLowerCase();
  }

  public getDateAsIsoFormatFromLocaleDate(dateString: string): string {
    const momentDate = moment(dateString, this.getLocaleDateFormat());
    return (!momentDate.isValid()) ? '' : momentDate.format(this.formatDateIso);
  }
  public getDateTimeAsIsoFormatFromLocaleDate(dateString: string, includeSeconds = false): string {
    const momentDate = moment(dateString, this.getLocaleDateTimeFormat(includeSeconds));
    return (!momentDate.isValid()) ? '' : momentDate.format(this.formatDateTimeIso);
  }

  public getDateAsIsoFormatFullISOString(dateString: string): string {
    const momentDate = moment(dateString);
    return (!momentDate.isValid()) ? '' : momentDate.format(this.formatDateIso);
  }

  public getDateAsLocaleFormatFromIsoDate(dateString: string): string {
    const momentDate = moment( dateString, this.formatDateIso);
    return (!momentDate.isValid()) ? '' : momentDate.format(this.getLocaleDateFormat());
  }

  public getDateTimeAsLocaleFormatFromIsoDate(dateString: string, includeSeconds = false): string {
    const momentDate = moment( dateString);
    return (!momentDate.isValid()) ? '' : momentDate.format(this.getLocaleDateTimeFormat(includeSeconds));
  }

  public getDateAsLocaleFormatFromJson(date: any): string {
    const momentDate = this.getMomentFromJsonDate(date);
    return (!momentDate?.isValid()) ? '' : momentDate.format('LL');
  }

  public getDateTimeAsLocaleFormatFromJson(dateObject: string, includeSeconds = false): string {
    const momentDate = this.getMomentFromJsonDateTime(dateObject);
    return (!momentDate?.isValid()) ? '' : momentDate.format(this.getLocaleDateTimeFormat(includeSeconds));
  }

  public getDateAsLocaleFormatLinux(linux: number): string {
    const momentDate = moment.tz(linux, this.geLocaleTimezone());
    return (!momentDate.isValid()) ? '' : momentDate.format(this.getLocaleDateFormat());
  }

  public getDateTimeAsLocaleFormatLinux(linux: number, includeSeconds = false): string {
    const momentDate = moment.tz(linux, this.geLocaleTimezone());
    return (!momentDate.isValid()) ?  '' : momentDate.format(this.getLocaleDateTimeFormat(includeSeconds));
  }

  public getLinuxFromDBServerFormat(dateString: string): number {
    const thisMoment = moment.tz(dateString, 'Etc/UTC');
    return thisMoment.valueOf();
  }

  public getLinuxFromLocale(dateString: string, addNumberDays?: string): number {
    const thisMoment = moment.tz(dateString, this.geLocaleTimezone());
    if (addNumberDays !== undefined) {
      thisMoment.add(addNumberDays, 'd');
    }
    return thisMoment.valueOf();
  }

  public getMomentLocaleFromLinux(linux: number): moment.Moment {
    const momentDate = moment.tz(linux,  this.geLocaleTimezone());
    return momentDate;
  }

  public getMomentLocaleCurrent(): moment.Moment  {
    return moment.tz(+ new Date(), this.geLocaleTimezone());
  }

  public geLocaleTimezone() {
    return 'Europe/Berlin';
  }

  public getCurrenDateAsIsoFormatLocaleTimezone(addNumberDays: string) {
    let momentDate = this.getMomentLocaleCurrent();
    if (addNumberDays) {
      momentDate  =  momentDate.add(addNumberDays, 'd');
    }
    return momentDate.format(this.formatDateIso);
  }

  public getISO8601UTCFromLinux(linux: number, removeTAndZ = false): string {
    const momentDate = moment.tz(linux, 'Etc/UTC');
    let isoString =  momentDate.toISOString();
    if (removeTAndZ) {
      isoString = isoString.replace('T', ' ');
      isoString = isoString.replace('Z', '');
    }
    return isoString;
  }

  public getLinuxFromISO8601UTC(dateString: string, addTAndZ = false): number | undefined {
    if (!dateString) {
      return undefined;
    }
    if (addTAndZ) {
      dateString = dateString.replace(' ', 'T');
      dateString += 'Z';
    }
    const momentDate = moment(dateString);
    return momentDate.valueOf();
  }

  public getMomentFromJsonDate(dateObject: any): moment.Moment | undefined {
    if (!dateObject) {
      return undefined;
    }
    return moment({
      day: dateObject.day,
      month: dateObject.month - 1,  // JS Date convention
      year: dateObject.year,
    });
  }

  public getMomentFromJsonDateTime(obj: any): moment.Moment | undefined {
    if (!obj) {
      return undefined;
    }
    return moment({
      day: obj.day,
      month: obj.month - 1,  // JS Date convention
      year: obj.year,
      hours: obj.hours,
      minutes: obj.minutes,
      seconds: obj.seconds,
    });
  }

  public getLinuxFromJsonDate(dateObject: any): number | undefined {
    const momentDate = this.getMomentFromJsonDate(dateObject);
    if (!momentDate) return undefined;
    return momentDate.valueOf();
  }

  public sortByDateNewestFirst(a: {year: number, month: number}, b: {year: number, month: number}) {
      return a.year == b.year ?
        a.month == b.month ? 0 :
          a.month > b.month ? -1 : 1
        : a.year > b.year ? -1 : 1;
  }
}
