import {NameValueMap} from '../../marketpartner-components/smart-forms/smart-shared/classes/KeyValueMap';
import {ApiRequestMockupInterceptorDefinition} from '../classes/ApiRequestMockupInterceptorDefinition';


export const APIREQUEST_MOCKUP_INTERCEPTORS_DEFS: NameValueMap<ApiRequestMockupInterceptorDefinition> = {
  'GET|/api/messagelog/search': {
    operations: [{
        mockupDataSource: 'MessagesLog',
        standardOperation: 'ListSearch',
    }],

    wrapInsideData: true
  },
};
