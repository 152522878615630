/* eslint-disable @angular-eslint/no-output-on-prefix, @angular-eslint/component-selector, @angular-eslint/no-input-rename */
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {UtilsService} from '../../../services/utils.service';
import {SmartModalService} from '../smart-modal/service/smart-modal.service';
import {SmartModalBuilder} from '../smart-modal/builder/smart-modal.builder';
import {SmartToolbarActionDefinition} from '../smart-shared/classes/SmartToolbarActionDefinition';
import {NameValueMap} from '../smart-shared/classes/KeyValueMap';
import {ActionEvent} from '../smart-shared/classes/ActionEvent';

@Component({
    selector: 'actions-toolbar',
    templateUrl: './actions-toolbar.component.html',
    styleUrls: ['./actions-toolbar.component.scss']
})

export class ActionsToolbarComponent {
    @Input('actionsToolbar') actionsToolbar: SmartToolbarActionDefinition[] = [];
    @Input('cssClasses') cssClasses: NameValueMap<string> = {
        actionToolbar: ' action-toolbar-container ',
        actionTitle: ' action-title '
    };
    @Input('title') title = '';
    @Input('showIfEmpty') showIfEmpty = false;
    @Output() actionClicked = new EventEmitter<ActionEvent>();

    currentSelectedValue: any | null = null;
    currentSelectedId: string | null = null;

    loadingActionId?: string;



    constructor( private utilsService: UtilsService,
                 private smartModalService: SmartModalService) {
    }

    public setSelectedData(id: string | null, seletedDataObject: any | null) {
        if (seletedDataObject === null ) {
            this.currentSelectedValue = seletedDataObject;
        } else {
            this.currentSelectedValue = this.utilsService.cloneJSON(seletedDataObject);
        }
        this.currentSelectedId = id;
    }
    public getSelectedData() {
        return this.currentSelectedValue;
    }
    public getSelectedId() {
        return this.currentSelectedId;
    }

    public setLoadingAction(actionId: string) {
        this.loadingActionId = actionId;
    }

    public unsetLoadingAction() {
        this.loadingActionId = undefined;
    }

    private checkIfToolbarActionIsDisabled(actionInfo: SmartToolbarActionDefinition) {
        if ( actionInfo.needsEntrySelected) {
            if (!this.isAnyDataSelected()) {
                return true;
            }

            if (actionInfo.fnEnabled) {
                return !(actionInfo.fnEnabled(this.getSelectedData()));
            }
        } else {
          if (actionInfo.fnEnabled) {
            return !(actionInfo.fnEnabled(null));
          }
        }
        return false;
    }

    private isAnyDataSelected() {
        return this.getSelectedData() !== null;
    }

  actionToolbarButtonClicked(actionId: string, actionInfo: SmartToolbarActionDefinition) {
        const self = this;
        const selectedData = this.getSelectedData();

        if (actionInfo.confirmationDialog ) {
            const modalOptionsForAction = self.getDialogForActionConfirmation(actionId, actionInfo);
            if (modalOptionsForAction) {
              modalOptionsForAction.onSuccessFormValue = function() {
                self.actionToolbarButtonConfirmed(actionId, actionInfo, selectedData);
              };
              this.smartModalService.showModal(modalOptionsForAction);
            }
        } else {
            self.actionToolbarButtonConfirmed(actionId, actionInfo, selectedData);
        }
    }

    private actionToolbarButtonConfirmed(actionId: string, actionInfo: SmartToolbarActionDefinition, selectedData: any[] | null= []) {
        if (selectedData === null) {
            selectedData = [];
        }
        const actionEvent: ActionEvent = {
            actionId: actionId,
            data: selectedData
        };
      if (actionInfo.fnClicked) {
        actionInfo.fnClicked(actionEvent);
      } else {
        this.actionClicked.emit(actionEvent);
      }
    }

    private getDialogForActionConfirmation(_actionId: string, actionInfo: SmartToolbarActionDefinition) {
        if (!actionInfo.confirmationDialog) {
          return undefined;
        }

        const modalOptions =  new SmartModalBuilder()
          .setTitle(actionInfo.confirmationDialog.title ||  actionInfo.title)
          .setBodyText(actionInfo.confirmationDialog.body )
          .showConfirmButton(true)
          .showCancelButton(true)
          .build();

        return modalOptions;
    }

  getDisabledState(actionInfo: SmartToolbarActionDefinition) {
        return this.checkIfToolbarActionIsDisabled(actionInfo);
    }

}
