import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {CompanypartnersBaseComponent} from "../../companypartners-base.component";
import {CustomValidators} from "../../../shared/marketpartner-components/smart-forms/smart-form/classes/CustomValidators";
import {NAV_API_ENDPOINTS_LIST} from "../../../shared/nav/api-endpoints-list";

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: []
})
export class ChangePasswordComponent extends CompanypartnersBaseComponent implements OnInit {

  @ViewChild('resetPwForm') resetPwForm!: FormGroupDirective;

  confirmControl = new FormControl<string>('', {validators: Validators.required, nonNullable: true});
  changePasswordForm: FormGroup = this.fb.group({
    oldPassword: ['', {validators: [Validators.required, CustomValidators.getValidatorControl({type: 'password'})], nonNullable: true}],
    newPassword: ['', {validators: [Validators.required, CustomValidators.getValidatorControl({type: 'password'})], nonNullable: true}],
    passwordConfirm: this.confirmControl,
  }, {validators: [CustomValidators.mustMatch('newPassword', 'passwordConfirm')]});

  ngOnInit() {
    this.initialized = true;
  }

  async onSubmit() {
    this.isSendingRequest = true;
    const result = await this.requestService.doApiRequest(NAV_API_ENDPOINTS_LIST.passwordUpdate,
      {oldPassword: this.changePasswordForm.value.oldPassword,
        newPassword: this.changePasswordForm.value.newPassword});
    this.isSendingRequest = false;
    this.changePasswordForm.reset();
    if(result.status == 'success') {
      this.resetPwForm.resetForm();
      this.openSnackbar(this.translate.instant('PasswordHasBeenChanged'));
    } else {
      const errorMessage = await this.requestService.convertApiErrorToString(result.data);
      this.changePasswordForm.setErrors({serverError: errorMessage});
      this.openSnackbar(errorMessage);
    }
  }

}
