import {EnumDefinition} from '../classes/enum-definition';

export const TRANSACTION_TYPE: EnumDefinition = {
  id: 'transactiontype',
  items: [
    {label: 'LabelEventTransactionType.TYPE_UNSPECIFIED', data: 'TYPE_UNSPECIFIED'},
    {label: 'LabelEventTransactionType.CREATE', data: 'CREATE'},
    {label: 'LabelEventTransactionType.UPDATE', data: 'UPDATE'},
    {label: 'LabelEventTransactionType.DELETE', data: 'DELETE'},
    {label: 'LabelEventTransactionType.INVALIDATE', data: 'INVALIDATE'},
  ]
};
