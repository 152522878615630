import {Injectable} from '@angular/core';
import {MarketPartnerInfo} from '../session/MarketPartnerInfo';
import {SessionService} from '../session/session.service';
import { ApiRequestBuilder } from '../api-request/builder/api-request.builder';
import { USERS_API_ENDPOINTS_LIST } from '../../users/requests/api-endpoints-list';
import { ApiRequestService } from '../api-request/service/api-request.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestConfigInfoDefinition } from '../api-request/classes/ApiRequestConfigInfoDefinition';

export interface FtpWarnings {
  toDatahubWarning: boolean;
  fromDatahubWarning: boolean;
  anyFtpWarning: boolean;
}

export function canAccessMeteringPointsUpload(mpi: MarketPartnerInfo): boolean {
  return mpi.partnerSettings.role === 'VNB'
    && mpi.partnerSettings.contractStatus === 'PARTNER_CONTRACT';
}

export function canUseSdatWebclient(mpi: MarketPartnerInfo): boolean {
  return ['VNB', 'LF', 'SDV'].includes(mpi.partnerSettings.role);
}

export function canAccessMeteringPointsDownload(mpi: MarketPartnerInfo): boolean {
  return canAccessMeteringPointsUpload(mpi) || mpi.partnerSettings.role === 'LF';
}

export function canShowUnsuppliedMeteringPoints(mpi?: MarketPartnerInfo): boolean {
  if (!mpi) return false;
  // ELDEXDHUB-2115
  return mpi.partnerSettings.role == 'VNB' && mpi.partnerSettings.contractStatus == 'PARTNER_CONTRACT';
}

export function canAccessOstral(mpi: MarketPartnerInfo) {
  return mpi.partnerSettings.role === 'VNB' && mpi.availableFeatures.includes('OSTRAL');
}
@Injectable()
export class MarketPartnerService {
  public partnerWarnings$: Observable<FtpWarnings> = this.sessionService.partnerInfoSource.pipe(
    map(partnerInfo => this.getFtpWarnings(partnerInfo))
  );
  public unreadNotifications$ = new BehaviorSubject<number>(0);
  public unreadProcesses$ = new BehaviorSubject<number>(0);
  public unsuppliedMeteringPoints$ = new BehaviorSubject<number>(0);

  constructor(
    private sessionService: SessionService,
    private apiRequestService: ApiRequestService,
  ) {}

  public getFtpWarnings(partnerInfo: MarketPartnerInfo|null): FtpWarnings {
    if (!partnerInfo) {
      // no warnings for owner
      return {
        toDatahubWarning: false,
        fromDatahubWarning: false,
        anyFtpWarning: false,
      };
    }
    const cd = partnerInfo.communicationData;
    const fromDatahubWarning =
      cd.receiveMessageLocation !== 'LOCAL' &&
      cd.externalFtpAccounts.length === 0;
    const toDatahubWarning =
      cd.localFtpAccounts.length === 0 && cd.sharedFtpAccounts?.length === 0;
    return {
      toDatahubWarning,
      fromDatahubWarning,
      anyFtpWarning: toDatahubWarning || fromDatahubWarning
    };
  }

  public updateUnreadNotificationsCount() {
    this.updateCount(this.unreadNotifications$, USERS_API_ENDPOINTS_LIST.notificationsUnread);
  }

  public updateUnreadProcessesCount() {
    this.updateCount(this.unreadProcesses$, USERS_API_ENDPOINTS_LIST.unreadProcess);
  }

  public updateUnsuppliedCount() {
    const mpi = this.sessionService.getCurrentPartnerInfo();
    if (canShowUnsuppliedMeteringPoints(mpi)) {
      this.updateCount(this.unsuppliedMeteringPoints$, USERS_API_ENDPOINTS_LIST.unsuppliedMeteringPoints);
    } else {
      this.unsuppliedMeteringPoints$.next(0);
    }
  }

  private async updateCount(bs: BehaviorSubject<number>, api: ApiRequestConfigInfoDefinition) {
    const partnerId = this.sessionService.getCurrentPartnerId();
    if (!partnerId) return;   // e.g. when logged in as owner
    const response = await this.apiRequestService.callApi(new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(api, {partnerId})
      .build());
    if (response.status === 'success') {
      bs.next(response.data);
    }
  }

  public canAccessMeteringPointsUpload(mpi: MarketPartnerInfo): boolean {
    return canAccessMeteringPointsUpload(mpi);
  }

  public canAccessMeteringPointsDownload(mpi: MarketPartnerInfo): boolean {
    return canAccessMeteringPointsDownload(mpi);
  }

}
