import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NavigateToInfo} from './classes/NavigateToInfo';
import {Subscription} from 'rxjs';

@Component({ template: '' })
export abstract class StandardViewComponent implements OnInit, OnDestroy {
    paramsSubscription?: Subscription;
    initialized = false;
    paramsUrlInitialized = false;
    constructor(  public activatedRoute: ActivatedRoute,
                  public router: Router) {
    }

    ngOnInit() {
        const self = this;
        this.paramsSubscription  = this.activatedRoute.params.subscribe((params: Params) => {
            if (!self.paramsUrlInitialized) {
                self.paramsUrlInitialized = true;
                self.onParamsUrlInitialized(params);
            } else {
                self.initialized = false;
                setTimeout(() =>  {
                    self.onParamsUrlInitialized(params);
                }, 0);
            }
        });
    }

    setInitialized(initialized: boolean) {
        this.initialized = initialized;
    }

    onParamsUrlInitialized(_params: any) {

    }
    ngOnDestroy(): void {
        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }
    }
    navigateTo(value: NavigateToInfo) {
        if (value) {
          let routeToNavigateReplaced = value.route;
          if (value.replace) {
            for (const replaceKey in value.replace) { //for..in to iterate over KEYS, not VALUES. see: https://www.typescriptlang.org/docs/handbook/iterators-and-generators.html
              routeToNavigateReplaced = routeToNavigateReplaced.replace( ('{' + replaceKey + '}'), (value.replace[replaceKey] + ''));
            }
          }

          if ('queryParams' in value) {
            this.router.navigate([routeToNavigateReplaced], { queryParams: value.queryParams});
          } else {
            this.router.navigate([routeToNavigateReplaced]);
          }

        }
    }
}
