<h2>{{'companyClient.account.general' | translate}}</h2>
<form *ngIf="initialized" [formGroup]="userInfoForm" (ngSubmit)="onSubmit()" method="post" class="m-top">
  <div class="multi-col-form">
    <div>
      <mat-button-toggle-group formControlName="lang" aria-label="Language" class="accent reduced-height" (change)="updateLanguage()">
        <mat-button-toggle value="de" id="langDe">DE</mat-button-toggle>
        <mat-button-toggle value="fr" id="langFr">FR</mat-button-toggle>
        <mat-button-toggle value="it" id="langIt">IT</mat-button-toggle>
      </mat-button-toggle-group><br><br>
    </div>
    <mat-form-field class="rowspan">
      <mat-label>{{'FormEmail' | translate}}</mat-label>
      <input matInput type="email" formControlName="email" name="email" id="email">
    </mat-form-field><br>
    <mat-form-field class="small-input">
      <mat-label>{{'FormSalutation' | translate}}</mat-label>
      <mat-select formControlName="salutation" id="salutation">
        <mat-option value="MR" id="salutationM">{{'LabelSalutationMr' | translate}}</mat-option>
        <mat-option value="MRS" id="salutationF">{{'LabelSalutationMrs' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'FormFirstName' | translate}}</mat-label>
      <input matInput type="text" formControlName="firstname" name="firstName" id="firstName">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'FormLastName' | translate}}</mat-label>
      <input matInput type="text" formControlName="lastname" name="lastName" id="lastName">
    </mat-form-field><br>
    <mat-form-field>
      <mat-label>{{'FormMobilePhone' | translate}}</mat-label>
      <input matInput type="tel" formControlName="telephone" name="phone" id="phone">
    </mat-form-field>
  </div>
  <submit-button [isSendingRequest]="isSendingRequest" [disabled]="userInfoForm.invalid" [id]="'submitProfile'"></submit-button>
</form>
