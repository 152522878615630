<mat-form-field>
  <mat-label>{{labelKey | translate}}</mat-label>
  <input matInput
         [type]="hideText ? 'password' : 'text'"
         [formControl]="form.controls[controlName] | formControl"
         [name]="controlName"
         [id]="controlName">
  <button tabindex="-1"
          type="button"
          mat-icon-button
          matSuffix
          (click)="hideText = !hideText"
          [attr.aria-label]="'FormHidePassword' | translate"
          [attr.aria-pressed]="hideText"
          [id]="'hide_'+controlName">
    <mat-icon [svgIcon]="hideText ? 'eye' : 'eye-strike'"></mat-icon>
  </button>
  <mat-error *ngIf="showHint">{{errorMessageKey | translate}}</mat-error>
</mat-form-field>
