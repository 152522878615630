import { Injectable } from '@angular/core';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import { BehaviorSubject, interval } from 'rxjs';

@Injectable()
export class UpdateService {
  public updatesAvailable$ = new BehaviorSubject<boolean>(false);

  constructor(private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((e: VersionEvent) => {
        if(e.type == "VERSION_READY") {
          this.updatesAvailable$.next(true);
        }
      });

      const updateCheckIntervalMinutes = 60;
      console.log(`polling swUpdate every ${updateCheckIntervalMinutes} minutes`);
      interval(updateCheckIntervalMinutes * 60 * 1000).subscribe(
        () => this.swUpdate.checkForUpdate()
      );
    } else {
      console.log('swUpdate is not enabled');
    }
  }

}

