<h1 mat-dialog-title>{{prefix+'h1' | translate}}</h1>

<div mat-dialog-content>
  <p>{{prefix+'description' | translate:{vnb: data.partnerName} }}</p>
  <br>
  <mat-form-field appearance="outline" class="rowspan">
    <mat-label>{{'companyClient.objectProperties.meteringPoint.id' | translate}}</mat-label>
    <input [formControl]="mpIdFormField" matInput type="text">
    <mat-error>{{mpIdFormField.errors?.translatableMessage | translate}}</mat-error>
  </mat-form-field>

  <mat-checkbox [(ngModel)]="deleteAll" id="admin" (change)="deleteAll ? mpIdFormField.disable() : mpIdFormField.enable()">{{prefix+'all' | translate}}</mat-checkbox>
</div>

<div mat-dialog-actions [align]="'end'" class="padded-actions">
  <button mat-flat-button (click)="onNoClick()" id="modalCancel">{{'ModalCancelButton' | translate}}</button>
  <submit-button [isSendingRequest]="isSendingRequest" [disabled]="!(deleteAll || (mpIdFormField.value && mpIdFormField.valid))" (submit)="submit()" [textKey]="'ModalConfirmButton'"></submit-button>
</div>
