import {Component, OnInit} from '@angular/core';
import {CompanypartnersBaseComponent} from "../companypartners-base.component";
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "../api-endpoints-list";
import {FormGroup, Validators} from "@angular/forms";
import {companyservice} from "../../shared/messaging-grpc/messaging-grpc";
import {CompanyPartnerInfo} from "../../shared/session/CompanyPartnerInfo";
import CompanyPartner = companyservice.CompanyPartner;

@Component({
  selector: 'company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: []
})
export class CompanySettingsComponent extends CompanypartnersBaseComponent implements OnInit {

  companySettingsForm!: FormGroup;
  contactEmail!: string;
  contactPhone!: string;

  ngOnInit() {
    this.fillForm();
    this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.getCompanyPartner, {partnerId: this.partnerId})
      .then((result: {status: string, data: any}) => {
        this.initialized = true;
        if(result.status == 'success') {
          this.fillForm(result.data.companyPartner, this.sessionService.getCurrentCompanyPartnerInfo());
        } else {
          this.openSnackbar(this.translate.instant('companyClient.companySettings.fetchError'));
        }
      });
  }

  onSubmit() {
    this.isSendingRequest = true;
    const apiConfigDefinition = COMPANYPARTNERS_API_ENDPOINTS_LIST.updateCompanyPartnerAllServices;
    apiConfigDefinition.replaceUrl = {partnerId: this.companySettingsForm.getRawValue().partnerId};
    this.requestService.doApiRequest(apiConfigDefinition, this.companySettingsForm.getRawValue())
      .then((result: {status: string, data: any}) => {
        this.isSendingRequest = false;
        if(result.status == 'success') {
          this.openSnackbar(this.translate.instant('ProfileHasBeenChanged'), 5000);
          this.sessionService.updateCurrentUserInfo();
        } else {
          this.openSnackbar(this.translate.instant('ProfileHasNotBeenChanged'), 7000);
        }
      });

  }

  fillForm(content: CompanyPartner | undefined = undefined, partnerInfo: CompanyPartnerInfo | null | undefined = undefined) {
    this.companySettingsForm = this.fb.group({
      partnerId: [partnerInfo?.companyPartnerSettings.partnerId || '', {nonNullable: true}],
      name: [{value: content?.name || '', disabled: true}, {nonNullable: true}],
      addressStreet: [{value: content?.addressStreet || '', disabled: true}, {nonNullable: true}],
      addressHouseNumber: [{value: content?.addressHouseNumber || '', disabled: true}, {nonNullable: true}],
      addressZipCode: [{value: content?.addressZipCode || '', disabled: true}, {nonNullable: true}],
      addressTown: [{value: content?.addressTown || '', disabled: true}, {nonNullable: true}],
      contactFirstName: [content?.contactFirstName || '', {validators: Validators.required, nonNullable: true}],
      contactName: [content?.contactName || '', {validators: Validators.required, nonNullable: true}],
      contactEmail: [partnerInfo?.companyPartnerSettings.contactEmail || '', {validators: Validators.required, nonNullable: true}],
      contactPhone: [partnerInfo?.companyPartnerSettings.contactPhone || '', {validators: Validators.required, nonNullable: true}],
    });
  }
}
