import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiRequestService} from "../../shared/api-request/service/api-request.service";
import {UserInfoServer} from "../../shared/session/UserInfo";
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "../api-endpoints-list";

@Component({
  selector: 'user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  styleUrls: []
})
export class UserDetailsDialogComponent {

  isSendingRequest = false;
  errorMessage: string = '';
  shouldBeAdmin: boolean;

  constructor(
    public dialogRef: MatDialogRef<UserDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {user: UserInfoServer},
    private requestService: ApiRequestService
  ) {
    this.shouldBeAdmin = data.user.scopes == 'ADMIN';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.isSendingRequest = true;
    const config = COMPANYPARTNERS_API_ENDPOINTS_LIST.editUserRoles;
    config.replaceUrl = {userId: this.data.user.id}
    this.requestService.doApiRequest(config, {scopes: this.shouldBeAdmin? 'ADMIN' : 'USER'})
      .then(_result => {
        this.isSendingRequest = false;
        this.dialogRef.close(true);
      });
  }
}
