/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';
import {SessionService} from '../../shared/session/session.service';

import {ActivatedRoute, Router} from '@angular/router';
import {SmartModalService} from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import {SmartTableBuilder} from '../../shared/marketpartner-components/smart-forms/smart-table/builder/smart-table.builder';
import {SmartTableConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableConfigDefinition';

import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import {SmartTableComponent} from '../../shared/marketpartner-components/smart-forms/smart-table/component/smart-table.component';
import {ActionEvent} from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/ActionEvent';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {AppConfig} from "../../config/app.config";
import {StandardViewComponent} from "../../shared/marketpartner-components/standard-view/standard-view.component";
import {CompanyPartnerInfo} from "../../shared/session/CompanyPartnerInfo";
import {ApiRequestBuilder} from "../../shared/api-request/builder/api-request.builder";

@Component({
  selector: 'page-admin-company-partners-content',
  templateUrl: './admin-company-partners.component.html',
  styleUrls: ['../users-common.scss']
})

export class AdminCompanyPartnersComponent extends StandardViewComponent {
  @ViewChild('tableCompanyPartners') tableCompanyPartners?: SmartTableComponent;


  initialized = false;

  tableCompanyPartnersConfig?: SmartTableConfigDefinition;

  constructor(public sessionService: SessionService,
              public activatedRoute: ActivatedRoute,
              private apiRequestService: ApiRequestService,
              public smartModalService: SmartModalService,
              public router: Router) {
    super(activatedRoute, router);

    this.tableCompanyPartnersConfig = new SmartTableBuilder()
      .setColumnNameForId('companyPartnerSettings:partnerId')
      .addColumnFromInfo({
        id: 'companyPartnerSettings:partnerId',
        title: 'FormPartnerId',
        filterable: false
      })
      .addColumnFromInfo({
        id: 'companyPartnerSettings:name',
        title: 'FormName',
        filterable: true
      })
      .addColumnFromInfo({
        id: 'companyPartnerSettings:contactPhone',
        title: 'FormContactPhone',
        filterable: true
      })
      .addColumnFromInfo({
        id: 'companyPartnerSettings:contactEmail',
        title: 'FormContactEmail',
        filterable: true
      })
      .addToolbarActionFromInfo({
        id: '',
        title: 'ActionDownloadOperatorReport',
        icon: 'pli-download',
        className: 'btn-primary',
        fnClicked: () => this.download()
      })

      .addRowActionFromInfo({
        id: 'view_companypartner',
        title: 'Companypartner bearbeiten',
        icon: 'pli-pencil',
        className: 'btn-mint',
        fnClicked: (actionEvent) => this.viewCompanyPartner(actionEvent)
      })
      .addRowActionFromInfo({
        id: 'delete_marketpartner',
        title: 'Unternehmenspartner Löschen',
        icon: 'pli-trash',
        className: 'btn-danger',
        fnClicked: (actionEvent) => this.deleteCompanyPartner(actionEvent)
      })

      .setApiRequestConfigInfoFromInfo(USERS_API_ENDPOINTS_LIST.listCompanyPartners)
      .showFilters(true)
      .showSearchField(false)
      .build();
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    this.setInitialized(true);
  }

  /* All possible actions */
  viewCompanyPartner (companyPartnerInfo: ActionEvent) {
    const entitySelected: CompanyPartnerInfo = companyPartnerInfo.data.length ? companyPartnerInfo.data[0] : null;
    this.navigateTo(
      {
        'route': AppConfig.routes.admin_companypartner_users,
        'replace': {'partnerId': entitySelected.companyPartnerSettings.partnerId}
      });
  }

  async deleteCompanyPartner (companyPartnerInfo: ActionEvent) {
    const entitySelected: CompanyPartnerInfo = companyPartnerInfo.data.length ? companyPartnerInfo.data[0] : null;

    const confirmationDialog = this.smartModalService.getConfirmationDialogConfig('ButtonDelete');
    confirmationDialog.onSuccessFormValue = async () => {
      const apiRequestConfig = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.deleteCompanyPartner,
          {partnerId: entitySelected.companyPartnerSettings!.partnerId+""})
        .setHandleLoading(true)
        .setHandleErrorNotification(true)
        .build();

      const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
      if (resultApiRequest.status === 'success') {
        this.tableCompanyPartners?.reload();
      }
    };
    this.smartModalService.showModal(confirmationDialog);
  }

  async download() {
    await this.apiRequestService.downloadFile(USERS_API_ENDPOINTS_LIST.operatorReportCompanyPartners);
  }
}
