import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiRequestService} from "../../shared/api-request/service/api-request.service";
import {FormBuilder, Validators} from "@angular/forms";
import {USERS_API_ENDPOINTS_LIST} from "../requests/api-endpoints-list";
import {NotificationsService} from "../../shared/services/notifications.service";
import {ApiRequestConfigInfoDefinition} from "../../shared/api-request/classes/ApiRequestConfigInfoDefinition";

@Component({
  selector: 'admin-ostral-letters-dialog',
  templateUrl: './admin-ostral-letters-dialog.component.html',
  styleUrls: ['./admin-ostral-letters-dialog.component.scss']
})
export class AdminOstralLettersDialogComponent {
  prefix = 'Contingents.'
  isSendingRequest = false;
  contingentForm = this.fb.nonNullable.group({
    Year: ['', {validators: Validators.required}],
    Month: ['', {validators: Validators.required}]
  });

  constructor(
    public dialogRef: MatDialogRef<AdminOstralLettersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {partnerId: string, businessPartnerId: string},
    private requestService: ApiRequestService,
    protected readonly fb: FormBuilder,
    private notificationService: NotificationsService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.isSendingRequest = true;

    let single = this.data.partnerId != undefined && this.data.businessPartnerId != undefined;

    let apiCallInfo: ApiRequestConfigInfoDefinition = single?
      USERS_API_ENDPOINTS_LIST.generateContingentLettersByBusinessPartners :
      USERS_API_ENDPOINTS_LIST.generateContingentLetters;

    let requestData: {Year: string, Month: string, PartnerId?: string, BusinessPartnerId?: string} = this.contingentForm.getRawValue();

    if(single) {
      requestData.PartnerId = this.data.partnerId;
      requestData.BusinessPartnerId = this.data.businessPartnerId;
    }

    this.requestService.downloadFile(apiCallInfo, requestData)
      .then(_ => {
        this.isSendingRequest = false;
        if(!single) {
          this.notificationService.showNotification({message: 'DownloadRunningNotification', type: "success", showAsNotification: true});
        }
        this.dialogRef.close(true);
      });
  }

}
