import {ApiRequestConfigInfoDefinitionMap} from '../shared/api-request/classes/ApiRequestConfigInfoDefinition';

export const ACCOUNT_API_ENDPOINTS_LIST: ApiRequestConfigInfoDefinitionMap = {
  'forgotPasswordRequestStart': {
    endpoint: '/api/forgot/password/start',
    method: 'POST',
    tokenNotRequired: true
  },
  'forgotPasswordRequestSendOtp': {
    endpoint: '/api/forgot/password/send_otp',
    method: 'POST',
    tokenNotRequired: false
  },
  'forgotPasswordRequestComplete': {
    endpoint: '/api/forgot/password/complete',
    method: 'PUT',
    tokenNotRequired: false
  },
  'forgotPhoneRequestStart': {
    endpoint: '/api/forgot/phone_number/start',
    method: 'POST',
    tokenNotRequired: false
  },
  'forgotPhoneRequestComplete': {
    endpoint: '/api/forgot/phone_number/complete',
    method: 'PUT',
    tokenNotRequired: false
  },
  'registrationComplete': {
    endpoint: '/api/accountregistration/complete',
    method: 'PUT',
    tokenNotRequired: false
  },
  'registrationInfo': {
    endpoint: '/api/accountregistration/info',
    method: 'GET',
    tokenNotRequired: false
  },

  'login1Password': {
    endpoint: '/api/login1Password',
    method: 'POST',
    tokenNotRequired: true
  },
  'login2Otp': {
    endpoint: '/api/login2Otp',
    method: 'POST',
    tokenNotRequired: true
  },
  'verifyEmail': {
    endpoint: '/api/verify/email',
    method: 'POST',
    tokenNotRequired: true
  },
  'profileInfo': {
    endpoint: '/api/profile/info',
    method: 'GET',
    tokenNotRequired: false
  },
  'marketpartners': {
    endpoint: '/api/marketpartners',
    method: 'GET'
  },
  'ftpserver': {
    endpoint: '/api/system/ftpserver',
    method: 'GET'
  },
  'registerCompanyPartner': {
    endpoint: '/api/companyservice/registerCompanyPartner',
    method: 'POST',
    tokenNotRequired: true
  },
  'getCompanyPartner': {
    endpoint: '/api/companypartner/{partnerId}',
    method: 'GET',
    tokenNotRequired: false
  }
};
