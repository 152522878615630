import {Component, OnInit} from '@angular/core';
import {CompanypartnersBaseComponent} from "../companypartners-base.component";

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: []
})
export class ReportsComponent extends CompanypartnersBaseComponent implements OnInit {

  ngOnInit(): void {
    this.initialized = true;
  }
}
