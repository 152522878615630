<page-loading *ngIf="!initialized"></page-loading>

<operator-sidebar-panel *ngIf="isOperatorMode"
  [mpinfo]="marketPartnerInfo"
  [upinfo]="companyPartnerInfo"
  [activeViewMode]="'mpd'"></operator-sidebar-panel>

<div *ngIf="initialized && marketPartnerInfo" id="page-content"
    [class.page-content-with-sidebar]="isOperatorMode"
  >
  <div class="before-panel-info">
    <div class="row">
      <div class="col-sm-8">
        <div class="before-panel-title">
          {{'CommunicationsMpdTitleManage' | translate}}
        </div>
      </div>
    </div>
  </div>

  <div class="panel panel-bordered panel-primary" *ngIf="!this.accessMpdSettings">
    <div class="panel-heading">
      <h3 class="panel-title">{{'CommunicationsNoSettingsTitle' | translate}}</h3>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-xs-12">
          <div class="category-container">
            {{'CommunicationsMpdNoSettingsExceptForVNB' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="panel panel-bordered panel-primary" *ngIf="this.accessMpdSettings">
    <div class="panel-heading">
      <h3 class="panel-title">{{'CommunicationsUseChangeProcessShortCutsTitle' | translate}}</h3>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-xs-12">
          <div class="category-container">
            <div>
              <p>{{'CommunicationsUseChangeProcessShortCutsExplanation' | translate}}</p>
              <div class="panel-form-container">
                <smart-form *ngIf="useChangeProcessShortcutsFormConfig"
                            #useChangeProcessShortcutsForm
                            (onFieldValueChanged)="changedUseChangeProcessShortcuts()"
                            [formConfig]=useChangeProcessShortcutsFormConfig></smart-form>
              </div>
              <span class="text-danger" *ngIf="!getShortcutsValue()">{{'CommunicationsUseChangeProcessShortCutsWarning' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="panel panel-bordered panel-primary" *ngIf="this.accessMpdSettings">
    <div class="panel-heading">
      <h3 class="panel-title">{{'CommunicationsMpdCompletenessTitle' | translate}}</h3>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-xs-12">
          <div class="category-container">
            <div>
              <p>{{'CommunicationsMpdCompletenessExplanation' | translate}}</p>
              <div class="panel-form-container">
                <smart-form *ngIf="mpdCompletenessFormConfig"
                            #mpdCompletenessForm
                            (onFieldValueChanged)="changedMpdCompleteness()"
                            [formConfig]=mpdCompletenessFormConfig></smart-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
