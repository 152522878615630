<page-loading *ngIf="!initialized"></page-loading>

<operator-sidebar-panel *ngIf="isOperatorMode"
                        [mpinfo]="marketPartnerInfo"
                        [upinfo]="companyPartnerInfo"
                        [activeViewMode]="'users'"></operator-sidebar-panel>
<div *ngIf="initialized" id="page-content"
     [class.page-content-with-sidebar]="isOperatorMode"
>
  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base  no-botton-margin">
        <!--Nav tabs-->
        <ul *ngIf="this.isOperator && !this.isOperatorMode" class="nav nav-tabs tabs-left">
          <li *ngIf="isOperator" class="active">
            <a data-toggle="tab" href="#demo-rgt-tab-1" ><i class="pli-conference"></i> {{'UsersTabGlobal' | translate}} <!--<span *ngIf="getInvitesCount()" class="badge badge-success">{{getInvitesCount()}}</span>--></a>
          </li>
          <li [class.active]="(!isOperator)">
            <a data-toggle="tab" href="#demo-rgt-tab-2" ><i class="pli-factory"></i> {{'UsersTabMarketPartners' | translate}}</a>
          </li>
        </ul>

        <!--Tabs Content-->
        <div class="tab-content">
          <div *ngIf="this.isOperator && !this.isOperatorMode" id="demo-rgt-tab-1" class="tab-pane fade active in">
              <smart-table #tableGlobalUsers
                           [offlineData]=dataSetGlobalUsers
                           [tableConfig]=tableGlobalUsersConfig
                           (onRowClicked)="onRowClicked($event)"
                           (onActionClicked)="onActionPerformed($event)"
              ></smart-table>
          </div>
          <div id="demo-rgt-tab-2" class="tab-pane fade" [class.active]="(!this.isOperator || this.isOperatorMode )" [class.in]="(!this.isOperator || this.isOperatorMode)">
            <smart-table #tableMarketPartners
                         [offlineData]=dataSetMarketPartnersUsers
                         [tableConfig]=tableMarketPartnersConfig
                         (onRowClicked)="onRowClicked($event)"
                         (onActionClicked)="onActionPerformed($event)"
            ></smart-table>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
