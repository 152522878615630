import { Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';
import { CustomTranslateService } from './custom-translate.service';

const baseUrl = 'https://manuals.datahub.swisseldex.ch';

@Injectable()
export class UserManualService {

  constructor(
    private sessionService: SessionService,
    private customTranslateService: CustomTranslateService
  ) {
  }

  public getUserManualLink() {
    const deployment = this.sessionService.getDeploymentCategory();
    const instance = deployment === undefined ? 'production' : deployment;
    const deploymentSuffix = deployment === undefined ? '' : `-${deployment}`;
    return `${baseUrl}/${instance}/user-manual${deploymentSuffix}-${this.customTranslateService.currentLang}.pdf`;
  }

  public getUserManualCompanyLink() {
    const deployment = this.sessionService.getDeploymentCategory();
    const instance = deployment === undefined ? 'production' : deployment;
    const deploymentSuffix = deployment === undefined ? '' : `-${deployment}`;
    return `${baseUrl}/${instance}/user-manual-company${deploymentSuffix}-${this.customTranslateService.currentLang}.pdf`;
  }

  public getQuickstartLink(userType: string) {
    switch(userType) {
      case 'mp': return `${baseUrl}/quick_start_guide-${this.customTranslateService.currentLang}.pdf`;
      case 'cp': return `${baseUrl}/quick_start_guide_large_consumers-${this.customTranslateService.currentLang}.pdf`;
      case 'mpa': return `${baseUrl}/quick_start_guide_metering_point_admin_ostral-${this.customTranslateService.currentLang}.pdf`;
    }
    return '';
  }

  public getSerialLetterTemplateLink() {
    const deployment = this.sessionService.getDeploymentCategory();
    const instance = deployment === undefined ? 'production' : deployment;
    return `${baseUrl}/${instance}/ostral-word-template-company-user-invitation-${this.customTranslateService.currentLang}.docx`;
  }
}
