/* eslint-disable no-bitwise */
import {Injectable} from '@angular/core';

import {saveAs} from 'file-saver-es';
import * as pako from 'pako';
import {isEmpty, xor, escape} from "lodash-es";

@Injectable()
export class UtilsService {

  constructor() {
  }

  getRandomUID() {
    return crypto.randomUUID();
  }

  escapeHtml(unsafe?: string): string {
    if (!unsafe) return '';
    return escape(unsafe);
  }

  cloneJSON(jsonObject: any): any {
    return JSON.parse(JSON.stringify(jsonObject));
  }

  createDownloadabeFileFromResponseAndSave(response: any) {
    // console.log(btoa(pako.deflate('column1,column2', { to: 'string' })));
    const base64decodedData = response.dataInfo.base64Encoded !== false ? atob(response.data) : response.data ;
    let unzippedData;
    const uint8 = new Uint8Array(base64decodedData.length);
    for (let i = 0; i <  uint8.length; i++) {
      uint8[i] = base64decodedData.charCodeAt(i);
    }

    if ( response.dataInfo.gzipped !== false) {
      unzippedData = pako.inflate(uint8);
    } else {
      unzippedData = uint8;
    }

    const blob = new Blob([
      unzippedData
    ], {type: response.dataInfo.fileMime});
    saveAs(blob, response.dataInfo.fileName);
  }

  doArraysHaveSameElements(array1: any[], array2: any[]): boolean {
    return isEmpty(xor(array1, array2));
  }

  quotemeta (str: string) {
    // http://kevin.vanzonneveld.net
    // +   original by: Paulo Freitas
    // *     example 1: quotemeta(". + * ? ^ ( $ )");
    // *     returns 1: '\. \+ \* \? \^ \( \$ \)'
    return (str + '').replace(/([\.\\\+\*\?\[\^\]\$\(\)])/g, '\\$1');
  }
  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  flattenObject(ob: any, levelIsArray = false, separator = ':') {
    // https://gist.github.com/slidenerd/04b78a5732f063a6ce6d73ef66a3fb3a
    if (levelIsArray) {
      const toReturnArray: any[] = [];
      if (ob &&  Array === ob.constructor) {
        for (let index = 0; index < ob.length; index++) {
          toReturnArray.push(this.flattenObject(ob[index]));
        }
      }
      return toReturnArray;
    }

    const toReturn: any = {};
    let flatObject: any;
    // @ts-ignore
    for (const i in ob) {
      if (!ob.hasOwnProperty(i)) {
        continue;
      }
      // Exclude arrays from the final result
      // Check this http://stackoverflow.com/questions/4775722/check-if-object-is-array
      if (ob[i] && Array === ob[i].constructor) {
        toReturn[i] = ob[i];
        continue;
        // toReturn[i] = ob[i];
      }
      if ((typeof ob[i]) === 'object') {
        flatObject = this.flattenObject(ob[i]);
        for (const x  in flatObject) {
          if (!flatObject.hasOwnProperty(x)) {
            continue;
          }
          // Exclude arrays from the final result
          if (flatObject[x] && Array === flatObject.constructor) {
            continue;
          }
          toReturn[i + (!!isNaN(Number(x)) ? separator + x : '')] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }

    return toReturn;
  }
  unflattenObject(ob: any) {
    if (!ob) {
      return ob;
    }
    const toReturnObj: any = {};
    Object.keys(ob).forEach(function(thisKey) {
      const idKeySplittedArray: string[] = thisKey.split(':');
      if (idKeySplittedArray) {
        let currentObject = toReturnObj;
        for (let indexArray = 0; indexArray < idKeySplittedArray.length; indexArray++) {
          if (typeof currentObject === 'string') {
            break;
          } else {
            if (!(idKeySplittedArray[indexArray] in currentObject)) {
              currentObject[idKeySplittedArray[indexArray]] = {};
            }
            if (indexArray === (idKeySplittedArray.length - 1)) {
              currentObject[idKeySplittedArray[indexArray]] = ob[thisKey];
            } else {
              currentObject = currentObject[idKeySplittedArray[indexArray]];
            }
          }

        }
      }
    });
    return toReturnObj;
  }
}
