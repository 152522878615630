<div *ngIf="(title || this.actionsToolbar &&  (this.actionsToolbar.length > 0) || showIfEmpty)" class="actions-toolbar-container">
    <div *ngIf="title" class="title">{{title | translate }}</div>
    <div *ngIf="this.actionsToolbar &&  (this.actionsToolbar.length > 0)" class="actions-toolbar">
        <button *ngFor="let actionInfo of actionsToolbar;let actionIndex = index"
             [disabled]="getDisabledState(actionInfo)"
             (click)="actionToolbarButtonClicked(actionInfo.id,actionInfo)"
             class="btn action-toolbar-container {{actionInfo.className}}"
             [class.display-none]="actionInfo.fnVisible && !actionInfo.fnVisible(currentSelectedValue)">
            <i *ngIf="(actionInfo.icon && loadingActionId !== actionInfo.id)" class=" {{actionInfo.icon}}"></i>
            <i *ngIf="(actionInfo.icon && loadingActionId === actionInfo.id)" class="fa fa-spinner fa-spin"></i>

            <div class="action-title"> {{actionInfo.title | translate }}</div>
        </button>
    </div>
</div>
