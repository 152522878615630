import {NgModule, ModuleWithProviders, SkipSelf, Optional} from '@angular/core';

import { CommonModule } from '@angular/common';
import {SessionService} from '../session/session.service';
import {UpdateService} from '../services/update-service';
import {MarketPartnerService} from '../services/marketpartner.service';
import {NotificationsService} from '../services/notifications.service';
import {SmartModalService} from '../marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import {ApiRequestService} from '../api-request/service/api-request.service';
import {EnumUtilsService} from '../services/enum-utils/enum-utils.service';
import {ApiRequestMockupInterceptorService} from '../api-request/service/api-request-mockup-interceptor.service';
import { UserManualService } from '../services/user-manual.service';
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [

  ],
  providers: [
    SessionService,
    UpdateService,
    MarketPartnerService,
    SmartModalService,
    NotificationsService,
    UserManualService,
    ApiRequestService,
    ApiRequestMockupInterceptorService,
    EnumUtilsService
  ],
  exports: [
  ]
})

export class SharedServicesModule {
  constructor (@Optional() @SkipSelf() parentModule: SharedServicesModule) {
    if (parentModule) {
      throw new Error(
        'SharedServicesModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<SharedServicesModule> {
    return {
      ngModule: SharedServicesModule,
      providers: []
    };
  }
}
