import {Pipe, PipeTransform} from '@angular/core';

// source: https://github.com/ngx-translate/core/issues/223#issuecomment-333335132
@Pipe({ name: 'translateCut' })
export class TranslateCut implements PipeTransform {
  transform(value: string, index: string): string {
    const cutIndex = Number(index);
    return value.split('|')[cutIndex];
  }
}
