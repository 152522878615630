import {Injectable} from '@angular/core';
import { ENUM_LIST } from './definitions/enum-list';
import {EnumDefinition, EnumItem, EnumSortType} from './classes/enum-definition';
import {CustomTranslateService} from '../custom-translate.service';
import {TranslateService} from '@ngx-translate/core';
import {sortBy} from 'lodash-es';

@Injectable()
export class EnumUtilsService {
    enumList: EnumDefinition[] = [];
    languageChangedSubscription: any;
    constructor(
        private customTranslateService: CustomTranslateService,
        private translate: TranslateService,
    ) {
        const self = this;
        this.enumList = ENUM_LIST;
        this.resortAllEnums();
        this.languageChangedSubscription = this.customTranslateService.languageChanged.subscribe(
          () => {
           self.resortAllEnums();
          }
        );
    }


  getDefinition(enumType: string): EnumDefinition | undefined {
        return this.enumList.find((value: EnumDefinition, _index: number, _obj: EnumDefinition[]) => value.id === enumType);
    }

    doesEnumTypeExist(enumType: string): boolean {
        return !this.getDefinition(enumType);
    }

    doesEnumValueExist(enumType: string, enumValue: any): boolean {
        const enumDefinition = this.getDefinition(enumType);
        if (!enumDefinition) {
            return false;
        }

        enumValue = enumValue + ''; // Cast to String
        for (let indexList = 0; indexList < enumDefinition.items.length; indexList++) {
            if (enumDefinition.items[indexList].data === enumValue) {
                return true;
            }
        }
        return false;
    }

    setEnumValues(enumType: string, enumItems: EnumItem[], sortType = EnumSortType.Alphabetical) {
      const currentEnumDefinition = this.getDefinition(enumType);
      if (currentEnumDefinition) {
        currentEnumDefinition.items = JSON.parse( JSON.stringify(enumItems));
      } else {
        this.enumList.push(
          this.resortEnumDefinition({
            id: enumType,
            items: JSON.parse( JSON.stringify(enumItems)),
            sortType: sortType
          })
        );
      }
      const modifiedDefinition = this.getDefinition(enumType);
      if (modifiedDefinition) {
        this.resortEnumDefinition(modifiedDefinition);
      }
    }

    getLabelFromEnumValue(enumType: string, enumValue: any): string | undefined {
        const value = enumValue + ''; // Cast to String
        const enumDefinition = this.getDefinition(enumType);
        if (!enumDefinition) {
            return undefined;
        }

        let toReturn: string | undefined;
        for (let indexList = 0; indexList < enumDefinition.items.length; indexList++) {
            if (enumDefinition.items[indexList].data === value) {
                toReturn = enumDefinition.items[indexList].label;
                break;
            }
        }
        return toReturn;
    }

    getLabelFromEnumValues(enumType: string, enumValues: string): string {
        let toReturn = '';
        const self = this;
        const enumValuesArray = enumValues.split(',');
        enumValuesArray.forEach(function(thisEnumValue) {
            if (toReturn !== '') { toReturn += ','; }
            toReturn += self.getLabelFromEnumValue(enumType, thisEnumValue);
        });
        return toReturn;
    }

    getEnumValues(enumType: string): EnumItem[] {
        const enumDefinition = this.getDefinition(enumType);
        if (!enumDefinition) {
            return [];
        }
        return enumDefinition.items;
    }

    getAllEnumValues(enumType: string): string[] {
      const toReturn: string[] = [];
      this.getEnumValues(enumType).forEach(function(thisEnumValue: EnumItem) {
        toReturn.push(thisEnumValue.data);
      });
      return toReturn;
    }

    getEnumTypeDatas(enumType: string): string[] {
        const enumDefinition = this.getDefinition(enumType);
        if (enumDefinition == null) {
            return [];
        }

        const toReturn: string[] = [];
        for (let indexList = 0; indexList < enumDefinition.items.length; indexList++) {
            toReturn.push(enumDefinition.items[indexList].data);
        }
        return toReturn;
    }

    resortAllEnums() {
      const self = this;
      this.enumList.forEach( function(thisEnumDefinition: EnumDefinition) {
        self.resortEnumDefinition(thisEnumDefinition);
      });
    }

    resortEnumDefinition(enumDefinition: EnumDefinition) {
      const self = this;
      const sortType = enumDefinition.sortType ? enumDefinition.sortType : EnumSortType.Alphabetical;
      switch (sortType) {
        case  EnumSortType.Alphabetical:
          const sortedItems = sortBy(enumDefinition.items, o => o.label ? self.translate.instant(o.label) : '');
          enumDefinition.items = sortedItems;
          break;
      }
      return enumDefinition;
    }
}
