import {UserInfoServer} from './UserInfo';
import {MarketPartnerInfo } from './MarketPartnerInfo';
import {CompanyPartnerInfo} from "./CompanyPartnerInfo";

export enum TokenAuthority {
  OPERATORADMIN = 'OPERATORADMIN',
  OPERATORUSER = 'OPERATORUSER',

  MARKETPARTNER = 'MARKETPARTNER',
  // only if MARKETPARTNER or COMPANYPARTNER:
  USER = 'USER',
  ADMIN = 'ADMIN',

  TOKEN_REFRESH = 'TOKEN_REFRESH',
  REGISTRATION = 'REGISTRATION',

  COMPANYPARTNER = 'COMPANYPARTNER',
}

// enum from backend; can be derived from token
export enum UserType {
  MARKETPARTNER = 'MARKETPARTNER',
  OPERATORADMIN = 'OPERATORADMIN',
  OPERATORUSER = 'OPERATORUSER',
  COMPANYPARTNER = 'COMPANYPARTNER',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface SessionInfo {
  authorities: TokenAuthority[];
  partnerId: number;
  partnerName: string;
  partnerInfo: MarketPartnerInfo | null;
  companyPartnerInfo: CompanyPartnerInfo | null;

  user_info: UserInfoServer;
  partners: PartnerInfo[];
  localFtpServer: string;
}

export interface PartnerInfo {   // marketpartner (in some places of the code still called "client")
  partnerId: number;
  partnerName: string;  // not yet used for anything (backend sets this to EIC)
  // partnerEic: string;  // not present
  // role: string;  // present, but not used
}
