/* eslint-disable @angular-eslint/component-selector */
import {Component, OnInit, OnDestroy} from '@angular/core';

import { UpdateService } from '../../shared/services/update-service';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'page-users-dashboard-content',
  templateUrl: './users-dashboard.component.html',
  styleUrls: []
})

export class UsersDashboardComponent implements OnInit, OnDestroy {

  destroy$: Subject<void> = new Subject();

  constructor(private updateService: UpdateService) {
  }

  ngOnInit(): void {
    this.updateService.updatesAvailable$.pipe(
      filter(available => !!available),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      console.log('page reload, reason: swUpdate available while on dashboard');
      window.location.reload();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
