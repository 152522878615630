import {SmartModalConfigDefinition} from '../classes/SmartModalConfigDefinition';
import {SmartFormConfigDefinition} from '../../smart-form/classes/SmartFormConfigDefinition';
import {SmartModalButtonDefinition} from '../classes/SmartModalButtonDefinition';
import {NameValueMap} from '../../smart-shared/classes/KeyValueMap';
import {SpecialModalDefinition} from '../classes/SpecialModalDefinition';

export class SmartModalBuilder {
  private _modalConfig: SmartModalConfigDefinition = {
    textModal: '',
    htmlModal: '',
    onSubmittedFormValue: undefined,
    onSuccessFormValue: undefined,
    onSuccessResponseValue: undefined,
    onActionSelected: undefined,
    closeOnSuccess: true,
    preventBackgroundClose: false,
    bckgNoWhite: false,
    formConfig: undefined,
    title: '',
    size: 'sm',
    buttons: [
      {
        id: 'cancel',
        title: 'commonButtonsNames.cancel',
        icon: undefined,
        visible: false,
        type: 'button',
        cssClasses: 'btn btn-default'
      },
      {
        id: 'confirm',
        title: 'commonButtonsNames.confirm',
        icon: undefined,
        visible: false,
        type: 'button',
        cssClasses: 'btn btn-primary'
      },
    ],
  };

  constructor( initConfig?: SmartModalConfigDefinition  ) {
    if (initConfig) {
      this._modalConfig = initConfig;
    }
  }

  public setModalCSSClassSize(size: 'sm' | 'md' | 'lg' | 'xl'): SmartModalBuilder {
    this._modalConfig.size = size;
    return this;
  }

  public setFormConfigFromInfo(formConfig: SmartFormConfigDefinition): SmartModalBuilder {
    this._modalConfig.formConfig = formConfig;
    return this;
  }

  public setSpecialModalFromInfo(specialModal: SpecialModalDefinition): SmartModalBuilder {
    this._modalConfig.specialModal = specialModal;
    return this;
  }

  public setOnSubmittedForm(fn: (_: NameValueMap<string>) => void): SmartModalBuilder {
    this._modalConfig.onSubmittedFormValue = fn;
    return this;
  }

  public setOnActionSelected(fn: (_: string) => void): SmartModalBuilder {
    this._modalConfig.onActionSelected = fn;
    return this;
  }
  public setOnSuccessForm(fn: (_: NameValueMap<any>) => void): SmartModalBuilder {
    this._modalConfig.onSuccessFormValue = fn;
    return this;
  }
  public setOnSuccessResponse(fn: (responseData: any) => void): SmartModalBuilder {
    this._modalConfig.onSuccessResponseValue = fn;
    return this;
  }
  public setSuccessButtonTitle(buttonTitle: string): SmartModalBuilder {
    if (this._modalConfig.buttons) {
      this._modalConfig.buttons.forEach(function(thisButtonInfo) {
        if (thisButtonInfo.id === 'confirm') {
          thisButtonInfo.title = buttonTitle;
        }
      });
    }
    return this;
  }
  public setTitle(title: string): SmartModalBuilder {
    this._modalConfig.title = title;
    return this;
  }
  public setModalBckgNoWhite(notWhite: boolean): SmartModalBuilder {
    this._modalConfig.bckgNoWhite = notWhite;
    return this;
  }

  public setBodyText(bodyText: string | undefined, asWarning = false): SmartModalBuilder {
    if (bodyText) {
      this._modalConfig.textModal = bodyText;
      this._modalConfig.textAsWarning = asWarning;
    }
    return this;
  }
  public setBodyHtml(bodyHtml: string): SmartModalBuilder {
    this._modalConfig.htmlModal =  bodyHtml;
    return this;
  }

  public showConfirmButton(show: boolean, title?: string): SmartModalBuilder {
    const buttonInfo = this.getButtonInfo('confirm');
    if (buttonInfo) {
      buttonInfo.visible = show;
      if (title !== undefined) {
        buttonInfo.title = title;
      }
    }
    return this;
  }

  setConfirmDangerous(isDangerous: boolean): SmartModalBuilder {
    const buttonInfo = this.getButtonInfo('confirm');
    if (buttonInfo && isDangerous) {
      buttonInfo.cssClasses = 'btn btn-danger';
    }
    return this;
  }

  public showCancelButton(show: boolean, title?: string): SmartModalBuilder {
    const buttonInfo = this.getButtonInfo('cancel');
    if (buttonInfo) {
      buttonInfo.visible = show;
      if (title !== undefined) {
        buttonInfo.title = title;
      }
    }
    return this;
  }

  private getButtonInfo(buttonId: string): SmartModalButtonDefinition | undefined {
    let toReturn;
    if ( this._modalConfig.buttons) {
      this._modalConfig.buttons.forEach(function(buttonInfo: SmartModalButtonDefinition) {
        if (buttonInfo.id === buttonId) {
          toReturn = buttonInfo;
        }
      });
    }
    return toReturn;
  }

  public preventClickOnBackgroundClose(prevent: boolean): SmartModalBuilder {
    this._modalConfig.preventBackgroundClose = prevent;
    return this;
  }

  public addButtonFromInfo(buttonInfo: SmartModalButtonDefinition, insertAt: string = 'start'): SmartModalBuilder {
    if (this._modalConfig.buttons) {
      if (insertAt === 'start') {
        this._modalConfig.buttons.unshift(buttonInfo);
      } else {
        this._modalConfig.buttons.push(buttonInfo);
      }
    }
    return this;
  }

  public build(): SmartModalConfigDefinition {
    return this._modalConfig;
  }
}
