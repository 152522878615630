/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {SmartFormBuilder} from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import {SmartFormConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormConfigDefinition';
import {SmartFormComponent} from '../../shared/marketpartner-components/smart-forms/smart-form/component/smart-form.component';
import {SmartTableBuilder} from '../../shared/marketpartner-components/smart-forms/smart-table/builder/smart-table.builder';
import {SmartTableConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableConfigDefinition';
import {SmartToolbarActionDefinition} from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/SmartToolbarActionDefinition';
import {SdatRequestDialogsService} from '../shared/sdat-request-dialogs.service';

import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {SessionService} from '../../shared/session/session.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';

import {MarketpartnerViewComponent} from '../shared/marketpartner-view.component';

import * as moment from 'moment-timezone';
import { SmartTableComponent } from '../../shared/marketpartner-components/smart-forms/smart-table/component/smart-table.component';
import { DateTimeService } from '../../shared/services/date-time.service';
import { MeteringPointsExportService } from '../users-metering-points/metering-points-export.service';
import { SmartModalBuilder } from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import { SmartModalService } from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import { EnumUtilsService } from '../../shared/services/enum-utils/enum-utils.service';
import {cloneDeep, forOwn, isArray, isEmpty, isObject, omit, sortBy, escape} from "lodash-es";


@Component({
  selector: 'page-users-metering-points-info',
  templateUrl: './users-metering-points-info.component.html',
  styleUrls: ['../users-common.scss', './users-metering-points-info.component.scss']
})

export class MeteringPointsInfoComponent extends MarketpartnerViewComponent {
  mpId = '';
  mpAdminEic = '';
  initialized = false;
  data: any = null;
  displayedEvents: any[] = [];

  accessMpAdminMode = false;
  mpAdminMode = false;

  accessInvalidations = false;
  showInvalidations = false;
  invalidatedCount = 0;

  hideState = false;
  dateSelectFormConfig?: SmartFormConfigDefinition;
  tableEventsConfig?: SmartTableConfigDefinition;
  actionsToolbarState: SmartToolbarActionDefinition[] = [];
  actionsToolbarEvents: SmartToolbarActionDefinition[] = [];

  @ViewChild('dateSelectForm') dateSelectForm?: SmartFormComponent;
  @ViewChild('eventTable') eventTable?: SmartTableComponent;

  constructor(public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              public apiRequestService: ApiRequestService,
              public router: Router,
              public translate: TranslateService,
              public sdatRequestDialogs: SdatRequestDialogsService,
              public dateTimeService: DateTimeService,
              private meteringPointsExportService: MeteringPointsExportService,
              private smartModalService: SmartModalService,
              private enumUtilsService: EnumUtilsService,
             ) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    if (!this.partnerId || !params.mpId || !params.mpAdminEic) return;

    this.mpId = params.mpId;
    this.mpAdminEic = params.mpAdminEic;
    this.accessInvalidations = this.marketPartnerRole === 'VNB';
    this.accessMpAdminMode = this.accessInvalidations || this.accessMeteringPointsUpload;

    const dateOfState = moment().format('YYYY-MM-DD');
    await this.loadData(dateOfState, true);

    this.dateSelectFormConfig = new SmartFormBuilder()
      .setInitData({date: dateOfState})
      .addFormFieldFromInfo({
        name: 'date',
        title: 'MeteringPoints.FormFieldSelectDateOfDisplayedState',
        type: 'date',
        cssClasses: 'col-xs-12',
      })
      .showSubmitButton(false)
      .showCancelButton(false)
      .build();

    this.actionsToolbarState = [];
    this.actionsToolbarEvents = [];
    if (this.marketPartnerRole === 'LF') {
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.SupplyEnd',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.endSupply(this.mpId, this.mpAdminEic);
          await this.reloadContent();
        }});
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.SupplyModify',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.modifySupply(this.mpId, this.mpAdminEic);
          await this.reloadContent();
        }});
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.PartyConnectedToGridChange',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.requestConsumerChange(this.mpId, this.mpAdminEic);
          await this.reloadContent();
        }});
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.PartyConnectedToGridEnd',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.requestConsumerEnd(this.mpId, this.mpAdminEic);
          await this.reloadContent();
        }});
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.ChangeConsumerMasterData',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.changeConsumerMasterData(this.mpId, this.mpAdminEic);
          await this.reloadContent();
        }});

    }
    if (this.accessMeteringPointsDownload) {
      this.actionsToolbarState.push({
        id: '', title: 'MeteringPoints.ActionDownloadState',
        icon: 'pli-download', className: 'btn-primary',
        fnEnabled: () => !!this.data.requestDateOfState,
        fnClicked: () => {
          const date = this.data.requestDateOfState;
          this.downloadMP(date);
        }});
    }
    if (this.marketPartnerRole === 'SDV') {
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.ASPDeregistration',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.deregisterASP(this.mpId, this.mpAdminEic);
          await this.reloadContent();
        }});
    }
    if (this.marketPartnerRole === 'VNB') {
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.RegisterBaseSupply',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.registerBaseSupply(this.mpId);
          await this.reloadContent();
        }});
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.RegisterSubstituteSupply',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.registerSubstituteSupply(this.mpId);
          await this.reloadContent();
        }});
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.ChangeMPMasterData',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.changeMpMasterData(this.mpId);
          await this.reloadContent();
        }});
    }
    if (this.marketPartnerRole && ['LF', 'VNB', 'UNB'].includes(this.marketPartnerRole)){
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.QueryMeasurements',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.queryMeasurements(this.mpId, this.mpAdminEic);
          await this.reloadContent();
        }});
    }
    if (this.marketPartnerRole && ['LF', 'VNB', 'UNB', 'BGV'].includes(this.marketPartnerRole)){
      this.actionsToolbarState.push({
        id: '', title: 'SDATButtons.QueryMeasurementAggregates',
        icon: 'pli-arrow-shuffle', className: 'btn-primary',
        fnClicked: async () => {
          await this.sdatRequestDialogs.queryMeasurementAggregates(this.mpAdminEic);
          await this.reloadContent();
        }});
    }
    this.actionsToolbarState.push({
      id: '', title: 'MeteringPoints.ActionDelete',
      icon: 'pli-trash', className: 'btn-danger',
      fnClicked: () => this.deleteMeteringPoint(),
      // same permissions for delete and "create" (upload)
      fnVisible: () => this.accessMeteringPointsUpload && this.mpAdminMode
    });


    const tableBuilder = new SmartTableBuilder()
      .addColumnFromInfo({id: 'index', title: '', visible: false})  // required for action buttons to work
      .setColumnNameForId('index')
      .addColumnFromInfo({id: 'dueDate', title: 'MeteringPoints.ColumnEventValidDate',
                          fieldType: 'date', displayAs: 'localedateL'})
      .addColumnFromInfo({id: 'transactionTime', title: 'MeteringPoints.ColumnEventTransactionTime',
                          fieldType: 'date', displayAs: 'localedatetimeLss'})
      .addColumnFromInfo({id: 'type', title: 'MeteringPoints.ColumnEventTransactionType',
                          enum: 'transactiontype', sortable: false})
      .addColumnFromInfo({id: 'details', title: 'MeteringPoints.ColumnEventTransactionDetails', sortable: false, showAsHTML: true})
      .addRowActionFromInfo({
        id: '', title: 'MeteringPointEvent.ButtonInvalidateTooltip',
        icon: 'pli-trash', className: 'btn-danger',
        fnVisible: event => (event.type === 'UPDATE' && event.invalidatedBy == null && this.mpAdminMode),
        fnClicked: event => this.invalidateEvent(event.data[0])
      })

      // Note about sorting:
      // smartTable accepts just a single sort key. But we need to sort by ['dueDate', 'transactionTime'].
      // If we set the sorting key on the table, JavaScript will re-sort.
      // But according to MDN, Edge (in contrast to other browsers) does not guarantee stable sort.
      // Because of all this, we just sort the list and don't set the sorting in the table header.
      //
      // .addInitialSorting({columnId: 'dueDate', order: 'desc'})

      .showFilters(false)
      .showSearchField(false);

    if (this.accessMeteringPointsDownload) {
      this.actionsToolbarEvents.push({id: '', title: 'MeteringPoints.ActionDownloadEvents',
                                      icon: 'pli-download', className: 'btn-primary',
                                      fnClicked: () => this.downloadMP() });
    }

    this.tableEventsConfig = tableBuilder.build();

    this.setInitialized(true);
  }

  downloadMP(date?: string) {
    return this.meteringPointsExportService.downloadSingleMeteringPoint(this.mpId, this.mpAdminEic, date);
  }

  async loadData(dateOfState: string, navigateBackOnError: boolean = false) {
    if (!this.partnerId) return;
    const [year, month, day] = dateOfState.split('-');
    const date = {year: parseInt(year, 10), month: parseInt(month, 10), day: parseInt(day, 10)};
    const apiRequest = new ApiRequestBuilder()
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.meteringPointsDetails)
      .setBodyInfo({
        partnerId: this.partnerId,
        meteringPoint: {
          id: this.mpId,
          administratorEic: this.mpAdminEic,
        },
        date
      })
      .setHandleErrorNotification(!navigateBackOnError)
      .build();

    const result = await this.apiRequestService.callApi(apiRequest);
    if (result.status !== 'success') {
      if (navigateBackOnError) {
        // If you change the EIC or if you logged in/out in another tab (which
        // triggers a reload) you may no longer have access. Cover up the error.
        this.goBack();
      }
      return;
    }

    this.data = {
      ...result.data,
      requestDateOfState: dateOfState,
      displayDateOfState: moment(dateOfState).format('L'),
      state: this.createStatePresentation(result.data.state)
    };
    this.updateEventTable();
  }

  toggleInvalidations(value: boolean) {
    this.showInvalidations = value;
    this.updateEventTable();
  }

  toggleMpAdminMode(value: boolean) {
    this.mpAdminMode = value;
    this.updateEventTable();
  }

  async deleteMeteringPoint() {
    // Step 1: confirm with password, and get token
    const formConfig = new SmartFormBuilder()
      .addRequired({name: 'password', type: 'password',
                    title: 'FormConfirmWithPassword', cssClasses: 'col-sm-12'})
      .setApiSendDataRequestConfigFromInfo(new ApiRequestBuilder().setEndpointInfo({
        endpoint: '/api/token/getMPDirAdmin',
        method: 'POST'
      }).allowAuthErrors(true).build())
      .showSubmitButton(true, 'MeteringPoints.DeleteConfirmButton')
      .addButtonFromInfo({
        id: '', title: 'MeteringPoints.ActionDownloadEvents',
        icon: 'pli-download', cssClasses: 'btn btn-primary',
        fnClicked: () => this.downloadMP()
      })
      .setSubmitDangerous(true)
      .showCancelButton(true)
      .build();
    const modalOptions = new SmartModalBuilder()
      .setTitle('MeteringPoints.DeleteConfirmTitle')
      .setBodyText('MeteringPoints.DeleteConfirmText')
      .setModalCSSClassSize('lg')
      .setFormConfigFromInfo(formConfig)
      .build();
    const successResponse = await this.smartModalService.showModalAwaitAPISuccess(modalOptions);
    const token = successResponse.mpdirAdminToken;

    // Step 2: actual delete
    const apiRequest = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.meteringPointsDeleteMeteringPoint)
      .setOverrideToken(token)
      .allowAuthErrors(true)
      .setBodyInfo({
        partnerId: this.partnerId,
        meteringPoint: {
          id: this.mpId,
          administratorEic: this.mpAdminEic,
        },
      })
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiRequest);
    if (resultApiRequest.status === 'success') {
      this.goBack();
    }
  }

  async invalidateEvent(event: any) {
    // Step 1: confirm with password, and get token
    const formConfig = new SmartFormBuilder()
      .addRequired({name: 'password', type: 'password',
                    title: 'FormConfirmWithPassword', cssClasses: 'col-sm-12'})
      .setApiSendDataRequestConfigFromInfo(new ApiRequestBuilder().setEndpointInfo({
        endpoint: '/api/token/getMPDirAdmin',
        method: 'POST'
      }).allowAuthErrors(true).build())
      .showSubmitButton(true, 'MeteringPointEvent.InvalidateConfirmButton')
      .setSubmitDangerous(true)
      .showCancelButton(true)
      .build();
    const modalOptions = new SmartModalBuilder()
      .setTitle('MeteringPointEvent.InvalidateConfirmTitle')
      .setBodyText('MeteringPointEvent.InvalidateConfirmText')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();
    const successResponse = await this.smartModalService.showModalAwaitAPISuccess(modalOptions);
    const token = successResponse.mpdirAdminToken;

    // Step 2: actual invalidation
    const apiRequest = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.meteringPointsInvalidateEvent)
      .setOverrideToken(token)
      .allowAuthErrors(true)
      .setBodyInfo({
        partnerId: this.partnerId,
        meteringPoint: {
          id: this.mpId,
          administratorEic: this.mpAdminEic,
        },
        eventIndex: event.index
      })
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiRequest);
    if (resultApiRequest.status === 'success') {
      this.reloadContent();
      this.marketPartnerService.updateUnsuppliedCount();
    }
  }

  formatAsReference(event: any) {
    return this.translate.instant('MeteringPointEvent.InvalidationReference', {
      transactionTime: this.dateTimeService.getDateTimeAsLocaleFormatLinux(event.transactionTime, true)
    });
  }

  private createStatePresentation(state: any) {
    state = cloneDeep(state);
    state.notAvailable = isEmpty(state.assigned);
    if (state.unassigned) {
      const since = this.dateTimeService.getMomentFromJsonDate(state.unassigned.since);
      if (since) state.displayDateAssignedSince = since.format('L');
      const until = this.dateTimeService.getMomentFromJsonDate(state.unassigned.until);
      if (until) state.displayDateAssignedUntil = until.format('L');
    }
    if (state.assigned) {
      const stateUntil = this.dateTimeService.getMomentFromJsonDate(state.assigned.stateUntil);
      if (stateUntil) {
        state.displayDateValidUntil = stateUntil.format('L');
      } else {
        state.displayDateValidUntil = this.translate.instant('MeteringPoints.DateOfStateValidForever');
      }
      console.log('state.displayDateValidUntil:', state.displayDateValidUntil);
      const parties = state.parties;
      if (parties) {
        parties.ancillaryServiceProviderEics = parties.ancillaryServiceProviderEics.join(', ');
      }
    }
    return state;
  }

  async onDateSelected() {
    if (!this.dateSelectForm) return;
    const date = this.dateSelectForm.getCurrentFormFieldValue('date');
    if (!date) return;

    if (this.data && this.data.requestDateOfState === date) return;
    await this.reloadContent();
  }

  async reloadContent() {
    // make sure we never show old data that doesn't match the selected date (in case of error)
    this.hideState = true;

    if (!this.eventTable) return;
    if (!this.dateSelectForm) return;
    // Loading "animation" to confirm to the user that the data was updated.
    const timeout = new Promise(resolve => setTimeout(resolve, 100));
    const date = this.dateSelectForm.getCurrentFormFieldValue('date');
    await Promise.all([this.loadData(date), timeout]);

    this.updateEventTable();
    this.hideState = false;
  }

  updateEventTable() {
    // augment and sort raw events
    const allEvents = this.preprocessEvents(this.data.events);

    // render event details
    allEvents.forEach((item: any) => {
      const invalidatedBy = item.invalidatedBy;
      const details = omit(item, ['dueDate', 'transactionTime', 'type', 'eventIndex', 'invalidatedBy', 'index']);
      if (!isEmpty(details)) {
        let html = this.renderEventSubtree('', details);
        if (invalidatedBy) {
          html = '<div style="opacity: 0.5;">' + html + '</div>';
          let redText = this.formatAsReference(invalidatedBy);
          redText += this.renderEventSubtree('', invalidatedBy.transactionReason);
          redText = '<b style="color:#800;">' + redText + '</b>';
          html = redText + html;
        }
        // tooltip for debugging:
        // html = '<span title="' + escape(JSON.stringify(details)) + '">' + html + '</span>';
        item.details = html;
      }
    });

    // show/hide invalidations
    this.invalidatedCount = allEvents.filter((item: any) => !!item.invalidatedBy).length;
    if (this.showInvalidations || !this.accessInvalidations) {
      this.displayedEvents = allEvents;
    } else {
      this.displayedEvents = allEvents.filter(
        (event: any) => event.type !== 'INVALIDATE' && event.invalidatedBy == null
      );
    }
    if (this.eventTable) {
      this.eventTable.reloadNewData(this.displayedEvents);
    }
  }

  preprocessEvents(originalEvents: any[]): any[] {
    // clone because we may process the original data multiple times to render different views
    let events = cloneDeep(originalEvents);

    events.forEach((e: any, index: number) => {
      e.index = index;
      if (this.accessInvalidations) {
        // For VNBs, the list is sorted by DB order.
        // List index is used as reference for invalidations.
        if (e.type === 'INVALIDATE') {
          const idx = e.eventIndex;
          if (idx == null) throw new Error('INVALIDATE event without eventIndex');
          const target = events[idx];
          target.invalidatedBy = e;
        }
      }

    });
    events = events.filter(e => e.type !== 'INVALIDATE');

    // modify "transactionTime" so sorting by it will also sort by order
    events.forEach((e: any, index: number) => {
      if (e.transactionTime) {
        e.transactionTime = moment(e.transactionTime)
          .milliseconds(index)
          .toISOString();
      }
    });

    // The backend used to do sorting, but if invalidations are possible we now
    // get the list in DB order to make references work.
    //
    // stable sort by 1. dueDate, 2. transactionTime
    events = sortBy(events, [
      e => this.dateTimeService.getMomentFromJsonDate(e.dueDate),
      e => moment(e.transactionTime)
    ]);

    return events.reverse();
  }

  goBack() {
    this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
  }

  translateMaybe(prefix: string, s: string) {
    const translate = prefix + '.' + s;
    const res = this.translate.instant(translate);
    return (res && res !== translate) ? res : s;
  }

  renderEventKey(key: string) {
    const label = this.translateMaybe('MeteringPointEvent.Keys', key);
    if (!label) return '';
    return escape(label) + ': ';
  }

  renderEventContent(key: string, content: string) {
    let label = 'MeteringPointEvent.Enums.' + key;
    if (key === 'causingProcessType') {
      label = 'SDATBusinessReason';
    }

    label = this.translateMaybe(label, content);
    if (label == null) label = '-';
    return '<span class="value">' + escape(label) + '</span>';
  }

  renderArray(key: string, content: any[]): string {
    if (content.length === 0) return '-';
    const onlyStrings = content.reduce((acc, item) => acc && (typeof item === 'string'), true);
    if (onlyStrings) {
      return content.map(s => this.renderEventContent(key, s)).join(', ');
    } else {
      // just repeating the key is the simples approach, layout-wise
      const keyHtml = this.renderEventKey(key);
      return content.map(obj => this.renderEventSubtree('', obj)).join(keyHtml);
      // bullet points don't look right:
      // const html = content.map(obj => this.renderEventSubtree('', obj)).join('</li><li>');
      // return '<ul class="tree-item tree-array"><li>' + html + '</li></ul>';
    }
  }

  shouldHide(key: string, content: any): boolean {
    if (key === 'aspUpdates' && content && content.length === 0) return true;
    if (key === 'gridBillingMethod' && content === 'GRID_BILLING_METHOD_UNSPECIFIED') return true;
    if (key === 'internet' && content && content.length === 0) return true;
    if (key === 'email' && content && content.length === 0) return true;
    if (key === 'phone' && content && content.length === 0) return true;
    return false;
  }

  renderEventSubtree(key: string, content: any): string {
    if (this.shouldHide(key, content)) return '';
    let html = this.renderEventKey(key);
    if (isArray(content)) {
      html += this.renderArray(key, content);
    } else if (isObject(content)) {
      html += '<ul class="tree-item">';
      forOwn(content, (value, key2) => {
        html += '<li>' + this.renderEventSubtree(key2, value) + '</li>';
      });
      html += '</ul>';
    } else if (typeof content === 'boolean') {
      const translate = content ? 'LabelYesornoYes' : 'LabelYesornoNo';
      const res = this.translate.instant(translate);
      const label = (res && res !== translate) ? res : content;
      html += this.renderEventContent(key, label);
    } else {
      html += this.renderEventContent(key, content);
    }
    return html;
  }

  mapYesOrNo(yesOrNo: boolean): string | undefined {
    return this.enumUtilsService.getLabelFromEnumValue('yesOrNo', yesOrNo ? 1 : 0);
  }
}
