export enum ColumnFilterOperator {
  NotContains = 'notcontains',
  Contains = 'contains',
  Equals = 'equals',
  NotEquals = 'notequals',
  FromToDate = 'fromtodate'
}

export interface TableColumnNameFilter {
  operator: ColumnFilterOperator;
  value: any;
  columnName: string;
}

export interface TableColumnFilter {
  operator: ColumnFilterOperator;
  value: any;
}
