export class ChartStyling {
  public static readonly contingentColor = '#51ABD4';
  public static readonly contingentBackgroundColor = 'transparent';
  public static readonly contingentLightColor = 'rgba(81, 171, 212, 0.2)';

  public static readonly currentColor = '#F9BE3C';
  public static readonly currentBackgroundColor = 'rgba(249,190,60,0.2)';

  public static readonly previousColor = '#999999';
  public static readonly previousBackgroundColor = 'rgba(153,153,153,0.2)';

  public static readonly selectedBackgroundColor = 'rgba(81, 171, 212, 0.2)';

  public static readonly warningColor= '#FF0000';
  public static readonly warningBackgroundColor= 'rgba(255, 0, 0, 0.2)';

  public static readonly fontFamily = "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif";
  public static readonly fontSize = 14;
  public static readonly footerWeight = '900';

  public static getOptions(pluginOptions: any, max: number, unit: string) {
    return {
      responsive: true,
      locale: 'DE-ch',
      maintainAspectRatio: false,
      font: {
        family: ChartStyling.fontFamily,
        size: ChartStyling.fontSize
      },
      scales: {
        y: {
          min: 0,
          suggestedMax: max,
          ticks: {
            precision: 0
          },
          title: {
            display: true,
            text: unit,
            align: 'end'
          }
        }
      },
      layout: {
        padding: {
          right: 10
        }
      },
      elements: {
        point: {
          radius: 0
        }
      },
      plugins: pluginOptions,
      interaction: {
        intersect: false,
        mode: "index"
      }
    } as const;
  }

}
