import {Component, Input} from '@angular/core';
import {MatIconComponent} from "../../../shared/material-components/mat-icon-component/mat-icon.component";

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: []
})
export class IconComponent extends MatIconComponent {
  @Input() icon: string = '';
}
