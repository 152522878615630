import {ApiRequestConfigDefinition} from '../classes/ApiRequestConfigDefinition';
import {ApiRequestConfigInfoDefinition} from '../classes/ApiRequestConfigInfoDefinition';
import {NameValueMap} from '../../marketpartner-components/smart-forms/smart-shared/classes/KeyValueMap';

export class ApiRequestBuilder {
    private _apiRequestConfig: ApiRequestConfigDefinition = {
        info: undefined,
        body: undefined,
        options: {
            handleLoading : false,
            handleErrorNotification: false
        }
    };
    constructor(initConfig?: ApiRequestConfigDefinition) {
        if (initConfig) {
            this._apiRequestConfig =  JSON.parse(JSON.stringify(initConfig));
        }
    }

    public setEndpointInfo(endpointInfo: ApiRequestConfigInfoDefinition, replaceUrl?: NameValueMap<string|number>): ApiRequestBuilder {
        if (!endpointInfo) throw new Error('endpointInfo missing');
        this._apiRequestConfig.info = JSON.parse(JSON.stringify(endpointInfo));
        if (replaceUrl && this._apiRequestConfig.info) {
            this._apiRequestConfig.info.replaceUrl = replaceUrl;
        }
        return this;
    }

    public setOverrideToken(token: string): ApiRequestBuilder {
        if (!this._apiRequestConfig.info) throw new Error('this must be called after setEndpointInfo()');
        if (!token) throw new Error('setOverrideToken() was called with empty token');
        this._apiRequestConfig.info.overrideToken = token;
        return this;
    }

    public allowAuthErrors(allow: boolean): ApiRequestBuilder {
        if (!this._apiRequestConfig.info) throw new Error('this must be called after setEndpointInfo()');
        this._apiRequestConfig.info.allowAuthErrors = allow;
        return this;
    }

    public setBodyFormData(form: FormData): ApiRequestBuilder {
        this._apiRequestConfig.body = form;
        return this;
    }

    public setBodyInfo(bodyInfo: NameValueMap<any>): ApiRequestBuilder {
        this._apiRequestConfig.body = JSON.parse(JSON.stringify(bodyInfo));
        return this;
    }

    public addBodyInfo(bodyInfo: NameValueMap<any>): ApiRequestBuilder {
        const self = this;
        if (bodyInfo) {
            if (!self._apiRequestConfig.body) {
              self._apiRequestConfig.body =  {};
            }
            Object.keys(bodyInfo).forEach(function(thisIdKey) {
                if (self._apiRequestConfig.body) {
                  if (self._apiRequestConfig.body instanceof FormData) {
                    throw new Error('cannot currently add fields to FormData body');
                  }
                  self._apiRequestConfig.body[thisIdKey] = bodyInfo[thisIdKey];
                }
            });
        }
        return this;
    }

    public setHandleLoading(handleLoading: boolean): ApiRequestBuilder {
        if (this._apiRequestConfig.options) {
          this._apiRequestConfig.options.handleLoading = handleLoading;
        }
        return this;
    }

    public setHandleErrorNotification(handleErrorNotification: boolean): ApiRequestBuilder {
        this._apiRequestConfig.options.handleErrorNotification = handleErrorNotification;
        return this;
    }

    public build(): ApiRequestConfigDefinition {
      return this._apiRequestConfig;
    }
}
