<page-loading-spinner [initialized]="initialized"></page-loading-spinner>
<div class="root fixed-header">
  <div class="headline-space-button">
    <h1>{{prefix+'allMeteringPoints' | translate}}</h1>
    <div>
      <button *ngIf="isAdmin" mat-flat-button color="primary" (click)="openUniteDialog('otherToSelf')" id="uniteCompaniesOTS">
        {{prefix+'uniteCompaniesOTS' | translate}}
      </button>
      <button *ngIf="isAdmin" mat-flat-button color="primary" (click)="openUniteDialog('selfToOther')" id="uniteCompaniesSTO">
        {{prefix+'uniteCompaniesSTO' | translate}}
      </button>
    </div>
  </div>
  <metering-points-table [meteringPoints]="meteringPoints" [showAction]="true"></metering-points-table>
</div>
