<page-loading *ngIf="!initialized"></page-loading>

<div *ngIf="initialized" id="page-content">
  <div class="before-panel-info">
    <div class="row">
      <div class="col-sm-8">
        <div (click)="goBack()" class="before-panel-title go-back-nav-button">
          <i class="fa fa-chevron-circle-left">
          </i>
          <div>{{'ColumnChangeProcess' | translate}} </div>
        </div>
      </div>
      <div class="col-sm-4">
      </div>
    </div>
  </div>
  <div class="row" *ngFor="let message of messages">
    <div class="col-sm-12">
      <div class="tab-base">
        <!--Tabs Content-->
        <div class="tab-content">
          <div class="tab-pane fade active in">
            <app-sdat-message
              [message]="message"
              [lastResponses]="lastResponses"
              (reloadRequest)="reload()"
            ></app-sdat-message>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
