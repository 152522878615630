import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, combineLatest, interval, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomTranslateService} from '../../shared/services/custom-translate.service';
import {NotificationsService} from '../../shared/services/notifications.service';
import {SmartModalBuilder} from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import {SmartModalService} from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {SessionService} from '../../shared/session/session.service';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';

interface BackendImportStatus {
  noUpload?: {};
  scheduled?: {};
  validating?: number;
  running?: BackendImportProgress;
  finished?: any;  // it's complicated
}
interface BackendImportProgress {
  processed: number;
  total: number;
}
interface Accepted {
  warnings: any[];
  errors: any[];
}
interface Rejected {
  errors: any[];
}
interface BackendImportResult {
  accepted?: Accepted;
  rejected?: Rejected;
}

export interface FrontendImportStatus {
  status: 'NONE' | 'UPLOADING' | 'SCHEDULED' | 'VALIDATING' | 'IMPORTING' | 'FINISHED';
  percentage?: number;
  items_processed?: number;
  not_updating?: boolean;
  result?: BackendImportResult;
}

@Injectable()
export class MeteringPointsImportService {
  public importStatus$ = new BehaviorSubject<FrontendImportStatus>({status: 'NONE' });
  private backendImportStatus$ = new BehaviorSubject<BackendImportStatus|undefined>(undefined);
  private backendImportStatusTimeout$ = new BehaviorSubject<boolean>(false);
  private uploading$ = new BehaviorSubject<boolean>(false);
  private pollSubscription?: Subscription;

  constructor(
    private smartModalService: SmartModalService,
    private customTranslateService: CustomTranslateService,
    private notificationsService: NotificationsService,
    private apiRequestService: ApiRequestService,
    private sessionService: SessionService,
  ) {
    let obs: Observable<FrontendImportStatus>;
    obs = combineLatest([this.uploading$, this.backendImportStatus$, this.backendImportStatusTimeout$])
      .pipe(
        map(([uploading, backendImportStatus, timeout]) => {
          let res: FrontendImportStatus;
          if (uploading) {
            res = { status: 'UPLOADING' };
          } else if (backendImportStatus === undefined) {
            res = { status: 'NONE' };
          } else {
            res = this.convertImportStatus(backendImportStatus);
          }
          if (timeout) res.not_updating = true;
          return res;
        })
      );
    obs.subscribe(this.importStatus$);
  }

  private convertImportStatus(status: BackendImportStatus): FrontendImportStatus {
    if (status.noUpload) {
      return {status: 'NONE'};
    } else if (status.scheduled) {
      return {status: 'SCHEDULED'};
    } else if (status.validating !== undefined) {
      return {
        status: 'VALIDATING',
        items_processed: status.validating,
      };
    } else if (status.running) {
      // always show at least 1%
      const percentage = Math.max(1, 100 * status.running.processed / Math.max(status.running.total, 1));
      return {
        status: 'IMPORTING',
        percentage,
        items_processed: status.running.processed,
      };
    } else if (status.finished) {
      return {
        status: 'FINISHED',
        result: status.finished
      };
    }
    console.error('status content not supported:', status);
    return {status: 'NONE'};
  }

  public startPolling() {
    this.updateUploadStatus();
    if (this.pollSubscription) return;
    const polling_interval_ms = 3000;
    this.pollSubscription = interval(polling_interval_ms)
      .subscribe(() => this.updateUploadStatus());
  }

  public stopPolling() {
    if (this.pollSubscription) this.pollSubscription.unsubscribe();
    this.pollSubscription = undefined;
  }

  public async startUpload(file: File) {
    // backend needs multipart to extract the original filename
    const formData = new FormData();
    formData.append('file', file);

    const requestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(false)  // show as dialog instead
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.meteringPointsUpload)
      .setBodyFormData(formData)
      .build();
    this.uploading$.next(true);
    let uploadResult;
    try {
      uploadResult = await this.apiRequestService.callApi(requestConfig);
      await this.updateUploadStatus();
    } finally {
      this.uploading$.next(false);
    }
    if (!uploadResult || uploadResult.status !== 'success') {
      let msg;
      if (uploadResult && uploadResult.data) {
        msg = await this.apiRequestService.convertApiErrorToString(uploadResult.data);
      }
      this.notificationsService.showNotification(
        {type: 'error', message: 'MeteringPoints.ImportUploadFailedText'}
      );
      // show as dialog, because a toast if easy to miss after 5min waiting
      const modalOptions = new SmartModalBuilder()
        .setModalCSSClassSize('md')
        .setTitle('MeteringPoints.ImportUploadFailedTitle')
        .setBodyText(msg || 'MeteringPoints.ImportUploadFailedText')
        .showCancelButton(true, 'ModalCloseButton')
        .build();
      await this.smartModalService.showModal(modalOptions);
      await this.updateUploadStatus();
    }
  }

  private async updateUploadStatus(): Promise<null | BackendImportStatus> {
    const requestConfig = new ApiRequestBuilder()
      .setHandleLoading(false)  // we handle request timeout manually
      .setHandleErrorNotification(false)  // we handle errors manually
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.meteringPointsUploadStatus)
      .setBodyInfo({partnerId: this.sessionService.getCurrentPartnerId()})
      .build();

    const status_timeout_ms = 10000;
    const resultP = this.apiRequestService.callApi(requestConfig);
    const timeoutP = new Promise(resolve => setTimeout(resolve, status_timeout_ms));
    const result = await Promise.race([resultP, timeoutP]);
    if (result && result.status === 'success') {
      // const mockData: BackendImportStatus = {scheduled: {}};
      // const mockData: BackendImportStatus = {validating: 12};
      // const mockData: BackendImportStatus = {running: {processed: 9, total: 112}};
      // const mockData: BackendImportStatus = {finished: { error: {}}};
      // const mockData: BackendImportStatus = {finished: { ok: { warnings: [{}, {}]}}};
      // if (true) result.data = mockData;
      this.backendImportStatusTimeout$.next(false);
      this.backendImportStatus$.next(result.data);
      return result.data;
    } else {
      this.backendImportStatusTimeout$.next(true);
      return null;
    }
  }

  private async acknowledgeUpload() {
    const requestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.meteringPointsUploadAcknowledge)
      .setBodyInfo({partnerId: this.sessionService.getCurrentPartnerId()})
      .build();
    return this.apiRequestService.callApi(requestConfig);
  }

  public async showImportResult(result: BackendImportResult) {
    try {
      const dialogSuccess = await this.showImportResultInternal(result);
      if (dialogSuccess) {
        this.importStatus$.next({status: 'NONE'});  // FIXME: why was this needed? causes glitch
        await this.acknowledgeUpload();
        await this.updateUploadStatus();
      }
    } catch (error) {
      console.error(error);
      // next time the backend changes API field names, don't fail silently
      this.notificationsService.showNotification(
        {type: 'error', message: 'UNKNOWN ERROR (js exception)'}
      );
    }
  }

  private async showImportResultInternal(result: BackendImportResult) {
    const lang = this.customTranslateService.getCurrentLangShort();
    const modalOptions = new SmartModalBuilder();
    if (result.accepted) {
      const warnings = result.accepted.warnings
        .concat(result.accepted.errors)
        .map((msg: any) => msg[lang]);
      if (warnings.length === 0) {
        modalOptions
          .setModalCSSClassSize('md')
          .setTitle('MeteringPoints.ImportSuccessTitle')
          .setBodyText('MeteringPoints.ImportSuccessText');
      } else {
        modalOptions
          .setModalCSSClassSize('lg')
          .setTitle('MeteringPoints.ImportSuccessWithNotificationsTitle')
          .setBodyText('MeteringPoints.ImportSuccessWithNotificationsText')
          .setSpecialModalFromInfo({type: 'errorlist', data: {warnings}});
      }
    } else if (result.rejected) {
      const errors = result.rejected.errors.map((msg: any) => msg[lang]);
      modalOptions
        .setModalCSSClassSize('lg')
        .setTitle('MeteringPoints.ImportFailedTitle')
        .setBodyText('MeteringPoints.ImportFailedText')
        .setSpecialModalFromInfo({type: 'errorlist', data: {errors}});
    } else {
      console.error(result);
      throw new Error('invalid response status');
    }
    modalOptions
      .showCancelButton(true, 'ModalCloseButton')
      .showConfirmButton(true, 'ModalConfirmButton');
    return this.smartModalService.showModal(modalOptions.build());
  }
}
