/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {SessionService} from '../../shared/session/session.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';

import {ActivatedRoute, Router} from '@angular/router';
import {SmartFormBuilder} from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import {SmartFormConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormConfigDefinition';

import {SmartFormComponent} from '../../shared/marketpartner-components/smart-forms/smart-form/component/smart-form.component';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {MarketpartnerViewComponent} from '../shared/marketpartner-view.component';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';

@Component({
  selector: 'page-users-marketpartner-communication-mpd-content',
  templateUrl: './users-marketpartner-communication-mpd.component.html',
  styleUrls: ['../users-common.scss']
})


export class MarketpartnerCommunicationMpdComponent extends MarketpartnerViewComponent {
  @ViewChild('useChangeProcessShortcutsForm')  useChangeProcessShortcutsForm?: SmartFormComponent;
  @ViewChild('mpdCompletenessForm')  mpdCompletenessForm?: SmartFormComponent;

  sendMpdSettings$: Subject<null> = new Subject();

  useChangeProcessShortcutsFormConfig?: SmartFormConfigDefinition;
  mpdCompletenessFormConfig?: SmartFormConfigDefinition;
  accessMpdSettings = false;


  constructor(public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              public apiRequestService: ApiRequestService,
              public router: Router) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);

    // Note: debouncing is to avoid current-state confusion when reading the
    // from-state from an event handler. The original code had this bug. We
    // could instead use angular reactive forms as intended, but probably not
    // in combination with multiple SmartForms.
    //
    this.sendMpdSettings$.pipe(
      debounceTime(200)
    ).subscribe(() => this.sendMpdSettings());
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);

    if (this.marketPartnerInfo) {
      const mpi = this.marketPartnerInfo;
      this.accessMpdSettings = this.marketPartnerService.canAccessMeteringPointsUpload(mpi);

      this.useChangeProcessShortcutsFormConfig = new SmartFormBuilder()
        .addOptional({
          type: 'checkboxmulti',
          name: 'use_change_process_shortcuts',
          cssClasses: 'chechboxes-2-columns',
          disabled: this.isOperatorMode,
          allowedValues: {
            type: 'globalRegister',
            data: 'usechangeprocessshortcuts'
          },
        })
        .setInitData({ use_change_process_shortcuts: mpi.meteringPointsDirectorySettings.useChangeProcessShortcuts ? '1' : '' })
        .showSubmitButton(false)
        .showCancelButton(false)
        .build();

      this.mpdCompletenessFormConfig = new SmartFormBuilder()
        .addOptional({
          type: 'checkboxmulti',
          name: 'complete',
          cssClasses: 'chechboxes-2-columns',
          disabled: this.isOperatorMode,
          allowedValues: {
            type: 'globalRegister',
            data: 'meteringPointsFlagCheckbox'
          },
        })
        .setInitData({ complete: mpi.meteringPointsDirectorySettings.meteringPointsCompleteFlag === 'COMPLETE' ? '1' : '' })
        .showSubmitButton(false)
        .showCancelButton(false)
        .build();
    }

    this.setInitialized(true);
  }

  changedUseChangeProcessShortcuts() {
    this.sendMpdSettings$.next(null);
  }

  getShortcutsValue(): boolean {
    if (!this.useChangeProcessShortcutsForm) {
      if (!this.marketPartnerInfo) return true;
      return this.marketPartnerInfo.meteringPointsDirectorySettings.useChangeProcessShortcuts;
    } else {
      return this.useChangeProcessShortcutsForm.getCurrentFormFieldValue('use_change_process_shortcuts') === '1';
    }
  }

  changedMpdCompleteness() {
    this.sendMpdSettings$.next(null);
  }

  async sendMpdSettings() {
    if (!this.partnerId) return;
    if (!this.mpdCompletenessForm) throw new Error('unreachable');
    if (!this.useChangeProcessShortcutsForm) throw new Error('unreachable');

    const dataToSend = {
      meteringPointsCompleteFlag: this.mpdCompletenessForm.getCurrentFormFieldValue('complete') === '1' ? 'COMPLETE' : 'INCOMPLETE',
      useChangeProcessShortcuts: this.useChangeProcessShortcutsForm.getCurrentFormFieldValue('use_change_process_shortcuts') === '1',
    };
    console.log('dataToSend:', dataToSend);

    const requestConfig = new ApiRequestBuilder()
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerMeteringPointsDirectorySettingsUpdate, {partnerId: this.partnerId})
      .setBodyInfo(dataToSend)
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .build();
    await this.apiRequestService.callApi(requestConfig);
  }
}
