/* eslint-disable max-len */

export const APIREQUEST_MOCKUP_DATASOURCES_DEFS: any = {
  'MessagesLog': {
    data: [
      {'uuid': '1', 'timestampIn': '2019-01-08T16:14:51.123456789Z', 'timestampOut': '2019-01-08T16:31:31.987654321Z', 'senderEic': '12XEWZ--VNB--ZHR', 'recipientEic': '12X-0000000858-F', 'messageId': '100001', 'parentId': null, 'processId': 'P01', 'processTypeCode': 'A03', 'messageTypeCode': 'D101', 'messageStatus': 'PROCESSED'},
      {'uuid': '2', 'timestampIn': '2019-01-08T16:18:53.123456789Z', 'timestampOut': '2019-01-08T16:41:12.987654321Z', 'senderEic': '12X-0000000858-F', 'recipientEic': '12XEWZ--VNB--ZHR', 'messageId': '100002', 'parentId': '100001', 'processId': 'P01', 'processTypeCode': 'A03', 'messageTypeCode': 'D102', 'messageStatus': 'SENT'},
    ],
    entityPropertyForId: 'id'
  },

  'MeteringPoints': {
    data: [
      {'id': '1', 'eligibleForSwitch': false, 'startDate': '2008-04-26T15:03:52Z', 'balanceResponsible': 'Some One', 'balanceSupplier': 'Some Supplier', 'ancillaryServiceProvider': 'Some Provider'},
      {'id': '2', 'eligibleForSwitch': true, 'startDate': '2008-05-26T15:03:52Z', 'balanceResponsible': 'Some One', 'balanceSupplier': 'Other Supplier', 'ancillaryServiceProvider': 'Some Provider'},
      {'id': '3', 'eligibleForSwitch': false, 'startDate': '2008-01-26T00:00:00Z', 'balanceResponsible': 'Some One Else', 'balanceSupplier': 'Some Supplier', 'ancillaryServiceProvider': 'Different Provider'},
    ]

  },

};
