<mat-drawer-container class="inner-nav-container" *ngIf="_consumptionData != undefined">
  <mat-drawer mode="side" #filter class="inner-nav-nav filter-panel" [opened]="opened">
    <h2>{{'companyClient.consumption.filterHeadline' | translate}}</h2>
    <filter-tree [treeData]="filterTree" (selectedNodes)="onNodesSelected($event)"></filter-tree>
  </mat-drawer>
  <mat-drawer-content class="inner-nav-content">
    <div class="consumption-grid">
      <div class="headline-double-button">
        <button mat-mini-fab
                color="accent"
                (click)="filter.toggle()"
                aria-label="Open filter">
          <mat-icon [svgIcon]="filter.opened ? 'chevron-left' : 'chevron-right'"></mat-icon>
        </button>
        <h2>{{'companyClient.consumption.yearlyData' | translate}}</h2>
        <div class="button-stepper">
          <button mat-icon-button (click)="onPreviousYearClick()" [disabled]="yearToDisplay == minimalYear"><mat-icon svgIcon="chevron-left"></mat-icon></button>
          <div>{{yearToDisplay}}</div>
          <button mat-icon-button (click)="onNextYearClick()" [disabled]="currentYear == yearToDisplay"><mat-icon svgIcon="chevron-right"></mat-icon></button>
        </div>
      </div>

      <bar-chart [consumptionData]="_consumptionData" [yearToDisplay]="yearToDisplay" [mpFilter]="mpFilter" [monthToHighlight]="monthToDisplay" (monthSelected)="onMonthSelected($event)"></bar-chart>

      <div class="headline-space-button mt">
        <h2>{{'companyClient.consumption.monthlyData' | translate}}</h2>
        <div class="button-stepper">
          <button mat-icon-button (click)="onPreviousMonthClick()" [disabled]="monthToDisplay == undefined || (monthToDisplay == 0 && yearToDisplay == minimalYear)"><mat-icon svgIcon="chevron-left"></mat-icon></button>
          <div>{{ (monthToDisplay !== undefined ? 'months.'+monthToDisplay : 'Contingents.month') | translate}}</div>
          <button mat-icon-button (click)="onNextMonthClick()" [disabled]="monthToDisplay == undefined || (monthToDisplay == 11 && currentYear == yearToDisplay)"><mat-icon svgIcon="chevron-right"></mat-icon></button>
        </div>
      </div>
      <line-chart [consumptionData]="_consumptionData" [yearToDisplay]="yearToDisplay" [mpFilter]="mpFilter" [monthToDisplay]="monthToDisplay"></line-chart>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
