import {Component} from '@angular/core';
import {CompanypartnersBaseComponent} from "../companypartners-base.component";
@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: []
})
export class AccountComponent extends CompanypartnersBaseComponent {

  onChildInitialized() {
    this.initialized = true;
  }

}
