<page-loading *ngIf="!initialized"></page-loading>

<operator-sidebar-panel *ngIf="isOperatorMode"
                        [mpinfo]="marketPartnerInfo"
                        [upinfo]="companyPartnerInfo"
                        [activeViewMode]="'webclient'"></operator-sidebar-panel>

<div *ngIf="initialized && marketPartnerInfo" id="page-content"
     [class.page-content-with-sidebar]="isOperatorMode"
>
  <div class="before-panel-info">
    <div class="row">
      <div class="col-sm-8">
        <div class="before-panel-title">
          {{'CommunicationsWebclientTitleManage' | translate}}
        </div>
      </div>
    </div>
  </div>

  <div class="panel panel-bordered panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">{{'CommunicationsChannelForExchangeProcesses' | translate}}</h3>

    </div>
    <div class="panel-body">
      <div class="row">

        <div class="col-xs-12">
          <div class="category-container">
            <div *ngIf="shortCutEnabledState; else shortCutsDisabled">
              <p *ngIf="true">{{'CommunicationsSDATWebClientShortCutsActivated' | translate}}</p>
            </div>
            <ng-template #shortCutsDisabled>
              <div>
                <p>{{'CommunicationsSDATWebClientExplanation' | translate}}</p>
                <p class="text-danger"
                      *ngIf="canAccessMeteringPointsUploadState">{{'CommunicationsSDATWebClientShortCutsDisabled' | translate}}</p>
                <p>{{'CommunicationsSDATWebClientSelectFromList' | translate}}</p>
                <div class="panel-form-container">
                  <smart-form *ngIf="sdatProcessForAllFormConfig"
                              #sdatProcessForAllForm
                              (onFieldValueChanged)="changedCheckboxSdatProcessForAll($event)"
                              [formConfig]=sdatProcessForAllFormConfig></smart-form>
                </div>
                <div class="panel-form-container">
                  <smart-form *ngIf="sdatProcessFormConfig"
                              #sdatProcessForm
                              (onFieldValueChanged)="changedSelectionSdatProcess($event)"
                              [formConfig]=sdatProcessFormConfig></smart-form>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
