<page-loading *ngIf="!initialized"></page-loading>

<div *ngIf="initialized" id="page-content">

  <div class="row" *ngIf="downloadProgress$ | async as progress">
    <div class="col-sm-12">
      <div class="tab-base">
        <div class="tab-content">
          <h4>{{ 'MeteringPoints.ExportStatusTitle' | translate }}</h4>
          {{ 'MeteringPoints.ExportStatusDownloadingText' | translate: progress }}
          <div class="progress progress-lg">
            <div class="progress-bar progress-bar-success" [ngStyle]="{width: progress.percentage+'%'}">
              {{progress.percentage | number:'1.0-0'}}%
            </div>
          </div>
          <button (click)="cancelExport()" class="btn-labeled  btn table-action-button btn-danger" style="margin-top: 12px;">
            {{'MeteringPoints.ExportCancelButtonText' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="importStatus$ | async as importStatus">
    <div class="row" *ngIf="importStatus.status !== 'NONE'">
      <div class="col-sm-12">
        <div class="tab-base">
          <div class="tab-content">
            <h4>{{ 'MeteringPoints.ImportStatusTitle' | translate }}</h4>
            {{ getImportStatusText(importStatus) | translate: importStatus }}
            <span *ngIf="importStatus.not_updating">
              {{ 'MeteringPoints.ImportStatusNotUpdatingText' | translate }}
            </span>
            <div *ngIf="importStatus.percentage !== undefined" class="progress progress-lg">
              <div class="progress-bar progress-bar-success" [ngStyle]="{width: importStatus.percentage+'%'}">
                {{importStatus.percentage | number:'1.0-0'}}%
              </div>
            </div>

            <div *ngIf="importStatus.result">
              <button (click)="showImportResult(importStatus)" class="btn-labeled  btn table-action-button btn-primary" style="margin-top: 12px;">
                {{'MeteringPoints.ImportResultButton' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base no-botton-margin">
        <!--Nav tabs-->
        <ul *ngIf="this.tableUnsuppliedConfig" class="nav nav-tabs tabs-left">
          <li class="active">
            <a data-toggle="tab" href="#meteringpoints-tab-1" >
              {{'MeteringPoints.TabAll' | translate}}
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#meteringpoints-tab-2" >
              {{'MeteringPoints.TabUnsupplied' | translate}}
              <span class="badge"
                [class.badge-warning]="(unsuppliedCount$ | async) !== 0"
                [class.badge-default]="(unsuppliedCount$ | async) === 0"
              >
                {{ unsuppliedCount$ | async }}
              </span>
            </a>
          </li>
        </ul>

        <!--Tabs Content-->
        <div class="tab-content">
          <div id="meteringpoints-tab-1" class="tab-pane fade active in">
            <smart-table #tableAll
              [tableConfig]=tableAllConfig
            ></smart-table>
          </div>
          <div id="meteringpoints-tab-2" class="tab-pane fade">
            <smart-table #tableUnsupplied
              [tableConfig]=tableUnsuppliedConfig
            ></smart-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
