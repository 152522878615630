import Dexie from 'dexie';
import { Injectable } from "@angular/core";

@Injectable()
export class DexieService extends Dexie {
  constructor() {
    super('dbapp');
    this.version(1).stores({
      userEntities: '[id+userId]',
      sessionsInfo: 'userId',
      currentSession: 'id'
    });
  }
}
