import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {AuthGuard} from './shared/session/auth-guard.service';

 const routes: Routes = [
   {path: 'users', canActivate: [AuthGuard], loadChildren: () => import('./users/users.module').then(m => m.UsersModule)},
   {path: 'company', canActivate: [AuthGuard], loadChildren: () => import('./companypartners/companypartners.module').then(m => m.CompanypartnersModule)},
   {path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},
   {path: '**', redirectTo: 'users', pathMatch: 'full'},
];


@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
