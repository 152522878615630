<page-loading *ngIf="!initialized"></page-loading>

<operator-sidebar-panel *ngIf="isOperatorMode"
  [mpinfo]="marketPartnerInfo"
  [upinfo]="companyPartnerInfo"
  [activeViewMode]="'notifications'"></operator-sidebar-panel>
<div *ngIf="initialized" id="page-content"
  [class.page-content-with-sidebar]="isOperatorMode"
>
  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base  no-botton-margin-margin">

        <div id="dropdown-create" class="dropdown app-dropdown" *ngIf="!isOperatorMode">
          <button class="btn-labeled btn table-action-button btn-danger" style="margin-top: 12px;" data-toggle="dropdown">
            <i class="pli-trash"></i>
            <span class="button-text">{{'DeleteNotificationsCreateDropdown' | translate}}</span>
            <i class="dropdown-caret"></i>
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let button of createButtons">
              <a (click)="button.fnClicked()" class="btn-a">{{button.label | translate}}</a>
            </li>
          </ul>
        </div>

        <!--Nav tabs-->
        <!--Tabs Content-->
        <div class="tab-content">
          <div class="tab-pane fade active in">
            <smart-table #tableNotificationsOwned
              [tableConfig]="tableNotifications"
              (onRowClicked)="onRowClicked($event)"
              (onActionClicked)="onActionPerformed($event)"
            ></smart-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
