/* eslint-disable @angular-eslint/component-selector */
import {Component, OnInit} from '@angular/core';
import {SmartModalService} from '../service/smart-modal.service';
import {UtilsService} from '../../../../services/utils.service';
import {SmartModalButtonDefinition} from '../classes/SmartModalButtonDefinition';
import {ActionEvent} from '../../smart-shared/classes/ActionEvent';
import {SmartModalConfigDefinition} from '../classes/SmartModalConfigDefinition';
import {NameValueMap} from '../../smart-shared/classes/KeyValueMap';
import {FieldValueChangedEvent} from '../../smart-shared/classes/FieldValueChangedEvent';
declare var $: any;

@Component({
  selector: 'smart-modal',
  templateUrl: './smart-modal.component.html',
  styleUrls: ['./smart-modal.component.scss']
})

export class SmartModalComponent implements OnInit {
  hideContent = true;
  invisibleContent = true;
  refreshingModal = false;
  options?: SmartModalConfigDefinition;
  dataBackdrop = '';
  dataKeyboard = '';
  selectedActionId = '';
  disableAllActions = false;
  currentModalId  = '';
  modalHidden = true;

  constructor(
    public utilsService: UtilsService,
    private smartModalService: SmartModalService) {
    this.currentModalId =  'modal-tmp-'  + this.utilsService.getRandomUID();
  }

  ngOnInit(): void {
    const self = this;

    this.smartModalService.onShowModal.subscribe(
      (value: SmartModalConfigDefinition) => {
        self.options = value;
        self.hideContent = true;
        self.invisibleContent = true;
        self.dataBackdrop = 'true';
        self.dataKeyboard = 'true';

        if ( self.options &&  self.options.preventBackgroundClose) {
          self.dataBackdrop = 'static';
          self.dataKeyboard = 'false';
        }

        self.currentModalId =  'modal-tmp-'  + this.utilsService.getRandomUID();
        self.refreshingModal = true;
        self.modalHidden = false;

        setTimeout(() =>  {
          self.refreshingModal = false;
          self.hideContent = false;
          self.invisibleContent = false;
          self.selectedActionId = '';
          self.disableAllActions = false;
          setTimeout(() => {
            $('#' + self.currentModalId).modal('show');
            $('#' + self.currentModalId).on('hidden.bs.modal', () => self.onModalHidden());
          } , 0 );
        }, 0 );
      }
    );
  }

  onSuccessFormValue(formValue: NameValueMap<any>) {
    if (!this.options) {
      return;
    }

    if (this.options.closeOnSuccess) {
      this.hideModal(formValue);
    }
    if (typeof this.options.onSuccessFormValue === 'function') {
      this.options.onSuccessFormValue(formValue);
    }
  }

  onSuccessResponseValue(responseData: any) {
    if (!this.options) {
      return;
    }

    if (typeof this.options.onSuccessResponseValue === 'function') {
      this.options.onSuccessResponseValue(responseData);
    }
  }

  onSubmittedFormValue(formValue: NameValueMap<string>) {
    if (!this.options) {
      return;
    }

    if (typeof this.options.onSubmittedFormValue === 'function') {
      this.options.onSubmittedFormValue(formValue);
    }
  }
  onInitFormValue(formValue: NameValueMap<string>) {
    if (!this.options) {
      return;
    }

    if (typeof this.options.onInitFormValue === 'function') {
      this.options.onInitFormValue(formValue);
    }
    this.invisibleContent = false;
    $('#' + this.currentModalId).modal('show');
  }

  onFieldValueChanged(event: FieldValueChangedEvent) {
    if (!this.options) {
      return;
    }

    if (typeof this.options.onFieldValueChanged === 'function') {
      this.options.onFieldValueChanged(event);
    }
  }

  cancelClicked() {
    this.hideModal();
  }

  closeClicked() {
    this.hideModal();
  }

  onModalHidden() {
    if (this.modalHidden) return;
    this.modalHidden = true;
    if (this.options && this.options.onModalClosed) {
      this.options.onModalClosed(undefined);
    }
  }

  hideModal(result?: NameValueMap<string>) {
    this.modalHidden = true;
    if (this.options && this.options.onModalClosed) {
      this.options.onModalClosed(result);
    }
    $('#' + this.currentModalId).modal('hide');
  }

  onActionFormValue(actionEvent: ActionEvent) {
    if (!this.options) {
      return;
    }

    if (typeof this.options.onActionSelected === 'function') {
      this.options.onActionSelected(actionEvent.actionId);
    }
  }

  actionButtonClick(actionButton: SmartModalButtonDefinition) {
    if (!this.options) {
      return;
    }

    if (typeof this.options.onActionSelected === 'function') {
      this.options.onActionSelected(actionButton.id);
    }

    this.selectedActionId = actionButton.id;
    this.disableAllActions = true;

    switch (actionButton.id) {
      case 'cancel':
        this.cancelClicked();
        break;
      case 'close':
        this.closeClicked();
        break;
      case 'confirm':
        this.onSuccessFormValue({});
        break;
    }
  }

  isAnyButtonVisible() {
    if (!this.options) {
      return;
    }

    let toReturn = false;
    if (this.options.buttons) {
      this.options.buttons.forEach(function(thisButtonInfo: SmartModalButtonDefinition) {
        if ( thisButtonInfo.visible !== false) {
          toReturn = true;
        }
      });
    }
    return toReturn;
  }
}
