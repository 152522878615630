/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';
import {SessionService} from '../../shared/session/session.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';

import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SmartFormBuilder} from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import {SmartFormConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormConfigDefinition';

import {SmartFormComponent} from '../../shared/marketpartner-components/smart-forms/smart-form/component/smart-form.component';
import {CustomTranslateService} from '../../shared/services/custom-translate.service';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';

import {NotificationsViewComponent} from '../shared/notifications-view.component';
import { SmartToolbarActionDefinition } from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/SmartToolbarActionDefinition';

@Component({
  selector: 'page-users-notifications-info-content',
  templateUrl: './users-notifications-info.component.html',
  styleUrls: ['../users-common.scss', './users-notifications-info.component.scss']
})


export class NotificationsInfoComponent extends NotificationsViewComponent  {
  @ViewChild('headerForm') headerForm?: SmartFormComponent;

  messageUuid = '';
  initialized = false;
  headerFormConfig?: SmartFormConfigDefinition;
  userMessages?: any[];
  originalMessageAvailable: boolean = false;
  actions: SmartToolbarActionDefinition[] = [];
  lang = this.customTranslateService.getCurrentLangShort();

  constructor(public customTranslateService: CustomTranslateService,
              public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              public apiRequestService: ApiRequestService,
              public router: Router,
              public translate: TranslateService) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);

    if (this.partnerId !== undefined && params.messageUuid) {
      this.messageUuid = params.messageUuid;

      const response = await this.apiRequestService.callApi(
        new ApiRequestBuilder().setEndpointInfo(
          USERS_API_ENDPOINTS_LIST.notificationInfo, {partnerId: this.partnerId, messageUuid: this.messageUuid}
        ).setHandleErrorNotification(false).build()  // see below for why we don't report errors
      );
      if (response.status !== 'success') {
        // e.g. when switching to a different partner (or using an old bookmark), the
        // backend doesn't return "access denied" or similar, just 500 "internal server error"
        this.goBackToNotifications();
        return;
      }
      const data = response.data;
      data.messageMetadata.senderEic = await this.fetchPartnerDescription(data.messageMetadata.senderId);
      data.messageMetadata.recipientEic = await this.fetchPartnerDescription(data.messageMetadata.recipientId);

      this.userMessages = data.userMessage;
      this.originalMessageAvailable = data.originalMessageAvailable;

      this.headerFormConfig = new SmartFormBuilder()
        .addReadOnly({
          name: 'errorCreationTimestamp',
          title: 'ColumnNotificationsTimestampCreated',
          type: 'datetimeIso',
        })
        .addReadOnly({
          name: 'messageMetadata:timestamp',
          title: 'ColumnNotificationsTimestampMessage',
          type: 'datetimeIso',
        })
        .addReadOnly({
          name: 'messageMetadata:fileName',
          title: 'ColumnNotificationsFileName',
        })
        .addReadOnly({
          name: 'messageMetadata:size',
          title: 'ColumnNotificationsSize',
        })
        .addReadOnly({
          name: 'messageMetadata:senderEic',
          title: 'ColumnNotificationsSenderEIC',
        })
        .addReadOnly({
          name: 'messageMetadata:recipientEic',
          title: 'ColumnNotificationsRecipientEIC',
        })
        .addReadOnly({
          name: 'messageMetadata:messageUuid',
          title: 'ColumnNotificationsMessageUUID',
        })
        .setInitData(data)
        .showSubmitButton(false)
        .showCancelButton(false)
        .build();

      this.actions = [{
        title: 'ActionNotificationDelete', id: '',
        icon: 'pli-trash',
        className: 'btn btn-danger',
        confirmationDialog: {
          title: 'ActionNotificationDelete',
          body: 'AreYouSureConfirmationBodyDeleteNotification',
          confirmButtonText: 'ButtonDelete'
        },
        fnClicked: async () => {
          await this.deleteNotification(this.messageUuid);
          this.goBackToNotifications();
        },
        fnEnabled: () => {
          return ((this.partnerId === 0) === (this.sessionService.isOperator()));
        },
      }, {
        title: 'ActionNotificationDownload', id: '',
        icon: 'pli-download',
        className: 'btn btn-success',
        fnEnabled: (_data: any) => {
          return ((this.partnerId === 0) === (this.sessionService.isOperator()))
            && this.originalMessageAvailable;
        },
        fnClicked: () => this.download(this.messageUuid, data.messageMetadata.fileName),
      }, {
        title: 'ActionNotificationBack', id: '',
          className: 'btn btn-success',
          fnClicked: () => this.goBackToNotifications(),
      }];
    }

    this.setInitialized(true);

    // opening the detail view automatically marks as read
    this.setNotificationRead(this.messageUuid, true);
  }


  private async fetchPartnerDescription(partnerId?: number) {
    if (partnerId === 0) {
      return this.translate.instant('LabelDatahubPartnerId');
    }
    if (!partnerId) return '-';
    const condition = {columnName: 'partnerId', operator: 'EQ', values: [partnerId]};
    const filter = {query: { andConditions: [{orConditions: [condition]}],
                             pageRequest: {pageIndex: 0, pageSize: 1}}};
    const response = await this.apiRequestService.doApiRequest(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryContactSearch, filter);
    if (response.status === 'success') {
      const partners = response.data.partners;
      if (partners.length === 1) {
        const eic = partners[0].eic;
        let role = partners[0].role;
        role = this.translate.instant('LabelMarketPartnerRole.' + role);
        return  eic + ' / ' + role;
      } else if (partners.length === 0) {
        return this.translate.instant('LabelUnknownPartnerId');
      }
    }
    return 'ERROR while resolving partnerId ' + partnerId;
  }

  download(messageUuid: string, filename: string) {
    console.log('download messageUuid:', messageUuid);
    this.apiRequestService.downloadFile({
      ...USERS_API_ENDPOINTS_LIST.notificationDownload,
      replaceUrl: {messageUuid}
    }, {
      Filename: filename
    });
  }
}
