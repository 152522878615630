/* eslint-disable max-len */
import {SmartFormLayoutLabelDefinitionContainer} from '../classes/SmartFormLayoutLabelDefinition';

export const SMART_FORM_LAYOUTLABEL_DEFS: SmartFormLayoutLabelDefinitionContainer[] = [
  {
    id: 'pageLayout',
    definition: {
      label: 'col-4',
      input: 'col-4',
      formGroupContainer: ' row '
    },
  },
];
